// TODO: 金額系の計算 (usePrice, useAmount)などをここにまとめる

import prices from "constants/prices";

const getPrice = (schoolMinutes: number): number => {
  if (schoolMinutes > 120) {
    return prices.lesson3time;
  }
  if (schoolMinutes > 60) {
    return prices.lesson2time;
  }
  return prices.lesson1time;
};

export default getPrice;
