import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useAtom } from "jotai";

import FormLabel from "components/atoms/FormLabel";
import { isAcceptAtom, teacherObjectAtom } from "store/RequestShowStore";

import type React from "react";

const AcceptSelectField: React.VFC = () => {
  const [isAccept, setIsAccept] = useAtom(isAcceptAtom);
  const [teacherObject] = useAtom(teacherObjectAtom);
  const theme = useTheme();

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setIsAccept(value === "true");
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box sx={{ flex: 1 }}>
        <FormLabel labelName="授業の可否" isRequired />
      </Box>
      <FormControl fullWidth>
        <RadioGroup
          row
          aria-label="can-interview"
          name="can-interview"
          value={String(isAccept)}
          onChange={handleSelectChange}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <FormControlLabel
              value="true"
              disabled={!teacherObject.is_identification}
              control={<Radio color="primary" />}
              label="承諾する"
              sx={{
                width: "100%",
                margin: 0,
                padding: "6px",
                border: `1px solid ${theme.palette.muted?.main}`,
                borderRadius: "6px",
                boxSizing: "border-box",
                backgroundColor: teacherObject.is_identification
                  ? theme.palette.common.white
                  : theme.palette.backgroundColor?.main
              }}
            />
            <FormControlLabel
              value="false"
              disabled={!teacherObject.is_identification}
              control={<Radio color="primary" />}
              label="辞退する"
              sx={{
                width: "100%",
                margin: 0,
                padding: "6px",
                border: `1px solid ${theme.palette.muted?.main}`,
                borderRadius: "6px",
                boxSizing: "border-box",
                backgroundColor: teacherObject.is_identification
                  ? theme.palette.common.white
                  : theme.palette.backgroundColor?.main
              }}
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default AcceptSelectField;
