import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import ToContactButton from "features/schools/components/atoms/mypage/AiTeachingPlan/ToContactButton";
import ToTeachersIndexButton from "features/schools/components/atoms/mypage/AiTeachingPlan/ToTeachersIndexButton";
import { teachingPlanResponseAtom } from "store/AiTeachingPlanStore";

import type React from "react";

const AiTeachingPlanDisplay: React.FC = () => {
  const theme = useTheme();
  const teachingPlanResponse = useAtomValue(teachingPlanResponseAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Typography variant="h3">AIによる授業内容のご提案</Typography>
      <Typography fontSize=".9rem" color={theme.palette.sub.main}>
        こちらはChatGPTのモデルを利用して生成されたのものになっています。
        <br />
        文章が不十分な点もありますが、授業内容を考える際の参考になれば幸いです。
      </Typography>
      <Typography sx={{ whiteSpace: "pre-wrap" }}>
        {teachingPlanResponse}
      </Typography>
      <ToTeachersIndexButton />
      <ToContactButton />
    </Box>
  );
};

export default AiTeachingPlanDisplay;
