import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type sectionTitleProps = {
  mainTitle: string;
  subTitle?: string;
};

const SectionTitle: React.FC<sectionTitleProps> = (props) => {
  const { mainTitle, subTitle } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "0 .75rem",
        borderLeft: `4px solid ${theme.palette.primary.main}`
      }}
    >
      <Typography variant="h2">{mainTitle}</Typography>
      {subTitle && <Typography>{subTitle}</Typography>}
    </Box>
  );
};

export default SectionTitle;
