import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import FormTextArea from "components/molecules/FormTextArea";
import { editAuthAtom } from "store/MeetingSheetStore";
import { useAtomValue } from "jotai";

const PreparationFields: React.FC = () => {
  const theme = useTheme();
  const editAuth = useAtomValue(editAuthAtom);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Typography variant="h3">当日までの準備</Typography>
      <FormTextArea
        label="【学校側】授業当日までに準備しておくこと"
        placeholder="例：生徒への事前共有（どんな人が来るか話しておく）など"
        name="school_advance_todo"
        noLabel
        readOnly={!editAuth?.has_auth}
      />
      <FormTextArea
        label="【複業先生側】授業当日までに準備しておくこと"
        placeholder="例：授業用スライドの作成、学校の先生に共有など"
        name="teacher_advance_todo"
        noLabel
        readOnly={!editAuth?.has_auth}
      />
      <FormTextArea
        label="その他 メモ欄"
        placeholder="その他、メモがあればこちらにどうぞ"
        name="lesson_memo"
        noLabel
        readOnly={!editAuth?.has_auth}
      />
    </Box>
  );
};

export default PreparationFields;
