import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

import AcceptsBox from "features/requests/components/molecules/new/AcceptsBox";
import usePrice from "features/requests/hooks/usePrice";

import type React from "react";
import FormSubmitButton from "components/molecules/FormSubmitButton";

type PriceBoxProps = {
  handleSubmit: () => void;
};

const PriceBox: React.FC<PriceBoxProps> = () => {
  const theme = useTheme();

  const { basicCharge, totalAmount, selectedLessonOptions } = usePrice();

  return (
    <Box
      sx={{
        height: "100%",
        position: { xs: "static", md: "relative" },
        padding: { xs: 0, md: "4.6rem 0 0" }
      }}
    >
      <Box
        sx={{
          backgroundColor: { xs: "#FFF5DD", md: theme.palette.common.white },
          padding: "1rem",
          margin: { xs: "2rem auto", md: "0 0 0 auto" },
          position: { xs: "static", md: "sticky" },
          top: { xs: "auto", md: 180 },
          width: { xs: "auto", md: "90%" }
        }}
      >
        <div>
          <Typography sx={{ color: theme.palette.sub.main }}>
            [ 基本料金 ]
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: ".2rem auto"
            }}
          >
            <Typography>{basicCharge.label}</Typography>
            <Typography>¥{basicCharge.price.toLocaleString()}</Typography>
          </Box>
        </div>
        <div>
          <Typography sx={{ color: theme.palette.sub.main }}>
            [ オプション料金 ]
          </Typography>
          {selectedLessonOptions.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: ".2rem auto"
              }}
            >
              <Typography>選択されていません</Typography>
              <Typography>¥-</Typography>
            </Box>
          ) : (
            selectedLessonOptions.map((selectedLessonOption, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: ".2rem auto"
                }}
              >
                <Typography>{selectedLessonOption.name}</Typography>
                <Typography>
                  ¥{selectedLessonOption.amount.toLocaleString()}
                </Typography>
              </Box>
            ))
          )}
        </div>
        <Divider />
        <div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: ".5rem auto"
            }}
          >
            <Typography>合計（税抜）</Typography>
            <Typography fontSize="1.5rem" fontWeight="bold">
              ¥{totalAmount.toLocaleString()}
            </Typography>
          </Box>
          <Typography fontSize=".9rem">
            授業料支払いについて：後日、授業実施後の請求になります。依頼が承諾されず、授業成立しなかった場合はこの授業料は発生しません。
          </Typography>
        </div>
        <div>
          <AcceptsBox />
          <FormSubmitButton label="依頼を送信する" fullWidth />
        </div>
      </Box>
    </Box>
  );
};

export default PriceBox;
