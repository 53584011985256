import Rating from "@mui/lab/Rating";

import { ReactComponent as RatingStarIcon } from "assets/Star.svg";
import { ReactComponent as RatingStarEmptyIcon } from "assets/StarEmpty.svg";

import type React from "react";

type RatingDisplayType = {
  rating: number;
  size?: string;
  precision?: number;
};

const RatingDisplay: React.FC<RatingDisplayType> = (props) => {
  const { rating, size, precision } = props;

  return (
    <Rating
      readOnly
      icon={
        <RatingStarIcon
          fontSize="inherit"
          width={size || "1.2rem"}
          height={size || "1.2rem"}
        />
      }
      emptyIcon={
        <RatingStarEmptyIcon
          fontSize="inherit"
          width={size || "1.2rem"}
          height={size || "1.2rem"}
        />
      }
      precision={precision || 1}
      color="default"
      value={rating}
      sx={{
        ".MuiRating-icon": {
          margin: "0 .1rem"
        }
      }}
    />
  );
};

export default RatingDisplay;
