import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import type React from "react";

type TagsProps = {
  tags: {
    id: number;
    name: string;
  }[];
};

const Tags: React.FC<TagsProps> = (props) => {
  const { tags } = props;
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag.name}
          variant="outlined"
          color="default"
          sx={{
            margin: { xs: "0 .25rem", sm: "1rem .5rem 0" },
            height: "24px",
            borderRadius: "12px",
            backgroundColor: theme.palette.backgroundColor?.main,
            borderColor: theme.palette.muted?.main,
            color: theme.palette.sub.main
          }}
        />
      ))}
    </Box>
  );
};

export default Tags;
