import { atom } from "jotai";

import type { teacherRequestsResponseType } from "types/LessonRequestTypes";

export type kindOfTeacherLessonsType =
  | "lesson_end"
  | "preparation"
  | "accepted";

export const teacherLessonsAtom = atom<teacherRequestsResponseType | null>(
  null
);

export const kindOfTeacherLessonsAtom =
  atom<kindOfTeacherLessonsType>("accepted");
