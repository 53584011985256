import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";

import type React from "react";

import { LessonRequestTeacher } from "api/@types";

type SchoolInfoBlock01Props = {
  school?: LessonRequestTeacher["send_user"];
};

const SchoolInfoBlock01: React.FC<SchoolInfoBlock01Props> = (props) => {
  const { school } = props;
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        {school?.image.url !== "no_image" ? (
          <Box
            component="img"
            src={school?.image.url}
            alt={`${school?.name} avatar`}
            sx={{
              width: { xs: "90px", sm: "120px" },
              height: { xs: "90px", sm: "120px" },
              objectFit: "cover",
              borderRadius: 4,
              display: "block"
            }}
          />
        ) : (
          <Avatar
            alt={school?.name}
            src={school?.image.url}
            sx={{
              width: { xs: "90px", sm: "120px" },
              height: { xs: "90px", sm: "120px" },
              fontSize: "36px",
              margin: ".5rem auto"
            }}
          />
        )}
      </Grid>
      <Grid item sx={{ margin: "0 1rem" }}>
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          {school?.school_name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography fontWeight="bold">{school?.name}</Typography>
          {school?.is_match_accepted && (
            <Box
              component="img"
              src={SchoolCertificationBadge}
              alt="認定校"
              sx={{
                display: "block",
                margin: "0 .5rem",
                // MEMO: 解像度を合わせるための調整
                width: "80px"
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SchoolInfoBlock01;
