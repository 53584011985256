import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import RatingDisplay from "features/teachers/components/atoms/show/RatingDisplay";
import useFormatDate from "hooks/useFormatDate";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type React from "react";

type RatingListItemProps = {
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  category: string;
  rating: number;
  reviewer: string;
  contents: string;
  createAt: string;
};

const RatingListItem: React.FC<RatingListItemProps> = (props) => {
  const { Icon, category, rating, reviewer, contents, createAt } = props;

  const theme = useTheme();
  const { getFormatDate } = useFormatDate();

  return (
    <Box
      sx={{
        margin: "0 auto",
        width: "100%",
        display: { xs: "block", md: "flex" },
        gap: "0 1rem"
      }}
    >
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0 .5rem"
          }}
        >
          <Icon />
          <Typography>{category}</Typography>
          <RatingDisplay rating={rating} precision={1} />
        </Box>
        <Typography
          color={theme.palette.sub.main}
          fontSize=".8rem"
          sx={{
            margin: ".2rem auto"
          }}
        >
          {getFormatDate(new Date(createAt))}
        </Typography>
      </div>
      <Typography fontSize=".9rem" sx={{ flex: 1 }}>
        {`${contents}（${reviewer}）`}
      </Typography>
    </Box>
  );
};

export default RatingListItem;
