import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type { Theme } from "@mui/material/styles";
import type React from "react";

type TitleBlockProps = {
  title: string;
  description?: string;
  Button?: React.ReactNode;
};

// TODO: 他のセクションで利用する際に都度調整
const TitleBlock: React.FC<TitleBlockProps> = (props) => {
  const { title, description, Button } = props;
  const inXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          gap: "1rem"
        }}
      >
        <Typography variant="h1">{title}</Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </Box>
      {!inXs && Button}
    </Box>
  );
};

export default TitleBlock;
