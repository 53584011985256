import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Axios from "axios";
import { useAtom } from "jotai";
import { useState } from "react";

import ErrorAlert from "components/atoms/ErrorAlert";
import SuccessAlert from "components/atoms/SuccessAlert";
import Meta from "components/organisms/Meta";
import Form from "features/root/components/organisms/contact/Form";
import CommonLayout from "components/layouts/CommonLayout";
import {
  ContactFormKeysDevelopment,
  ContactCorsProxyLocal,
  ContactFormKeysProduction,
  ContactCorsProxyNetwork
} from "constants/ContactFormKeys";
import {
  lastNameAtom,
  firstNameAtom,
  lastNameKanaAtom,
  firstNameKanaAtom,
  emailAtom,
  contactTypeAtom,
  contactContentsAtom
} from "store/ContactFormStore";

import type React from "react";

const Contact: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastNameKana, setLastNameKana] = useAtom(lastNameKanaAtom);
  const [firstNameKana, setFirstNameKana] = useAtom(firstNameKanaAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [contactType, setContactType] = useAtom(contactTypeAtom);
  const [contactContents, setContactContents] = useAtom(contactContentsAtom);

  const ContactFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? ContactFormKeysProduction
      : ContactFormKeysDevelopment;
  const ContactCorsProxy =
    process.env.REACT_APP_BUILD_ENV === "development"
      ? ContactCorsProxyLocal
      : ContactCorsProxyNetwork;
  const theme = useTheme();

  const handleSubmit = () => {
    const submitParams = new FormData();
    submitParams.append(ContactFormKeys.name, lastName + firstName);
    submitParams.append(ContactFormKeys.nameKana, lastNameKana + firstNameKana);
    submitParams.append(ContactFormKeys.email, email);
    submitParams.append(ContactFormKeys.contactType, contactType);
    submitParams.append(ContactFormKeys.contactContents, contactContents);

    Axios.post(ContactCorsProxy + ContactFormKeys.action, submitParams)
      .then((response) => {
        console.log(response);
        setLastName("");
        setFirstName("");
        setLastNameKana("");
        setFirstNameKana("");
        setEmail("");
        setContactType("");
        setContactContents("");
        setIsSuccess(true);
      })
      .catch((error) => {
        console.log(error.response);
        setIsError(true);
      });
  };

  return (
    <CommonLayout>
      <Meta
        title="複業先生 | お問い合わせ"
        description="複業先生の使い方やシステムの利用についてなど、不明なことがありましたら、こちらのフォームからお問い合わせください。"
      />
      <ErrorAlert isError={isError} setIsError={setIsError} />
      <SuccessAlert
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        successMessage="お問い合わせを受け付けました。"
      />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="sm"
          sx={{
            padding: "1.5rem 1rem"
          }}
        >
          <Box
            sx={{
              marginBottom: "1.5rem"
            }}
          >
            <Typography variant="h1">お問い合わせ</Typography>
          </Box>
          <Form handleSubmit={handleSubmit} />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default Contact;
