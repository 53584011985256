import QreSheetLink from "features/requests/components/molecules/show/QreSheetLink";
import Step03Text from "features/teachers/components/molecules/show/requests/Step03Text";
import RatingDislay from "features/requests/components/organisms/Show/RatingDisplay";

import type React from "react";

import { LessonRequestTeacher, RatingGet } from "api/@types";

type Step04Props = {
  details?: LessonRequestTeacher["details"];
  ratingDetails?: RatingGet["details"];
};

const Step04: React.FC<Step04Props> = (props) => {
  const { details, ratingDetails } = props;

  const isRating =
    ratingDetails?.lesson_rating || ratingDetails?.teacher_rating;

  return (
    <div>
      <Step03Text />
      <div>
        <QreSheetLink details={details} />
        {isRating && <RatingDislay ratingDetails={ratingDetails} />}
      </div>
    </div>
  );
};

export default Step04;
