import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import type React from "react";

type CommonDialogWithCloseButtonProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullWidth?: boolean;
};

const CommonDialogWithCloseButton: React.FC<
  CommonDialogWithCloseButtonProps
> = (props) => {
  const { open, setOpen, children, maxWidth, fullWidth } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth={maxWidth || "md"}
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth || false}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px",
          zIndex: 1600
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 6,
            top: 6
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          padding: "1rem 2rem",
          height: "100%"
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CommonDialogWithCloseButton;
