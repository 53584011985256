import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import type { SelectChangeEvent } from "@mui/material";

import RequireFormLabel from "features/schools/components/atoms/mypage/AiTeachingPlan/RequireFormLabel";
import { schoolGradesAtom, schoolGradeIdAtom } from "store/AiTeachingPlanStore";

import type React from "react";

type FieldProps = {
  isLoading: boolean;
};

const SchoolGradesField: React.FC<FieldProps> = (props) => {
  const { isLoading } = props;
  const [schoolGrades] = useAtom(schoolGradesAtom);
  const [schoolGradeId, setSchoolGradeId] = useAtom(schoolGradeIdAtom);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number | null>) => {
    setSchoolGradeId(Number(event.target.value));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <RequireFormLabel labelName="授業を受ける学年" />
      <FormControl
        variant="outlined"
        fullWidth
        sx={{
          "& > .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.advanced?.main} !important`
          }
        }}
      >
        <Select
          value={schoolGradeId}
          onChange={handleChange}
          disabled={isLoading}
        >
          {schoolGrades.map((schoolGrade) => (
            <MenuItem key={schoolGrade.name} value={schoolGrade.id}>
              {schoolGrade.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SchoolGradesField;
