import Box from "@mui/material/Box";
import { useRef } from "react";

import STEAMImage from "assets/lpClassi/STEAM.png";
import Overview from "features/root/components/molecules/lpClassi/Overview";
import useFilterTeachers from "hooks/useFilterTeachers";

import type React from "react";
import LessonExample from "./LessonExample";
import LessonIdeas from "./LessonIdeas";

const STEAM: React.FC = () => {
  // MEMO: 基本的に複数回のレンダリングが必要ないと目されるため。
  const teacherWithVideoIdsRef = useRef([953, 936, 490]);
  const teacherIdsRef = useRef([784, 928, 245, 953, 936, 843, 490, 1159]);
  const teachersWithVideo = useFilterTeachers(teacherWithVideoIdsRef.current);
  const teachers = useFilterTeachers(teacherIdsRef.current);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem"
      }}
    >
      <Overview
        title="STEAM教育"
        desc="探究活動の導入期に最適。STEAM教育をベースに、実践者の生き方や考え方に触れ、生徒の皆様が自分の探究テーマを見つけるきっかけを作ります。"
        Image={STEAMImage}
      />
      <LessonIdeas title="STEAM教育" teachers={teachersWithVideo} />
      <LessonExample title="STEAM教育" teachers={teachers} />
    </Box>
  );
};

// ファイル名が大文字のためエラーが出るため、一旦無効化
export default STEAM;
