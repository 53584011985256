import Button from "@mui/material/Button";

import type React from "react";

type RequestGrayButtonProps = {
  name: string;
  handleClick: () => void;
};

const RequestGrayButton: React.FC<RequestGrayButtonProps> = (props) => {
  const { name, handleClick } = props;

  return (
    <Button
      onClick={handleClick}
      color="inherit"
      sx={{
        width: "100%",
        margin: "1rem auto"
      }}
    >
      {name}
    </Button>
  );
};

export default RequestGrayButton;
