import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import FAQItem from "features/root/components/molecules/schoolHome/FAQItem";

import type React from "react";

const Faq: React.FC = () => {
  const [expand01, setExpand01] = useState(false);
  const [expand02, setExpand02] = useState(false);
  const [expand03, setExpand03] = useState(false);
  const [expand04, setExpand04] = useState(false);

  return (
    <section>
      <Box
        sx={{
          width: "100%",
          maxWidth: "360px",
          margin: "1.2rem auto"
        }}
      >
        <Typography
          variant="h2"
          align="center"
          color="#FFEFC7"
          fontSize="2.4rem"
        >
          FAQ
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{
            title: "1.5rem",
            margin: "1rem auto .5rem"
          }}
        >
          よくある質問
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", md: "80%" },
          margin: { xs: "1rem auto", md: "2rem auto" },
          maxWidth: { xs: "none", md: "600px" }
        }}
      >
        <FAQItem
          question="どんな授業ができますか？"
          answer="キャリア教育、探求学習、教科学習など、ご希望の学年・内容に合わせて幅広く対応しております。"
          expand={expand01}
          setExpand={setExpand01}
        />
        <FAQItem
          question="どんな人が複業先生登録をしていますか？"
          answer="IT、金融、グローバル、医療、SDGs等様々な分野でご活躍されている方々がご登録されています。登壇前に事務局による面談を経て、学校登壇となっております。"
          expand={expand02}
          setExpand={setExpand02}
        />
        <FAQItem
          question="費用はかかりますか？"
          answer="基本、事務局手数料＋講師謝礼をいただいておりますが、予算に応じた授業内容を提案させていただきますのでご相談ください。"
          expand={expand03}
          setExpand={setExpand03}
        />
        <FAQItem
          question="他社の似たサービスとどんなところがちがいますか？"
          answer="教育業界出身者による授業のコーディネート（申し込みから実施までの伴走）・サポートがあり、初めての方でも安心して授業をご依頼いただけます。"
          expand={expand04}
          setExpand={setExpand04}
        />
      </Box>
    </section>
  );
};

export default Faq;
