import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import SettingsContentsAccordion from "features/teachers/components/molecules/show/settings/SettingsContentsAccordion";
import Meta from "components/organisms/Meta";
import BankAccountContents from "features/teachers/components/organisms/show/settings/BankAccountContents";
import BasicSettingContents from "features/teachers/components/organisms/show/settings/BasicSettingContents";
import BirthdayForm from "features/teachers/components/organisms/show/settings/BirthdayForm";
import ChangeEmailContents from "features/teachers/components/organisms/show/settings/ChangeEmailContents";
import ChangePasswordContents from "features/teachers/components/organisms/show/settings/ChangePasswordContents";
import IdentificationCheckContents from "features/teachers/components/organisms/show/settings/IdentificationCheckContents";
import WithdrawalContents from "features/teachers/components/organisms/show/settings/WithdrawalContents";
import CommonLayout from "components/layouts/CommonLayout";
import { currentUserAtom } from "store/AuthStore";

import type React from "react";
import useSettingsPageApi from "features/teachers/hooks/mypage/useSettingsPageApi";
import { FormProvider } from "react-hook-form";
import SendFamilyRegisterNameModal from "../../organisms/show/settings/SendFamilyRegisterNameModal";

const Settings: React.FC = () => {
  const theme = useTheme();
  const currentUser = useAtomValue(currentUserAtom);
  const {
    updateProfileForm,
    updateProfile,
    profileIsLoading,
    updatePassword,
    updatePasswordForm,
    updateEmail,
    updateEmailForm,
    createBankAccount,
    createBankAccountForm,
    bankAccount,
    bankAccountLoading,
    deleteBankAccount,
    updateBirthdayForm,
    updateBirthday,
    postIdentification,
    sendFamilyRegisterNameForm,
    updateFamilyRegisterName,
    sendFamilyRegisterNameModal
  } = useSettingsPageApi();

  return (
    <CommonLayout>
      <Meta title="複業先生 | アカウント設定" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          boxSizing: "border-box",
          padding: "1.5rem 0",
          minHeight: "100%"
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <Typography variant="h2">アカウント設定</Typography>
          {/* MEMO: サイドバー挿入のために残しておく */}
          <Box
            sx={{
              display: "flex",
              gap: "1.5rem"
            }}
          >
            <Box
              sx={{
                flex: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem"
              }}
            >
              <SettingsContentsAccordion
                title="基本設定"
                label="basic"
                isLoading={profileIsLoading}
              >
                <FormProvider {...updateProfileForm}>
                  <form
                    onSubmit={updateProfileForm.handleSubmit(updateProfile)}
                  >
                    <BasicSettingContents />
                  </form>
                </FormProvider>
              </SettingsContentsAccordion>
              {!currentUser.is_identification && (
                <SettingsContentsAccordion
                  title="本人確認"
                  label="identification-check"
                >
                  {currentUser.has_birthday ? (
                    <IdentificationCheckContents
                      postIdentification={postIdentification}
                      handleModalOpen={sendFamilyRegisterNameModal.onOpen}
                    />
                  ) : (
                    <FormProvider {...updateBirthdayForm}>
                      <form
                        onSubmit={updateBirthdayForm.handleSubmit(
                          updateBirthday
                        )}
                      >
                        <BirthdayForm />
                      </form>
                    </FormProvider>
                  )}
                </SettingsContentsAccordion>
              )}
              {currentUser.is_reward && (
                <SettingsContentsAccordion
                  title="報酬の振込先登録"
                  label="bank-account"
                >
                  <FormProvider {...createBankAccountForm}>
                    <form
                      onSubmit={createBankAccountForm.handleSubmit(
                        createBankAccount
                      )}
                    >
                      <BankAccountContents
                        handleDelete={deleteBankAccount}
                        isLoading={bankAccountLoading}
                        teacherBankAccount={bankAccount?.teacher_bank_account}
                      />
                    </form>
                  </FormProvider>
                </SettingsContentsAccordion>
              )}
              <SettingsContentsAccordion
                title="メールアドレス変更"
                label="change-email"
              >
                <FormProvider {...updateEmailForm}>
                  <form onSubmit={updateEmailForm.handleSubmit(updateEmail)}>
                    <ChangeEmailContents />
                  </form>
                </FormProvider>
              </SettingsContentsAccordion>
              <SettingsContentsAccordion
                title="パスワード変更"
                label="change-password"
              >
                <FormProvider {...updatePasswordForm}>
                  <form
                    onSubmit={updatePasswordForm.handleSubmit(updatePassword)}
                  >
                    <ChangePasswordContents />
                  </form>
                </FormProvider>
              </SettingsContentsAccordion>
              <SettingsContentsAccordion title="退会申請" label="withdrawal">
                <WithdrawalContents />
              </SettingsContentsAccordion>
            </Box>
          </Box>
          <FormProvider {...sendFamilyRegisterNameForm}>
            <SendFamilyRegisterNameModal
              isOpen={sendFamilyRegisterNameModal.isOpen}
              onClose={sendFamilyRegisterNameModal.onClose}
              onSubmit={sendFamilyRegisterNameForm.handleSubmit(
                updateFamilyRegisterName
              )}
            />
          </FormProvider>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default Settings;
