import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import RequireFormLabel from "components/atoms/fieldLabels/RequireFormLabel";
import FormCheckboxGroup from "components/molecules/FormCheckboxGroup";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSelect from "components/molecules/FormSelect";
import dayjs from "dayjs";

import CandidateFieldMenuItem from "features/schools/components/molecules/recruitments/show/CandidateFieldMenuItem";
import ImportantInfoBox from "features/schools/components/molecules/recruitments/show/ImportantInfoBox";
import SelectedCandidateDisplay from "features/schools/components/molecules/recruitments/show/SelectedCandidateDisplay";
import usePrice from "features/schools/hooks/recruitments/usePrice";
import { useAtomValue } from "jotai";

import type React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  candidatesAtom,
  lessonOptionsAtom
} from "store/schools/recruitments/ShowStore";

const Step01: React.FC = () => {
  const { setValue, watch } = useFormContext();
  const { totalPrice } = usePrice();
  const theme = useTheme();
  const candidateId = watch("candidate_id");
  const selectedOptionIds: number[] = watch("lesson_option_ids", []);
  const candidates = useAtomValue(candidatesAtom);
  const lessonOptions = useAtomValue(lessonOptionsAtom);
  const candidatesOptions = candidates.map((candidate) => ({
    value: candidate.details.id,
    label: (
      <CandidateFieldMenuItem
        candidateName={candidate.teacher.name}
        candidateImage={candidate.teacher.image.url}
        candidateRank={candidate.teacher.teacher_rank}
      />
    )
  }));
  const selectedCandidateDisplay = candidates.find(
    (candidate) => candidate?.details?.id.toString() === candidateId?.toString()
  );
  const proposedDateOptions =
    selectedCandidateDisplay?.details.proposed_dates.data?.map((date) => ({
      value: date.id,
      label: dayjs(date.start_at_datetime)
        .locale("ja")
        .format("YYYY年 MM月 DD日 (ddd) HH時mm分")
    })) || [];

  const options = lessonOptions.map((lessonOption) => ({
    label: (
      <>
        <strong>{lessonOption.name}：</strong>
        {lessonOption.amount.toLocaleString()}円
        <Typography
          sx={{ fontSize: { xs: ".9rem", sm: "1rem" } }}
          color={
            lessonOption.id === 5 && !selectedOptionIds.includes(4)
              ? "#7D7670"
              : "inherit"
          }
        >
          {lessonOption.description}
        </Typography>
      </>
    ),
    value: lessonOption.id
  }));

  useEffect(() => {
    // オプション「事務局の有料サポート」が選択されている場合、オプション「複業先生のサポート」を選択できないようにする
    if (!selectedOptionIds.includes(4) && selectedOptionIds.includes(5)) {
      setValue(
        "lesson_option_ids",
        selectedOptionIds.filter((id) => id !== 5)
      );
    }
    // amountの計算
    setValue("amount", totalPrice);
  }, [selectedOptionIds, setValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <FormSelect
        label="複業先生を選択してください"
        name="candidate_id"
        options={candidatesOptions}
        isRequierd
        fullWidth
      />
      <SelectedCandidateDisplay />
      {candidateId ? (
        <FormRadioGroup
          name="proposed_date_id"
          label="授業日を選択してください"
          options={proposedDateOptions}
          fullWidth
          isRequired
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <RequireFormLabel labelName="授業日を選択してください" />
          <Typography color={theme.palette.sub.main} fontSize=".9rem">
            まずは複業先生を選択してください
          </Typography>
        </Box>
      )}
      <FormCheckboxGroup
        name="lesson_option_ids"
        label="オプションを選択してください"
        options={options}
        supplementalText="初めて授業を実施する場合、複業先生がジュニアクラス以下の場合は、事務局の有料サポートを推奨しています。"
        isRequired={false}
        disabledValues={selectedOptionIds.includes(4) ? [] : [5]}
      />
      <ImportantInfoBox />
    </Box>
  );
};

export default Step01;
