import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link as RouterLink } from "react-router-dom";

import FormCheckBox from "components/molecules/FormCheckBox";

import type React from "react";

const AcceptsBox: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ margin: "1rem auto" }}>
      {/* <TermAccepted /> */}

      <FormCheckBox
        name="is_term_accepted"
        label={
          <Typography>
            <Box
              component={RouterLink}
              to="/fukugyosensei_school_kiyaku.pdf"
              target="_blank"
              sx={{
                textDecoration: "none",
                color: theme.palette.link?.main,
                fontWeight: 600
              }}
            >
              利用規約
            </Box>
            と
            <Box
              component={RouterLink}
              to="/privacy_policy.pdf"
              target="_blank"
              sx={{
                textDecoration: "none",
                color: theme.palette.link?.main,
                fontWeight: 600
              }}
            >
              プライバシーポリシー
            </Box>
            に同意
          </Typography>
        }
      />
    </Box>
  );
};

export default AcceptsBox;
