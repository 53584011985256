import React from "react";

import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

type FileUploadLoadingProps = {
  isSubmitting: boolean;
};

const FileUploadLoading: React.FC<FileUploadLoadingProps> = (props) => {
  const { isSubmitting } = props;
  const theme = useTheme();

  return (
    <Backdrop
      open={isSubmitting}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "center"
        }}
      >
        <CircularProgress color="primary" />
        <Typography
          color={theme.palette.common.white}
          align="center"
          fontSize=".9rem"
        >
          アップロード中‥
          <br />
          時間がかかる場合があります
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default FileUploadLoading;
