import { atom } from "jotai";

import type { schoolRecruitmentResponseType } from "types/lessonRecruitmentsTypes";

export type kindOfSchoolRecruitmentsType =
  | ""
  | "select_candidate"
  | "recruting"
  | "closed_or_canceled";

export const schoolRecruitmentsAtom =
  atom<schoolRecruitmentResponseType | null>(null);
export const kindOfSchoolRecruitmentsAtom =
  atom<kindOfSchoolRecruitmentsType>("");
