import { atom } from "jotai";

type bankAccountType = {
  id: number;
  name: string;
};

type teacherBankAccountType = {
  id: number;
  teacher_id: number;
  financial_institution_name: string;
  account_number_last_four: string;
};

// MEMO: for display
export const teacherBankAccountAtom = atom<teacherBankAccountType>({
  id: 0,
  teacher_id: 0,
  financial_institution_name: "",
  account_number_last_four: ""
});

export const bankAccountTypesAtom = atom<Array<bankAccountType>>([]);
