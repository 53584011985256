import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import { RESET } from "jotai/utils";

import {
  schoolIsLoggedInAtom,
  currentSchoolAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom
} from "store/SchoolAuthStore";

const useCurrentSchool = () => {
  const [, setCurrentSchool] = useAtom(currentSchoolAtom);
  const [, setAccessToken] = useAtom(schoolAccessTokenAtom);
  const [, setClient] = useAtom(schoolClientAtom);
  const [, setUid] = useAtom(schoolUidAtom);
  const [isLoggedIn, setSchoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);

  const { clearAlertMessages, addErrorMessage } = useAlertMessages();
  const headers = useHeaders();

  const { mutate } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers.login_school_teacher,
    {
      onSuccess: (data) => {
        setCurrentSchool(data);
      },
      onError: () => {
        clearAlertMessages();
        addErrorMessage(
          "セッションの有効期限が切れました。再ログインをお願いします。"
        );
        setAccessToken(RESET);
        setClient(RESET);
        setUid(RESET);
        setCurrentSchool(RESET);
        setSchoolIsLoggedIn(RESET);
      },
      enabled: isLoggedIn && Object.keys(headers).length > 0
    }
  );

  return { mutate };
};

export default useCurrentSchool;
