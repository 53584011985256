import { useAtom } from "jotai";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";

import SendButton from "features/teachers/components/atoms/show/requests/SendButton";
import AcceptSelectField from "features/teachers/components/molecules/show/requests/AcceptSelectField";
import IdentificationAlert from "features/teachers/components/molecules/show/requests/IdentificationAlert";
import ReplyCommentField from "features/teachers/components/molecules/show/requests/ReplyCommentField";
import StartAtSelectField from "features/teachers/components/molecules/show/requests/StartAtSelectField";
import usePostFormDataRequest from "features/teachers/hooks/usePostFormDataRequest";
import useFormatDateTime from "hooks/useFormatDateTime";
import {
  requestObjectAtom,
  updateSuccessAtom,
  updateIsLoadingAtom,
  updateErrorAtom,
  isAcceptAtom,
  possibleDateIdAtom,
  replyCommentAtom,
  possibleDatesAtom,
  teacherObjectAtom
} from "store/RequestShowStore";

import type React from "react";

const ReplyForm: React.VFC = () => {
  const [requestObject] = useAtom(requestObjectAtom);
  const [isAccept] = useAtom(isAcceptAtom);
  const [possibleDateId] = useAtom(possibleDateIdAtom);
  const [replyComment] = useAtom(replyCommentAtom);
  const [possibleDates] = useAtom(possibleDatesAtom);
  const [, setUpdateSuccess] = useAtom(updateSuccessAtom);
  const [, setUpdateIsLoading] = useAtom(updateIsLoadingAtom);
  const [, setUpdateError] = useAtom(updateErrorAtom);
  const [teacherObject] = useAtom(teacherObjectAtom);

  const history = useHistory();
  const { getFormatDateTime } = useFormatDateTime();

  const { postFormDataRequest, isLoading, isError } = usePostFormDataRequest({
    url: `/api/api/v1/lesson_requests/${requestObject.id}`,
    method: "put"
  });

  const handleSubmit = () => {
    const formData = new FormData();
    if (isAccept) {
      formData.append(
        "accept_at",
        String(new Date(getFormatDateTime(new Date())))
      );
      formData.append("possible_date_id", String(possibleDateId));
    } else {
      formData.append(
        "denied_at",
        String(new Date(getFormatDateTime(new Date())))
      );
    }
    formData.append("reply_comment", replyComment);

    postFormDataRequest({
      params: formData,
      onSuccess: () => {
        setUpdateSuccess(true);
        history.push("/teachers/mypage");
      }
    });
  };

  useEffect(() => {
    setUpdateIsLoading(isLoading);
    setUpdateError(isError);
  }, [isError, isLoading, setUpdateError, setUpdateIsLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFF5DD",
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      {!teacherObject.is_identification && <IdentificationAlert />}
      <AcceptSelectField />
      {isAccept && possibleDates.length !== 0 && <StartAtSelectField />}
      <ReplyCommentField />
      <SendButton handleClick={handleSubmit} />
    </Box>
  );
};

export default ReplyForm;
