import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link as RouterLink } from "react-router-dom";

import type React from "react";

const PasswordChangeLink: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ margin: ".5rem auto", width: "100%" }}>
      <Typography fontSize=".9rem">
        パスワードの変更は
        <Box
          component={RouterLink}
          to="/schools/mypage/change-password"
          sx={{
            textDecoration: "none",
            color: theme.palette.link?.main,
            fontWeight: "bold"
          }}
        >
          こちら
        </Box>
      </Typography>
    </Box>
  );
};

export default PasswordChangeLink;
