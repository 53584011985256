import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import TeacherFirstViewImage from "assets/TeacherFirstViewImage.png";

import type React from "react";

const FirstView: React.FC = () => (
  <Box
    sx={{
      background: `no-repeat url(${TeacherFirstViewImage}), linear-gradient(to right, #FFEFC7, #FFDBAA)`,
      backgroundPosition: { xs: "90% 100%", md: "90% 120%" },
      backgroundSize: { xs: "180px, 100% 100%", md: "210px, 100% 100%" },
      display: "flex",
      alignItems: "center",
      height: { xs: "100px", md: "120px" }
    }}
  >
    <Typography
      variant="h2"
      sx={{
        margin: "0 auto",
        width: "90%"
      }}
    >
      複業先生一覧
    </Typography>
  </Box>
);

export default FirstView;
