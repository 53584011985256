import { is } from "date-fns/locale";

export const newProfileFormDefaultValues = {
  image: undefined,
  image_file_name: "profile",
  name: "",
  kana_name: "",
  phone_number: "",
  origin_prefecture_id: undefined,
  organization_name: "",
  is_organization_name_public: true,
  birthday: "",
  introduction: "",
  frequency: undefined,
  is_experience_lesson: true,
  enthusiasm: "",
  tag_ids: [],
  organization_category_ids: [],
  residence_prefecture_id: undefined,
  is_reward: true,
  can_interview: false,
  lesson_contents: "",
  know_from: undefined,
  register_reason: "",
  origin_school_name: "",
  origin_high_school_name: "",
  origin_graduate_school_name: "",
  is_mail_magazine: true,
  websites: [
    {
      title: "",
      url: ""
    }
  ],
  is_limited_public: false
};
