import { useEffect, useRef } from "react";
import videojs from "video.js";

import type React from "react";
import "video.js/dist/video-js.css";

interface VideoJSProps {
  options: videojs.PlayerOptions;
  onReady?: (player: videojs.Player) => void;
}

export const VideoJS: React.VFC<VideoJSProps> = ({ options, onReady }) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<videojs.Player | null>(null);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      // eslint-disable-next-line
      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady?.(player);
      }));
    } else if (playerRef.current) {
      const player = playerRef.current;
      if (options.autoplay) {
        player.autoplay(options.autoplay);
      }
      if (options.sources) {
        player.src(options.sources);
      }
    }
  }, [onReady, options]);

  useEffect(
    () => () => {
      if (playerRef.current) {
        const player = playerRef.current;
        if (!player.isDisposed()) {
          player.dispose();
        }
        playerRef.current = null;
      }
    },
    []
  );

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
