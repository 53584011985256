import type React from "react";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

const IncorrectIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="svg"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: "absolute",
        // MEMO: 50%だと若干上にずれる
        top: "51%",
        right: ".5rem",
        transform: "translateY(-50%)",
        width: "1.5rem",
        height: "1.5rem",
        fill: theme.palette.error.main,
        stroke: theme.palette.error.main
      }}
    >
      <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
    </Box>
  );
};

export default IncorrectIcon;
