import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";

import RequireFormLabel from "features/root/components/atoms/contact/RequireFormLabel";
import { contactTypeAtom } from "store/ContactFormStore";

import type React from "react";

const ContactTypeField: React.FC = () => {
  const [contactType, setContactType] = useAtom(contactTypeAtom);
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setContactType(value);
  };

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <RequireFormLabel labelName="お問い合わせの種類" />
      <FormControl fullWidth>
        <RadioGroup
          row
          aria-label="contactContents"
          name="contactContents"
          value={contactType}
          onChange={handleChange}
          sx={{ width: "100%" }}
        >
          <Grid container sx={{ gap: ".5rem" }}>
            <Grid xs={12}>
              <FormControlLabel
                value="システムの利用方法について"
                control={<Radio color="primary" />}
                label="システムの利用方法について"
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box",
                  margin: "0"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormControlLabel
                value="サービスの利用について"
                control={<Radio color="primary" />}
                label="サービスの利用について"
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormControlLabel
                value="その他"
                control={<Radio color="primary" />}
                label="その他"
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ContactTypeField;
