import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import { isClickableAtom } from "store/ContactFormStore";

import type React from "react";

type SubmitButtonProps = {
  handleSubmit: () => void;
};

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { handleSubmit } = props;
  const isClickable = useAtomValue(isClickableAtom);

  return (
    <Box
      sx={{
        margin: "0 auto",
        padding: "0 0 1rem"
      }}
    >
      <Button
        type="button"
        color="primary"
        fullWidth
        disableElevation
        variant="contained"
        onClick={handleSubmit}
        disabled={!isClickable}
      >
        送信する
      </Button>
    </Box>
  );
};

export default SubmitButton;
