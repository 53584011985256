import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import IsuueImage from "assets/schoolLp/IsuueImage.png";

import type React from "react";

const Issue: React.FC = () => {
  const theme = useTheme();

  return (
    <Box component="section" sx={{ marginTop: "1.5rem" }}>
      <Box sx={{ margin: "1.5rem auto" }}>
        <Typography align="center">日々の授業について</Typography>
        <Typography
          variant="h2"
          align="center"
          fontSize="1.5rem"
          sx={{ margin: ".5rem auto" }}
        >
          こんな課題をお持ちの先生方へ
        </Typography>
      </Box>
      <Box
        sx={{
          height: { xs: "auto", md: "390px" },
          width: { xs: "90%", md: "100%" },
          maxWidth: { xs: "600px", md: "none" },
          margin: "0 auto",
          position: { xs: "static", md: "relative" }
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            padding: "1rem",
            width: "300px",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            position: { xs: "static", md: "absolute" },
            borderRadius: "32px 32px 32px 0",
            margin: { xs: "1.5rem auto", md: "auto" },
            top: { xs: "auto", md: 0 },
            right: { xs: 0, md: "calc(50% - 150px)" }
          }}
        >
          <Typography>
            社会の変化に対応できる資質や
            <br />
            <Typography
              component="span"
              fontWeight="bold"
              fontSize="1.1rem"
              sx={{ verticalAlign: "baseline" }}
            >
              能力を育てる機会
            </Typography>
            を増やしたい
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            padding: "1rem",
            width: "300px",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            position: { xs: "static", md: "absolute" },
            margin: "1.5rem auto 1.5rem 0",
            bottom: { xs: "auto", md: "40%" },
            right: { xs: 0, md: "calc(32% - 150px)" },
            borderRadius: "32px 32px 0 32px"
          }}
        >
          <Typography>
            学びの中で先生や親以外の
            <br />
            <Typography
              component="span"
              fontWeight="bold"
              fontSize="1.1rem"
              sx={{ verticalAlign: "baseline" }}
            >
              大人との接点
            </Typography>
            を作りたい
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            padding: "1rem",
            width: "300px",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            position: { xs: "static", md: "absolute" },
            margin: { xs: "1.5rem 0 1.5rem auto", md: "auto" },
            bottom: { xs: "auto", md: "40%" },
            left: { xs: 0, md: "calc(32% - 150px)" },
            borderRadius: "32px 32px 32px 0"
          }}
        >
          <Typography>
            学校で学んだことを
            <br />
            <Typography
              component="span"
              fontWeight="bold"
              fontSize="1.1rem"
              sx={{ verticalAlign: "baseline" }}
            >
              実践する場
            </Typography>
            を与えたい
          </Typography>
        </Box>
        <Box
          component="img"
          src={IsuueImage}
          alt="issue"
          sx={{
            display: "block",
            margin: { xs: "0 auto", md: "auto" },
            position: { xs: "static", md: "absolute" },
            bottom: { xs: "auto", md: 0 },
            right: { xs: 0, md: "calc(50% - 160px)" }
          }}
        />
      </Box>
    </Box>
  );
};

export default Issue;
