import { Methods } from "api/api/v1/teacher_profiles";

export type StepType = {
  required: (keyof Methods["post"]["reqBody"])[];
  optional: (keyof Methods["post"]["reqBody"])[];
  title?: string;
};

const newProfileFormSteps: StepType[] = [
  {
    required: [
      "image",
      "name",
      "kana_name",
      "organization_name",
      "is_organization_name_public",
      "phone_number",
      "birthday",
      "is_limited_public"
    ],
    optional: ["websites"],
    title: "あなたのことについて教えてください。"
  },
  {
    required: ["residence_prefecture_id", "origin_prefecture_id"],
    optional: [
      "origin_high_school_name",
      "origin_school_name",
      "origin_graduate_school_name",
      "license",
      "teaching_licenses"
    ],
    title: "経歴について教えてください。"
  },
  {
    required: [
      "is_experience_lesson",
      "organization_category_ids",
      "lesson_contents",
      "tag_ids"
    ],
    optional: ["frequency"],
    title: "複業先生登録にあたり、必要なことを教えてください。"
  },
  {
    required: [
      "enthusiasm",
      "introduction",
      "is_reward",
      "can_interview",
      "register_reason",
      "is_mail_magazine"
    ],
    optional: ["know_from"],
    title: "最後に自己紹介の作成をお願いします。"
  }
];

export default newProfileFormSteps;
