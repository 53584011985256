import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import FormHelperText from "@mui/material/FormHelperText";

import FormLabel from "components/atoms/FormLabel";
import Select from "components/atoms/fields/Select";

import { useFormContext, Controller } from "react-hook-form";

type FormSelectProps = {
  name: string;
  label: string;
  supplementalText?: string;
  isRequierd?: boolean;
  noLabel?: boolean;
  options: {
    value: string | number | boolean;
    label: string | React.ReactNode;
  }[];
  defaultValue?: string | number;
  fullWidth?: boolean;
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
};

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const {
    name,
    label,
    supplementalText,
    isRequierd,
    noLabel,
    options,
    defaultValue = "",
    fullWidth,
    width,
    disabled = false,
    readOnly = false
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {!noLabel ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "0.25rem"
          }}
        >
          <FormLabel labelName={label} isRequired={isRequierd} />
          {supplementalText && (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.sub.main }}
            >
              {supplementalText}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography fontWeight="bold">{label}</Typography>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormControl error={!!errors[name]} fullWidth={fullWidth}>
            <Select
              {...field}
              options={options}
              fullWidth={fullWidth}
              width={fullWidth ? "100%" : width}
              disabled={disabled}
              readOnly={readOnly}
            />
            {!!errors[name] && (
              <FormHelperText sx={{ color: theme.palette.error.main }}>
                {errors[name]?.message?.toString()}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
};

export default FormSelect;
