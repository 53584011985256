import { useAtom } from "jotai";
import { useCallback, useState } from "react";

import useGetRequest from "hooks/useGetRequest";
import usePostFormDataRequest from "hooks/usePostFormDataRequest";
import { requestObjectAtom, chatIndexAtom } from "store/RequestShowStore";

const useLessonRequestComments = (messageId?: number) => {
  const [requestObject] = useAtom(requestObjectAtom);
  const [, setChatIndex] = useAtom(chatIndexAtom);
  const [postMessageIsSuccess, setPostMessageIsSuccess] = useState(false);
  const [postFileIsSuccess, setPostFileIsSuccess] = useState(false);
  const [deleteMessageIsSuccess, setDeleteMessageIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    getRequest: getMessagesRequest,
    isLoading: getMessageIsLoading,
    isError: getMessageIsError,
    setIsError: setGetMessageIsError
  } = useGetRequest({
    url: `/api/api/v1/lesson_requests/${requestObject.id}/lesson_request_comments`
  });

  const {
    postFormDataRequest: postMessageRequest,
    isError: postMessageIsError,
    setIsError: setPostMessageIsError
  } = usePostFormDataRequest({
    url: `/api/api/v1/lesson_requests/${requestObject.id}/lesson_request_comments`
  });

  const {
    postFormDataRequest: postFileRequest,
    isLoading: isPostFileLoading,
    isError: postFileIsError,
    setIsError: setPostFileIsError
  } = usePostFormDataRequest({
    url: `/api/api/v1/lesson_requests/${requestObject.id}/lesson_request_comments`
  });

  const {
    postFormDataRequest: messageDeleteRequest,
    isLoading: isMessageDeleteLoading,
    isError: messageDeleteIsError,
    setIsError: setMessageDeleteIsError
  } = usePostFormDataRequest({
    url: `/api/api/v1/lesson_requests/${requestObject.id}/lesson_request_comments/${messageId}/logical_delete`,
    method: "patch"
  });

  const getMessages = useCallback(async () => {
    getMessagesRequest({
      onSuccess: (response) => {
        setChatIndex(response.lesson_request_comments);
      }
    });
  }, [getMessagesRequest, setChatIndex]);

  const postMessage = useCallback(
    async (message: string, isOfficeNotification: boolean) => {
      const formData = new FormData();
      formData.append("comment", message);
      formData.append(
        "is_office_notification",
        isOfficeNotification ? "true" : "false"
      );
      postMessageRequest({
        params: formData,
        onSuccess: () => {
          setPostMessageIsSuccess(true);
          getMessages();
        }
      });
    },
    [getMessages, postMessageRequest]
  );

  const postLessonFile = useCallback(
    async (acceptedFiles: Array<File>) => {
      let isValid = true;

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        // MEMO: ファイル形式チェック
        if (
          !file.name.endsWith(".mp4") &&
          !file.name.endsWith(".mov") &&
          !file.name.endsWith(".ppt") &&
          !file.name.endsWith(".pptx") &&
          !file.name.endsWith(".pdf") &&
          !file.name.endsWith(".key") &&
          !file.name.endsWith(".jpg") &&
          !file.name.endsWith(".jpeg") &&
          !file.name.endsWith(".png")
        ) {
          setPostFileIsError(true);
          setErrorMessage(
            "資料ファイルは次の形式のみアップロード可能です。.ppt, .pptx, .pdf, .mp4, .key, .mov, .jpg, .jpeg, .png"
          );
          isValid = false;
          return;
        }

        // MEMO: ファイルサイズチェック
        if (file.size > 100000000) {
          setPostFileIsError(true);
          setErrorMessage("ファイルは100MB以下のみアップロード可能です。");
          isValid = false;
          return;
        }

        // MEMO: 現状1ファイルずつしかアップできないため一旦よしとする
        formData.append("file", file);
      });

      if (!isValid) return;

      postFileRequest({
        params: formData,
        onSuccess: () => {
          setPostFileIsSuccess(true);
          getMessages();
        },
        onError: () => {
          setPostFileIsError(true);
        }
      });
    },
    [getMessages, postFileRequest, setPostFileIsError]
  );

  const deleteMessage = useCallback(async () => {
    messageDeleteRequest({
      onSuccess: () => {
        setDeleteMessageIsSuccess(true);
        getMessages();
      }
    });
  }, [getMessages, messageDeleteRequest, setDeleteMessageIsSuccess]);

  return {
    getMessages,
    postMessage,
    postMessageIsSuccess,
    setPostMessageIsSuccess,
    postMessageIsError,
    setPostMessageIsError,
    isPostFileLoading,
    postLessonFile,
    postFileIsSuccess,
    errorMessage,
    postFileIsError,
    setPostFileIsError,
    setPostFileIsSuccess,
    getMessageIsLoading,
    getMessageIsError,
    setGetMessageIsError,
    deleteMessage,
    isMessageDeleteLoading,
    messageDeleteIsError,
    setMessageDeleteIsError,
    deleteMessageIsSuccess,
    setDeleteMessageIsSuccess
  };
};

export default useLessonRequestComments;
