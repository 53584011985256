import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import type React from "react";
import { postTeacherVideo } from "features/teachers/components/types/teacherVideoApiTypes";
import { useDialog } from "hooks/useDialog";
import VideoUploadModal from "./VideoUploadModal";

type IntroVideoFieldProps = {
  keyForApi: string;
  postTeacherVideo: postTeacherVideo;
};

const IntroVideoField: React.FC<IntroVideoFieldProps> = (props) => {
  const { keyForApi, postTeacherVideo } = props;
  const theme = useTheme();
  const fileUploadModal = useDialog();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: ".5rem",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.border?.main}`
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "180px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: ".5rem",
            backgroundColor: theme.palette.common.white,
            borderRadius: "4px"
          }}
        >
          <IconButton onClick={fileUploadModal.onOpen}>
            <AddCircleOutlineIcon color="primary" fontSize="large" />
          </IconButton>
          <Button
            color="primary"
            variant="outlined"
            onClick={fileUploadModal.onOpen}
          >
            動画をアップロード
          </Button>
        </Box>
      </Box>
      <VideoUploadModal
        keyForApi={keyForApi}
        postTeacherVideo={postTeacherVideo}
        fileUploadModalControl={fileUploadModal}
      />
    </>
  );
};

export default IntroVideoField;
