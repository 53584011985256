import { Box, Typography } from "@mui/material";
import theme from "themes/theme";

interface ConfirmFieldProps {
  title: string;
  value: string | React.ReactNode;
}

const ConfirmField: React.FC<ConfirmFieldProps> = ({ title, value }) => (
  <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}`, p: "1rem 0" }}>
    <Typography variant="h3" fontSize="1.1rem" sx={{ mb: "0.2rem" }}>
      {title}
    </Typography>
    <Typography>{value}</Typography>
  </Box>
);

export default ConfirmField;
