import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";

type ExampleBoxProps = {
  image: string;
  title: string;
  desc: string;
  sub: string;
};

const ExampleBox: React.FC<ExampleBoxProps> = (props) => {
  const { image, title, desc, sub } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: { sx: "100%", md: "80%" },
        maxWidth: { xs: "360px", sm: "600px", md: "360px" },
        margin: { xs: "1rem auto", md: "0 auto 1rem" }
      }}
    >
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{
          width: "100%",
          borderRadius: "32px 32px 4px 4px"
        }}
      />
      <Typography
        variant="h5"
        align="center"
        fontWeight="bold"
        sx={{
          margin: ".5rem auto",
          position: "relative",
          "&:before": {
            content: '"["',
            position: "absolute",
            left: ".75rem",
            top: "calc(50% - 1.1rem)",
            color: theme.palette.muted?.main
          },
          "&:after": {
            content: '"]"',
            position: "absolute",
            right: ".75rem",
            top: "calc(50% - 1.1rem)",
            color: theme.palette.muted?.main
          }
        }}
      >
        {title}
      </Typography>
      <Typography>{desc}</Typography>
      <Typography color={theme.palette.sub.main}>{sub}</Typography>
    </Box>
  );
};

export default ExampleBox;
