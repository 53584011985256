import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";
import theme from "themes/theme";
import { useFormContext } from "react-hook-form";
import ConfirmField from "features/teachers/components/molecules/profile/ConfirmField";
import { knowFromOptions } from "constants/selectOptions";

import { isTrue } from "utils/checkBool";

const ConfirmStep04: React.FC = () => {
  const { getValues } = useFormContext();

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px"
      }}
    >
      <Typography variant="h2">自己紹介</Typography>
      <ConfirmField
        title="意気込み"
        value={getValues("enthusiasm") || "未記入"}
      />
      <ConfirmField
        title="講師紹介文"
        value={getValues("introduction") || "未記入"}
      />
      <ConfirmField
        title="報酬の有無"
        value={isTrue(getValues("is_reward")) ? "報酬あり" : "報酬なし"}
      />
      <ConfirmField
        title="メディアからの取材"
        value={isTrue(getValues("can_interview")) ? "許可する" : "許可しない"}
      />
      <ConfirmField
        title="メルマガ配信"
        value={
          isTrue(getValues("is_mail_magazine")) ? "許可する" : "許可しない"
        }
      />
      <ConfirmField
        title="複業先生に登録した理由やきっかけ"
        value={getValues("register_reason") || "未記入"}
      />
      <ConfirmField
        title="「複業先生」をどのように知りましたか？"
        value={
          knowFromOptions.find(
            (option) =>
              option.value.toString() === getValues("know_from")?.toString()
          )?.label || "未記入"
        }
      />
    </Box>
  );
};

export default ConfirmStep04;
