import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";

import RequireFormLabel from "features/schools/components/atoms/mypage/AiTeachingPlan/RequireFormLabel";
import { ideaRequestAtom } from "store/AiTeachingPlanStore";

import type React from "react";

type FieldProps = {
  isLoading: boolean;
};

const IdeaRequestField: React.FC<FieldProps> = (props) => {
  const { isLoading } = props;
  const [ideaRequest, setIdeaRequest] = useAtom(ideaRequestAtom);

  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdeaRequest(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <RequireFormLabel labelName="こんな授業がしたい" />
      <TextField
        variant="outlined"
        fullWidth
        multiline
        minRows={8}
        maxRows={12}
        value={ideaRequest}
        onChange={handleChange}
        disabled={isLoading}
        placeholder="例：プログラミングを手を動かしながら学ぶような授業がしたい"
        sx={{
          "& > .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.advanced?.main} !important`
          }
        }}
      />
    </Box>
  );
};

export default IdeaRequestField;
