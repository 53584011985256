import Box from "@mui/material/Box";
import dayjs from "dayjs";
import OptionalFormLabel from "components/atoms/fieldLabels/OptionalFormLabel";
import RequireFormLabel from "components/atoms/fieldLabels/RequireFormLabel";

import type React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "components/atoms/fields/DatePicker";
import Select from "components/atoms/fields/Select";
import { hoursOptions, minutesOptions } from "constants/selectOptions";

type PossibleDateFieldProps = {
  index: number;
};

const PossibleDateField: React.FC<PossibleDateFieldProps> = (props) => {
  const { index } = props;
  const { control, setValue, trigger } = useFormContext();
  const isFirst = index === 0;
  const displayIndex = index + 1;

  const setPossibleDate = (date: Date) => {
    // invalid dateの場合は何もしない
    if (Number.isNaN(date.getTime())) return;
    setValue(`proposed_dates[${index}]`, date, {
      shouldValidate: true,
      shouldDirty: true
    });
    trigger("recruitment_dead_at");
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <Box sx={{ width: "100%" }}>
        {isFirst ? (
          <RequireFormLabel labelName={`第${displayIndex}希望`} />
        ) : (
          <OptionalFormLabel labelName={`第${displayIndex}希望`} />
        )}
      </Box>
      <Box>
        <Controller
          name={`proposed_dates[${index}]`}
          control={control}
          render={({ field }) => {
            const value = dayjs(field.value);
            const date = value.toDate();
            const hour = value.hour();
            const minute = value.minute() || 0;

            return (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: { xs: "column", sm: "row" }
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <DatePicker
                    value={date}
                    width="100%"
                    onChange={(value) => {
                      setPossibleDate(
                        dayjs(value).hour(hour).minute(minute).toDate()
                      );
                    }}
                    minDate={dayjs().add(14, "day").toDate()}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    gap: "1rem"
                  }}
                >
                  <Select
                    value={hour}
                    options={hoursOptions}
                    width="100%"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value) {
                        setPossibleDate(
                          dayjs(date)
                            .hour(Number(value))
                            .minute(minute)
                            .toDate()
                        );
                      }
                    }}
                  />
                  <Select
                    value={minute}
                    options={minutesOptions}
                    width="100%"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value) {
                        setPossibleDate(
                          dayjs(date).hour(hour).minute(Number(value)).toDate()
                        );
                      }
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default PossibleDateField;
