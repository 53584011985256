import Box from "@mui/material/Box";
import { useRef } from "react";

import CareerImage from "assets/lpClassi/Career.png";
import Overview from "features/root/components/molecules/lpClassi/Overview";
import useFilterTeachers from "hooks/useFilterTeachers";

import type React from "react";
import LessonExample from "./LessonExample";
import LessonIdeas from "./LessonIdeas";

const Career: React.FC = () => {
  // MEMO: 基本的に複数回のレンダリングが必要ないと目されるため。
  const teacherWithVideoIdsRef = useRef([861, 895, 520]);
  const teacherIdsRef = useRef([33, 861, 851, 895, 1010, 520, 42, 758]);
  const teachersWithVideo = useFilterTeachers(teacherWithVideoIdsRef.current);
  const teachers = useFilterTeachers(teacherIdsRef.current);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem"
      }}
    >
      <Overview
        title="キャリア教育"
        desc="キャリア教育の導入期に最適。多様な職業・働き方のロールモデルとなる複業先生の生き方・考え方に触れ、生徒様自身のキャリアについて考えるきっかけ作りとします。"
        Image={CareerImage}
      />
      <LessonIdeas title="キャリア教育" teachers={teachersWithVideo} />
      <LessonExample title="キャリア教育" teachers={teachers} />
    </Box>
  );
};

export default Career;
