import { useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import type React from "react";

type FAQItemProps = {
  question: string;
  answer: string;
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

const FAQItem: React.FC<FAQItemProps> = (props) => {
  const { question, answer, expand, setExpand } = props;
  const theme = useTheme();
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        "&.MuiAccordion-root": {
          "&::before": {
            content: "none"
          }
        }
      }}
    >
      <AccordionSummary
        expandIcon={
          // FIXME: style適用されていない可能性あり。ただ、現状で違和感がなく、適用されているかの判断ができない
          expand ? (
            <RemoveIcon
              sx={{
                "&.MuiAccordionSummary-root": {
                  color: `${theme.palette.textColor.main} !important`
                }
              }}
            />
          ) : (
            <AddIcon
              sx={{
                "&.MuiAccordionSummary-root": {
                  color: `${theme.palette.textColor.main} !important`
                }
              }}
            />
          )
        }
        onClick={toggleAcordion}
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          borderRadius: "4px",
          margin: "1rem auto"
        }}
      >
        <Typography fontWeight="bold">
          <Typography
            component="span"
            color={theme.palette.muted?.main}
            fontWeight="bold"
          >
            Q.{" "}
          </Typography>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItem;
