import { AgendaSheetNew } from "api/@types";
import { atom } from "jotai";

// 依頼内容について
export const requestObjectAtom = atom<
  AgendaSheetNew["lesson_request"]["details"] | undefined
>(undefined);

export const lessonContentCategoriesAtom = atom<
  AgendaSheetNew["timelines"]["lesson_content_categories"]["data"]
>([]);

// 編集権限確認
export const editAuthAtom = atom<AgendaSheetNew["edit_auth"] | undefined>(
  undefined
);

export const editUserNameWithPrefixAndSuffixAtom = atom((get) => {
  const editAuth = get(editAuthAtom);
  switch (editAuth?.auth_user_category) {
    case "teacher":
      return `${editAuth.auth_user_name}さん`;
    case "school_teacher":
      return `${editAuth.auth_user_name}先生`;
    case "office_user":
      return `サポーター：${editAuth.auth_user_name}`;
    default:
      return "";
  }
});

// 自動更新関連
export const updateDateDisplayAtom = atom<string>("");
