import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TeacherListItemCard from "features/teachers/components/molecules/index/TeacherListItemCard";

import type React from "react";

// MEMO: 型指定をどこかでうまく統一したい
type TeacherListItemProps = {
  teacher_id: number;
  name: string;
  image: {
    url: string;
  };
  organization_name: string;
  enthusiasm: string;
  introduction: string;
  teacher_rank: string;
  total_rating: number;
  rating_count: number;
  tags: {
    id: number;
    name: string;
  }[];
};

type TeacherListProps = {
  teachers: TeacherListItemProps[];
};

const TeachersList: React.FC<TeacherListProps> = (props) => (
  <section>
    <Grid container>
      {props.teachers.length !== 0 ? (
        props.teachers.map((teacher) => (
          <TeacherListItemCard
            key={teacher.teacher_id}
            id={teacher.teacher_id}
            name={teacher.name}
            imageUrl={teacher.image.url}
            organizationName={teacher.organization_name}
            enthusiasm={teacher.enthusiasm}
            introduction={teacher.introduction}
            teacher_rank={teacher.teacher_rank}
            total_rating={teacher.total_rating}
            rating_count={teacher.rating_count}
            tags={teacher.tags}
          />
        ))
      ) : (
        <Grid item xs={12}>
          <Typography sx={{ margin: "2rem auto" }} align="center">
            該当する先生がいません。
          </Typography>
        </Grid>
      )}
    </Grid>
  </section>
);

export default TeachersList;
