import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";

import RequireFormLabel from "features/schools/components/atoms/mypage/AiTeachingPlan/RequireFormLabel";
import {
  schoolMinutesAtom,
  schoolMinutesSelectAtom
} from "store/AiTeachingPlanStore";

import type React from "react";

type FieldProps = {
  isLoading: boolean;
};

const SchoolMinutesField: React.FC<FieldProps> = (props) => {
  const { isLoading } = props;
  const [, setSchoolMinutes] = useAtom(schoolMinutesAtom);
  const [radioValue, setRadioValue] = useAtom(schoolMinutesSelectAtom);

  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setRadioValue(value);
    setSchoolMinutes(Number(value));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <RequireFormLabel labelName="授業時間" />
      <FormControl fullWidth>
        <RadioGroup
          row
          aria-label="schoolMinutes"
          name="schoolMinutes"
          value={radioValue}
          onChange={handleChange}
          sx={{ width: "100%" }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="45"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="45分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="50"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="50分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="60"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="60分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="90"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="90分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="120"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="120分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="150"
                control={
                  <Radio
                    color="primary"
                    disabled={isLoading}
                    sx={{
                      "&.Mui-checked": {
                        color: `${theme.palette.advanced?.main} !important`
                      }
                    }}
                  />
                }
                label="150分"
                disabled={isLoading}
                sx={{
                  width: "100%",
                  padding: "6px",
                  border: `1px solid ${theme.palette.muted?.main}`,
                  borderRadius: "6px",
                  boxSizing: "border-box"
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SchoolMinutesField;
