import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { Link as RouterLink } from "react-router-dom";

import SendIcon from "components/atoms/SendIcon";
import { registeredEmailAtom } from "store/SignupStore";

import type React from "react";

const ThanksSignupBlock: React.FC = () => {
  const [registeredEmail] = useAtom(registeredEmailAtom);
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: { xs: theme.spacing(3), md: "auto" },
        height: "100%"
      }}
    >
      <Grid item xs={10} sm={9} md={7}>
        <SendIcon />
        <Typography
          align="center"
          variant="h1"
          sx={{
            margin: "1rem auto",
            width: "100%"
          }}
        >
          <Typography
            align="center"
            variant="h1"
            component="span"
            sx={{ display: "inline-block" }}
          >
            仮登録が
          </Typography>
          <Typography
            align="center"
            variant="h1"
            component="span"
            sx={{ display: "inline-block" }}
          >
            完了しました！
          </Typography>
        </Typography>
        <Box
          sx={{
            width: "100%",
            margin: "1rem auto",
            padding: ".75rem",
            backgroundColor: theme.palette.backgroundColor?.main
          }}
        >
          <Typography
            align="center"
            fontWeight="bold"
            sx={{
              width: "100%"
            }}
          >
            {registeredEmail}
          </Typography>
        </Box>
        <div>
          <Typography>
            メールが届かない場合：アドレスが正しいものかご確認の上、
            間違っていた場合は
            <Box
              component={RouterLink}
              to="/teachers/signup"
              sx={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
                fontWeight: 600
              }}
            >
              こちら
            </Box>
            から
            再度登録してください。また、迷惑メールフォルダに入っていないか、ご確認ください。
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default ThanksSignupBlock;
