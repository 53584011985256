import { ThemeOptions } from "@mui/material/styles/createTheme";
import { customColors } from "./customColors";

export const MuiButtonTheme: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
          padding: "12px 24px"
        },
        containedPrimary: {
          fontWeight: 600,
          "&:disabled": {
            backgroundColor: customColors.primary.main,
            opacity: 0.3,
            color: customColors.textColor.white
          }
        },
        containedSecondary: {
          fontWeight: 600,
          "&:disabled": {
            backgroundColor: customColors.secondary.main,
            opacity: 0.3,
            color: customColors.textColor.white
          }
        },
        outlinedPrimary: {
          border: `2px solid ${customColors.primary.main}`,
          fontWeight: 600,
          color: customColors.primary.dark,
          "&:hover": {
            border: `2px solid ${customColors.primary.main}`,
            backgroundColor: customColors.primary.background
          },
          "&:disabled": {
            border: `2px solid ${customColors.primary.main}`,
            opacity: 0.3,
            color: customColors.primary.dark
          }
        },
        containedInherit: {
          backgroundColor: customColors.background?.gray,
          border: `2px solid ${customColors.border.main}`,
          color: customColors.textColor.main,
          "&:hover": {
            fontWeight: 600,
            border: `2px solid ${customColors.textColor.main}`
          },
          "&:disabled": {
            backgroundColor: customColors.background?.gray,
            opacity: 0.3
          }
        }
      }
    }
  }
};
