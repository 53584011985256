import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { editAuthAtom, requestObjectAtom } from "store/MeetingSheetStore";

import type React from "react";
import FormTextField from "components/molecules/FormTextField";
import FormTextArea from "components/molecules/FormTextArea";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { useFormContext } from "react-hook-form";

const ClassContentsFields: React.FC = () => {
  const { watch } = useFormContext();
  const editAuth = useAtomValue(editAuthAtom);
  const requestObject = useAtomValue(requestObjectAtom);
  const isOnline = requestObject?.is_online;
  const timeAllocation = watch("time_allocation");
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Typography variant="h3">授業の概要</Typography>
      {isOnline ? (
        <FormTextField
          label="使用する端末形式"
          name="device"
          placeholder="例：一人一台タブレット"
          fullWidth
          noLabel
          readOnly={!editAuth?.has_auth}
        />
      ) : (
        <FormTextField
          label="授業実施場所"
          name="place"
          placeholder="例：教室"
          fullWidth
          noLabel
          readOnly={!editAuth?.has_auth}
        />
      )}
      <FormTextArea
        label="授業のめあて（目的）"
        name="purpose"
        placeholder="例：複業先生の話を通して、自分がやりたいことや将来の仕事について考える"
        noLabel
        readOnly={!editAuth?.has_auth}
      />
      {timeAllocation && (
        <FormTextArea
          label="授業内の時間配分"
          name="time_allocation"
          placeholder="例：複業先生の簡単な自己紹介：5分&#13;
            これまでのキャリアと今の仕事について：15分&#13;
            グループで感想共有・質問考える：10分&#13;
            全体で質疑応答：10分&#13;
            まとめ（ふりかえり記入）：5分"
          noLabel
          readOnly={!editAuth?.has_auth}
        />
      )}
      <FormTextField
        label="使用するツール"
        name="tool"
        placeholder="例：Googleフォーム・Canva"
        fullWidth
        noLabel
        readOnly={!editAuth?.has_auth}
      />
      <FormRadioGroup
        label="授業の様子を記録したり、学級通信や学校HPなどで発信しますか。"
        supplementalText="記録・発信する場合、複業先生の許可をとってください。また、事務局より確認のご連絡が行く場合があります。"
        name="is_transmit"
        options={[
          { label: "記録・発信しない", value: false },
          { label: "（複業先生の許可を取った上で）記録・発信する", value: true }
        ]}
        disabled={!editAuth?.has_auth}
        noLabel
        row
      />
    </Box>
  );
};

export default ClassContentsFields;
