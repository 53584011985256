import Box from "@mui/material/Box";

import TeacherImage01 from "assets/lpClassi/TeacherImage01.png";
import TeacherImage02 from "assets/lpClassi/TeacherImage02.png";
import TeacherImage03 from "assets/lpClassi/TeacherImage03.png";
import TeacherImage04 from "assets/lpClassi/TeacherImage04.png";
import TeacherImage05 from "assets/lpClassi/TeacherImage05.png";
import TeacherImage06 from "assets/lpClassi/TeacherImage06.png";

import type React from "react";

const SPSliderContents: React.FC = () => (
  <Box
    component="ul"
    sx={{
      display: "flex",
      columnGap: "1rem",
      listStyle: "none",
      boxSizing: "border-box",
      margin: ".5rem .5rem 1rem",
      padding: 0,
      animationName: "horizontalSlide",
      animationDuration: "36s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear",
      animationDelay: "1s",
      animationFillMode: "both",
      "@keyframes horizontalSlide": {
        from: {
          transform: "translateX(0)"
        },
        to: {
          transform: "translateX(-100%)"
        }
      }
    }}
  >
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem01"
        src={TeacherImage01}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem02"
        src={TeacherImage02}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem03"
        src={TeacherImage03}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem04"
        src={TeacherImage04}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem05"
        src={TeacherImage05}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
    <Box
      component="li"
      sx={{
        width: { xs: "30vw", sm: "20vw" }
      }}
    >
      <Box
        component="img"
        alt="SliderItem06"
        src={TeacherImage06}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          margin: "0 auto",
          padding: "0",
          borderRadius: "8px"
        }}
      />
    </Box>
  </Box>
);

export default SPSliderContents;
