import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import AlertMessages from "components/molecules/AlertMessages";

import Header from "features/teachers/components/organisms/mypage/eLearning/Header";

const ELearningLayout = ({ children }: any) => {
  const theme = useTheme();

  return (
    <div id="layout-root">
      <Header />
      <Box sx={theme.mixins.toolbar} />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: "1.5rem 0",
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        {children}
      </Box>
      <AlertMessages />
    </div>
  );
};

export default ELearningLayout;
