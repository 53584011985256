// TODO: AtomsのChipに統一したい

import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type LessonThemeChipType = {
  name: string | undefined;
  size?: "small" | "medium";
};

const LessonThemeChip: React.FC<LessonThemeChipType> = (props) => {
  const { name, size } = props;
  const theme = useTheme();

  if (!name) return null;

  return (
    <Chip
      label={name}
      variant="outlined"
      color="default"
      size={size}
      sx={{
        height: size === "small" ? "28px" : "36px",
        borderRadius: size === "small" ? "14px" : "18px",
        fontSize: size === "small" ? ".8rem" : "1rem",
        backgroundColor: theme.palette.backgroundColor?.main,
        borderColor: theme.palette.muted?.main,
        color: theme.palette.sub.main
      }}
    />
  );
};

export default LessonThemeChip;
