import { atom } from "jotai";

import type {
  schoolRecruitmentResponseType,
  schoolRequestResponseType
} from "types/lessonRecruitmentsTypes";

export const schoolRequestsAtom = atom<schoolRequestResponseType | null>(null);

export const schoolLessonsAtom = atom<schoolRequestResponseType | null>(null);

export const schoolRecruitmentsAtom =
  atom<schoolRecruitmentResponseType | null>(null);
