import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useState } from "react";

import IntroVideoModal from "features/root/components/molecules/lpClassi/IntroVideoModal";
import { pushDataLayer } from "services/analytics";

import type React from "react";

type LessonIdeaProps = {
  id: number;
  lessonTitle: string;
  teacherOrganization: string;
  teacherName: string;
  teacherImageUrl: string;
};

const LessonIdea: React.FC<LessonIdeaProps> = (props) => {
  const { id, lessonTitle, teacherOrganization, teacherName, teacherImageUrl } =
    props;

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    // MEMO: GTMにデータ送信
    pushDataLayer({
      event: "view_profile_video",
      teacher_id: id.toString()
    });
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          rowGap: ".5rem"
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            position: "relative",
            cursor: "pointer",
            width: "100%",
            borderRadius: "4px"
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "4px"
            }}
          />
          <div>
            <Box
              component="video"
              // MEMO: いただき次第、idを使って取ってくる
              src={`https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/${id}.mp4`}
              title={`${teacherName}さんの動画`}
              controls
              sx={{
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                borderRadius: "4px"
              }}
            />
          </div>
        </Box>
        <Typography fontSize="1.1rem" fontWeight="bold">
          {lessonTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem"
          }}
        >
          <Avatar
            src={teacherImageUrl}
            alt={teacherName}
            sx={{
              height: "60px",
              width: "60px"
            }}
          />
          <div>
            <Typography fontSize="0.9rem" color={theme.palette.sub.main}>
              {teacherOrganization}
            </Typography>
            <Typography fontSize="1.1rem" fontWeight="bold">
              {teacherName}
            </Typography>
          </div>
        </Box>
      </Box>
      <IntroVideoModal
        open={open}
        setOpen={setOpen}
        videoUrl={`https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/classi/${id}.mp4`}
      />
    </>
  );
};

export default LessonIdea;
