import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import { useAtom } from "jotai";

import ErrorAlert from "components/atoms/ErrorAlert";
import Meta from "components/organisms/Meta";
import Sidebar from "features/teachers/components/organisms/common/Sidebar";
import AnnouncementDialog from "features/teachers/components/organisms/show/AnnouncementDialog";
import ProfileJudges from "features/teachers/components/organisms/show/ProfileJudges";
import Home from "features/teachers/components/templates/mypage/Home";
import Lessons from "features/teachers/components/templates/mypage/Lessons";
import Recruitments from "features/teachers/components/templates/mypage/Recruitments";
import Requests from "features/teachers/components/templates/mypage/Requests";
import useQuery from "hooks/useQuery";
import CommonLayout from "components/layouts/CommonLayout";
import { teacherAnnouncementIsErrorAtom } from "store/AnnouncementsStore";

import type { Theme } from "@mui/material/styles";
import type React from "react";

const Mypage_v2: React.FC = () => {
  const theme = useTheme();
  const [announcementIsError, setAnnouncementIsError] = useAtom(
    teacherAnnouncementIsErrorAtom
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getQuery } = useQuery();

  const pageName = getQuery("page_name");

  const getTemplate = () => {
    switch (pageName) {
      case "lessons":
        return <Lessons />;
      case "requests":
        return <Requests />;
      case "recruitments":
        return <Recruitments />;
      default:
        return <Home />;
    }
  };

  return (
    <>
      <Meta title="複業先生｜マイページ" />
      <ErrorAlert
        isError={announcementIsError}
        setIsError={setAnnouncementIsError}
        errorMessage="お知らせの処理に失敗しました"
      />
      <CommonLayout>
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              gap: "1.5rem",
              padding: "1.5rem"
            }}
          >
            {!isMobile && <Sidebar />}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem"
              }}
            >
              <ProfileJudges />
              {getTemplate()}
            </Box>
          </Container>
        </Box>
      </CommonLayout>
      <AnnouncementDialog />
    </>
  );
};

export default Mypage_v2;
