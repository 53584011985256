import { useAtom } from "jotai";
import { useCallback } from "react";
import { alertMessagesAtom } from "store/AlertsStore";

export const useAlertMessages = () => {
  const [alertMessages, setAlertMessages] = useAtom(alertMessagesAtom);

  const addAlertMessage = useCallback(
    (
      message: string | React.ReactNode,
      type: "error" | "success" | "warning"
    ) => {
      setAlertMessages((prevMessages) => [...prevMessages, { message, type }]);
    },
    [setAlertMessages]
  );

  const removeAlertMessage = useCallback(
    (index: number) => {
      setAlertMessages((oldMessages) =>
        oldMessages.filter((_, i) => i !== index)
      );
    },
    [setAlertMessages]
  );

  const clearAlertMessages = useCallback(() => {
    setAlertMessages([]);
  }, [setAlertMessages]);

  return {
    alertMessages,
    addErrorMessage: (message: string | React.ReactNode) =>
      addAlertMessage(message, "error"),
    addSuccessMessage: (message: string | React.ReactNode) =>
      addAlertMessage(message, "success"),
    addWarningMessage: (message: string | React.ReactNode) =>
      addAlertMessage(message, "warning"),
    removeAlertMessage,
    clearAlertMessages
  };
};
