import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Tags from "features/schools/components/atoms/show/requests/Tags";
import useTeacherRankBadge from "features/teachers/hooks/useTeacherRankBadge";

import type React from "react";

import { LessonRequestSchoolTeacher } from "api/@types";

type TeacherInfoBlockStep01Props = {
  teacher?: LessonRequestSchoolTeacher["teacher"];
};

const TeacherInfoBlockStep01: React.FC<TeacherInfoBlockStep01Props> = (
  props
) => {
  const { teacher } = props;
  const teacherRankBadge = useTeacherRankBadge(teacher?.teacher_rank || "");
  const theme = useTheme();

  return (
    <Grid container alignItems="center" sx={{ gap: "1rem" }}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          src={teacher?.image.url}
          alt={`${teacher?.name} avatar`}
          sx={{
            width: { xs: "90px", sm: "120px" },
            height: { xs: "90px", sm: "120px" },
            objectFit: "cover",
            borderRadius: 4,
            display: "block"
          }}
        />
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          {teacher?.organization_name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography fontWeight="bold">{teacher?.name}</Typography>
          {teacherRankBadge.src && (
            <Box
              component="img"
              src={teacherRankBadge.src}
              alt={teacherRankBadge.alt}
              sx={{
                display: "block",
                margin: "0 .5rem",
                // MEMO: 解像度を合わせるための調整
                width: "85px"
              }}
            />
          )}
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Tags tags={teacher?.tags || []} />
        </Box>
      </Grid>
      <Box sx={{ display: { xs: "block", sm: "none" }, flexBasis: "100%" }}>
        <Grid item xs={12}>
          <Tags tags={teacher?.tags || []} />
        </Grid>
      </Box>
    </Grid>
  );
};

export default TeacherInfoBlockStep01;
