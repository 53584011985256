import { Box, Typography } from "@mui/material";
import FormBirthdayField from "components/molecules/FormBirthdayField";
import FormFacePhotoField from "components/molecules/FormFacePhotoField";
import FormFullNameField from "components/molecules/FormFullNameField";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSelect from "components/molecules/FormSelect";
import FormTextField from "components/molecules/FormTextField";
import {
  isLimitedPublicOptions,
  isPublicOptions
} from "constants/selectOptions";

import StepTitle from "features/teachers/components/atoms/profile/StepTitle";
import SitesField from "features/teachers/components/molecules/profile/SitesField";

import type React from "react";
import { useFormContext } from "react-hook-form";
import theme from "themes/theme";

const NewProfileFormStep1: React.FC = () => {
  const { watch } = useFormContext();
  const isLimitedPublic = watch("is_limited_public");
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "0.5rem 0"
      }}
    >
      <StepTitle
        stepName={["あなたのことについて教えてください。"]}
        indicateStatus="none"
      />
      <FormFacePhotoField
        name="image"
        label="顔写真"
        isPublic
        isRequired
        supplementalText={
          <div>
            お顔がはっきり写っているご本人写真を登録してください。
            ご本人の確認できない写真の場合、審査を通過することができません。
            <br />
            ※お写真は1:1のものを推奨しております。
          </div>
        }
      />
      <FormFullNameField label="氏名" isRequired />
      <FormTextField
        name="organization_name"
        label="所属企業（学生の方は学校・学部名）"
        placeholder="例：〇〇株式会社"
        isRequired
      />
      <FormRadioGroup
        name="is_organization_name_public"
        label="所属先の公開"
        options={isPublicOptions}
        isRequired
      />
      <FormTextField
        name="phone_number"
        label="電話番号（ハイフンなし）"
        isRequired
        supplementalText="審査内容について、事務局からご連絡をする場合があります。"
        placeholder="例：01234567890"
        isPrivate
      />
      <FormBirthdayField
        name="birthday"
        label="生年月日"
        isRequired
        placeholders={{
          year: "1990",
          month: "1",
          date: "1"
        }}
        supplementalText="本人確認の際に利用するため、一般公開はされません。"
        isPrivate
      />
      <SitesField />
      <FormSelect
        name="is_limited_public"
        label="プロフィール公開設定"
        options={isLimitedPublicOptions}
        isRequierd
        fullWidth
      />
      <Box>
        <Typography color={theme.palette.sub.main} fontSize=".9rem">
          {isLimitedPublic
            ? "教員アカウントのみに公開されています。"
            : "公開されています。"}
          教員アカウントのみに公開されています。
        </Typography>
        <Typography fontSize=".9rem">
          {isLimitedPublic ? (
            <>
              公開にすると多くの人に見てもらえます。
              <br />
              現在サイトに登録していない人には見えていません。
            </>
          ) : (
            "現在サイトに登録していない人にも見えています。"
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default NewProfileFormStep1;
