import useAspidaSWR from "@aspida/swr";
import { useEffect, useState } from "react";
import aspida from "libs/aspida";
import { Methods } from "api/api/v1/master_schools";
import { masterSchoolType, paginationType } from "types/masterDataTypes";
import useHeaders from "./useHeaders";
import { useAlertMessages } from "./useAlertMessages";

export const useMasterSchoolsApi = () => {
  const headers = useHeaders();
  const [query, setQuery] =
    useState<Partial<Methods["get"]["query"]>>(undefined);
  const [masterSchools, setMasterSchools] = useState<Array<masterSchoolType>>(
    []
  );
  const [pagination, setPagination] = useState<paginationType | null>(null);
  const { addErrorMessage } = useAlertMessages();

  const { data, error, isLoading, mutate } = useAspidaSWR(
    aspida(headers).api.v1.master_schools,
    {
      query,
      onError: () => {
        addErrorMessage("学校の検索に失敗しました。");
      },
      enabled: !!query
    }
  );

  const search = (searchQuery: Partial<Methods["get"]["query"]>) => {
    setQuery({ ...query, ...searchQuery });
    mutate();
  };

  // MEMO: useEffectでdataを監視しないと、無限ループが発生する
  useEffect(() => {
    if (data?.schools.length === 0) {
      addErrorMessage("該当する学校が見つかりませんでした。");
    } else if (data) {
      setPagination(data.pagination);
      setMasterSchools(data.schools);
    }
  }, [data]);

  return {
    // MEMO: 以下2行で返す値は、複業先生側の検索で使用している。別途修正が必要
    data,
    isError: error,
    masterSchools,
    pagination,
    setPagination,
    isLoading,
    search
  };
};
