import Typography from "@mui/material/Typography";

import type React from "react";

const SendAnswersLeading: React.FC = () => (
  <div>
    <Typography align="center">
      <Typography
        component="span"
        fontWeight="bold"
        sx={{ display: "inline-block" }}
      >
        全問正解すると、複業先生ランクが、
      </Typography>
      <Typography
        component="span"
        fontWeight="bold"
        sx={{ display: "inline-block" }}
      >
        「ジュニアクラス」になります。
      </Typography>
    </Typography>
    <Typography align="center">
      <Typography component="span" sx={{ display: "inline-block" }}>
        最後に見直しをして、
      </Typography>
      <Typography component="span" sx={{ display: "inline-block" }}>
        OKであれば回答ボタンを押しましょう。
      </Typography>
    </Typography>
  </div>
);

export default SendAnswersLeading;
