import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import CompleteIcon from "components/atoms/CompleteIcon";
import InspectionDisplay from "components/molecules/InspectionDisplay";
import Meta from "components/organisms/Meta";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";

const Complete: React.FC = () => (
  <AuthLayout>
    <Meta />
    <Container maxWidth="sm">
      <Grid container>
        <Grid item xs={12} sx={{ padding: "1.5rem 0" }}>
          <CompleteIcon />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ margin: "1rem auto" }}>
            <Typography
              component="span"
              variant="h1"
              sx={{ display: "inline-block" }}
            >
              すべての入力
            </Typography>
            <Typography
              component="span"
              variant="h1"
              sx={{ display: "inline-block" }}
            >
              ありがとうございました！
            </Typography>
            <br />
            <Typography
              component="span"
              variant="h1"
              sx={{ display: "inline-block" }}
            >
              内容の確認に入ります。
            </Typography>
          </Typography>
          <Typography sx={{ margin: ".5rem auto" }}>
            確認が完了しましたら、メールで結果をお送りします。
            <br />
            今しばらくお待ちください。（確認には最大1週間かかることがあります）
            <br />
            <RouterLink to="/schools/mypage">マイページへ</RouterLink>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InspectionDisplay />
        </Grid>
      </Grid>
    </Container>
  </AuthLayout>
);

export default Complete;
