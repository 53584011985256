import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Procedure2 from "assets/Procedure2.png";
import Procedure3 from "assets/Procedure3.png";
import Procedure4 from "assets/Procedure4.png";
import SchoolProcedure1 from "assets/schoolLp/SchoolProcedure1.png";
import ProcedureCard from "features/root/components/molecules/ProcedureCard";

import type React from "react";

const Procedures: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        padding: "calc(1.5rem + .25vw) 0",
        backgroundColor: theme.palette.backgroundColor?.main,
        maxWidth: "1280px",
        margin: "3rem auto"
      }}
    >
      <Box
        sx={{
          width: "100%",
          margin: "1.2rem auto"
        }}
      >
        <Typography
          variant="h2"
          align="center"
          color="#EFEDE8"
          fontSize="2.4rem"
        >
          HOW IT WORKS
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{
            title: "1.5rem",
            margin: "1rem auto .5rem"
          }}
        >
          授業実施までの流れ
        </Typography>
      </Box>
      <Box
        sx={{
          width: "90%",
          maxWidth: "960px",
          margin: "1.5rem auto"
        }}
      >
        <ProcedureCard
          number={1}
          image={SchoolProcedure1}
          title="複業先生を探す"
          desc="様々な分野での登録がある複業先生。キーワード検索やテーマ検索から、ぴったりの複業先生を探してみましょう。"
        />
        <ProcedureCard
          number={2}
          image={Procedure2}
          title="授業を依頼する"
          desc="複業先生への授業依頼は日時・希望テーマ等必要な情報を入力するだけ3分で完了！（会員登録が必要）"
        />
        <ProcedureCard
          number={3}
          image={Procedure3}
          title="授業計画を立てる"
          desc="事務局からの連絡後、”複業先生”と授業当日に向けて打ち合わせを行います。オンラインで完結！"
        />
        <ProcedureCard
          number={4}
          image={Procedure4}
          title="授業の実施"
          desc="オンライン（またはオフライン）での授業実施。授業当日は生徒のサポート役に集中できます。"
        />
      </Box>
    </Box>
  );
};

export default Procedures;
