import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PublicIcon from "@mui/icons-material/Public";

import type React from "react";

const IndicatePublic: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <PublicIcon
        sx={{
          fontSize: "1rem",
          color: theme.palette.sub.main
        }}
      />
      <Typography
        fontSize=".8rem"
        sx={{
          color: theme.palette.sub.main,
          margin: "0 .4rem"
        }}
      >
        一般公開
      </Typography>
    </Box>
  );
};

export default IndicatePublic;
