import Grid from "@mui/material/Grid";

import Meta from "components/organisms/Meta";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";
import ThanksResetRequestBlock from "components/organisms/auth/ThanksResetRequestBlock";

const ThanksResetRequest: React.FC = () => (
  <AuthLayout>
    <Meta />
    <Grid
      container
      sx={{
        height: { xs: "auto", lg: "100vh" },
        position: "relative"
      }}
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sx={{
          height: { xs: "auto", lg: "100%" },
          position: "relative"
        }}
      >
        <ThanksResetRequestBlock variant="school" />
      </Grid>
    </Grid>
  </AuthLayout>
);

export default ThanksResetRequest;
