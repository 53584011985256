import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/lab/Alert";

import Portal from "components/layouts/Portal";

import type React from "react";

type SuccessAlertProps = {
  isSuccess: boolean;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  successMessage?: string;
};

const SuccessAlert: React.FC<SuccessAlertProps> = (props) => {
  const { isSuccess, setIsSuccess, successMessage } = props;
  const theme = useTheme();

  return (
    <Portal>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: { xs: theme.spacing(8), sm: theme.spacing(10) },
          width: "90vw",
          maxWidth: "720px",
          margin: "auto",
          zIndex: 1600
        }}
      >
        <Collapse in={isSuccess}>
          <Alert
            severity="success"
            elevation={2}
            sx={{
              "& .MuiAlert-icon": {
                alignItems: "center",
                marginTop: "2px"
              }
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {successMessage || "送信しました"}
          </Alert>
        </Collapse>
      </Box>
    </Portal>
  );
};

export default SuccessAlert;
