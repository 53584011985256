import Divider from "@mui/material/Divider";
import { useAtom } from "jotai";
import React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import MessageBlock from "features/requests/components/molecules/show/MessageBlock";
import MessageField from "features/requests/components/molecules/show/MessageField";
import MessageFileBlock from "features/requests/components/molecules/show/MessageFileBlock";

import useFormatDateTime from "hooks/useFormatDateTime";

import { currentUserAtom } from "store/AuthStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";

import {
  LessonRequestCommentsGet,
  LessonRequestTeacher,
  LessonRequestSchoolTeacher
} from "api/@types";

type ChatProps = {
  details?:
    | LessonRequestTeacher["details"]
    | LessonRequestSchoolTeacher["details"];
  teacher?: LessonRequestSchoolTeacher["teacher"];
  supporter?:
    | LessonRequestTeacher["supporter"]
    | LessonRequestSchoolTeacher["supporter"];
  schoolTeacher?: LessonRequestTeacher["send_user"];
  requestCommentsData?: LessonRequestCommentsGet;
  postRequestComments: (data: Methods["post"]["reqBody"]) => void;
  deleteRequestComments: (commentId: string) => void;
};

const Chat: React.FC<ChatProps> = (props) => {
  const {
    details,
    teacher,
    supporter,
    schoolTeacher,
    requestCommentsData,
    postRequestComments,
    deleteRequestComments
  } = props;

  const theme = useTheme();

  const [currentUser] = useAtom(currentUserAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);

  const { getFormatDateTime } = useFormatDateTime();

  const isChatActive = details?.lesson_completed_at
    ? dayjs().diff(dayjs(details.lesson_completed_at), "day") <= 7
    : true;

  return (
    <Box
      component="section"
      sx={{
        padding: "1.5rem",
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.border?.main}`,
        borderRadius: "4px"
      }}
    >
      {isChatActive && (
        <>
          <MessageField postRequestComments={postRequestComments} />
          <Divider />
        </>
      )}
      <div>
        {requestCommentsData?.lesson_request_comments.map((chat, index) => {
          if (chat.file?.url) {
            return (
              <React.Fragment key={index}>
                <Divider />
                <MessageFileBlock
                  messageId={chat.id}
                  sendUserId={chat.send_user.id}
                  src={chat.send_user.image.url}
                  name={chat.send_user.name}
                  date={getFormatDateTime(new Date(chat.updated_at))}
                  file={chat.file}
                  deleteRequestComments={deleteRequestComments}
                />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={index}>
              <Divider />
              <MessageBlock
                messageId={chat.id}
                sendUserId={chat.send_user.id}
                src={chat.send_user.image.url}
                name={
                  chat.send_user.category === "office_user"
                    ? `LX事務局−${chat.send_user.name}`
                    : chat.send_user.name
                }
                date={getFormatDateTime(new Date(chat.updated_at))}
                message={chat.comment}
                isDeleted={chat.is_deleted}
                isOfficeNotification={chat.is_office_notification}
                deleteRequestComments={deleteRequestComments}
              />
            </React.Fragment>
          );
        })}
        <Divider />
        <MessageBlock
          src=""
          name={`LX事務局−${supporter?.name}`}
          date={getFormatDateTime(new Date(details?.accept_at || ""))}
          message={`お世話になっております。LX事務局です。
この度はご縁に感謝しております。
今後、授業に関するやりとりは当チャットにてよろしくお願いいたします。
授業日の日時をお間違いないか、今一度ご確認をお願いいたします。
${
  details?.lesson_options.some((option) => option.id === 3)
    ? `授業の前に打ち合わせを行なっていただくため、事務局のからの連絡をお待ちください。`
    : `また、授業詳細を話し合うためのお打ち合わせの日時を確定していただきたく存じます。
お打ち合わせ日は授業日から２週間程前を目安にお願いいたします。
当チャット内にて学校様、複業先生様で日程調整、打ち合わせのURL発行をお願いいたします。`
}
授業終了まで、どうぞよろしくお願いいたします。`}
        />
        {details?.is_recruitment === true ? (
          <MessageBlock
            src={schoolTeacher?.image.url || currentSchool.image.url}
            name={schoolTeacher?.name || currentSchool.name}
            date={getFormatDateTime(new Date(details.accept_at || ""))}
            message={details.reply_comment}
            deleteRequestComments={deleteRequestComments}
          />
        ) : (
          <MessageBlock
            src={teacher?.image.url || currentUser.image.url}
            name={teacher?.name || currentUser.name}
            date={getFormatDateTime(new Date(details?.accept_at || ""))}
            message={details?.reply_comment || ""}
            deleteRequestComments={deleteRequestComments}
          />
        )}
      </div>
    </Box>
  );
};

export default Chat;
