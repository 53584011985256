import { Box, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import {
  candidatesAtom,
  recruitmentAtom
} from "store/schools/recruitments/ShowStore";
import { useFormContext } from "react-hook-form";
import CandidatesListItem from "./CandidatesListItem";

// TODO: CandidatesListItemのPropsをまとめ、このコンポーネントは不要にしたい
const SelectedCandidateDisplay: React.FC = () => {
  const theme = useTheme();
  const { watch } = useFormContext();

  const candidateId = watch("candidate_id");
  const candidates = useAtomValue(candidatesAtom);
  const recruitment = useAtomValue(recruitmentAtom);
  const selectedCandidateDisplay = candidates.find(
    (candidate) => candidate?.details?.id.toString() === candidateId?.toString()
  );

  if (!selectedCandidateDisplay) return null;
  return (
    <Box
      sx={{
        padding: "1rem",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border?.main}`
      }}
    >
      <CandidatesListItem
        candidateTeacherId={selectedCandidateDisplay.teacher.id}
        candidateAvatar={selectedCandidateDisplay.teacher.image.url || ""}
        candidateName={selectedCandidateDisplay.teacher.name}
        candidateTeacherRank={selectedCandidateDisplay.teacher.teacher_rank}
        candidateOrganizationName={
          selectedCandidateDisplay.teacher.organization_name || ""
        }
        candidateTotalRating={selectedCandidateDisplay.teacher.total_rating}
        candidateRatingCount={selectedCandidateDisplay.teacher.rating_count}
        schoolMinutes={recruitment?.school_minutes || 0}
        lessonContents={selectedCandidateDisplay.details.lesson_contents}
        appeal={selectedCandidateDisplay.details.appeal}
      />
    </Box>
  );
};

export default SelectedCandidateDisplay;
