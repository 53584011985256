import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import type React from "react";

import { LessonRequestTeacher } from "api/@types";

type QreSheetLinkProps = {
  details?: LessonRequestTeacher["details"];
};

const QreSheetLink: React.FC<QreSheetLinkProps> = (props) => {
  const { details } = props;

  // MEMO: 一旦回答期限を一週間後に設定。
  // カスタムフックの活用などで、マジックナンバーの除去が必要？
  const deadlineFormat = details?.lesson_completed_at
    ? dayjs(details.lesson_completed_at)
        .locale(ja)
        .add(1, "week")
        .format("YYYY年MM月DD日（ddd）")
    : "";

  return (
    <Box
      sx={{
        backgroundColor: "#FFF5DD",
        padding: "2rem 0"
      }}
    >
      {details?.lesson_completed_at && (
        <Typography
          sx={{ margin: "1.5rem auto" }}
          fontWeight="bold"
          align="center"
        >
          期限：{deadlineFormat}まで
        </Typography>
      )}
      <Box
        component="a"
        target="_blank"
        href="https://forms.gle/Qqz2LsVsRgXCvmvFA"
        rel="noreferrer"
        sx={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          sx={{
            display: "block",
            margin: "1rem auto",
            padding: ".5rem 2.5rem",
            fontWeight: "bold"
          }}
        >
          回答する
        </Button>
      </Box>
    </Box>
  );
};

export default QreSheetLink;
