import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import JuniorRankBadge from "assets/teachers/mypage/JuniorRankBadge.png";
import MiddleRankBadge from "assets/teachers/mypage/MiddleRankBadge.png";
import NoRankBadge from "assets/teachers/mypage/NoRankBadge.png";
import ProRankBadge from "assets/teachers/mypage/ProRankBadge.png";

// MEMO: 変則的なので、明示的型指定なし
const TeacherRankLabels = (rank: string) => {
  switch (rank) {
    case "ランクなし":
      return (
        <Box
          component="img"
          src={NoRankBadge}
          alt={rank}
          sx={{
            width: "3rem",
            display: "block",
            margin: "0 auto"
          }}
        />
      );
    case "ジュニア":
      return (
        <>
          <Box
            component="img"
            src={JuniorRankBadge}
            alt={rank}
            sx={{
              width: "3rem",
              display: "block",
              margin: "0 auto"
            }}
          />
          <Typography color="#B15C25" fontWeight="bold" align="center">
            ジュニア
          </Typography>
        </>
      );
    case "ミドル":
      return (
        <>
          <Box
            component="img"
            src={MiddleRankBadge}
            alt={rank}
            sx={{
              width: "3rem",
              display: "block",
              margin: "0 auto"
            }}
          />
          <Typography align="center" color="#7D7670" fontWeight="bold">
            ミドル
          </Typography>
        </>
      );
    case "プロ":
      return (
        <>
          <Box
            component="img"
            src={ProRankBadge}
            alt={rank}
            sx={{
              width: "3rem",
              display: "block",
              margin: "0 auto"
            }}
          />
          <Typography align="center" color="#B69201" fontWeight="bold">
            プロ
          </Typography>
        </>
      );
    default:
      return <img src={NoRankBadge} alt={rank} />;
  }
};

export default TeacherRankLabels;
