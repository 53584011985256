import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import SubmitButton from "features/root/components/atoms/contact/SubmitButton";
import ContactContentsField from "features/root/components/molecules/contact/ContactContentsField";
import ContactTypeField from "features/root/components/molecules/contact/ContactTypeField";
import EmailField from "features/root/components/molecules/contact/EmailField";
import FullNameField from "features/root/components/molecules/contact/FullNameField";

import type React from "react";

type FormProps = {
  handleSubmit: () => void;
};

const Form: React.FC<FormProps> = (props) => {
  const { handleSubmit } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        boxSizing: "border-box",
        padding: "1rem 1rem 0"
      }}
    >
      <Typography variant="h2">必要事項をご入力ください。</Typography>
      <FullNameField />
      <EmailField />
      <ContactTypeField />
      <ContactContentsField />
      <SubmitButton handleSubmit={handleSubmit} />
    </Box>
  );
};

export default Form;
