import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link } from "react-router-dom";

import HeaderLogo from "assets/HeaderLogo.png";
import AITeachingPlanIcon from "assets/schools/mypage/AITeachingPlanIcon.png";

import type React from "react";

const AITeachingPlanLead: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.lighter,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.secondary.main}`,
        width: "100%",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem"
        }}
      >
        <img src={AITeachingPlanIcon} alt="AI授業案" />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Box
            component="img"
            src={HeaderLogo}
            alt="複業先生"
            sx={{ height: "1rem" }}
          />
          <Typography>
            <Typography
              component="span"
              fontWeight="bold"
              sx={{ display: "inline-block" }}
            >
              AIで授業案を
            </Typography>
            <Typography
              component="span"
              fontWeight="bold"
              sx={{ display: "inline-block" }}
            >
              生成できます
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        component={Link}
        to="/schools/mypage/ai-teaching-plan/new"
        sx={{
          fontWeight: "bold",
          width: "100%"
        }}
      >
        無料で授業案をつくる
      </Button>
    </Box>
  );
};

export default AITeachingPlanLead;
