import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { useEffect } from "react";

import AnnouncementBox from "features/teachers/components/molecules/show/AnnouncementBox";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import {
  teacherAnnouncementsAtom,
  teacherDialogIsOpenAtom,
  teacherAnnouncementIsErrorAtom
} from "store/AnnouncementsStore";
import { currentUserAtom } from "store/AuthStore";

import type React from "react";

const AnnouncementDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useAtom(teacherDialogIsOpenAtom);
  const [currentUser] = useAtom(currentUserAtom);
  const [announcements, setAnnouncements] = useAtom(teacherAnnouncementsAtom);
  const [, setIsError] = useAtom(teacherAnnouncementIsErrorAtom);

  const { getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/teacher_announcements`
  });

  useEffect(() => {
    getRequest({
      onSuccess: (response) => {
        setAnnouncements(response.data);
        if (response.data.length > 0) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      },
      onError: () => {
        setIsError(true);
      }
    });
  }, [getRequest, setAnnouncements, setIsError, setIsOpen]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <Box sx={{ padding: "1rem" }}>
        <Typography
          fontSize="1.2rem"
          fontWeight="bold"
          margin="0 auto 1rem"
          align="center"
        >
          重要なお知らせ
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".75rem 0"
          }}
        >
          {announcements.map((announcement, index) => (
            <AnnouncementBox
              key={index}
              id={announcement.id}
              title={announcement.title}
              contents={announcement.contents}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AnnouncementDialog;
