import { useAtomValue } from "jotai";
import { currentUserAtom, isLoggedInAtom } from "store/AuthStore";
import { currentSchoolAtom, schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import { Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAlertMessages } from "./useAlertMessages";

const useAlertToNew = () => {
  const { addWarningMessage, clearAlertMessages } = useAlertMessages();
  const isTeacherLoggedIn = useAtomValue(isLoggedInAtom);
  const currentTeacher = useAtomValue(currentUserAtom);
  const isSchoolLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);

  useEffect(() => {
    clearAlertMessages();
    if (isTeacherLoggedIn && !currentTeacher.teacher_profile_id) {
      addWarningMessage(
        <Typography>
          プロフィール登録が未完了です。
          <Link href="/teachers/profile/new">こちら</Link>
          からプロフィール登録を完了してください。
        </Typography>
      );
    }
    if (isSchoolLoggedIn && !currentSchool.school_teacher_profile_id) {
      addWarningMessage(
        <Typography>
          プロフィール登録が未完了です。
          <Link href="/schools/profile/new">こちら</Link>
          からプロフィール登録を完了してください。
        </Typography>
      );
    }
  }, [currentTeacher, currentSchool]);
};

export default useAlertToNew;
