import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import type React from "react";

type ServiceListItemProps = {
  avatar: string;
  title: string;
  list: Array<string>;
  color: string;
};

const ServiceListItem: React.FC<ServiceListItemProps> = (props) => {
  const { avatar, title, list, color } = props;

  return (
    <Box
      sx={{
        padding: "1rem",
        fontSize: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: ".5rem"
        }}
      >
        <Avatar src={avatar} alt={title} sx={{ display: "block" }} />
        <Typography
          variant="h5"
          fontSize="1.1rem"
          fontWeight="bold"
          sx={{
            marginLeft: ".5rem"
          }}
        >
          {title}
        </Typography>
      </Box>
      <div>
        <Box
          component="ul"
          sx={{
            margin: "0 auto",
            paddingInlineStart: 0
          }}
        >
          {list.map((item, index) => (
            <Box
              component="li"
              key={index}
              sx={{
                listStyleType: "none",
                position: "relative",
                paddingLeft: "0.6rem",
                "&:before": {
                  borderRadius: "50%",
                  width: "6px",
                  height: "6px",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  top: { xs: "calc(.6rem + 6px)", sm: "calc(.6rem + 3px)" },
                  content: '""',
                  background: color
                }
              }}
            >
              <Box
                component="span"
                sx={{
                  whiteSpace: "pre-wrap",
                  fontSize: { xs: ".9rem", sm: "1rem" }
                }}
              >
                {item}
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    </Box>
  );
};

export default ServiceListItem;
