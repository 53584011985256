import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type ACCListItemProps = {
  text: string;
  exLink?: string;
  desc?: string;
};

const ACCListItem: React.FC<ACCListItemProps> = (props) => {
  const { text, exLink, desc } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        margin: "1rem auto"
      }}
    >
      <DoneOutlineIcon color="primary" sx={{ marginRight: "1rem" }} />
      <div>
        <Typography>{text}</Typography>
        {exLink && (
          <Box
            component="a"
            href={exLink}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: theme.palette.link?.main,
              textDecoration: "none"
            }}
          >
            <span>{exLink}</span>
            <LaunchIcon
              sx={{
                verticalAlign: "middle",
                height: "1rem"
              }}
            />
          </Box>
        )}
        {desc && <Typography>{desc}</Typography>}
      </div>
    </Box>
  );
};

export default ACCListItem;
