import Axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import useSchoolReset from "features/schools/hooks/useSchoolReset";
import {
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom,
  loginAgainModalAtom
} from "store/SchoolAuthStore";

import type { AxiosError } from "axios";

type usePostProps = {
  url: string;
  method?: "post" | "put" | "patch";
};

export type doPostProps = {
  varUrl?: string;
  params?: FormData;
  onSuccess?: (data?: any) => void;
  onError?: (error: AxiosError) => void;
};

const usePostFormDataRequest = ({ url, method = "post" }: usePostProps) => {
  const accessToken = useAtomValue(schoolAccessTokenAtom);
  const client = useAtomValue(schoolClientAtom);
  const uid = useAtomValue(schoolUidAtom);
  const [, setLoginAgainModal] = useAtom(loginAgainModalAtom);
  const history = useHistory();
  const [schoolReset] = useSchoolReset();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<any>();

  const memoizeUrl = useMemo(() => url, [url]);
  const memoizeAccessToken = useMemo(() => accessToken, [accessToken]);
  const memoizeClient = useMemo(() => client, [client]);
  const memoizeUid = useMemo(() => uid, [uid]);

  const postFormDataRequest = useCallback(
    async ({ varUrl, params, onSuccess, onError }: doPostProps) => {
      setIsLoading(true);
      await Axios[method](varUrl || memoizeUrl, params, {
        headers: {
          "access-token": memoizeAccessToken,
          uid: memoizeUid,
          client: memoizeClient,
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          onSuccess && onSuccess(response.data);
          return response.data;
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            schoolReset();
            setLoginAgainModal(true);
            history.push("/schools/login");
          }

          setIsError(true);
          setError(error);
          onError && onError(error);
          return null;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      method,
      memoizeUrl,
      memoizeAccessToken,
      memoizeUid,
      memoizeClient,
      schoolReset,
      setLoginAgainModal,
      history
    ]
  );

  return {
    postFormDataRequest,
    isLoading,
    isError,
    setIsError,
    error,
    setError
  };
};

export default usePostFormDataRequest;
