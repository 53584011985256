import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import prices from "constants/prices";

import type React from "react";

const PriceBox: React.FC = () => {
  const price = () => {
    const price: number = prices.lesson1time;

    return price;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFF5DD",
        width: "100%",
        margin: "1rem auto",
        padding: "1rem 1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Typography align="center" fontWeight="bold" fontSize="1.5rem">
        ¥{price().toLocaleString()}-
      </Typography>
      <Typography align="center" fontSize=".9rem">
        授業料目安（60分）
      </Typography>
    </Box>
  );
};

export default PriceBox;
