import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import TeacherImage1 from "assets/TeacherImage1.jpg";
import TeacherImage2 from "assets/TeacherImage2.jpg";
import TeacherImage3 from "assets/TeacherImage3.jpg";
import TeacherImage4 from "assets/TeacherImage4.jpg";
import TeacherImage5 from "assets/TeacherImage5.jpg";
import TeacherImage6 from "assets/TeacherImage6.jpg";
import ViewMoreTeachersButton from "features/root/components/atoms/ViewMoreTeachersButton";
import TeacherBox from "features/root/components/molecules/TeacherBox";
import TopPageTitle from "features/root/components/molecules/TopPageTitle";

import type React from "react";

// SwiperCore.use([Pagination, Autoplay]);
SwiperCore.use([Navigation]);

const TeachersBox: React.VFC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const overXs = useMediaQuery("(min-width:480px)");
  const underSm = useMediaQuery("(max-width: 720px)");
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const perView = () => {
    if (isXs) {
      return 1.2;
    }
    if (overXs && underSm) {
      return 1.8;
    }
    if (isSm) {
      return 2.2;
    }
    if (isMd) {
      return 2.7;
    }
    if (isLg) {
      return 3.5;
    }
    return 4.2;
  };

  return (
    <section>
      <Box
        sx={{
          padding: "calc(1.5rem + .25vw) 0",
          backgroundColor: "#FFF6DE"
        }}
      >
        <TopPageTitle title="TEACHERS" subText={['"複業先生"増えてます']} />
        <Box margin="2rem 0">
          <Swiper slidesPerView={perView()} navigation>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="主に海外生活が人生の半分以上という私の特殊な経歴や経験を授業でシェアしています。日本の日常生活では聞けない話、他国での学びや私の価値観を伝えることで、中高生が海外や英語学習に興味を持ってくれるので、とてもやりがいを感じています。"
                avatar={TeacherImage1}
                titles={["ブロガー、", "通訳"]}
                name="阿辻 香子（Coco）"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="子どもたちの前で講演する機会は以前からありましたが、複業先生によって日本全国の多種多様な子どもたちと接する機会をいただけるのは有り難いです。オンラインでも伝わる、心を揺さぶる授業は実現できると実感しました。"
                avatar={TeacherImage2}
                titles={["SOLTILO UGANDA Ltd.", "Managing Director"]}
                name="大場 由太"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="小中学生に向けての講義は初めてでしたが、事務局と一緒に丁寧に準備を進められ、スムーズに当日を迎えられました。何より、色々な学びに意欲的な子供たちに直接ものごとを伝え、反応をもらえる機会は自分にとって貴重な経験となりました。"
                avatar={TeacherImage3}
                titles={["株式会社LiB", "プロダクトマネージャー"]}
                name="武井 梨名"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="子どもたちに仕事の意義やプロセスを伝えることで、長らく携わっている仕事に新たな気持ちで向き合えるようになりました。「教えることで、気づかされる。」そんな体験を通して、こちらも学ばせていただきました。"
                avatar={TeacherImage4}
                titles={[
                  "株式会社東急エージェンシー",
                  "SDGsプランニング・ユニット POZI"
                ]}
                name="丸原 孝紀"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="事前に打ち合わせがしっかりできたことで、生徒たちが何を聞きたがっているのかを知ることができ、自分の今までの経験をどの切り口からお話すればよいかが分かりました。実際に生徒たちにメッセージが伝わったと感じることができた。"
                avatar={TeacherImage5}
                titles={["元外資系航空会社", "CA"]}
                name="坂東 法子"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeacherBox
                teachersVoice="事務局に事前の準備から、当日の盛り上げまでサポートいただいたことで、自分の経験談が飽きることなく生徒に伝わり、生徒皆さんの選択肢を広げる機会にできたと思います。生徒からの喜びのお手紙は宝物です！"
                avatar={TeacherImage6}
                titles={["NTT東日本", "人事育成"]}
                name="小林 千夏"
              />
            </SwiperSlide>
          </Swiper>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ViewMoreTeachersButton />
        </Box>
      </Box>
    </section>
  );
};

export default TeachersBox;
