import type { Crop } from "react-image-crop";

type CroppedImgProps = {
  crop: Crop;
  imageRef: HTMLImageElement | null;
};

const getCroppedImg = ({ crop, imageRef }: CroppedImgProps) => {
  const canvas = document.createElement("canvas");

  if (imageRef) {
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (ctx !== null) {
      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }
  }

  const url: string = canvas.toDataURL("image/png");
  const bin: string = atob(url.split(",")[1]);
  const buffer: any = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob: Blob = new Blob([buffer.buffer], { type: "image/png" });

  return blob;
};

export default getCroppedImg;
