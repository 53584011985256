import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

const InspectionDisplay: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: "8px",
        fontWeight: 600,
        margin: "1rem auto"
      }}
    >
      <Box
        sx={{
          padding: ".5rem 0",
          backgroundColor: theme.palette.primary.main
        }}
      >
        <Typography align="center" color={theme.palette.common.white}>
          あなたのステータス
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "1rem 0",
          backgroundColor: theme.palette.backgroundColor?.main
        }}
      >
        <Typography align="center" fontSize="1.5rem" fontWeight="bold">
          審査中
        </Typography>
      </Box>
    </Box>
  );
};

export default InspectionDisplay;
