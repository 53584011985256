import Grid from "@mui/material/Grid";

import AuthEyeCatch from "features/teachers/components/atoms/auth/AuthEyeCatch";
import Meta from "components/organisms/Meta";
import ThanksSignupBlock from "features/teachers/components/organisms/auth/ThanksSignupBlock";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";

const ThanksSignup: React.FC = () => (
  <AuthLayout>
    <Meta />
    <Grid
      container
      sx={{ height: { xs: "100%", lg: "100vh" } }}
      alignItems="center"
    >
      <Grid item xs={12} lg={6} sx={{ height: "100%" }}>
        <AuthEyeCatch />
      </Grid>
      <Grid item xs={12} lg={6} sx={{ height: "100%" }}>
        <ThanksSignupBlock />
      </Grid>
    </Grid>
  </AuthLayout>
);

export default ThanksSignup;
