import { atom } from "jotai";

import type { commonRecruitmentResponseType } from "types/lessonRecruitmentsTypes";

type paginationType = {
  current: number;
  previous: number;
  next: number | null;
  limit_value: number;
  pages: number;
  count: number;
};

export const commonRecruitmentsAtom =
  atom<commonRecruitmentResponseType | null>(null);

export const paginationAtom = atom<paginationType | null>(null);

export const pageAtom = atom(1);
