import useTheme from "@mui/material/styles/useTheme";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

// MEMO: 変則的なので、明示的型指定なし
const TeacherRankCardContents = (rank: string) => {
  const theme = useTheme();

  switch (rank) {
    case "ランクなし":
      return (
        <>
          <Box
            sx={{
              display: "flex",
              gap: { xs: ".1rem", sm: "1rem" },
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Typography fontWeight="bold">ジュニアランクになるには</Typography>
          </Box>
          <Typography sx={{ lineHeight: "1.8rem" }}>
            e-Learningを受講し、合格する（所要目安：15分）
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            component={Link}
            to="/teachers/mypage/e-learnings/new"
            sx={{
              fontWeight: "bold",
              width: "160px",
              height: "2rem",
              alignSelf: "flex-start"
            }}
          >
            受講する
          </Button>
        </>
      );
    case "ジュニア":
      return (
        <>
          <Box
            sx={{
              display: "flex",
              gap: { xs: ".1rem", sm: "1rem" },
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Typography fontWeight="bold">ミドルランクになるには</Typography>
          </Box>
          <Typography sx={{ lineHeight: "1.8rem" }}>
            以下のいずれかを満たすこと
            <br />
            ①養成講座2回受講 + 動画提出or指導案（資料込み）提出
            <br />
            動画の提出は
            <MuiLink
              href="/teachers/profile/edit?accordion=intro-videos"
              sx={{
                color: theme.palette.link?.main,
                fontWeight: "bold"
              }}
            >
              こちら
            </MuiLink>
            からお願いします。
            <br />
            ②授業登壇回数2回 + 平均評価4以上
          </Typography>
        </>
      );
    case "ミドル":
      return (
        <>
          <Box
            sx={{
              display: "flex",
              gap: { xs: ".1rem", sm: "1rem" },
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Typography fontWeight="bold">プロランクになるには</Typography>
          </Box>
          <Typography sx={{ lineHeight: "1.8rem" }}>
            下記条件を満たすこと
            <br />
            ・授業に5回以上登壇
            <br />
            ・評価の平均が4.5以上
          </Typography>
        </>
      );
    case "プロ":
      return (
        <Typography sx={{ lineHeight: "1.8rem" }}>
          現在のランクが最高ランクになっております。
        </Typography>
      );
    default:
      return (
        <>
          <Box
            sx={{
              display: "flex",
              gap: { xs: ".1rem", sm: "1rem" },
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Typography fontWeight="bold">ジュニアランクになるには</Typography>
          </Box>
          <Typography sx={{ lineHeight: "1.8rem" }}>
            e-Learningを受講し、合格する（所要目安：15分）
          </Typography>
        </>
      );
  }
};

export default TeacherRankCardContents;
