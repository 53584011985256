import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import RatingDislay from "features/requests/components/organisms/Show/RatingDisplay";
import AfterClassCheckList from "features/schools/components/organisms/show/requests/AfterClassCheckList";
import RatingForm from "features/schools/components/organisms/show/requests/RatingForm";

import type React from "react";
import { LessonRequestSchoolTeacher, RatingGet } from "api/@types";
import { postRatingsSchemaType } from "features/schools/constants/postRatingsSchema";

type Step03Props = {
  details?: LessonRequestSchoolTeacher["details"];
  ratingDetails?: RatingGet["details"];
  postRating: (data: postRatingsSchemaType) => void;
};

const Step03: React.FC<Step03Props> = (props) => {
  const { details, postRating, ratingDetails } = props;

  const deadlineFormat = details?.lesson_completed_at
    ? dayjs(details.lesson_completed_at)
        .locale(ja)
        .add(1, "week")
        .format("YYYY年MM月DD日（ddd）")
    : "";

  const claimTiming =
    (new Date(details?.exact_start_at || "").getMonth() + 2) % 12 || 12;

  const isRating =
    ratingDetails?.lesson_rating || ratingDetails?.teacher_rating;

  return (
    <div>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Typography>
          <Box component="span" sx={{ display: "inline-block" }}>
            この度は複業先生をご利用いただき
          </Box>
          <Box component="span" sx={{ display: "inline-block" }}>
            ありがとうございました。
          </Box>
          <br />
          <Box component="span" sx={{ display: "inline-block" }}>
            {claimTiming}
            月中に、請求書をメールで送付するのでご対応お願いします。
          </Box>
          <br />
          <br />
          <Box component="span" sx={{ display: "inline-block" }}>
            最後に、授業後チェックリストをご確認ください。
          </Box>
        </Typography>
        <Typography
          align="center"
          fontWeight="bold"
          sx={{ margin: "1.5rem auto" }}
        >
          期限：{deadlineFormat}まで
        </Typography>
      </Box>
      {!isRating && <RatingForm postRating={postRating} />}
      <AfterClassCheckList />
      {isRating && <RatingDislay ratingDetails={ratingDetails} />}
    </div>
  );
};

export default Step03;
