import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type React from "react";

type ImportantInfoCardProps = {
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  title: string;
  mainInfo: string;
  subInfo?: string;
};

const ImportantInfoCard: React.FC<ImportantInfoCardProps> = (props) => {
  const { Icon, title, mainInfo, subInfo } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.backgroundColor?.main,
        gap: ".25rem",
        padding: "1rem",
        width: { xs: "100%", sm: "138px" },
        height: { xs: "100%", sm: "138px" }
      }}
    >
      <Icon />
      <Typography color={theme.palette.sub?.main} fontSize=".9rem">
        {title}
      </Typography>
      <Typography fontWeight="bold">{mainInfo}</Typography>
      {subInfo && <Typography fontSize=".9rem">{subInfo}</Typography>}
    </Box>
  );
};

export default ImportantInfoCard;
