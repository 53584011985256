import Button from "components/atoms/mui/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import type React from "react";
import { LessonRequestSchoolTeacher } from "api/@types";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

type UpdateExactStartAtDisplayProps = {
  details?: LessonRequestSchoolTeacher["details"];
  onOpen: () => void;
};

const UpdateExactStartAtDisplay: React.FC<UpdateExactStartAtDisplayProps> = (
  props
) => {
  const { details, onOpen } = props;
  const theme = useTheme();

  const formatJapaneseDaytime = dayjs(details?.exact_start_at)
    .locale(ja)
    .format("YYYY年MM月DD日（ddd） HH時mm分");
  const canChange = dayjs(details?.exact_start_at).isAfter(dayjs());

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "4px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5rem"
      }}
    >
      {canChange && (
        <Button
          color="secondary"
          variant="text"
          size="small"
          isLink
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            fontWeight: "bold",
            padding: ".2rem .5rem"
          }}
          onClick={onOpen}
        >
          変更する
        </Button>
      )}
      <Typography fontWeight="bold">授業実施日と開始時間</Typography>
      <Typography fontWeight="bold" fontSize="1.1rem">
        {formatJapaneseDaytime}
      </Typography>
    </Box>
  );
};

export default UpdateExactStartAtDisplay;
