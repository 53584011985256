import Axios from "axios";
import { useAtom } from "jotai";
import { useCallback } from "react";

import {
  currentSchoolAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom,
  schoolTokenTypeAtom,
  schoolIsLoggedInAtom
} from "store/SchoolAuthStore";

const useSchoolReset = () => {
  const [, setCurrentSchool] = useAtom(currentSchoolAtom);
  const [accessToken, setAccessToken] = useAtom(schoolAccessTokenAtom);
  const [client, setClient] = useAtom(schoolClientAtom);
  const [uid, setUid] = useAtom(schoolUidAtom);
  const [, setTokenType] = useAtom(schoolTokenTypeAtom);
  const [, setIsLoggedIn] = useAtom(schoolIsLoggedInAtom);

  const schoolReset = useCallback(async () => {
    await Axios.delete("/api/api/v1/school_teacher_auth/sign_out", {
      headers: {
        "access-token": accessToken,
        uid,
        client
      }
    })
      .then(() => {
        setAccessToken("");
        setClient("");
        setUid("");
        setTokenType("");
        setIsLoggedIn(false);
        setCurrentSchool({
          allow_password_change: false,
          deleted_at: "",
          email: "",
          id: 0,
          is_public: false,
          is_term_accepted: false,
          provider: "",
          school_teacher_profile_id: 0,
          uid: "",
          user_id: 0,
          name: "",
          image: { url: "" },
          user_type: "",
          is_match_accepted: false
        });
      })
      .catch(() => {
        // 失敗した際もログアウトさせる
        setAccessToken("");
        setClient("");
        setUid("");
        setTokenType("");
        setIsLoggedIn(false);
        setCurrentSchool({
          allow_password_change: false,
          deleted_at: "",
          email: "",
          id: 0,
          is_public: false,
          is_term_accepted: false,
          provider: "",
          school_teacher_profile_id: 0,
          uid: "",
          user_id: 0,
          name: "",
          image: { url: "" },
          user_type: "",
          is_match_accepted: false
        });
      });
  }, [
    accessToken,
    client,
    setAccessToken,
    setClient,
    setCurrentSchool,
    setIsLoggedIn,
    setTokenType,
    setUid,
    uid
  ]);

  return [schoolReset];
};

export default useSchoolReset;
