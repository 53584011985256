import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { VideoJS } from "components/atoms/VideoJS";

import type React from "react";
import type videojs from "video.js";
import { useDialog } from "hooks/useDialog";
import VideoDeleteModal from "./VideoDeleteModal";

type IntroVideoPreviewProps = {
  id: number;
  url: string;
  isPublic: boolean;
  removeTeacherVideo: (videoId: number) => Promise<void>;
};

const IntroVideoPreview: React.FC<IntroVideoPreviewProps> = (props) => {
  const { id, url, isPublic, removeTeacherVideo } = props;
  const theme = useTheme();
  const fileRemoveModal = useDialog();

  const videoJSOptions: videojs.PlayerOptions = {
    controls: true,
    aspectRatio: "16:9",
    fluid: true,
    sources: [
      {
        src: url,
        type: "video/mp4"
      }
    ]
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: ".5rem",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.border?.main}`
        }}
      >
        <Box sx={{ width: "100%" }}>
          <VideoJS options={videoJSOptions} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography fontSize="0.8rem" color={theme.palette.sub.main}>
            {isPublic ? "公開中" : "審査中"}
          </Typography>
          <Button
            color="inherit"
            variant="text"
            onClick={fileRemoveModal.onOpen}
            startIcon={<DeleteForeverIcon />}
            size="small"
            sx={{
              padding: ".5rem"
            }}
          >
            削除する
          </Button>
        </Box>
      </Box>
      <VideoDeleteModal
        videoId={id}
        removeTeacherVideo={removeTeacherVideo}
        fileRemoveModalControl={fileRemoveModal}
      />
    </>
  );
};

export default IntroVideoPreview;
