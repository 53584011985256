import type React from "react";
import FormLabel from "components/atoms/FormLabel";
import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "components/atoms/mui/Button";
import { Add } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import SiteCard from "../../atoms/profile/SiteCard";

const SitesField: React.FC = () => {
  const { control } = useFormContext();
  const webSites = useFieldArray({
    control,
    name: "websites"
  });

  return (
    <Box>
      <FormLabel
        labelName="活動内容がわかるWEBサイト・SNS"
        isRequired={false}
      />
      <Grid container>
        {webSites.fields.map((webSite, index) => (
          <Grid item xs={12} key={webSite.id}>
            <SiteCard
              removeSite={() => {
                webSites.remove(index);
              }}
              order={index}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            startIcon={<Add />}
            variant="text"
            onClick={() => {
              webSites.append({ title: "", url: "" });
            }}
            color="secondary"
          >
            サイトを追加
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SitesField;
