import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

import type React from "react";

type IntroVideoModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  videoUrl: string;
};

const IntroVideoModal: React.FC<IntroVideoModalProps> = (props) => {
  const { open, setOpen, videoUrl } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} fullWidth>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px",
          zIndex: 1600
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 6,
            top: 6
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          padding: "1rem 2rem",
          height: "100%"
        }}
      >
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%", // 16:9 のアスペクト比
            height: 0,
            overflow: "hidden"
          }}
        >
          <Box
            component="video"
            src={videoUrl}
            title="intro video in modal"
            controls
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: 0
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default IntroVideoModal;
