import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useState } from "react";

import SeeNextButton from "features/teachers/components/atoms/show/v2/SeeNextButton";
import BasicProfileBox from "features/teachers/components/molecules/show/v2/BasicProfileBox";
import CareerBox from "features/teachers/components/molecules/show/v2/CareerBox";
import LicenseBox from "features/teachers/components/molecules/show/v2/LicenseBox";
import WebsitesBox from "features/teachers/components/molecules/show/v2/WebsitesBox";
import { teacherInfoAtom } from "store/TeachersShowStore";

import type React from "react";

const ProfileBox: React.FC = () => {
  const theme = useTheme();
  const teacherInfo = useAtomValue(teacherInfoAtom);
  const [seeNext, setSeeNext] = useState(false);

  return (
    <Box component="section" sx={{ boxSizing: "border-box" }}>
      <Typography
        variant="h3"
        sx={{
          margin: "0 auto .5rem",
          padding: { xs: "0 1rem", sm: "inherit" }
        }}
      >
        プロフィール
      </Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          position: "relative"
        }}
      >
        <Collapse in={seeNext} collapsedSize={270}>
          <Box
            sx={{
              padding: { xs: "1rem", sm: "1.5rem" },
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <BasicProfileBox />
            {teacherInfo.websites.length !== 0 && <WebsitesBox />}
            <CareerBox />
            {teacherInfo.teaching_licenses.length !== 0 && <LicenseBox />}
          </Box>
        </Collapse>
        <SeeNextButton seeNext={seeNext} setSeeNext={setSeeNext} />
      </Box>
    </Box>
  );
};

export default ProfileBox;
