import FormTextArea from "components/molecules/FormTextArea";

import type React from "react";

const Step02: React.FC = () => (
  <>
    <FormTextArea
      name="lesson_contents"
      label="どんな授業ができるか、記入してください"
      placeholder="例：私は現在、IT系の事業に携わっており、実務経験を通じて得た知識やスキルを若い世代に伝えることに大きな関心を持っています。特に、論理的思考に重点を置いた授業を考えています。"
      isRequired
    />
    <FormTextArea
      name="appeal"
      label="授業への意気込みをお願いします。"
      placeholder="例：授業を通じて、生徒一人一人が自己の強みや関心を発見し、それをキャリア形成に活かす方法を模索できるようサポートいたします！"
      isRequired
    />
  </>
);

export default Step02;
