import StepTitle from "features/teachers/components/atoms/profile/StepTitle";
import LicenseField from "features/teachers/components/molecules/profile/LicenseField";
import OriginSchoolField from "features/teachers/components/molecules/profile/OriginSchoolField";

import type React from "react";
import FormSelect from "components/molecules/FormSelect";
import { useAtomValue } from "jotai";
import { prefectures } from "features/teachers/stores/profileStore";
import FormTextField from "components/molecules/FormTextField";
import { Box } from "@mui/material";

const NewProfileFormStep2: React.FC = () => {
  const prefectureList = useAtomValue(prefectures);
  const prefectureOptions = prefectureList.map((p) => ({
    value: p.id,
    label: p.name
  }));

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "0.5rem 0"
      }}
    >
      <StepTitle
        stepName={["経歴について教えてください。"]}
        indicateStatus="public"
      />
      <FormSelect
        name="origin_prefecture_id"
        label="出身地"
        options={prefectureOptions}
        isRequierd
        fullWidth
      />
      <FormSelect
        name="residence_prefecture_id"
        label="居住地"
        options={prefectureOptions}
        isRequierd
        fullWidth
      />
      <OriginSchoolField
        name="origin_high_school_name"
        codeName="origin_high_school_code"
        label="出身高校（または高等専門学校）"
        schoolDivision="high_school"
      />
      <OriginSchoolField
        name="origin_school_name"
        codeName="origin_school_code"
        label="出身大学（または専門学校）"
        schoolDivision="university"
      />
      <FormTextField
        name="origin_graduate_school_name"
        label="出身大学院等"
        placeholder="例：〇〇大学院"
        fullWidth
      />
      <LicenseField />
      <FormTextField
        name="license"
        label="その他の免許・資格"
        placeholder="例：潜水士免許"
        fullWidth
      />
    </Box>
  );
};

export default NewProfileFormStep2;
