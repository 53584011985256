import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link } from "react-router-dom";

import HeaderLogo from "assets/HeaderLogo.png";

import type React from "react";

const AuthHeader: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" elevation={0} color="transparent">
        <Toolbar>
          <Box
            component={Link}
            to="/"
            sx={{
              display: "flex",
              textDecoration: "none",
              color: theme.palette.textColor.main,
              flexGrow: 1
            }}
          >
            <img src={HeaderLogo} alt="複業先生" />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AuthHeader;
