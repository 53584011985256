import Container from "@mui/material/Container";
import { useAtom } from "jotai";
import { useLayoutEffect, useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";

import ErrorAlert from "components/atoms/ErrorAlert";
import Footer from "components/organisms/Footer";
import Meta from "components/organisms/Meta";
import Appeal from "features/root/components/organisms/lpClassi/Appeal";
import Conclusion from "features/root/components/organisms/lpClassi/Conclusion";
import FirstView from "features/root/components/organisms/lpClassi/FirstView";
import MainContents from "features/root/components/organisms/lpClassi/MainContents";
import useGetRequest from "hooks/useGetRequest";
import { exampleTeachersAtom } from "store/LpClassiStore";

import type React from "react";
import AuthLayout from "components/layouts/AuthLayout";

const LPClassi: React.FC = () => {
  const [, setExampleTeachers] = useAtom(exampleTeachersAtom);
  const { getRequest, isError, setIsError } = useGetRequest({
    url: "/api/api/v1/teachers/lp_classi"
  });

  const [, setCookie] = useCookies(["classi_key"]);
  useEffect(() => {
    setCookie("classi_key", "f5XUpRqV");
  });

  useLayoutEffect(() => {
    // MEMO: 動画を公開する先生方をここで選択
    const ids = {
      teacher_ids: [
        "33",
        "861",
        "851",
        "895",
        "1010",
        "520",
        "42",
        "758",
        "784",
        "928",
        "245",
        "953",
        "936",
        "843",
        "490",
        "1159",
        "965",
        "92",
        "1303",
        "1133",
        "773",
        "256",
        "381",
        "515",
        "414",
        "500",
        "125",
        "1016"
      ]
    };

    getRequest({
      params: ids,
      onSuccess: (response) => {
        setExampleTeachers(response.data);
      }
    });
  }, [getRequest, setExampleTeachers]);

  return (
    <CookiesProvider>
      <AuthLayout>
        <Meta noIndex />
        <ErrorAlert
          isError={isError}
          setIsError={setIsError}
          errorMessage="授業情報の取得に失敗しました"
        />
        <FirstView />
        <Container maxWidth="lg">
          <MainContents />
          <Appeal />
        </Container>
        <Conclusion />
        <Footer />
      </AuthLayout>
    </CookiesProvider>
  );
};

export default LPClassi;
