import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";

import FormLabel from "components/atoms/FormLabel";
import IndicatePrivate from "components/atoms/fieldLabels/IndicatePrivate";
import TextField from "components/atoms/fields/TextField";

import { useFormContext, Controller } from "react-hook-form";

type FormTextFieldProps = {
  name: string;
  label: string;
  supplementalText?: string;
  isRequired?: boolean;
  noLabel?: boolean;
  noLabelAndTitle?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  isPrivate?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
} & MuiTextFieldProps;

const FormTextField: React.FC<FormTextFieldProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const {
    name,
    label,
    supplementalText,
    isRequired,
    noLabel,
    noLabelAndTitle,
    placeholder,
    fullWidth,
    multiline,
    isPrivate,
    disabled = false,
    readOnly = false,
    type = "text"
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {!noLabelAndTitle ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "0.25rem"
          }}
        >
          {noLabel ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {label}
            </Typography>
          ) : (
            <FormLabel labelName={label} isRequired={isRequired} />
          )}
          {supplementalText && (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.sub.main }}
            >
              {supplementalText}
            </Typography>
          )}
        </Box>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...props}
            placeholder={placeholder}
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString()}
            fullWidth={fullWidth}
            multiline={multiline}
            disabled={disabled}
            InputProps={{
              readOnly,
              ...props.InputProps
            }}
            label=""
            type={type}
          />
        )}
      />
      {isPrivate && <IndicatePrivate />}
    </Box>
  );
};

export default FormTextField;
