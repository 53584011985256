import FormTextField from "components/molecules/FormTextField";
import LicenseField from "features/teachers/components/molecules/profile/LicenseField";
import ProfileEditContentsAccordion from "features/teachers/components/organisms/profile/edit/ProfileEditContentsAccordion";

import type React from "react";

const LicensesCard: React.FC = () => (
  <ProfileEditContentsAccordion
    title="資格"
    label="licenses"
    fields={["teaching_licenses", "license"]}
  >
    <LicenseField />
    <FormTextField
      name="license"
      label="その他の免許・資格"
      placeholder="例：潜水士免許"
      fullWidth
    />
  </ProfileEditContentsAccordion>
);

export default LicensesCard;
