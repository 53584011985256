import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

import ErrorAlert from "components/atoms/ErrorAlert";
import Meta from "components/organisms/Meta";
import Sidebar from "features/schools/components/organisms/common/Sidebar";
import PublicCheckAlert from "features/schools/components/organisms/mypage/v2/PublicCheckAlert";
import AnnouncementDialog from "features/schools/components/organisms/show/AnnouncementDialog";
import RatingsAttentionModal from "features/schools/components/organisms/show/requests/RatingsAttentionModal";
import Home from "features/schools/components/templates/mypage/Home";
import Lessons from "features/schools/components/templates/mypage/Lessons";
import Recruitments from "features/schools/components/templates/mypage/Recruitments";
import Requests from "features/schools/components/templates/mypage/Requests";
import useGetRequest from "features/schools/hooks/useGetRequest";
import useQuery from "hooks/useQuery";
import CommonLayout from "components/layouts/CommonLayout";
import { schoolAnnouncementIsErrorAtom } from "store/AnnouncementsStore";
import { noRatingRequestsAtom } from "store/RequestIndexStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import { useDialog } from "hooks/useDialog";

const Mypage_v2: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getQuery } = useQuery();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [, setNoRatingRequests] = useAtom(noRatingRequestsAtom);
  const [announcementIsError, setAnnouncementIsError] = useAtom(
    schoolAnnouncementIsErrorAtom
  );

  const ratingsAttentionModal = useDialog();

  const pageName = getQuery("page_name");

  const noRatingRequests = useAtomValue(noRatingRequestsAtom);

  // 未評価の案件がある場合、評価依頼のモーダルを開く
  useEffect(() => {
    if (noRatingRequests.length !== 0) {
      ratingsAttentionModal.onOpen();
    }
  }, [noRatingRequests]);

  const {
    getRequest: getNoRatingLessonRequest,
    isError: noRatingLessonRequestIsError,
    setIsError: setNoRatingLessonRequestIsError
  } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lesson_requests/no_rating`
  });

  const getTemplate = () => {
    switch (pageName) {
      case "lessons":
        return <Lessons />;
      case "requests":
        return <Requests />;
      case "recruitments":
        return <Recruitments />;
      default:
        return <Home />;
    }
  };

  useEffect(() => {
    getNoRatingLessonRequest({
      onSuccess: (response: any) => {
        setNoRatingRequests(response.data);
      }
    });
  }, [currentSchool, getNoRatingLessonRequest, setNoRatingRequests]);

  return (
    <>
      <Meta title="複業先生｜教員マイページ" />
      <ErrorAlert
        isError={noRatingLessonRequestIsError}
        setIsError={setNoRatingLessonRequestIsError}
        errorMessage="未評価の案件の取得に失敗しました"
      />
      <ErrorAlert
        isError={announcementIsError}
        setIsError={setAnnouncementIsError}
        errorMessage="お知らせの処理に失敗しました"
      />
      <CommonLayout>
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              gap: "1.5rem",
              padding: "1.5rem"
            }}
          >
            {!isMobile && <Sidebar />}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem"
              }}
            >
              {currentSchool.is_public || <PublicCheckAlert />}
              {getTemplate()}
            </Box>
          </Container>
        </Box>
      </CommonLayout>
      <RatingsAttentionModal
        isOpen={ratingsAttentionModal.isOpen}
        onClose={ratingsAttentionModal.onClose}
      />
      <AnnouncementDialog />
    </>
  );
};

export default Mypage_v2;
