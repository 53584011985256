import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { currentUserAtom, isLoggedInAtom } from "store/AuthStore";
import { currentSchoolAtom, schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import FormTextField from "components/molecules/FormTextField";
import FormCheckBox from "components/molecules/FormCheckBox";

import Typography from "@mui/material/Typography";
import type React from "react";
import { useTheme } from "@mui/material";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import postChatFormSchema from "features/requests/constants/postChatFormShema";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FileUploadModal from "features/requests/components/molecules/show/FileUploadModal";
import FileUploadLoading from "features/requests/components/molecules/show/FileUploadLoading";

import { useDialog } from "hooks/useDialog";

type MessageFieldProps = {
  postRequestComments: (data: Methods["post"]["reqBody"]) => any;
};

const MessageField: React.FC<MessageFieldProps> = (props) => {
  const { postRequestComments } = props;
  const fileUploadModal = useDialog();
  const [currentUser] = useAtom(currentUserAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(postChatFormSchema)
  });

  const getImage = () => {
    let image = "";

    if (isLoggedIn) {
      image = currentUser.image.url;
    }

    if (schoolIsLoggedIn) {
      image = currentSchool.image.url;
    }

    return image;
  };

  const getName = () => {
    let name = "";

    if (isLoggedIn) {
      name = currentUser.name;
    }

    if (schoolIsLoggedIn) {
      name = currentSchool.name;
    }

    return name;
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    await postRequestComments(data);
    methods.reset();
  });

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={onSubmit} sx={{ margin: "1.5rem auto" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            margin: "1rem auto"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Avatar
              alt={getName()}
              src={getImage()}
              sx={{ marginRight: "1rem" }}
            />
          </Box>
          <FormTextField
            name="comment"
            label=""
            placeholder="メッセージを入力してください"
            noLabelAndTitle
            fullWidth
            multiline
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <FormCheckBox
            name="is_office_notification"
            label="このメッセージに対する事務局からの回答を希望する"
          />
          <Typography variant="caption">
            ※事務局にサポートをお願いしたい場合はチェックしてください
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "1rem auto",
            gap: "1rem"
          }}
        >
          {methods.formState.errors.file?.message && (
            <Typography color={theme.palette.error.main} fontSize=".8rem">
              {methods.formState.errors.file?.message.toString()}
            </Typography>
          )}
          <Button
            color="inherit"
            startIcon={
              <InsertDriveFileOutlinedIcon sx={{ fontSize: ".8rem" }} />
            }
            onClick={fileUploadModal.onOpen}
            sx={{ height: "3rem", width: "10rem", padding: ".5rem" }}
          >
            ファイルを送信
          </Button>
          <FormSubmitButton
            label="送信する"
            sx={{ height: "3rem", width: "10rem" }}
          />
        </Box>
      </Box>
      <FileUploadModal
        isOpen={fileUploadModal.isOpen}
        onClose={fileUploadModal.onClose}
        onSubmit={onSubmit}
      />
      <FileUploadLoading
        isSubmitting={
          methods.formState.isSubmitting && !!methods.getValues("file")
        }
      />
    </FormProvider>
  );
};

export default MessageField;
