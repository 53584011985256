import Button from "components/atoms/mui/Button";
import { ButtonProps } from "@mui/material";
import { useFormContext } from "react-hook-form";

type FormSubmitButtonProps = {
  label: string;
} & ButtonProps;

const FormSubmitButton: React.FC<FormSubmitButtonProps> = (props) => {
  const {
    formState: { isValid, isDirty, isSubmitting }
  } = useFormContext();
  const { label, fullWidth } = props;

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={!isValid || !isDirty || isSubmitting}
      disableElevation
      fullWidth={fullWidth}
      {...props}
    >
      {label}
    </Button>
  );
};

export default FormSubmitButton;
