import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type ConfirmFieldProps = {
  title: string;
  value: string | React.ReactNode;
};

const ConfirmField: React.FC<ConfirmFieldProps> = (props) => {
  const { title, value } = props;

  return (
    <Box sx={{ margin: "1rem auto" }}>
      <Typography fontWeight="bold" fontSize="1.1rem" marginBottom=".2rem">
        {title}
      </Typography>
      {typeof value === "string" ? <Typography>{value}</Typography> : value}
    </Box>
  );
};

export default ConfirmField;
