import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AnswerField from "features/teachers/components/molecules/mypage/eLearning/AnswerField";
import AnswerResult from "features/teachers/components/molecules/mypage/eLearning/AnswerResult";

import type React from "react";

type PartProps = {
  isResult?: boolean;
};

const Part3: React.FC<PartProps> = (props) => {
  const { isResult } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        ③授業本番に向けては、
      </Typography>
      <Typography sx={{ lineHeight: "2rem !important" }}>
        <span>
          必要な資料を手元に用意し、余裕を持って準備を整え集合することでスムーズなスタートを切りましょう。
        </span>
        <br />
        <span>
          緊急時は、事前に交換した学校とLX事務局の緊急連絡先に連絡をいれましょう。
        </span>
        <br />
        <span>授業中は、声のトーンや</span>
        {isResult ? (
          <AnswerResult index={7} />
        ) : (
          <AnswerField index={7} wordCount={2} />
        )}
        <span>
          、目線を意識し、児童生徒により伝わりやすいよう配慮しましょう。
        </span>
        <br />
        <span>
          授業を一方的に進めるのではなく、時には呼びかけたり、チャットを活用したり、児童生徒や先生方を巻き込んだ活動を取り入れることで、授業に参加しやすい雰囲気を作りましょう。
        </span>
      </Typography>
    </Box>
  );
};

export default Part3;
