import React from "react";
import {
  Checkbox as MuiCheckbox,
  FormControlLabelProps,
  FormControlLabel,
  useTheme
} from "@mui/material";

type CheckboxProps = { noDecoration?: boolean } & Omit<
  FormControlLabelProps,
  "control"
>;

const Check: React.FC<CheckboxProps> = (props) => {
  const theme = useTheme();
  const outlinedStyle = {
    padding: "12px 16px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border?.main}`,
    "&:hover": {
      borderColor: theme.palette.textColor.main
    },
    "&.Mui-disabled": {
      borderColor: theme.palette.border?.main
    }
  };

  return (
    <FormControlLabel
      {...props}
      sx={props.noDecoration ? {} : outlinedStyle}
      control={<MuiCheckbox />}
    />
  );
};

export default Check;
