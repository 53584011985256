import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";

import type React from "react";

type RequestItemDataProps = {
  schoolName: string;
  schoolTeacherName: string;
  schoolTeacherImage: string;
  isCertification: boolean;
};

const RequestItemData: React.FC<RequestItemDataProps> = (props) => {
  const { schoolName, schoolTeacherName, schoolTeacherImage, isCertification } =
    props;
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <Avatar
        src={schoolTeacherImage}
        alt={schoolTeacherName}
        sx={{
          width: "3.5rem",
          height: "3.5rem",
          marginRight: "1rem"
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".25rem",
          justifyContent: "center"
        }}
      >
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          {schoolName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography fontWeight="bold">{schoolTeacherName}</Typography>
          {isCertification && (
            <Box
              component="img"
              src={SchoolCertificationBadge}
              alt="認定校"
              sx={{
                display: "block",
                margin: "0 .5rem",
                // MEMO: 解像度を合わせるための調整
                width: "85px"
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestItemData;
