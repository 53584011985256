import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";

import { kindOfTeacherRecruitmentsAtom } from "store/teachers/mypage/RecruitmentsStore";

import type React from "react";
import type { kindOfTeacherRecruitmentsType } from "store/teachers/mypage/RecruitmentsStore";

type RecruitmentSelectLabelProps = {
  label: string;
  value: kindOfTeacherRecruitmentsType;
};

const RecruitmentSelectLabel: React.FC<RecruitmentSelectLabelProps> = (
  props
) => {
  const { label, value } = props;
  const theme = useTheme();
  const [kindOfTeacherRecruitments, setKindOfTeacherRecruitments] = useAtom(
    kindOfTeacherRecruitmentsAtom
  );

  const handleClick = () => {
    setKindOfTeacherRecruitments(value);
  };

  return (
    <Chip
      label={label}
      variant="outlined"
      clickable
      onClick={handleClick}
      sx={{
        fontWeight: kindOfTeacherRecruitments === value ? "bold" : "normal",
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        borderColor:
          kindOfTeacherRecruitments === value
            ? theme.palette.common.black
            : theme.palette.border?.main,
        borderWidth: kindOfTeacherRecruitments === value ? "2px" : "1px"
      }}
    />
  );
};

export default RecruitmentSelectLabel;
