import { useAtomValue } from "jotai";
import { Route, Redirect } from "react-router-dom";

import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

function SchoolGuestRoute(props: any) {
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  if (!schoolIsLoggedIn) {
    return <Route {...props} />;
  }
  return <Redirect to="/schools/mypage" />;
}

export default SchoolGuestRoute;
