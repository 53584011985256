import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { masterSchoolType } from "types/masterDataTypes";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import SelectSchoolContents from "./SelectSchoolContents";
import ExpectionSchoolFields from "./ExpectionSchoolFields";

interface AddSchoolModalProps extends CommonDialogProps {
  handleSelect: (value: masterSchoolType) => void;
  isException: boolean;
  handleExpectionChange: () => void;
}

const AddSchoolModal: React.FC<AddSchoolModalProps> = (props) => {
  const { isOpen, onClose, handleSelect, isException, handleExpectionChange } =
    props;

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%"
        }}
      >
        {isException ? (
          <ExpectionSchoolFields isOpen={isOpen} onClose={onClose} />
        ) : (
          <SelectSchoolContents handleSelect={handleSelect} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginTop: "1rem"
        }}
      >
        <Button
          variant="text"
          color="secondary"
          onClick={handleExpectionChange}
          disableElevation
        >
          {isException ? "学校検索へ戻る" : "学校が見つからない場合はこちら"}
        </Button>
      </Box>
    </CommonDialog>
  );
};

export default AddSchoolModal;
