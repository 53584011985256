import Box from "@mui/material/Box";

import Guides from "features/schools/components/organisms/mypage/v2/home/Guides";
import Lessons from "features/schools/components/organisms/mypage/v2/home/Lessons";
import Recruitments from "features/schools/components/organisms/mypage/v2/home/Recruitments";
import Requests from "features/schools/components/organisms/mypage/v2/home/Requests";
import TitleBlock from "features/schools/components/organisms/mypage/v2/TitleBlock";

import type React from "react";

const Home: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1.5rem",
      flexGrow: 1
    }}
  >
    <TitleBlock title="マイページ" />
    <Guides />
    <Box
      sx={{
        display: "flex",
        alignItems: { sx: "center", md: "start" },
        gap: "1.5rem",
        width: "100%",
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Requests />
      <Recruitments />
    </Box>
    <Lessons />
  </Box>
);

export default Home;
