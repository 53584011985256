import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { LessonRequestSchoolTeacher, LessonRequestTeacher } from "api/@types";

type RequestBlockProps = {
  details?:
    | LessonRequestSchoolTeacher["details"]
    | LessonRequestTeacher["details"];
};

const RequestBlock: React.FC<RequestBlockProps> = (props) => {
  const { details } = props;
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={underSm ? 1 : 2}>
      <Grid item xs={12}>
        <Typography variant="h3">依頼内容</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>学年・生徒数</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>
          {details?.school_grade}（{details?.student_number}人）
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>授業時間</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>{details?.school_minutes}分</Typography>
      </Grid>
      {details?.start_at && (
        <>
          <Grid item xs={12} md={3}>
            <Typography color={theme.palette.sub.main}>希望実施時期</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>{details?.start_at}</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>実施方法</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        {details?.is_online ? (
          <Typography>オンライン</Typography>
        ) : (
          <Typography>対面授業</Typography>
        )}
      </Grid>
      {details?.is_online || (
        <>
          <Grid item xs={12} md={3}>
            <Typography color={theme.palette.sub.main}>交通費</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>{details?.travel_cost}</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>授業テーマ</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>{details?.tag}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>話してほしいこと</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {details?.lesson_content}
        </Typography>
      </Grid>
      {details?.student_condition !== "" && (
        <>
          <Grid item xs={12} md={3}>
            <Typography color={theme.palette.sub.main}>
              生徒の今の様子
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {details?.student_condition}
            </Typography>
          </Grid>
        </>
      )}
      {details?.issue !== "" && (
        <>
          <Grid item xs={12} md={3}>
            <Typography color={theme.palette.sub.main}>
              今感じている課題
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {details?.issue}
            </Typography>
          </Grid>
        </>
      )}
      {details?.reward !== undefined &&
        (details.reward > 0 ? (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            ￥{details.reward.toLocaleString()}(税抜)
          </Typography>
        ) : (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            依頼時点で報酬なしの設定だったため報酬は、0円となっております。
            <br />
            報酬ありに変更する場合は
            <RouterLink to="/teachers/mypage/settings?accordion=basic">
              こちら
            </RouterLink>
            からお願いします。
          </Typography>
        ))}
    </Grid>
  );
};

export default RequestBlock;
