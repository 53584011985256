import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormLabel from "components/atoms/FormLabel";
import Chip from "components/atoms/mui/Chip";

import { useFormContext, Controller } from "react-hook-form";

type FormChipGroupProps = {
  name: string;
  label: string;
  supplementalText?: string;
  isRequired?: boolean;
  noLabel?: boolean;
  multiple?: boolean;
  options: { value: string | number; label: string }[];
  noWrap?: boolean;
};

const FormChipGroup: React.FC<FormChipGroupProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const theme = useTheme();
  const {
    name,
    label,
    supplementalText,
    isRequired,
    noLabel,
    multiple,
    options,
    noWrap
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {!noLabel ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexShrink: "0",
            width: "100%",
            gap: "0.25rem"
          }}
        >
          <FormLabel labelName={label} isRequired={isRequired} />
          {supplementalText && (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.sub.main }}
            >
              {supplementalText}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography fontWeight="bold">{label}</Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: noWrap ? "nowrap" : "wrap",
              overflowX: noWrap ? "scroll" : "auto",
              gap: "0.5rem"
            }}
          >
            {options.map((option) => {
              let isSelected = false;

              if (multiple) {
                isSelected = field.value && field.value.includes(option.value);
              } else {
                isSelected =
                  field.value &&
                  field.value.toString() === option.value.toString();
              }

              return (
                <Chip
                  key={option.value}
                  clickable
                  label={option.label}
                  onClick={() => {
                    if (multiple) {
                      let newValue = field.value || [];
                      if (isSelected) {
                        newValue = newValue.filter(
                          (value: string) => value !== option.value
                        );
                      } else {
                        newValue = [...newValue, option.value];
                      }
                      field.onChange(newValue);
                    } else {
                      field.onChange(option.value);
                    }
                  }}
                  color={isSelected ? "primary" : "default"}
                />
              );
            })}
          </Box>
        )}
      />
      {errors[name] && (
        <Typography variant="caption" sx={{ color: theme.palette.error.main }}>
          {errors[name]?.message?.toString()}
        </Typography>
      )}
    </Box>
  );
};

export default FormChipGroup;
