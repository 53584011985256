import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import { schoolObjectAtom } from "store/RequestShowStore";

import type React from "react";

type SchoolInfoGuidProps = {
  SchoolInfoSpRef: React.RefObject<HTMLDivElement>;
};

const SchoolInfoGuid: React.FC<SchoolInfoGuidProps> = (props) => {
  // 以下stateはのちにアップデート予定
  const schoolObject = useAtomValue(schoolObjectAtom);
  const { SchoolInfoSpRef } = props;
  const theme = useTheme();

  const handleClick = (ref: React.RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white,
        padding: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Avatar
          alt={schoolObject.name}
          src={schoolObject.image.url}
          sx={{
            width: "3.5rem",
            height: "3.5rem",
            marginRight: "1rem"
          }}
        />
        <div>
          <Typography fontSize=".8rem" color={theme.palette.sub.main}>
            {schoolObject.school_name}
          </Typography>
          <Typography fontWeight="bold">{schoolObject.name}</Typography>
        </div>
      </Box>
      <Button
        variant="outlined"
        onClick={() => handleClick(SchoolInfoSpRef)}
        sx={{
          borderColor: theme.palette.muted?.main,
          color: theme.palette.sub.main
        }}
      >
        案件情報を見る
      </Button>
    </Box>
  );
};

export default SchoolInfoGuid;
