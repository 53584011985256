import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

const TreatedAsCanceledDisplay: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: ".5rem",
        width: "100%"
      }}
    >
      <Typography>
        この募集には応募者がいなかったため、キャンセルとなりました。
      </Typography>
    </Box>
  );
};

export default TreatedAsCanceledDisplay;
