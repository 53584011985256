import Axios from "axios";
import { useAtomValue } from "jotai";
import { useCallback, useState, useMemo } from "react";

import {
  isLoggedInAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom
} from "store/AuthStore";
import {
  schoolIsLoggedInAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom
} from "store/SchoolAuthStore";

import type { AxiosError } from "axios";

type usePostProps = {
  // TODO: urlとvarUrlの混在がコードをおかしくしているため、後ほど何らかの形で安全な形に再検討。
  url: string;
  method?: "post" | "put" | "patch";
};

type doPostProps = {
  varUrl?: string;
  params?: FormData;
  onSuccess?: (data?: any) => void;
  onError?: (error: AxiosError) => void;
};

const usePostFormDataRequest = ({ url, method = "post" }: usePostProps) => {
  // for teacher
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const accessToken = useAtomValue(accessTokenAtom);
  const client = useAtomValue(clientAtom);
  const uid = useAtomValue(uidAtom);

  const memoizeIsLoggedIn = useMemo(() => isLoggedIn, [isLoggedIn]);
  const memoizeAccessToken = useMemo(() => accessToken, [accessToken]);
  const memoizeClient = useMemo(() => client, [client]);
  const memoizeUid = useMemo(() => uid, [uid]);

  // for school
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const schoolAccessToken = useAtomValue(schoolAccessTokenAtom);
  const schoolClient = useAtomValue(schoolClientAtom);
  const schoolUid = useAtomValue(schoolUidAtom);

  const memoizeSchoolIsLoggedIn = useMemo(
    () => schoolIsLoggedIn,
    [schoolIsLoggedIn]
  );
  const memoizeSchoolAccessToken = useMemo(
    () => schoolAccessToken,
    [schoolAccessToken]
  );
  const memoizeSchoolClient = useMemo(() => schoolClient, [schoolClient]);
  const memoizeSchoolUid = useMemo(() => schoolUid, [schoolUid]);

  const setHeaders = useCallback(() => {
    // FIXME: 本当はswitchで書きたいがログイン状態の判定を別々の変数、storeで持ってしまっているため、取り回しが悪くなっている。
    if (memoizeIsLoggedIn) {
      return {
        "access-token": memoizeAccessToken,
        uid: memoizeUid,
        client: memoizeClient
      };
    }
    if (memoizeSchoolIsLoggedIn) {
      return {
        "access-token": memoizeSchoolAccessToken,
        uid: memoizeSchoolUid,
        client: memoizeSchoolClient
      };
    }
    return {
      "access-token": "",
      uid: "",
      client: ""
    };
  }, [
    memoizeAccessToken,
    memoizeClient,
    memoizeIsLoggedIn,
    memoizeSchoolAccessToken,
    memoizeSchoolClient,
    memoizeSchoolIsLoggedIn,
    memoizeSchoolUid,
    memoizeUid
  ]);

  const memoizeUrl = useMemo(() => url, [url]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<any>();

  const postFormDataRequest = useCallback(
    async ({ varUrl, params, onSuccess, onError }: doPostProps) => {
      setIsLoading(true);
      await Axios[method](varUrl || memoizeUrl || "", params, {
        headers: setHeaders()
      })
        .then((response) => {
          onSuccess && onSuccess(response.data);
          return response.data;
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
          onError && onError(error);
          return null;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [method, memoizeUrl, setHeaders]
  );

  return {
    postFormDataRequest,
    isLoading,
    isError,
    setIsError,
    error,
    setError
  };
};

export default usePostFormDataRequest;
