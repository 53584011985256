import Typography from "@mui/material/Typography";

import type React from "react";

const PrepatationLeading: React.FC = () => (
  <div>
    <Typography align="center" variant="h4" fontSize="1rem">
      <Typography
        component="span"
        sx={{ display: "inline-block" }}
        fontWeight="bold"
      >
        必要に応じて、このタイミングで
      </Typography>
      <Typography
        component="span"
        sx={{ display: "inline-block" }}
        fontWeight="bold"
      >
        緊急連絡先を交換してください。
      </Typography>
    </Typography>
    <Typography align="center">
      <Typography component="span" sx={{ display: "inline-block" }}>
        授業準備シートの記入が完了したら、
      </Typography>
      <Typography component="span" sx={{ display: "inline-block" }}>
        保存ボタンを押してください。
      </Typography>
      <br />
      複業先生と、事務局に共有されます。
    </Typography>
  </div>
);

export default PrepatationLeading;
