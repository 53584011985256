import * as yup from "yup";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId@string/update_exact_start_at";
import { futureDateStringSchema } from "utils/yupUtils";

export type patchExactStartAtSchemaType = Methods["patch"]["reqBody"];

const patchExactStartAtSchema: yup.ObjectSchema<patchExactStartAtSchemaType> =
  yup.object().shape({
    exact_start_at:
      futureDateStringSchema.required("授業日時を入力してください")
  });

export default patchExactStartAtSchema;
