import Box from "@mui/material/Box";

import RequestSelectLabel from "features/schools/components/atoms/mypage/v2/requests/RequestSelectLabel";

import type React from "react";

const RequestSelectLabels: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem"
    }}
  >
    <RequestSelectLabel label="すべて" value="only_request" />
    <RequestSelectLabel label="承諾" value="accepted" />
    <RequestSelectLabel label="依頼中" value="requesting" />
    <RequestSelectLabel label="辞退・キャンセル" value="denied_or_canceled" />
  </Box>
);

export default RequestSelectLabels;
