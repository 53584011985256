import { atom } from "jotai";

import type { teacherRequestsResponseType } from "types/LessonRequestTypes";

export type kindOfTeacherRequestsType =
  | "requesting"
  | "only_request"
  | "denied_or_canceled"
  | "accepted";

export const teacherRequestsAtom = atom<teacherRequestsResponseType | null>(
  null
);

export const kindOfTeacherRequestsAtom =
  atom<kindOfTeacherRequestsType>("only_request");
