import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import RequestListItem from "features/schools/components/molecules/mypage/v2/requests/RequestListItem";
import useGetRequest from "features/schools/hooks/useGetRequest";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  schoolRequestsAtom,
  kindOfSchoolRequestsAtom
} from "store/schools/mypage/RequestsStore";
import useLimitCheck from "hooks/useLimitCheck";

const RequestList: React.FC = () => {
  const theme = useTheme();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolRequests, setSchoolRequests] = useAtom(schoolRequestsAtom);
  const [kindOfSchoolLessons] = useAtom(kindOfSchoolRequestsAtom);
  const { getLimit } = useLimitCheck();

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lesson_requests`
  });

  useEffect(() => {
    const params = {
      status: kindOfSchoolLessons
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setSchoolRequests(data);
      }
    });
  }, [getRequest, kindOfSchoolLessons, setSchoolRequests]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="依頼一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 16 }}>
                依頼した複業先生
              </Typography>
              <Typography
                sx={{
                  flex: 4,
                  width: "100%"
                }}
                align="center"
                fontWeight="bold"
              >
                ステータス
              </Typography>
              <Typography
                sx={{
                  flex: 3,
                  width: "100%"
                }}
                fontWeight="bold"
                align="center"
              >
                期限
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {!isLoading ? (
            schoolRequests && schoolRequests.data.length ? (
              schoolRequests.data.map((request, index) => {
                const { limit } = getLimit(request.details.accept_dead_at);

                return (
                  <React.Fragment key={index}>
                    <RequestListItem
                      id={request.details.id}
                      teacherName={request.teacher.name}
                      teacherImage={request.teacher.image.url}
                      teacherRank={request.teacher.teacher_rank}
                      organizationName={request.teacher.organization_name}
                      limit={limit}
                      step={request.details.step}
                    />
                    <Divider sx={{ width: "100%" }} />
                  </React.Fragment>
                );
              })
            ) : (
              <Typography color={theme.palette.sub.main}>
                依頼がありません。
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default RequestList;
