import Chip from "@mui/material/Chip";
import { useAtom } from "jotai";
import { useTheme } from "@mui/material/styles";

import { kindOfTeacherLessonsAtom } from "store/teachers/mypage/LessonsStore";

import type React from "react";
import type { kindOfTeacherLessonsType } from "store/teachers/mypage/LessonsStore";

type LessonSelectLabelProps = {
  label: string;
  value: kindOfTeacherLessonsType;
};

const LessonSelectLabel: React.FC<LessonSelectLabelProps> = (props) => {
  const { label, value } = props;
  const theme = useTheme();
  const [kindOfTeacherLessons, setKindOfTeacherLessons] = useAtom(
    kindOfTeacherLessonsAtom
  );

  const handleClick = () => {
    setKindOfTeacherLessons(value);
  };

  return (
    <Chip
      label={label}
      variant="outlined"
      clickable
      onClick={handleClick}
      sx={{
        fontWeight: kindOfTeacherLessons === value ? "bold" : "normal",
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        borderColor:
          kindOfTeacherLessons === value
            ? theme.palette.common.black
            : theme.palette.border?.main,
        borderWidth: kindOfTeacherLessons === value ? "2px" : "1px"
      }}
    />
  );
};

export default LessonSelectLabel;
