import React, { useState } from "react";
import useTheme from "@mui/system/useTheme";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import SearchIcon from "@mui/icons-material/Search";

import CommonPagination from "components/organisms/CommonPagination";

import { useMasterSchoolsApi } from "hooks/useMasterSchoolsApi";

import { masterSchoolType } from "types/masterDataTypes";

type SelectSchoolContentsProps = {
  handleSelect: (value: masterSchoolType) => void;
};

const SelectSchoolContents: React.FC<SelectSchoolContentsProps> = (props) => {
  const { handleSelect } = props;
  const theme = useTheme();
  const [searchWord, setSearchWord] = useState("");

  const { masterSchools, pagination, setPagination, isLoading, search } =
    useMasterSchoolsApi();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const handleSearch = () => {
    search({
      search_school_name: searchWord,
      pages: 1
    });
  };

  const handleChangePage = (n: number) => {
    search({
      pages: n
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography align="center" fontSize="1.2rem" fontWeight="bold">
        学校検索
      </Typography>
      <Box
        component="form"
        sx={{
          display: "flex",
          gap: "1rem",
          flex: 1
        }}
      >
        <TextField
          id="school-search-word"
          variant="outlined"
          value={searchWord}
          onChange={handleChange}
          placeholder="学校名を入力してください"
          fullWidth
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disableElevation
          disabled={isLoading || !searchWord}
          startIcon={<SearchIcon />}
          sx={{
            width: "8rem",
            padding: { xs: "4px 8px", sm: "12px, 24px" }
          }}
        >
          検索
        </Button>
      </Box>
      {masterSchools && masterSchools.length > 0 && (
        <List>
          {masterSchools?.map((masterSchool) => (
            <ListItem
              key={masterSchool.school_code}
              onClick={() => handleSelect(masterSchool)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: ".5rem",
                borderBottom: theme.palette.divider
              }}
            >
              <Typography>{masterSchool.name}</Typography>
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={() => handleSelect(masterSchool)}
                sx={{
                  fontWeight: "bold",
                  padding: { xs: "4px 8px", sm: "12px, 24px" }
                }}
              >
                選択
              </Button>
            </ListItem>
          ))}
        </List>
      )}
      <CommonPagination
        pagination={pagination}
        setPagination={setPagination}
        setPage={handleChangePage}
      />
    </Box>
  );
};

export default SelectSchoolContents;
