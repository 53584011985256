const useFormatDateTime = () => {
  const getFormatDateTime = (date: Date) => {
    const y = date.getFullYear();
    const m = `00${date.getMonth() + 1}`.slice(-2);
    const d = `00${date.getDate()}`.slice(-2);
    let hh: number | string = date.getHours();
    let mm: number | string = date.getMinutes();
    let ss: number | string = date.getSeconds();
    hh < 10 && (hh = `0${hh}`);
    mm < 10 && (mm = `0${mm}`);
    ss < 10 && (ss = `0${ss}`);

    return `${`${y}/${m}/${d} ${hh}:${mm}:${ss}`}`;
  };

  return { getFormatDateTime };
};

export default useFormatDateTime;
