import { useTheme } from "@mui/material/styles";
import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";

import type React from "react";

const JudgeConfirmationAlert: React.FC = () => {
  const theme = useTheme();

  return (
    <Alert
      severity="warning"
      sx={{
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "4px",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.textColor.main,
        "& .MuiAlert-icon": {
          color: theme.palette.primary.main
        }
      }}
    >
      <AlertTitle sx={{ fontWeight: 600 }}>現在登録内容の確認中です</AlertTitle>
      確認完了まで今しばらくお待ちください。
    </Alert>
  );
};

export default JudgeConfirmationAlert;
