import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";

import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import RecruitmentDetails from "features/recruitments/components/organisms/show/RecruitmentDetails";
import RecruitmentMainInfo from "features/recruitments/components/organisms/show/RecruitmentMainInfo";
import CommonLayout from "components/layouts/CommonLayout";
import { candidateAtom } from "store/recruitments/ShowStore";

import type React from "react";

import useShowPageApi from "features/recruitments/hooks/useShowPageApi";
import { useDialog } from "hooks/useDialog";
import { FormProvider } from "react-hook-form";
import CandidateCreateForm from "../organisms/show/CandidateCreateForm";
import CandidateCancelModal from "../organisms/show/CandidateCancelModal";
import CandidateDetail from "../organisms/show/CandidateDetail";
import CandidateResultLabel from "../organisms/show/CandidateResultLabel";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<paramsTypes>();
  const [candidate] = useAtom(candidateAtom);

  const { isLoading, cancelCandidate, cancelIsLoading, form, createCandidate } =
    useShowPageApi(id);
  const candidateCancelModal = useDialog();

  return (
    <CommonLayout>
      <Meta
        title="複業先生｜募集詳細"
        description="複業先生の募集詳細ページです。"
      />
      {isLoading ? (
        <Loading headerOnly />
      ) : (
        <>
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              padding: "1.5rem",
              width: "100%"
            }}
          >
            <RecruitmentMainInfo />
          </Container>
          <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "1.5rem",
                width: "100%"
              }}
            >
              <CandidateResultLabel />
              <RecruitmentDetails />
              {/* 未応募の場合は応募フォームを表示 */}
              {(!candidate || candidate?.status === "candidate_canceled") && (
                <FormProvider {...form}>
                  <Box
                    component="form"
                    onSubmit={form.handleSubmit(createCandidate)}
                  >
                    <CandidateCreateForm />
                  </Box>
                </FormProvider>
              )}
              {/* 応募済みの場合は応募内容を表示 */}
              {candidate && (
                <CandidateDetail
                  openCancelModal={candidateCancelModal.onOpen}
                />
              )}
            </Container>
            <CandidateCancelModal
              isOpen={candidateCancelModal.isOpen}
              onClose={candidateCancelModal.onClose}
              cancelCandidate={cancelCandidate}
              isLoading={cancelIsLoading}
            />
          </Box>
        </>
      )}
    </CommonLayout>
  );
};

export default Show;
