import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import { isClickableAtom } from "store/AiTeachingPlanStore";

import type React from "react";

type GenerateTeachingPlanIdeaButtonProps = {
  handleSubmit: () => void;
  isLoading: boolean;
};

const GenerateTeachingPlanIdeaButton: React.FC<
  GenerateTeachingPlanIdeaButtonProps
> = (props) => {
  const { handleSubmit, isLoading } = props;
  const theme = useTheme();
  const isClickable = useAtomValue(isClickableAtom);

  return (
    <div>
      <Typography align="center" fontSize=".8rem">
        授業案の生成には30~40秒程度かかります。
      </Typography>
      <Button
        color="primary"
        disableElevation
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        disabled={isLoading || !isClickable}
        sx={{
          display: "block",
          height: "3rem",
          margin: ".5rem auto",
          fontWeight: "bold",
          backgroundColor: theme.palette.advanced?.main,
          "&:hover": {
            backgroundColor: theme.palette.advanced?.dark
          },
          "&:disabled": {
            backgroundColor: theme.palette.advanced?.main,
            opacity: 0.3
          }
        }}
      >
        授業案を生成する
      </Button>
    </div>
  );
};

export default GenerateTeachingPlanIdeaButton;
