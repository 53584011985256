import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";

import ErrorAlert from "components/atoms/ErrorAlert";
import SuccessAlert from "components/atoms/SuccessAlert";
import useLessonRequestComments from "features/requests/hooks/useLessonRequestComments";

import type React from "react";

type DeleteCheckModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // FIXME: APIはcommentになっているので、どこかで合わせる
  messageId: number;
  deleteRequestComments?: (commentId: string) => void;
};

const DeleteCheckModal: React.VFC<DeleteCheckModalProps> = (props) => {
  const { open, setOpen, messageId, deleteRequestComments } = props;
  const theme = useTheme();

  const {
    getMessageIsError,
    setGetMessageIsError,
    // deleteMessage,
    messageDeleteIsError,
    setMessageDeleteIsError,
    deleteMessageIsSuccess,
    setDeleteMessageIsSuccess
  } = useLessonRequestComments(messageId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (deleteRequestComments !== undefined) {
      deleteRequestComments(messageId.toString());
      setOpen(false);
    }
  };

  return (
    <>
      <ErrorAlert
        isError={messageDeleteIsError}
        setIsError={setMessageDeleteIsError}
        errorMessage="メッセージの削除に失敗しました"
      />
      <SuccessAlert
        isSuccess={deleteMessageIsSuccess}
        setIsSuccess={setDeleteMessageIsSuccess}
        successMessage="メッセージを削除しました"
      />
      <ErrorAlert
        isError={getMessageIsError}
        setIsError={setGetMessageIsError}
        errorMessage="メッセージの取得に失敗しました"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        sx={{ position: "relative" }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            width: "100%",
            height: "0px",
            zIndex: 1600
          }}
        >
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 6,
              top: 6
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "2rem" },
            height: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              height: "100%"
            }}
          >
            <Typography>
              <Box sx={{ display: "inline-block" }}>メッセージを削除しても</Box>
              <Box sx={{ display: "inline-block" }}>よろしいですか？</Box>
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: "1rem"
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                disableElevation
                fullWidth
                sx={{
                  backgroundColor: theme.palette.backgroundColor?.main,
                  color: theme.palette.textColor.main,
                  border: `1px solid ${theme.palette.border?.main}`,
                  "&:hover": {
                    backgroundColor: theme.palette.backgroundColor?.dark
                  }
                }}
              >
                キャンセル
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                onClick={handleDelete}
                sx={{ fontWeight: "bold" }}
              >
                削除
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteCheckModal;
