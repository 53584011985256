import { useEffect, useState } from "react";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import { NewFormSchemaType } from "features/requests/constants/newFormSchema";
import useHeaders from "hooks/useHeaders";

import {
  teacherProfileAtom,
  tagsAtom,
  schoolGradesAtom,
  lessonOptionsAtom
} from "store/RequestNewStore";

const useNewPageApi = (teacher_id: number) => {
  const headers = useHeaders();
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [, setTeacherProfile] = useAtom(teacherProfileAtom);
  const [, setTags] = useAtom(tagsAtom);
  const [, setSchoolGrades] = useAtom(schoolGradesAtom);
  const [, setLessonOptions] = useAtom(lessonOptionsAtom);

  const {
    data,
    error: getError,
    isLoading: getIsLoading
  } = useAspidaSWR(aspida(headers).api.v1.lesson_requests.new, {
    query: { teacher_id }
  });

  const postNewRequest = async (data: NewFormSchemaType) => {
    setPostIsLoading(true);
    try {
      const response = await aspida(headers).api.v1.lesson_requests.post({
        body: data
      });
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    } finally {
      setPostIsLoading(false);
    }
  };

  const postGenerateTeachingPlan = async (lessonRequestId: string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .generate_teaching_plan.post();
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  };

  useEffect(() => {
    if (data) {
      setTags(data.tags);
      setTeacherProfile(data.teacher_profile);
      setSchoolGrades(data.school_grades);
      setLessonOptions(data.lesson_options);
    }
  }, [data]);

  return {
    postNewRequest,
    postGenerateTeachingPlan,
    getError,
    getIsLoading,
    postIsLoading
  };
};

export default useNewPageApi;
