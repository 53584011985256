// FIXME: 色味の調整が完全に済んだ段階でpallet.tsにマージしたい
// 一方、paletteとmuiのスタイルのカスタムが分離しているため、循環参照が発生しうる

export const customColors = {
  primary: {
    main: "#FF8A00",
    dark: "#F66800",
    background: "#FFF5DD"
  },
  secondary: {
    main: "#00749B",
    dark: "#005977",
    background: "#EDFBFF"
  },
  error: {
    main: "#EE1D00"
  },
  textColor: {
    main: "#201807",
    sub: "#7D7670",
    white: "#ffffff"
  },
  border: {
    main: "#E3E2DF"
  },
  background: {
    default: "#ffffff",
    gray: "#FAF9F7"
  }
};
