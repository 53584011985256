import { atom } from "jotai";

import type { schoolRequestResponseType } from "types/lessonRecruitmentsTypes";

export type kindOfSchoolRequestsType =
  | "requesting"
  | "only_request"
  | "denied_or_canceled"
  | "accepted";

export const schoolRequestsAtom = atom<schoolRequestResponseType | null>(null);

export const kindOfSchoolRequestsAtom =
  atom<kindOfSchoolRequestsType>("only_request");
