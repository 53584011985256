import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import type React from "react";

type AddExactStartAtDisplayProps = {
  onOpen: () => void;
};

const AddExactStartAtDisplay: React.FC<AddExactStartAtDisplayProps> = (
  props
) => {
  const { onOpen } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".2rem"
        }}
      >
        <Typography align="center" fontWeight="bold">
          授業実施日と開始時間の設定
        </Typography>
        <Typography
          align="center"
          fontSize=".8rem"
          color={theme.palette.sub.main}
        >
          以下のボタンをクリックし、授業の日時を入力してください。
        </Typography>
      </Box>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          onClick={onOpen}
          sx={{
            height: "48px",
            fontWeight: "bold"
          }}
        >
          授業日時を入力する
        </Button>
      </Box>
    </Box>
  );
};

export default AddExactStartAtDisplay;
