import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import type React from "react";

type sectionLoading = {
  hasColor?: boolean;
};

const SectionLoading: React.FC<sectionLoading> = (props) => {
  const { hasColor } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: hasColor ? theme.palette.common?.white : "transparent",
        opacity: hasColor ? 0.4 : "initial"
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default SectionLoading;
