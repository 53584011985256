import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import ConfirmContentsBox from "features/recruitments/components/organisms/new/ConfirmContentsBox";
import LessonContentsFields from "features/recruitments/components/organisms/new/LessonContentsFields";
import PossibleDatesFields from "features/recruitments/components/organisms/new/PossibleDatesFields";
import StudentsInfoFields from "features/recruitments/components/organisms/new/StudentsInfoFields";

import type React from "react";
import { useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import AboutFields from "../../organisms/new/AboutFields";

const Step01: React.FC = () => {
  const theme = useTheme();
  const {
    watch,
    formState: { dirtyFields }
  } = useFormContext();
  const isOnline = watch("is_online") === "true" || watch("is_online") === true;
  const showLessonContentsFields = dirtyFields.title;
  const showStudentsInfoFields =
    showLessonContentsFields &&
    dirtyFields.school_minutes &&
    dirtyFields.is_online &&
    dirtyFields.lesson_contents &&
    dirtyFields.tag_id &&
    dirtyFields.want_to &&
    (isOnline || dirtyFields.travel_cost);
  const showConfirmContentsBox =
    showStudentsInfoFields &&
    dirtyFields.school_grade_id &&
    dirtyFields.student_number;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box sx={{ padding: { xs: "0 1.5rem", sm: 0 } }}>
        <Typography variant="h2">募集内容</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          backgroundColor: theme.palette.common.white,
          padding: "1.5rem"
        }}
      >
        <PossibleDatesFields />
        <Divider />
        <AboutFields />
        {showLessonContentsFields && (
          <>
            <Divider />
            <LessonContentsFields />
          </>
        )}
        {showStudentsInfoFields && (
          <>
            <Divider />
            <StudentsInfoFields />
          </>
        )}
        {showConfirmContentsBox && (
          <>
            <Divider />
            <ConfirmContentsBox />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Step01;
