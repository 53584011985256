import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import GuidesIcon from "assets/schools/mypage/GuidesIcon.png";

import type React from "react";

const GuidesTitle: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: { xs: "start", md: "center" },
      gap: ".25rem 1rem",
      flexDirection: { xs: "column", md: "row" }
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "1rem"
      }}
    >
      <img src={GuidesIcon} alt="ご利用ガイド" />
      <Typography variant="h2">ご利用ガイド</Typography>
    </Box>
    <Typography>複業先生を活用した授業を実施しましょう。</Typography>
  </Box>
);

export default GuidesTitle;
