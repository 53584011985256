import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";

import { LessonRequestTeacher, LessonRequestSchoolTeacher } from "api/@types";

import dayjs from "dayjs";

type ToAgendaSheetBannerProps = {
  details?:
    | LessonRequestTeacher["details"]
    | LessonRequestSchoolTeacher["details"];
};

const ToAgendaSheetBanner: React.FC<ToAgendaSheetBannerProps> = (props) => {
  const { details } = props;
  const history = useHistory();
  const theme = useTheme();
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const linkTo = isLoggedIn
    ? `/teachers/mypage/requests/${details?.id}/agenda`
    : schoolIsLoggedIn
      ? `/schools/mypage/requests/${details?.id}/agenda`
      : "/";

  const handleClick = () => {
    history.push(linkTo);
  };

  const isBeforeLesson =
    details && dayjs(details.exact_start_at).isAfter(dayjs());

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: { xs: "1rem", sm: "2rem" },
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        borderRadius: "4px 4px 0 0",
        overflow: "hidden",
        cursor: "pointer"
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          backgroundColor: theme.palette.secondary.dark,
          padding: "1rem 1.5rem"
        }}
      >
        <EditIcon sx={{ color: theme.palette.secondary.contrastText }} />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            flex: 1,
            color: theme.palette.secondary.contrastText
          }}
        >
          授業準備シート
        </Typography>
        <ArrowForwardIosIcon
          sx={{ color: theme.palette.secondary.contrastText }}
        />
      </Box>
      <Box
        sx={{
          padding: "1rem 1.5rem",
          backgroundColor: theme.palette.secondary.contrastText
        }}
      >
        {isBeforeLesson ? (
          <Typography>
            しっかり活用し、
            <br />
            より良い授業を作りましょう！
            <br />
            編集はいつでもできます。
          </Typography>
        ) : (
          <Typography>
            授業お疲れ様でした！
            <br />
            今回の授業の経験を活かし、
            <br />
            より良い授業を作りましょう！
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ToAgendaSheetBanner;
