import { createPortal } from "react-dom";

type PortalProps = React.PropsWithChildren<Record<string, unknown>>;

const Portal: React.FC<PortalProps> = ({ children }: PortalProps) => {
  const element = document.getElementById("layout-root");

  return element !== null ? createPortal(children, element) : null;
};

export default Portal;
