import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import RequireFormLabel from "features/root/components/atoms/contact/RequireFormLabel";
import {
  lastNameAtom,
  firstNameAtom,
  lastNameKanaAtom,
  firstNameKanaAtom,
  lastNameKanaIsErrorAtom,
  firstNameKanaIsErrorAtom
} from "store/ContactFormStore";

import type React from "react";

const FullNameField: React.FC = () => {
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastNameKana, setLastNameKana] = useAtom(lastNameKanaAtom);
  const [firstNameKana, setFirstNameKana] = useAtom(firstNameKanaAtom);
  const [lastNameKanaIsError] = useAtom(lastNameKanaIsErrorAtom);
  const [firstNameKanaIsError] = useAtom(firstNameKanaIsErrorAtom);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastNameKana(event.target.value);
  };

  const handleFirstNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstNameKana(event.target.value);
  };

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <RequireFormLabel labelName="氏名" />
      <Grid container spacing={1}>
        <Grid xs={6} item>
          <TextField
            variant="outlined"
            placeholder="姓：山田"
            fullWidth
            value={lastName}
            onChange={handleLastNameChange}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            variant="outlined"
            placeholder="名：花子"
            fullWidth
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            variant="outlined"
            placeholder="セイ：ヤマダ"
            fullWidth
            value={lastNameKana}
            onChange={handleLastNameKanaChange}
            error={lastNameKanaIsError}
            helperText={lastNameKanaIsError && "全角カタカナで入力してください"}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            variant="outlined"
            placeholder="メイ：ハナコ"
            fullWidth
            value={firstNameKana}
            onChange={handleFirstNameKanaChange}
            error={firstNameKanaIsError}
            helperText={
              firstNameKanaIsError && "全角カタカナで入力してください"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FullNameField;
