import Box from "@mui/material/Box";
import { useRef } from "react";

import GlobalImage from "assets/lpClassi/Global.png";
import Overview from "features/root/components/molecules/lpClassi/Overview";
import useFilterTeachers from "hooks/useFilterTeachers";

import type React from "react";
import LessonExample from "./LessonExample";
import LessonIdeas from "./LessonIdeas";

const Global: React.FC = () => {
  // MEMO: 基本的に複数回のレンダリングが必要ないと目されるため。
  const teacherWithVideoIdsRef = useRef([773, 515]);
  const teacherIdsRef = useRef([773, 256, 381, 515, 414, 500, 125, 1016]);
  const teachersWithVideo = useFilterTeachers(teacherWithVideoIdsRef.current);
  const teachers = useFilterTeachers(teacherIdsRef.current);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem"
      }}
    >
      <Overview
        title="グローバル教育"
        desc="探究活動の導入期に最適。STEAM教育をベースに、実践者の生き方や考え方に触れ、生徒の皆様が自分の探究テーマを見つけるきっかけを作ります。"
        Image={GlobalImage}
      />
      <LessonIdeas title="グローバル教育" teachers={teachersWithVideo} />
      <LessonExample title="グローバル教育" teachers={teachers} />
    </Box>
  );
};

export default Global;
