import { Methods } from "api/api/v1/lesson_recruitments";
import { futureDateStringSchema } from "utils/yupUtils";
import * as yup from "yup";

export type NewRecruitmentFormSchemaType = Methods["post"]["reqBody"];

const newRecruitmentFormSchema: yup.ObjectSchema<NewRecruitmentFormSchemaType> =
  yup.object().shape({
    proposed_dates: yup
      .array()
      .required("必須の項目です")
      .of(futureDateStringSchema.required("必須の項目です")),
    title: yup
      .string()
      .max(40, "40文字以内にしてください")
      .required("必須の項目です"),
    recruitment_dead_at: futureDateStringSchema
      .test(
        "is-before-possible-dates",
        "授業日より前の日付を選択してください",
        (value, context) => {
          if (!value) return false;
          let isBefore = false;
          const possibleDates: string[] = context.parent.proposed_dates;
          possibleDates.forEach((possibleDate) => {
            if (new Date(value) < new Date(possibleDate)) {
              isBefore = true;
            }
          });
          return isBefore;
        }
      )
      .required("必須の項目です"),
    school_minutes: yup.number().required("必須の項目です"),
    is_online: yup.boolean().required("必須の項目です"),
    travel_cost: yup
      .string()
      .when("is_online", (isOnline, schema) =>
        isOnline.toString() === "true"
          ? schema.notRequired()
          : schema.required("必須の項目です")
      ),
    tag_id: yup.number().required("必須の項目です"),
    lesson_contents: yup.string().required("必須の項目です"),
    want_to: yup.string().required("必須の項目です"),
    school_grade_id: yup.number().required("必須の項目です"),
    student_number: yup.number().required("必須の項目です"),
    is_term_accepted: yup
      .boolean()
      .oneOf([true], "利用規約に同意してください")
      .required("必須の項目です")
  });

export default newRecruitmentFormSchema;
