import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import { ratingAtom } from "store/TeachersShowStore";

import type React from "react";
import TotalRating from "./TotalRating";
import TotalRatingsItem from "./TotalRatingsItem";

const TotalRatingsBlock: React.FC = () => {
  const theme = useTheme();
  const rating = useAtomValue(ratingAtom);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.backgroundColor?.main,
        padding: ".5rem 2.5rem",
        gap: "0 1.5rem"
      }}
    >
      <TotalRating rating={rating?.total_rating || 0} />
      <Divider orientation="vertical" flexItem />
      <TotalRatingsItem
        rating={rating?.total_lesson_rating || 0}
        ratingName="授業の満足度"
      />
      <TotalRatingsItem
        rating={rating?.total_teacher_rating || 0}
        ratingName="講師への評価"
      />
    </Box>
  );
};

export default TotalRatingsBlock;
