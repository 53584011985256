import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ServiceListItemImage01 from "assets/schoolLp/ServiceListItemImage01.png";
import ServiceListItemImage02 from "assets/schoolLp/ServiceListItemImage02.png";
import ServiceListItemImage03 from "assets/schoolLp/ServiceListItemImage03.png";
import ServiceListItemImage04 from "assets/schoolLp/ServiceListItemImage04.png";
import ServiceListItemImage05 from "assets/schoolLp/ServiceListItemImage05.png";
import ServiceListItemImage06 from "assets/schoolLp/ServiceListItemImage06.png";
import ServiceListItemImage07 from "assets/schoolLp/ServiceListItemImage07.png";
import ServiceListItemImage08 from "assets/schoolLp/ServiceListItemImage08.png";

import type React from "react";
import ServiceListItem from "./ServiceListItem";

const ServiceList: React.FC = () => (
  <Box sx={{ margin: "0 auto" }}>
    <Box
      sx={{
        width: "90%",
        margin: "1rem auto"
      }}
    >
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontSize: { xs: "inherit", md: "1.5rem" },
          margin: "1rem auto"
        }}
      >
        例えば、こんな授業ができます。
      </Typography>
      <Typography align="center" sx={{ margin: ".5rem auto" }}>
        <Typography component="span" sx={{ display: "inline-block" }}>
          テーマは一例です。
        </Typography>
        <br />
        <Typography component="span" sx={{ display: "inline-block" }}>
          学校カリキュラムや
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          学年（小1〜高3）に合わせ、
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          カスタマイズした授業ができます。
        </Typography>
      </Typography>
    </Box>
    <Grid
      container
      sx={{
        width: { xs: "90%", md: "80%" },
        maxWidth: { xs: "630px", md: "960px" },
        margin: "1.5rem auto"
      }}
    >
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage01}
          title="IT"
          list={[
            "Webデザインを学んで自分だけの\nホームページを作成しよう！",
            "どうやって世の中のサービスが出来ているの？",
            "データ活用を通して身の周りの課題を解決しよう"
          ]}
          color="#497FA4"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage02}
          title="グローバル・海外"
          list={[
            "海外の学校と繋がって、\n自分の学校を紹介しよう！",
            "実際に留学に行った先輩からの留学のリアル",
            "海外で仕事をするってどんなこと？"
          ]}
          color="#4CC33B"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage03}
          title="金融・貧困・経済"
          list={[
            "これからのお金について学ぼう！",
            "食×SDGsの世界について学ぼう！貧困ってなに？",
            "私たちにできることを考えてみよう！"
          ]}
          color="#A45FA4"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage04}
          title="起業"
          list={[
            "起業家が教える！本当のリーダーシップ論",
            "アイディアを形にしてみよう！ ",
            "あなたの身の回りには何がある？"
          ]}
          color="#F5C20A"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage05}
          title="地方創生・地域活性・観光"
          list={[
            "自分の地域の魅力をSNSで発信しよう！",
            "地域×オリジナルの商品開発をしてみよう！",
            "自分の地域の人口を増やすために何が出来る？"
          ]}
          color="#FCA100"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage06}
          title="医療・健康"
          list={[
            "自分の身体や心について見直してみよう！",
            "医学部生が教える！医療の世界 ",
            "ボランティアの世界について学ぼう！"
          ]}
          color="#2BBEE9"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage07}
          title="宇宙・バイオ・農業"
          list={[
            "ロケットは私たちにどんな影響があるの？",
            "私たちの食べているものはどこから来ているの？",
            "今ホットな宇宙ビジネスについて知ろう！"
          ]}
          color="#93C021"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ServiceListItem
          avatar={ServiceListItemImage08}
          title="スポーツ・アスリート"
          list={[
            "プロスポーツ選手から学ぶ、心の整え方",
            "スポーツとの様々な関わり方について知ろう！",
            "セカンドキャリア/デュアルキャリアってなに？"
          ]}
          color="#F24F5A"
        />
      </Grid>
    </Grid>
    <div>
      <Typography align="center">
        <Typography component="span" sx={{ display: "inline-block" }}>
          その他、教職員向け研修、
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          部活動支援、生徒会研修など、
        </Typography>
        <br />
        <Typography component="span" sx={{ display: "inline-block" }}>
          授業以外でも活用いただいています。
        </Typography>
      </Typography>
    </div>
  </Box>
);

export default ServiceList;
