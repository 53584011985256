import React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

type CommonMypageHomeSectionTitleProps = {
  Icon: React.ReactElement;
  title: string;
  count: number;
  linkName: string;
  link: string;
};

const CommonMypageHomeSectionTitle: React.FC<
  CommonMypageHomeSectionTitleProps
> = (props) => {
  const { title, count, linkName, link, Icon } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: { xs: "flex-start", sm: "center" },
        width: "100%",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem"
        }}
      >
        {Icon}
        <Typography variant="h2">
          {title} ({count})
        </Typography>
      </Box>
      <Button variant="text" color="secondary" component={Link} to={link}>
        {linkName}
      </Button>
    </Box>
  );
};

export default CommonMypageHomeSectionTitle;
