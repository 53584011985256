import { atom } from "jotai";

type schoolDivisionsDataType = {
  id: number;
  name: string;
};

type schoolTypesDataType = {
  id: number;
  name: string;
};

type prefecturesDataType = {
  id: number;
  name: string;
  code: number;
};

export const schoolDivisionsData = atom<schoolDivisionsDataType[]>([]);
export const schoolTypesData = atom<schoolTypesDataType[]>([]);
export const prefecturesData = atom<prefecturesDataType[]>([]);
