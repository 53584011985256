import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";
import { teacherInfoAtom } from "store/TeachersShowStore";

import type React from "react";

const LessonThemeBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <div>
      <Typography
        variant="h4"
        fontSize="1rem"
        sx={{
          margin: "0 auto .5rem"
        }}
      >
        授業できるテーマ
      </Typography>
      <Box
        sx={{
          margin: "0 auto .75rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: ".5rem"
        }}
      >
        {teacherInfo.tags.map((tag, index) => (
          <LessonThemeChip key={index} name={tag.name} />
        ))}
      </Box>
      {teacherInfo.lesson_contents && (
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {teacherInfo.lesson_contents}
        </Typography>
      )}
    </div>
  );
};

export default LessonThemeBox;
