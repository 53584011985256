import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import GenerateTeachingPlanIdeaButton from "features/schools/components/atoms/mypage/AiTeachingPlan/GenerateTeachingPlanIdeaButton";
import IdeaRequestField from "features/schools/components/molecules/mypage/AiTeachingPlan/IdeaRequestField";
import SchoolGradesField from "features/schools/components/molecules/mypage/AiTeachingPlan/SchoolGradesField";
import SchoolMinutesField from "features/schools/components/molecules/mypage/AiTeachingPlan/SchoolMinutesField";

import type React from "react";

type AiTeachingPlanFormProps = {
  handleSubmit: () => void;
  isLoading: boolean;
};

const AiTeachingPlanForm: React.FC<AiTeachingPlanFormProps> = (props) => {
  const { handleSubmit, isLoading } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Typography variant="h3">
        以下の項目に必要事項を記入し、
        <br />
        AIに授業案を考えてもらいましょう！
      </Typography>
      <IdeaRequestField isLoading={isLoading} />
      <SchoolGradesField isLoading={isLoading} />
      <SchoolMinutesField isLoading={isLoading} />
      <GenerateTeachingPlanIdeaButton
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress sx={{ color: theme.palette.advanced?.main }} />
        </Box>
      )}
    </Box>
  );
};

export default AiTeachingPlanForm;
