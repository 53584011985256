import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useAtom } from "jotai";

import CommonDialogWithCloseButton from "components/atoms/CommonDialogWithCloseButton";
import ErrorAlert from "components/atoms/ErrorAlert";
import usePostFormDataRequest from "features/schools/hooks/usePostFormDataRequest";
import {
  recruitmentAtom,
  candidatesCountAtom,
  earlyCloseSuccessAtom
} from "store/schools/recruitments/ShowStore";

import type React from "react";

type EarlyCloseModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EarlyCloseModal: React.FC<EarlyCloseModalProps> = (props) => {
  const { open, setOpen } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [recruitment, setRecruitment] = useAtom(recruitmentAtom);
  const [, setCandidatesCount] = useAtom(candidatesCountAtom);
  const [, setIsSuccess] = useAtom(earlyCloseSuccessAtom);

  const { isLoading, isError, setIsError, postFormDataRequest } =
    usePostFormDataRequest({
      url: `/api/api/v1/lesson_recruitments/${recruitment?.id}/manual_close`,
      method: "patch"
    });

  const handleEarlyClose = async () => {
    await postFormDataRequest({
      onSuccess: (response) => {
        setRecruitment(response.details);
        setCandidatesCount(response.candidates_count);
        setIsSuccess(true);
      }
    });

    setOpen(false);
  };

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="募集の終了に失敗しました。"
      />
      <CommonDialogWithCloseButton
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem"
          }}
        >
          <Typography align="center" fontWeight="bold">
            期限前ですが、募集を終了しますか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: { xs: "column-reverse", sm: "row" }
            }}
          >
            <Button
              variant={isMobile ? "text" : "outlined"}
              disableElevation
              onClick={() => setOpen(false)}
              sx={{
                fontWeight: "bold",
                flex: 1
              }}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={handleEarlyClose}
              disabled={isLoading}
              sx={{
                fontWeight: "bold",
                flex: 1
              }}
            >
              募集を終了する
            </Button>
          </Box>
        </Box>
      </CommonDialogWithCloseButton>
    </>
  );
};

export default EarlyCloseModal;
