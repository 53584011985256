import {
  Rating as MuiRating,
  RatingProps as MuiRatingProps
} from "@mui/material";

import { ReactComponent as RatingStarIcon } from "../../../assets/Star.svg";
import { ReactComponent as RatingStarEmptyIcon } from "../../../assets/StarEmpty.svg";

const Rating: React.FC<MuiRatingProps> = (props) => (
  <MuiRating
    name="customized-empty"
    defaultValue={3}
    precision={1}
    emptyIcon={<RatingStarEmptyIcon width="2.4rem" height="2.4rem" />}
    icon={<RatingStarIcon width="2.4rem" height="2.4rem" />}
    sx={{ margin: "0 auto" }}
    {...props}
  />
);

export default Rating;
