import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AiFirstViewImage from "assets/AiFirstViewImage.png";

import type React from "react";

const FirstView: React.FC = () => (
  <Box
    sx={{
      background: `no-repeat url(${AiFirstViewImage}), #D7EEF3`,
      backgroundPosition: { xs: "97% 50%", sm: "90% 50%" },
      display: "flex",
      alignItems: "center",
      backgroundSize: { xs: "180px, 100% 100%", sm: "210px, 100% 100%" },
      height: { xs: "100px", sm: "120px" }
    }}
  >
    <Typography
      variant="h2"
      sx={{
        margin: "0 auto",
        width: "90%"
      }}
    >
      AIで授業案を生成
    </Typography>
  </Box>
);

export default FirstView;
