import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RequestItem from "features/schools/components/molecules/mypage/v2/home/RequestItem";
import useGetRequest from "features/schools/hooks/useGetRequest";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { schoolRequestsAtom } from "store/schools/mypage/HomeStore";

import RequestIcon from "assets/common/sidebar/unselected/schools/request.svg";
import useLimitCheck from "hooks/useLimitCheck";

const Requests: React.FC = () => {
  const theme = useTheme();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolRequests, setSchoolRequests] = useAtom(schoolRequestsAtom);

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lesson_requests`
  });

  const { getLimit } = useLimitCheck();

  useEffect(() => {
    const params = {
      status: "only_request",
      limit: 3
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setSchoolRequests(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="依頼の取得に失敗しました。"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: "100%"
        }}
      >
        <CommonMypageHomeSectionTitle
          Icon={<img src={RequestIcon} alt="直接依頼状況" />}
          title="直接依頼状況"
          count={schoolRequests?.data_count || 0}
          linkName="すべての依頼を見る"
          link="/schools/mypage?page_name=requests"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%",
            height: "100%"
          }}
        >
          {!isLoading ? (
            schoolRequests ? (
              schoolRequests.data.map((schoolRequest, index) => {
                const { limit } = getLimit(
                  schoolRequest.details.accept_dead_at
                );

                return (
                  <React.Fragment key={index}>
                    <RequestItem
                      id={schoolRequest.details.id}
                      teacherName={schoolRequest.teacher.name}
                      teacherImage={schoolRequest.teacher.image.url}
                      organizationName={schoolRequest.teacher.organization_name}
                      teacherRank={schoolRequest.teacher.teacher_rank}
                      limit={limit}
                      step={schoolRequest.details.step}
                    />
                    <Divider sx={{ width: "100%" }} />
                  </React.Fragment>
                );
              })
            ) : (
              <Typography color={theme.palette.sub.main}>
                依頼がありません
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Requests;
