import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import FormFacePhotoField from "components/molecules/FormFacePhotoField";

import type React from "react";

type EditSectionProps = {
  editSectionRef: React.RefObject<HTMLDivElement>;
};

const FacePhotoCard: React.FC<EditSectionProps> = ({ editSectionRef }) => (
  <Card
    sx={{
      borderRadius: 0,
      margin: "1rem auto"
    }}
    elevation={0}
    ref={editSectionRef}
  >
    <CardContent sx={{ margin: ".5rem 0" }}>
      <Typography variant="h3" sx={{ marginBottom: ".5rem" }}>
        プロフィール画像
      </Typography>
      <FormFacePhotoField
        name="image"
        supplementalText={
          <div>
            プロフィール画像として適切なものの設定をお願いします。（顔写真でなくても構いません）
            <br />
            ※お写真は1:1のものを推奨しております。
          </div>
        }
      />
    </CardContent>
  </Card>
);

export default FacePhotoCard;
