import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import ManImage from "assets/ManImage.png";
import WomanImage from "assets/WomanImage.png";

const Conclusion: React.FC = () => {
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="section"
      sx={{
        background: "linear-gradient(to right, #FFEFC7, #FFDBAA)",
        padding: "2rem 0",
        overflow: "hidden"
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "80%",
          maxWidth: "960px",
          margin: "0 auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontSize: { xs: "1.3rem", sm: "1.5rem", md: "1.8rem" }
            }}
          >
            <Typography
              variant="h3"
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              生徒により豊かな学びを。
            </Typography>
            <br />
            <Typography
              variant="h3"
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              私たちが授業づくりの
            </Typography>
            <Typography
              variant="h3"
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              お手伝いをします！
            </Typography>
          </Typography>
          <Typography
            align="center"
            fontSize=".9rem"
            sx={{ margin: ".5rem auto" }}
          >
            <Typography
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              掲載しているカリキュラムや、
            </Typography>
            <Typography
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              外部人材はサンプルです。
            </Typography>
            <br />
            <Typography
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              実際は、学校の教育方針や
            </Typography>
            <Typography
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              既存のプログラムを踏まえながら、
            </Typography>
            <br />
            <Typography
              align="center"
              component="span"
              sx={{ display: "inline-block" }}
            >
              オリジナルの授業計画をご提案します。
            </Typography>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeNq4_e6RM47SrgHKQzlIqlG11678zPcrUceuuuCPwHqb2huQ/viewform"
            sx={{
              display: "block",
              textAlign: "center",
              margin: "0 auto",
              width: "80%",
              maxWidth: "480px",
              padding: "calc(.8rem + .3vw)",
              fontWeight: 600
            }}
          >
            お問い合わせ
          </Button>
        </Box>
        {underSm || (
          <>
            <Box
              component="img"
              alt="man"
              src={ManImage}
              sx={{
                display: "block",
                position: "absolute",
                bottom: "-85%",
                left: { xs: "calc(-9vw - 9rem)", lg: "calc(-20%)" },
                zIndex: 1
              }}
            />
            <Box
              component="img"
              alt="woman"
              src={WomanImage}
              sx={{
                display: "block",
                position: "absolute",
                bottom: "-85%",
                right: { xs: "calc(-9vw - 9rem)", lg: "calc(-20%)" },
                zIndex: 1
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Conclusion;
