import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAtom } from "jotai";
import { useState } from "react";

import { requestObjectAtom } from "store/MeetingSheetStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";
import Button from "components/atoms/mui/Button";

interface SuggestLessonPlanProps {
  generateTeachingPlan: () => void;
  isLoading: boolean;
}

const SuggestLessonPlan: React.FC<SuggestLessonPlanProps> = ({
  generateTeachingPlan,
  isLoading
}) => {
  const [open, setOpen] = useState(false);
  const [requestObject] = useAtom(requestObjectAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "1.5rem auto"
      }}
    >
      {requestObject?.teaching_plan && (
        <Accordion
          elevation={0}
          onChange={(event, expanded) => setOpen(expanded)}
          sx={{
            border: `solid 1px ${theme.palette.border?.main}`,
            borderRadius: "4px"
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="lesson-request-content"
            id="lesson-request-header"
          >
            {open ? (
              <div>
                <Typography variant="h4" fontWeight="bold" fontSize="1.1rem">
                  AIによる授業内容のご提案
                </Typography>
              </div>
            ) : (
              <div>
                <Typography variant="h4" fontWeight="bold" fontSize="1.1rem">
                  AIに提案された授業内容を確認する
                </Typography>
                <Typography fontSize=".9rem" color={theme.palette.sub.main}>
                  依頼内容を元にAIによる授業内容の作成を行うことができます。
                </Typography>
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {requestObject?.teaching_plan}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {!requestObject?.teaching_plan && schoolIsLoggedIn && (
        <Button
          color="primary"
          variant="contained"
          size="large"
          disableElevation
          onClick={generateTeachingPlan}
          disabled={isLoading}
        >
          AIで授業内容を作成する
        </Button>
      )}
    </Box>
  );
};

export default SuggestLessonPlan;
