import { useState } from "react";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtomValue } from "jotai";

import { currentSchoolAtom } from "store/SchoolAuthStore";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { patchExactStartAtSchemaType } from "features/requests/constants/PatchExactStartAtSchema";
import { postChatFormSchemaType } from "features/requests/constants/postChatFormShema";
import { postRatingsSchemaType } from "features/schools/constants/postRatingsSchema";

const useShowPageApi = (lessonRequestId: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentSchool = useAtomValue(currentSchoolAtom);
  const [updateExactStartAtIsLoading, setUpdateExactStartAtIsLoading] =
    useState(false);

  const {
    data: requestData,
    error: getRequestError,
    isLoading: getRequestIsLoading,
    mutate: mutateRequest
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers)
      .api.v1.school_teachers._schoolTeacherId_string(
        currentSchool?.id.toString()
      )
      .lesson_requests._lessonRequestId(lessonRequestId)
  );

  const teacher = requestData?.teacher;
  const supporter = requestData?.supporter;
  const details = requestData?.details;

  // for meeting sheet
  const { data: meetingSheetData, error: getMeetingSheetDataError } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers).api.v1.lesson_requests._lessonRequestId_string(
        lessonRequestId
      ).agenda_sheets.new
    );

  const meetingSheet = meetingSheetData?.details;

  // For chat
  const {
    data: requestCommentsData,
    error: getRequestCommentsError,
    isLoading: getRequestCommentsIsLoading,
    mutate: mutateRequestComments
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId(lessonRequestId)
      .lesson_request_comments,
    {
      refreshInterval: 60000,
      onError: (error) => {
        if (error.response?.status !== 404) {
          addErrorMessage("メッセージの取得に失敗しました");
        }
      }
    }
  );

  const postRequestComments = async (data: postChatFormSchemaType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId(lessonRequestId)
        .lesson_request_comments.post({
          body: data
        });

      mutateRequestComments();

      addSuccessMessage("メッセージが送信されました。");

      return { response, error: null };
    } catch (error) {
      console.error(error);
      addErrorMessage("メッセージの送信に失敗しました。");

      return { response: null, error };
    }
  };

  const deleteRequestComments = async (commentId: string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .lesson_request_comments._lessonRequestCommentId(commentId)
        .logical_delete.patch({});

      mutateRequestComments();

      return { response, error: null };
    } catch (error) {
      console.error(error);

      return { response: null, error };
    }
  };

  // for update exact_start_at
  const updateExactStartAt = async (data: patchExactStartAtSchemaType) => {
    setUpdateExactStartAtIsLoading(true);
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .update_exact_start_at.patch({
          body: data
        });

      mutateRequest();
      setUpdateExactStartAtIsLoading(false);
      addSuccessMessage("授業日程が変更されました。");

      return { response, error: null };
    } catch (error) {
      console.error(error);
      addErrorMessage("授業日時の変更に失敗しました");
      setUpdateExactStartAtIsLoading(false);

      return { response: null, error };
    }
  };

  // for rating
  const {
    mutate: mutateRating,
    data: ratingData,
    error: getRatingError
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId_string(
      lessonRequestId
    ).ratings
  );

  const ratingDetails = ratingData?.details;

  const postRating = async (data: postRatingsSchemaType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .ratings.post({
          body: data
        });

      mutateRequest();
      mutateRating();

      addSuccessMessage("評価を送信しました");

      return { response, error: null };
    } catch (error) {
      console.error(error);
      addErrorMessage("評価の送信に失敗しました");

      return { response: null, error };
    }
  };

  return {
    teacher,
    supporter,
    details,
    requestData,
    getRequestError,
    getRequestIsLoading,
    meetingSheet,
    getMeetingSheetDataError,
    updateExactStartAt,
    postRequestComments,
    updateExactStartAtIsLoading,
    requestCommentsData,
    getRequestCommentsError,
    getRequestCommentsIsLoading,
    mutateRequestComments,
    deleteRequestComments,
    ratingDetails,
    getRatingError,
    mutateRating,
    postRating
  };
};

export default useShowPageApi;
