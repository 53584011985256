import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";

type FormLavelProps = {
  labelName: string;
};

const RequireFormLabel: React.FC<FormLavelProps> = (props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center"
    }}
  >
    <Chip
      label="必須"
      color="primary"
      size="small"
      sx={{
        borderRadius: "4px",
        fontWeight: 600,
        fontSize: ".8rem"
      }}
    />
    <Typography
      component="label"
      fontWeight="bold"
      sx={{ margin: "0 .6rem" }}
      fontSize="1rem"
    >
      {props.labelName}
    </Typography>
  </Box>
);

export default RequireFormLabel;
