import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";
import React from "react";

import RequestStepperBg from "assets/RequestStepperBg.png";
import PreListItem from "features/requests/components/atoms/show/PreListItem";
import PreListItemWithLink from "features/requests/components/atoms/show/PreListItemWithLink";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import { LessonRequestSchoolTeacher, AgendaSheetNew } from "api/@types";

type PreparationListProps = {
  details?: LessonRequestSchoolTeacher["details"];
  meetingSheetDetails?: AgendaSheetNew["details"];
};

const PreparationList: React.FC<PreparationListProps> = (props) => {
  const { details, meetingSheetDetails } = props;
  // TODO: storeに依存しない形にする調整を別途行う
  const currentSchool = useAtomValue(currentSchoolAtom);

  return (
    <Box
      sx={{
        backgroundImage: `url(${RequestStepperBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 0%",
        padding: "2rem 0",
        margin: { xs: "0 -1.5rem", sm: "0" }
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "84%", sm: "80%" }
        }}
      >
        <Typography
          fontWeight="bold"
          align="center"
          sx={{ margin: "2rem auto 0" }}
        >
          事前準備リスト
        </Typography>
        <Typography fontWeight="bold">授業打ち合わせ準備</Typography>
        <PreListItem title="チャット内で授業日2週間前を目安にお打ち合わせの日程調整をした（された）" />
        <PreListItem title="お打ち合わせのzoom/meetリンクを発行し、チャット内で共有した（された）" />
        <PreListItemWithLink
          title={[
            { kindOf: "text", content: "事前に" },
            {
              kindOf: "link",
              content: "授業準備シート",
              link: `/schools/mypage/requests/${details?.id}/agenda`
            },
            {
              kindOf: "text",
              content: "に授業内容を入力し複業先生に記入したことを伝えた"
            }
          ]}
        />
        <Typography fontWeight="bold">授業打ち合わせ</Typography>
        <PreListItemWithLink
          title={[
            {
              kindOf: "link",
              content: "授業準備シート",
              link: `/schools/mypage/requests/${details?.id}/agenda`
            },
            {
              kindOf: "text",
              content:
                "を開いて画面共有しながら、メモ・タイムライン等に授業詳細を記入した"
            }
          ]}
        />
        <PreListItem title="お打ち合わせで緊急連絡先の交換をした" />
        <Typography fontWeight="bold">授業準備(授業打ち合わせ後)</Typography>
        <PreListItem title="授業当日のzoomを発行、チャット内で共有した（された）" />
        <PreListItem title="授業本番と同じ環境の準備・接続テストをした" />
        {meetingSheetDetails?.school_advance_todo && (
          <PreListItem
            title="事前準備の確認（当日までにやること）"
            text={meetingSheetDetails.school_advance_todo}
          />
        )}
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "84%", sm: "80%" }
        }}
      >
        <Typography
          fontWeight="bold"
          align="center"
          sx={{ margin: "2rem auto 0" }}
        >
          授業実施時に以下の生徒向けアンケートの実施をお願いします
        </Typography>
        <Typography>
          ※下記URLを先頭から末尾まで正しくコピーして生徒にお渡しください
        </Typography>
        <PreListItem
          title="事前アンケート（約3分）"
          text={`https://docs.google.com/forms/d/e/1FAIpQLScbXvUgluzS-A0y4EvMphkB7IXbt1B46o57_FYtr7_MwpS6IA/viewform?usp=pp_url&entry.583827525=${details?.id}&entry.1960217994=${currentSchool.school_name}`}
        />
        <PreListItem
          title="事後アンケート（約5~10分）"
          text={`https://docs.google.com/forms/d/e/1FAIpQLSewTm4MjQ8mjOYgDPdb_ZcFHykJlbrnzSQLpi12UNB0m93Kzw/viewform?usp=pp_url&entry.464159861=${details?.id}&entry.1960217994=${currentSchool.school_name}`}
        />
        <Typography>
          事前・事後のアンケートに回答いただくことで、授業実施後に今回の授業の結果レポートが返却されます。
        </Typography>
      </Box>
    </Box>
  );
};

export default PreparationList;
