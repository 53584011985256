import { Container, Grid, Typography, Box } from "@mui/material";
import CompleteIcon from "components/atoms/CompleteIcon";
import theme from "themes/theme";
import { Link as RouterLink } from "react-router-dom";

interface PasswordResetCompleteBlockProps {
  variant: "school" | "teacher";
}

const PasswordResetCompleteBlock: React.FC<PasswordResetCompleteBlockProps> = ({
  variant
}) => (
  <Container maxWidth="sm">
    <Grid container>
      <Grid item xs={12} sx={{ padding: "1.5rem 0" }}>
        <CompleteIcon />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" align="center" sx={{ margin: "1rem auto" }}>
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            パスワードの再設定が
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            完了しました。
          </Typography>
        </Typography>
        <Typography sx={{ margin: ".5rem auto" }} align="center">
          <Typography component="span" sx={{ display: "inline-block" }}>
            <Box
              component={RouterLink}
              to={variant === "school" ? "/schools/login" : "/teachers/login"}
              sx={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
                fontWeight: 600
              }}
            >
              こちら
            </Box>
            から新しいパスワードで
          </Typography>
          <Typography component="span" sx={{ display: "inline-block" }}>
            ログインしてください。
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  </Container>
);

export default PasswordResetCompleteBlock;
