import Grid from "@mui/material/Grid";

import AuthEyeCatch from "features/teachers/components/atoms/auth/AuthEyeCatch";
import Meta from "components/organisms/Meta";

import type React from "react";
import useTeacherAuthApi from "features/teachers/hooks/useTeacherAuthApi";
import { FormProvider } from "react-hook-form";
import PasswordResetRequestFormBlock from "components/organisms/auth/PasswordResetRequestFormBlock";
import { Box } from "@mui/material";
import AuthLayout from "../../../../components/layouts/AuthLayout";

const ForgotPassword: React.FC = () => {
  const { forgotPassword, forgotPasswordForm } = useTeacherAuthApi();

  return (
    <AuthLayout>
      <Meta />
      <Grid
        container
        sx={{
          height: { xs: "auto", lg: "100vh" }
        }}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            height: { xs: "auto", lg: "100%" }
          }}
        >
          <AuthEyeCatch />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            height: { xs: "auto", lg: "100%" }
          }}
        >
          <FormProvider {...forgotPasswordForm}>
            <Box
              component="form"
              onSubmit={forgotPasswordForm.handleSubmit(forgotPassword)}
              sx={{
                height: { xs: "auto", lg: "100%" }
              }}
            >
              <PasswordResetRequestFormBlock variant="teacher" />
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default ForgotPassword;
