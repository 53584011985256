import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import RequireFormLabel from "features/root/components/atoms/contact/RequireFormLabel";
import { emailAtom, emailIsErrorAtom } from "store/ContactFormStore";

import type React from "react";

const EmailField: React.FC = () => {
  const [email, setEmail] = useAtom(emailAtom);
  const [emailIsError] = useAtom(emailIsErrorAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <RequireFormLabel labelName="メールアドレス" />
      <Grid container>
        <Grid xs={12}>
          <TextField
            type="email"
            variant="outlined"
            placeholder="sample@fukugyo.co.jp"
            fullWidth
            value={email}
            onChange={handleChange}
            error={emailIsError}
            helperText={
              emailIsError && "メールアドレスの形式が正しくありません"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailField;
