import { atom } from "jotai";

type TeacherVideoType = {
  id: number;
  teacher_id: number;
  key: string;
  file: {
    url: string;
  };
  is_public: boolean;
};

type TeacherVideoShowType = {
  key: string;
  file: {
    url: string;
  };
};

export const teacherVideosAtom = atom<Array<TeacherVideoType>>([]);

export const teacherVideosShowAtom = atom<Array<TeacherVideoShowType>>([]);

export const removeTeacherVideoAtom = atom(null, (_get, set, id: number) => {
  set(teacherVideosAtom, (prev) => prev.filter((video) => video.id !== id));
});

export const deleteIsErrorAtom = atom<boolean>(false);
export const postIsErrorAtom = atom<boolean>(false);
export const postIsLoadingAtom = atom<boolean>(false);
export const deleteIsLoadingAtom = atom<boolean>(false);
