import Box from "@mui/material/Box";
import type React from "react";
import FormBirthdayField from "components/molecules/FormBirthdayField";
import FormSubmitButton from "components/molecules/FormSubmitButton";

const BirthdayForm: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      flexDirection: "column",
      gap: "1rem"
    }}
  >
    <FormBirthdayField
      name="birthday"
      label="生年月日"
      isRequired
      placeholders={{
        year: "1990",
        month: "1",
        date: "1"
      }}
      supplementalText="本人確認には生年月日の登録が必要です"
    />
    <FormSubmitButton label="生年月日を登録" fullWidth />
  </Box>
);
export default BirthdayForm;
