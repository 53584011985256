import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";

import SendIcon from "components/atoms/SendIcon";

import type React from "react";

interface ThanksResetRequestBlockProps {
  variant: "school" | "teacher";
}

const ThanksResetRequestBlock: React.FC<ThanksResetRequestBlockProps> = (
  props
) => {
  const { variant } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: { xs: theme.spacing(3), lg: "auto" },
        height: "100%"
      }}
    >
      <Grid item xs={10} sm={9} md={7}>
        <SendIcon />
        <Typography
          align="center"
          variant="h1"
          sx={{
            margin: "1rem auto",
            width: "100%"
          }}
        >
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            登録中のメールアドレスに
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            パスワード
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            再設定用の
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{ display: "inline-block" }}
          >
            メールを送信しました
          </Typography>
        </Typography>
        <div>
          <Typography textAlign="center">
            <Typography component="span" sx={{ display: "inline-block" }}>
              メールが届かない場合：アドレスが正しいものか
            </Typography>
            <Typography component="span" sx={{ display: "inline-block" }}>
              ご確認の上、
            </Typography>
            <Typography component="span" sx={{ display: "inline-block" }}>
              間違っていた場合は
            </Typography>
            <Box
              component={RouterLink}
              to={
                variant === "school"
                  ? "/schools/forgot-password"
                  : "/teachers/forgot-password"
              }
              sx={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
                fontWeight: 600
              }}
            >
              こちら
            </Box>
            から再度送信してください。
            <Typography component="span" sx={{ display: "inline-block" }}>
              また、迷惑メールフォルダに入っていないか、
            </Typography>
            <Typography component="span" sx={{ display: "inline-block" }}>
              ご確認ください。
            </Typography>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default ThanksResetRequestBlock;
