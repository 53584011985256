import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";

import type React from "react";

const PublicCheckAlert: React.FC = () => (
  <Alert
    severity="warning"
    sx={{
      width: { xs: "90%", sm: "100%" }
    }}
  >
    <AlertTitle sx={{ marginTop: "-6px" }}>現在内容確認中です</AlertTitle>
    確認完了まで今しばらくお待ちください。
  </Alert>
);

export default PublicCheckAlert;
