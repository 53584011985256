type PageViewEvent = {
  event: "page_view";
  pagePath: string;
};

type InViewEvent = {
  event: "inView";
  label: string;
};

type ClickEvent = {
  event: "click";
  label: string;
};

// MEMO: custom event
type TeacherVideoClickEvent = {
  event: "view_profile_video";
  teacher_id: string;
};

export type DataLayerType =
  | PageViewEvent
  | InViewEvent
  | ClickEvent
  | TeacherVideoClickEvent;

export const pushDataLayer = (data: DataLayerType): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(data);
};

// MEMO: thanks for https://qiita.com/cheez921/items/a9e8d257684098db55c3#%E4%BA%8B%E5%89%8D%E6%BA%96%E5%82%99-datalayerpush%E3%81%AE%E5%87%A6%E7%90%86%E3%82%92%E5%88%A5%E9%96%A2%E6%95%B0%E3%81%A7%E3%82%8F%E3%81%91%E3%82%8B
