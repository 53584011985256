import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";

import ToGoSetupEyeCatch from "assets/ToGoSetupEyeCatch.png";

import type React from "react";

const ToNew: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          component="img"
          src={ToGoSetupEyeCatch}
          alt="to go setup"
          sx={{
            objectFit: "cover",
            width: "100%",
            minHeight: "100px"
          }}
        />
      </Box>
      <Container maxWidth="sm" sx={{ margin: "1rem auto" }}>
        <Grid container>
          <Grid xs={12}>
            <Typography variant="h1" sx={{ margin: "1rem auto" }}>
              本登録には審査が必要です。
              <br />
              審査に必要な情報を
              <br />
              下のボタンから入力してください。
              <br />
            </Typography>
            <Typography sx={{ margin: "1rem auto" }}>
              ご安心してご利用いただけるよう、みなさまに実名での登録をお願いしております。登録された情報は審査後一般公開されますのでご注意ください。※登録された内容が、ご本人様のものでないと判断した場合、アカウントを停止することがあります。
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              size="large"
              sx={{ margin: "1rem auto" }}
              onClick={() => history.push("/teachers/profile/new")}
            >
              本登録の入力へ進む
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ToNew;
