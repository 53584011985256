import { atom } from "jotai";

import type { RatingBlockType } from "types/ratingTypes";
import type {
  teacherInfoType,
  teacherLessonRequestType
} from "types/teacherInfoType";

export const requestModalOpenAtom = atom(false);
export const teacherInfoAtom = atom<teacherInfoType>({
  teacher_id: 0,
  organization_name: "",
  name: "",
  image: {
    url: ""
  },
  organization_categories: [],
  enthusiasm: "",
  introduction: "",
  tags: [],
  teacher_rank: "",
  lesson_contents: "",
  origin_prefecture_name: "",
  residence_prefecture_name: "",
  origin_school_name: "",
  origin_high_school_name: "",
  origin_graduate_school_name: "",
  teaching_licenses: [],
  teaching_plan_links: [],
  video_url: "",
  is_lesson_experience: false,
  websites: []
});

export const ratingAtom = atom<RatingBlockType | null>(null);

export const teacherLessonRequestsAtom = atom<Array<teacherLessonRequestType>>(
  []
);

// for: UI control
export const ratingTargetAtom = atom<HTMLDivElement | null>(null);
