import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

import { VideoJS } from "components/atoms/VideoJS";

import type React from "react";
import type videojs from "video.js";

type TeachingMovieModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  videoUrl: string;
};

const TeachingVideoModal: React.FC<TeachingMovieModalProps> = (props) => {
  const { open, setOpen, videoUrl } = props;

  const handleClose = () => {
    setOpen(null);
  };

  const videoJSOptions: videojs.PlayerOptions = {
    controls: true,
    aspectRatio: "16:9",
    fluid: true,
    sources: [
      {
        src: videoUrl,
        type: "video/mp4"
      }
    ]
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} fullWidth>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px",
          zIndex: 1600
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 6,
            top: 6
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          padding: "1rem 2rem",
          height: "100%"
        }}
      >
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%", // 16:9 のアスペクト比
            height: 0,
            overflow: "hidden"
          }}
        >
          <VideoJS options={videoJSOptions} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TeachingVideoModal;
