import { useState, useEffect } from "react";

// TODO: 純粋関数かつ不要なので以下に置き換えたい
//  dayjs(date).locale("ja").format("YYYY年 MM月 DD日 (ddd) HH時mm分");

const useFormatJapaneseDaytime = (importedDate?: Date) => {
  const [formatJapaneseDaytime, setFormatJapaneseDaytime] = useState("");

  const getFormatJapaneseDaytime = (importedDate: Date) => {
    const year = importedDate.getFullYear();
    const month = importedDate.getMonth() + 1;
    const date = importedDate.getDate();
    const dayNumber = importedDate.getDay();
    const dayArray = ["日", "月", "火", "水", "木", "金", "土"];
    const day = dayArray[dayNumber];
    const hours = importedDate.getHours();
    const minutes = importedDate.getMinutes();
    const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${String(year)}年 ${String(month)}月 ${String(date)}日（${day}）${hours}時${formatMinutes}分`;
  };

  useEffect(() => {
    if (importedDate) {
      const formatedImportedDate = getFormatJapaneseDaytime(importedDate);
      setFormatJapaneseDaytime(formatedImportedDate);
    }
  }, [importedDate]);

  return { formatJapaneseDaytime, getFormatJapaneseDaytime };
};

export default useFormatJapaneseDaytime;
