import SitesField from "features/teachers/components/molecules/profile/SitesField";
import ProfileEditContentsAccordion from "features/teachers/components/organisms/profile/edit/ProfileEditContentsAccordion";

import type React from "react";

const WebsitesCard: React.FC = () => (
  <ProfileEditContentsAccordion
    title="ウェブサイト"
    label="sites"
    fields={["websites"]}
  >
    <SitesField />
  </ProfileEditContentsAccordion>
);

export default WebsitesCard;
