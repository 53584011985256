import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import useFormatDate from "hooks/useFormatDate";
import { recruitmentAtom } from "store/schools/recruitments/ShowStore";

import type React from "react";

const SelectCandidateAlert: React.FC = () => {
  const theme = useTheme();
  const recruitment = useAtomValue(recruitmentAtom);
  const { getFormatDate } = useFormatDate();

  return (
    <Typography
      sx={{
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.main,
        padding: ".5rem",
        width: "100%"
      }}
    >
      応募を締め切りました。
      {getFormatDate(new Date(recruitment?.select_deadline || ""))}
      までに応募者の中から、複業先生を選んでください。
    </Typography>
  );
};

export default SelectCandidateAlert;
