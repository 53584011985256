import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import ErrorAlert from "components/atoms/ErrorAlert";
import Loading from "components/atoms/Loading";
import ToAgendaSheetBanner from "features/requests/components/atoms/show/ToAgendaSheetBanner";
import AddExactStartAtDisplay from "features/schools/components/molecules/show/requests/AddExactStartAtDisplay";
import UpdateExactStartAtDisplay from "features/schools/components/molecules/show/requests/UpdateExactStartAtDisplay";
import Meta from "components/organisms/Meta";
import Chat from "features/requests/components/organisms/Show/Chat";
import ExactStartAtUpdateModal from "features/requests/components/organisms/Show/ExactStartAtUpdateModal";
import Step01 from "features/schools/components/organisms/show/requests/Step01";
import Step02 from "features/schools/components/organisms/show/requests/Step02";
import Step03 from "features/schools/components/organisms/show/requests/Step03";
import TeacherInfoBlock from "features/schools/components/organisms/show/requests/TeacherInfoBlock_v2";
import TeacherInfoGuid from "features/schools/components/organisms/show/requests/TeacherInfoGuid_v2";
import CommonLayout from "components/layouts/CommonLayout";
import { updateErrorAtom, updateIsLoadingAtom } from "store/RequestShowStore";

import type { routerIdType } from "types/routerParamsType";

import useShowPageApi from "features/schools/hooks/requests/useShowPageApi";
import RequestFellThrough from "features/schools/components/organisms/show/requests/RequestFellThrough";
import { useDialog } from "hooks/useDialog";

const Show: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDialog();
  const [updateError, setUpdateError] = useAtom(updateErrorAtom);
  const [updateIsLoading] = useAtom(updateIsLoadingAtom);
  const theme = useTheme();
  const { id } = useParams<routerIdType>();

  const {
    getRequestIsLoading,
    teacher,
    supporter,
    details,
    getRequestCommentsIsLoading,
    requestCommentsData,
    meetingSheet,
    postRequestComments,
    deleteRequestComments,
    updateExactStartAt,
    updateExactStartAtIsLoading,
    ratingDetails,
    postRating
  } = useShowPageApi(id);

  const teacherGuildInfoRef = useRef<HTMLDivElement>(null);

  const inValid =
    details?.step === "denied" ||
    details?.step === "canceled" ||
    details?.step === "dead" ||
    details?.step === "lesson_request";

  const getStepContents = (step: string) => {
    switch (step) {
      case "canceled":
      case "denied":
      case "dead":
        return <RequestFellThrough details={details} teacher={teacher} />;
      case "lesson_request":
        return <Step01 details={details} />;
      case "preparation":
      case "lesson_end":
        return <Step02 details={details} meetingSheetDetails={meetingSheet} />;
      case "completed":
        return (
          <Step03
            details={details}
            ratingDetails={ratingDetails}
            postRating={postRating}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業依頼" />
      <ErrorAlert
        isError={updateError}
        setIsError={setUpdateError}
        errorMessage="情報が更新できませんでした。"
      />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          padding: { sm: 0, lg: "1.5rem 0" }
        }}
      >
        {!getRequestIsLoading &&
        !getRequestCommentsIsLoading &&
        !updateIsLoading ? (
          <Container maxWidth="lg" sx={{ padding: { xs: 0 } }}>
            <Box
              sx={{
                display: "flex",
                margin: "0 auto",
                width: { sm: "100%", md: "90%" },
                gap: "1.5rem",
                flexDirection: { xs: "column", md: "row" }
              }}
            >
              <Box
                sx={{ display: { xs: "none", md: "block" }, width: "270px" }}
              >
                <TeacherInfoBlock
                  teacherObject={teacher}
                  lessonObject={details}
                />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <TeacherInfoGuid
                  TeacherInfoSpRef={teacherGuildInfoRef}
                  teacherObject={teacher}
                />
              </Box>
              <ExactStartAtUpdateModal
                details={details}
                updateExactStartAt={updateExactStartAt}
                isOpen={isOpen}
                onClose={onClose}
                isLoading={updateExactStartAtIsLoading}
              />
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    overflow: "hidden",
                    padding: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem"
                  }}
                >
                  {!inValid &&
                    (details?.exact_start_at ? (
                      <UpdateExactStartAtDisplay
                        details={details}
                        onOpen={onOpen}
                      />
                    ) : (
                      <AddExactStartAtDisplay onOpen={onOpen} />
                    ))}
                  {getStepContents(details?.step || "")}
                  {!inValid && (
                    <Chat
                      details={details}
                      teacher={teacher}
                      supporter={supporter}
                      requestCommentsData={requestCommentsData}
                      postRequestComments={postRequestComments}
                      deleteRequestComments={deleteRequestComments}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    marginBottom: { xs: "1.5rem", lg: 0 }
                  }}
                  ref={teacherGuildInfoRef}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      margin: "1rem auto",
                      width: "100%",
                      padding: "0 1.5rem"
                    }}
                  >
                    案件情報
                  </Typography>
                  <TeacherInfoBlock
                    teacherObject={teacher}
                    lessonObject={details}
                  />
                </Box>
              </Box>
              {!inValid && <ToAgendaSheetBanner details={details} />}
            </Box>
          </Container>
        ) : (
          <Loading headerOnly />
        )}
      </Box>
    </CommonLayout>
  );
};

export default Show;
