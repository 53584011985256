import { ArrowUpward } from "@mui/icons-material";
import { Grid, Collapse, Typography, Button } from "@mui/material";
import { useState } from "react";
import theme from "themes/theme";

import AddIcon from "@mui/icons-material/Add";

const IntroductionText: React.FC = () => {
  const [introductionSeeNext, setIntroductionSeeNext] = useState(false);

  return (
    <Grid container>
      <Collapse in={introductionSeeNext} collapsedSize={120}>
        <Grid item xs={12}>
          <Typography>【例文】</Typography>
          <Typography>
            コンサルティングファームでXX年勤務し、現在XXという会社を運営しています。自分の得意なロジカルシンキングやフレームワークの知識を生かし、生徒に対して探究活動のアドバイスを行うことができます。また、海外プロジェクトに携わった経験やNPOで働いた経験もあるので、キャリア教育の分野でも講演をすることが可能です。
          </Typography>
        </Grid>
      </Collapse>
      <Grid
        item
        xs={12}
        sx={{
          margin: `${theme.spacing(1)} auto`,
          textAlign: "center",
          display: { xs: "block", md: "none" }
        }}
      >
        <Button
          variant="text"
          color="secondary"
          startIcon={introductionSeeNext ? <ArrowUpward /> : <AddIcon />}
          onClick={() => setIntroductionSeeNext(!introductionSeeNext)}
        >
          {introductionSeeNext ? "折りたたむ" : "続きを読む"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default IntroductionText;
