import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "components/atoms/mui/Button";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import type React from "react";

type BottomButtonGroupProps = {
  handleBack: () => void;
  handleNext: () => void;
  activeStep: number;
  isNextClickable?: boolean;
  lastStep: number;
  confirmText?: string;
  submitText?: string;
};

const BottomButtonGroup: React.FC<BottomButtonGroupProps> = (props) => {
  const {
    handleBack,
    handleNext,
    activeStep,
    isNextClickable,
    lastStep,
    confirmText,
    submitText = "送信"
  } = props;
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={6}>
        {activeStep !== 0 && (
          <Button
            color="inherit"
            variant="contained"
            size="large"
            disableElevation
            startIcon={<ChevronLeft />}
            onClick={handleBack}
            sx={{
              boxSizing: "border-box",
              width: { xs: "100%", sm: "90%" },
              maxWidth: { xs: "150px", sm: "none" },
              margin: theme.spacing(2),
              "& .MuiButton-startIcon": {
                position: "absolute",
                left: "1rem"
              }
            }}
          >
            戻る
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        {activeStep === lastStep ? (
          <FormSubmitButton
            label={submitText}
            fullWidth
            size="large"
            disabled={false}
            sx={{
              boxSizing: "border-box",
              width: { xs: "100%", sm: "90%" },
              maxWidth: { xs: "150px", sm: "none" },
              margin: theme.spacing(2)
            }}
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            disableElevation
            endIcon={<ChevronRight />}
            onClick={handleNext}
            disabled={isNextClickable !== undefined && !isNextClickable}
            sx={{
              boxSizing: "border-box",
              width: { xs: "100%", sm: "90%" },
              maxWidth: { xs: "150px", sm: "none" },
              margin: theme.spacing(2),
              "& .MuiButton-endIcon": {
                position: "absolute",
                right: "1rem"
              }
            }}
          >
            {confirmText && lastStep - 1 === activeStep ? confirmText : "次へ"}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default BottomButtonGroup;
