import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { lessonOptionsAtom } from "store/RequestNewStore";

import prices from "constants/prices";

const useAmount = (schoolMinutes: number, selectedOptionIds: number[] = []) => {
  const [amount, setAmount] = useState(prices.lesson1time);
  const lessonOptions = useAtomValue(lessonOptionsAtom);

  useEffect(() => {
    let price = prices.lesson1time;

    if (schoolMinutes > 60) {
      price = prices.lesson2time;
    }

    if (schoolMinutes > 120) {
      price = prices.lesson3time;
    }

    if (selectedOptionIds && selectedOptionIds.length !== 0) {
      const selectedOptions = lessonOptions.filter((option) =>
        selectedOptionIds.includes(option.id)
      );
      const optionPrice = selectedOptions.reduce(
        (acc, option) => acc + option.amount,
        0
      );
      setAmount(price + optionPrice);
    } else {
      setAmount(price);
    }
  }, [selectedOptionIds, schoolMinutes]);

  // FIXME: 何故か4回呼ばれる
  // console.log(amount);

  return amount;
};

export default useAmount;
