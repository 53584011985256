import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import type React from "react";

type PreListItemProps = {
  title: string;
  text?: string;
};

const PreListItem: React.FC<PreListItemProps> = (props) => {
  const { title, text } = props;

  return (
    <Box sx={{ display: "flex", margin: "1rem auto" }}>
      <DoneOutlineIcon color="primary" sx={{ marginRight: "1rem" }} />
      <Box>
        <Typography>{title}</Typography>
        {text && (
          <Typography
            sx={{
              marginTop: "0.25rem",
              whiteSpace: "pre-wrap",
              wordBreak: "break-all"
            }}
          >
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PreListItem;
