import Grid from "@mui/material/Grid";

import { Link as RouterLink } from "react-router-dom";
import Meta from "components/organisms/Meta";

import type React from "react";
import useSchoolAuthApi from "features/schools/hooks/useSchoolAuthApi";
import { Typography, Box, FormGroup, useTheme } from "@mui/material";
import FormTextField from "components/molecules/FormTextField";
import { FormProvider } from "react-hook-form";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import useTeacherAuthApi from "features/teachers/hooks/useTeacherAuthApi";
import AuthLayout from "../../../../components/layouts/AuthLayout";

const Login: React.FC = () => {
  const theme = useTheme();
  const { login, loginForm } = useSchoolAuthApi();
  const { resetTeacher } = useTeacherAuthApi();

  const handleSubmit = async () => {
    await resetTeacher().finally(() => {
      login();
    });
  };

  return (
    <AuthLayout>
      <Meta
        title="教員|ログイン"
        description="教員向けのログインページです。複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。"
      />
      <Grid
        container
        sx={{
          margin: { xs: "3rem auto", lg: 0 },
          height: { xs: "100%", lg: "100vh" }
        }}
        alignItems="center"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: { xs: theme.spacing(3), lg: 0 },
            height: "100%"
          }}
        >
          <Grid item xs={10} sm={9} md={7}>
            <Typography variant="h3" align="center">
              教員アカウントログイン
            </Typography>
            <Typography align="center">
              複業先生アカウントのログインは
              <Box
                component={RouterLink}
                to="/teachers/login"
                sx={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                  fontWeight: 600
                }}
              >
                こちらから
              </Box>
            </Typography>
            <FormGroup>
              <FormProvider {...loginForm}>
                <form onSubmit={loginForm.handleSubmit(handleSubmit)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      margin: "1.5rem auto"
                    }}
                  >
                    <FormTextField
                      name="email"
                      label="メールアドレス"
                      noLabel
                      placeholder="sample@fukugyo.co.jp"
                      fullWidth
                    />
                    <FormPasswordField name="password" label="パスワード" />
                    <FormSubmitButton label="ログイン" size="large" fullWidth />
                  </Box>
                </form>
              </FormProvider>
            </FormGroup>
            <Typography align="center">
              <Box
                component={RouterLink}
                to="/schools/forgot-password"
                sx={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                  fontWeight: 600
                }}
              >
                パスワードをお忘れですか？
              </Box>
              ・
              <Box
                component={RouterLink}
                to="/schools/signup"
                sx={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                  fontWeight: 600
                }}
              >
                新規登録
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Login;
