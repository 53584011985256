import aspidaAxios from "@aspida/axios";

import axios from "axios";

import api from "../api/$api";

const aspida = (headers: Object) => {
  const client = axios.create({
    baseURL: `${process.env.REACT_APP_HOST}/api`,
    headers
  });

  // POSTのフォームデータの配列を加工する処理
  client.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
      const newFormData = new FormData();
      const formDataKeys = Object.keys(config.data.entries());
      config.data.forEach((value, key) => {
        formDataKeys.push(key);
      });

      config.data.forEach((value, key) => {
        const isMultiple = formDataKeys.filter((k) => k === key).length > 1;
        if (isMultiple) {
          newFormData.append(`${key}[]`, value);
        } else {
          newFormData.append(key, value);
        }
      });
      config.data = newFormData;
    }
    return config;
  });

  return api(
    aspidaAxios(client, {
      // クエリパラメータの配列を加工する処理
      paramsSerializer: { indexes: false }
    })
  );
};

export default aspida;
