import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useQuery = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getQuery = (key: string) => query.get(key);

  const setQuery = (key: string, value: string) => {
    query.set(key, value);
    history.replace({ pathname, search: query.toString() });
    return query.get(key);
  };

  const deleteQuery = (key: string) => {
    query.delete(key);
    history.replace({ pathname, search: query.toString() });
    return query.get(key);
  };

  return { getQuery, setQuery, deleteQuery };
};

export default useQuery;
