import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };

  return (
    <CommonLayout>
      <Meta
        title="404 Not Found"
        description="お探しのページが見つかりません。URLを確認してください。"
      />
      <Container>
        <Typography variant="h1" align="center">
          404 Not Found
        </Typography>
        <Typography align="center">お探しのページが見つかりません</Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleClick}
          sx={{
            display: "block",
            margin: "2rem auto 1rem",
            height: "48px",
            fontWeight: "bold",
            width: "360px"
          }}
        >
          ホームへ戻る
        </Button>
      </Container>
    </CommonLayout>
  );
};

export default NotFound;
