import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router";

import ToGoSetupEyeCatch from "assets/ToGoSetupEyeCatch.png";
import Meta from "components/organisms/Meta";

import type React from "react";

const ToNew: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <Meta title="複業先生｜本登録" />
      <Box sx={{ width: "100%" }}>
        <Box
          component="img"
          src={ToGoSetupEyeCatch}
          alt="to go setup"
          sx={{
            objectFit: "cover",
            width: "100%",
            minHeight: "100px"
          }}
        />
      </Box>
      <Container maxWidth="sm" sx={{ margin: "1rem auto" }}>
        <Grid container>
          <Grid xs={12}>
            <Typography variant="h1" sx={{ margin: "1rem auto" }}>
              本登録に必要な情報を
              <br />
              下のボタンから入力してください。
              <br />
            </Typography>
            <Typography sx={{ margin: "1rem auto" }}>
              ご安心してご利用いただけるよう、登録された情報を弊社にて確認後、複業先生への授業依頼が送信できるようになります。
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              size="large"
              onClick={() => history.push("/schools/profile/new")}
              sx={{ margin: "1rem auto" }}
            >
              本登録の入力へ進む
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ToNew;
