import Box from "@mui/material/Box";
import Pagination from "@mui/lab/Pagination";
import { useAtom } from "jotai";

import { paginationAtom, pageAtom } from "store/TeachersIndexStore";

import type React from "react";

import { getIndexType } from "features/teachers/hooks/useIndexPageApi";

type TeachersPaginationProps = {
  search: (value: getIndexType) => void;
};

const TeachersPagination: React.FC<TeachersPaginationProps> = (props) => {
  const { search } = props;
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [, setPage] = useAtom(pageAtom);

  const handleChange = (page: number) => {
    setPagination({ ...pagination, current: page });
    setPage(page);
    search({ pages: page });
  };

  return (
    <Box
      sx={{
        padding: "2rem 0",
        width: "100%"
      }}
    >
      {pagination.count !== 0 && (
        <Pagination
          count={pagination.pages}
          color="primary"
          variant="outlined"
          shape="rounded"
          onChange={(e, page) => handleChange(page)}
          page={pagination.current}
        />
      )}
    </Box>
  );
};

export default TeachersPagination;
