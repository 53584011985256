import Box from "@mui/material/Box";
import type React from "react";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import {
  allowOptions,
  isLimitedPublicOptions,
  rewardOptions,
  mailMagazineOptions
} from "constants/selectOptions";
import FormSelect from "components/molecules/FormSelect";
import { Typography } from "@mui/material";
import theme from "themes/theme";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { useFormContext } from "react-hook-form";
import SectionLoading from "components/atoms/SectionLoading";

const BasicSettingContents: React.FC = () => {
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext();
  const isLimitedPublic = watch("is_limited_public");

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        width: "100%"
      }}
    >
      <FormSelect
        name="is_limited_public"
        label="プロフィール公開設定"
        options={isLimitedPublicOptions}
        isRequierd
        fullWidth
      />
      <Box>
        <Typography color={theme.palette.sub.main} fontSize=".9rem">
          {isLimitedPublic
            ? "教員アカウントのみに公開されています。"
            : "公開されています。"}
          教員アカウントのみに公開されています。
        </Typography>
        <Typography fontSize=".9rem">
          {isLimitedPublic ? (
            <>
              公開にすると多くの人に見てもらえます。
              <br />
              現在サイトに登録していない人には見えていません。
            </>
          ) : (
            "現在サイトに登録していない人にも見えています。"
          )}
        </Typography>
      </Box>
      <FormRadioGroup
        name="is_reward"
        label="報酬の有無"
        options={rewardOptions}
        isRequired
        supplementalText="報酬なしを選択した場合は、地域のNPO等に寄付されます。"
      />
      <FormRadioGroup
        name="can_interview"
        label="メディアからの取材"
        options={allowOptions}
        isRequired
        supplementalText="許可するを選択した場合、LX事務局を通じて、授業に取材が入ることがあります。"
      />
      <FormRadioGroup
        name="is_mail_magazine"
        label="メールマガジンの配信"
        options={mailMagazineOptions}
        isRequired
        supplementalText="イベントなど、LX DESIGN（『複業先生』事務局）からのお知らせ・情報が届きます。『複業先生』プラットフォームから自動的に配信されるメールは停止されません。例＞授業募集　等"
      />
      <FormSubmitButton label="保存する" size="large" />
      {isSubmitting && <SectionLoading hasColor />}
    </Box>
  );
};

export default BasicSettingContents;
