import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useHistory } from "react-router-dom";

import useFormatJapaneseDay from "hooks/useFormatJapaneseDay";

import type React from "react";

type RatingAttentionBlockProps = {
  id: number;
  lessonStartAt: Date | "";
  avatar: string;
  organization: string | null;
  teacherName: string;
};

const RatingAttentionBlock: React.FC<RatingAttentionBlockProps> = (props) => {
  const { id, lessonStartAt, avatar, organization, teacherName } = props;

  const history = useHistory();
  const theme = useTheme();
  const lessonStartAtFormat = useFormatJapaneseDay(new Date(lessonStartAt));

  const handleChange = () => {
    history.push(`/schools/mypage/requests/${id}`);
  };

  return (
    <Box sx={{ margin: "1rem auto" }}>
      <Box
        sx={{
          border: `1px solid ${theme.palette.border?.main}`,
          borderRadius: "4px",
          padding: "1rem"
        }}
      >
        <Typography sx={{ margin: "0 auto .5rem" }}>
          授業日：{lessonStartAtFormat}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Avatar
            src={avatar}
            alt={teacherName}
            sx={{ marginRight: ".75rem" }}
          />
          <div>
            {organization && (
              <Typography color={theme.palette.sub.main} fontSize=".9rem">
                {organization}
              </Typography>
            )}
            <Typography fontWeight="bold">{teacherName}</Typography>
          </div>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleChange}
        disableElevation
        sx={{
          fontWeight: "bold",
          margin: "1rem auto"
        }}
      >
        評価する
      </Button>
    </Box>
  );
};

export default RatingAttentionBlock;
