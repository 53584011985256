import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";

import type React from "react";

type LessonSchoolDataProps = {
  schoolName: string;
  schoolImage: string;
  isCertification: boolean;
};

const LessonSchoolData: React.FC<LessonSchoolDataProps> = (props) => {
  const { schoolName, schoolImage, isCertification } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: ".5rem"
      }}
    >
      <Avatar
        src={schoolImage}
        alt={schoolName}
        sx={{
          width: "1.5rem",
          height: "1.5rem"
        }}
      />
      <Typography>{schoolName}</Typography>
      {isCertification && (
        <Box
          component="img"
          src={SchoolCertificationBadge}
          alt={schoolName}
          sx={{
            height: "16px",
            margin: "0.25rem"
          }}
        />
      )}
    </Box>
  );
};

export default LessonSchoolData;
