import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import RequestItemData from "./RequestItemData";

type RequestBoxProps = {
  id: number;
  teacherName: string;
  teacherImage: string;
  organizationName: string;
  teacherRank: string;
  limit?: number;
  step?: string;
};

const RequestItem: React.FC<RequestBoxProps> = (props) => {
  const {
    id,
    teacherName,
    teacherImage,
    organizationName,
    teacherRank,
    limit,
    step
  } = props;

  const theme = useTheme();

  return (
    <Link
      href={`/schools/mypage/requests/${id}`}
      sx={{
        width: "100%",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <RequestItemData
          teacherName={teacherName}
          teacherImage={teacherImage}
          organizationName={organizationName}
          teacherRank={teacherRank}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: ".5rem"
          }}
        >
          <Typography fontSize=".8rem" color={theme.palette.sub.main}>
            返答期限
          </Typography>
          {step === "lesson_request" && limit && limit > 0 ? (
            <Typography fontWeight="bold">あと{limit}日</Typography>
          ) : (
            <Typography color={theme.palette.sub.main}>------</Typography>
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default RequestItem;
