import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AnswerField from "features/teachers/components/molecules/mypage/eLearning/AnswerField";
import AnswerResult from "features/teachers/components/molecules/mypage/eLearning/AnswerResult";

import type React from "react";

type PartProps = {
  isResult?: boolean;
};

const Part4: React.FC<PartProps> = (props) => {
  const { isResult } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        ④最後に
      </Typography>
      <Typography sx={{ lineHeight: "2rem !important" }}>
        <span>授業を通して、彼らが</span>
        {isResult ? (
          <AnswerResult index={8} />
        ) : (
          <AnswerField index={8} wordCount={4} />
        )}
        <span>
          になってほしいか、自分ならではの提供できる価値は何かを意識し、授業を楽しむ心を大切にしましょう。
        </span>
      </Typography>
    </Box>
  );
};

export default Part4;
