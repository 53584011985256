import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormLabel from "components/atoms/FormLabel";

import FacePhoto from "components/atoms/FacePhoto";
import IndicatePublic from "components/atoms/fieldLabels/IndicatePublic";

import type React from "react";
import { useDialog } from "hooks/useDialog";
import { useFormContext } from "react-hook-form";
import { convertBlobToBase64 } from "utils/covertBase64";
import ImageCropModal from "./ImageCropModal";

type FormFacePhotoFieldProps = {
  name: string;
  label?: string;
  supplementalText?: string | React.ReactNode;
  isRequired?: boolean;
  isPublic?: boolean;
};

const FormFacePhotoField: React.FC<FormFacePhotoFieldProps> = ({
  name,
  label,
  supplementalText = "",
  isRequired,
  isPublic
}) => {
  const { setValue, watch } = useFormContext();
  const cropDialog = useDialog();
  const imageBase64 = watch(name);

  const handleImageSelected = async (imageBlob: Blob) => {
    const base64 = await convertBlobToBase64(imageBlob);
    setValue(name, base64, { shouldDirty: true });
    cropDialog.onOpen();
  };

  const handleCropComplete = async (croppedImageBlob: Blob) => {
    const base64 = await convertBlobToBase64(croppedImageBlob);
    cropDialog.onClose();
    setValue(name, base64, { shouldDirty: true });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        width: "100%"
      }}
    >
      {label && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "0.25rem"
          }}
        >
          <FormLabel labelName={label} isRequired={isRequired} />
        </Box>
      )}
      <Grid container alignContent="center" alignItems="center" spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ marginBottom: { xs: "1rem", sm: "inherit" } }}
        >
          <FacePhoto src={imageBase64} onImageSelected={handleImageSelected} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="caption" whiteSpace="pre-wrap">
            {supplementalText}
          </Typography>
        </Grid>
      </Grid>
      {isPublic && <IndicatePublic />}
      <ImageCropModal
        open={cropDialog.isOpen}
        onClose={cropDialog.onClose}
        src={imageBase64}
        onComplete={handleCropComplete}
      />
    </Box>
  );
};

export default FormFacePhotoField;
