import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { Link } from "react-router-dom";

type GuideCardProps = {
  title: string;
  description: Array<string>;
  Image: string;
  buttonTitle: string;
  link: string;
};

const GuideCard: React.FC<GuideCardProps> = (props) => {
  const { title, description, Image, buttonTitle, link } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "1rem",
        backgroundColor: theme.palette.primary.lighter,
        borderRadius: "8px",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "350px",
          margin: "0 auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem"
          }}
        >
          <img src={Image} alt={title} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start"
            }}
          >
            <Typography variant="h3">{title}</Typography>
            <Typography>
              {description.map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={link}
          sx={{
            border: `2px solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.dark,
            fontWeight: "bold",
            backgroundColor: theme.palette.common.white,
            width: "100%",
            borderRadius: "8px",
            height: "3rem",
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.common.white
            }
          }}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
};

export default GuideCard;
