import { Methods } from "api/api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates";
import * as yup from "yup";

export type FormSchemaType = Methods["post"]["reqBody"];

const createCandidateFormSchema: yup.ObjectSchema<FormSchemaType> = yup
  .object()
  .shape({
    proposed_date_ids: yup
      .array()
      .required("必須の項目です")
      .of(yup.number().required("必須の項目です")),
    lesson_contents: yup.string().required("必須の項目です"),
    appeal: yup.string().required("必須の項目です")
  });

export default createCandidateFormSchema;
