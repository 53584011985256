import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

import useTeacherRankBadge from "features/teachers/hooks/useTeacherRankBadge";
import useShortSentences from "hooks/useShortSentences";

import type React from "react";
import RatingBox from "./RatingBox";

// MEMO: 型指定をどこかでうまく統一したい
type TeacherListItemCardProps = {
  id: number;
  name: string;
  imageUrl: string;
  organizationName: string;
  enthusiasm: string;
  introduction: string;
  teacher_rank: string;
  total_rating: number;
  rating_count: number;
  tags: {
    id: number;
    name: string;
  }[];
};

const TeacherListItemCard: React.FC<TeacherListItemCardProps> = (props) => {
  const {
    id,
    name,
    imageUrl,
    organizationName,
    enthusiasm,
    introduction,
    teacher_rank,
    total_rating,
    rating_count,
    tags
  } = props;
  const teacherRankBadge = useTeacherRankBadge(teacher_rank);
  const history = useHistory();
  const theme = useTheme();

  const handleClick = () => {
    history.push(`/teachers/${id}`);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        margin: "1rem auto"
      }}
      key={`${name} card box`}
    >
      <Card elevation={0} onClick={handleClick} sx={{ cursor: "pointer" }}>
        <CardContent sx={{ position: "relative" }}>
          {total_rating && rating_count !== 0 && (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <RatingBox
                totalRating={total_rating / 10}
                ratingCount={rating_count}
              />
            </Box>
          )}
          <Grid container alignItems="center" spacing={1}>
            <Grid
              item
              sx={{
                display: "flex",
                marginRight: { xs: theme.spacing(1), md: theme.spacing(2) },
                marginBottom: { xs: theme.spacing(1), md: 0 }
              }}
            >
              <Box
                component="img"
                src={imageUrl}
                alt={`${name} avatar`}
                sx={{
                  display: "block",
                  width: { xs: "64px", md: "96px" },
                  height: { xs: "64px", md: "96px" },
                  objectFit: "cover"
                }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.sub.main} fontSize=".9rem">
                {organizationName}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="1.2rem"
                  sx={{ lineHeight: 1.5 }}
                >
                  {name}
                </Typography>
                {teacherRankBadge.src && (
                  <Box
                    component="img"
                    src={teacherRankBadge.src}
                    alt={teacherRankBadge.alt}
                    sx={{
                      display: "block",
                      margin: "0 .5rem",
                      // MEMO: 解像度を合わせるための調整
                      width: "85px"
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          {total_rating && rating_count !== 0 && (
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <RatingBox
                totalRating={total_rating / 10}
                ratingCount={rating_count}
              />
            </Box>
          )}
          <Grid container alignItems="center" spacing={1}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Grid
                item
                sx={{
                  width: { xs: "72px", md: "104px" },
                  marginRight: theme.spacing(2)
                }}
              />
            </Box>
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      whiteSpace: "pre-wrap",
                      width: "100%"
                    }}
                  >
                    {enthusiasm}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      whiteSpace: "pre-wrap",
                      width: "100%"
                    }}
                  >
                    {useShortSentences(introduction)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.name}
                      variant="outlined"
                      color="default"
                      sx={{
                        margin: theme.spacing(1),
                        height: { xs: "24px", md: "36px" },
                        borderRadius: { xs: "12px", md: "18px" },
                        backgroundColor: theme.palette.backgroundColor?.main,
                        borderColor: theme.palette.muted?.main,
                        color: theme.palette.sub.main
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeacherListItemCard;
