import { useAtom } from "jotai";
import {
  currentUserAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom,
  tokenTypeAtom,
  isLoggedInAtom
} from "store/AuthStore";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";
import { RESET } from "jotai/utils";

const useCurrentTeacher = () => {
  const [, setCurrentUser] = useAtom(currentUserAtom);
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [, setClient] = useAtom(clientAtom);
  const [, setUid] = useAtom(uidAtom);
  const [, setTokenType] = useAtom(tokenTypeAtom);
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);

  const { clearAlertMessages, addErrorMessage } = useAlertMessages();
  const headers = useHeaders();

  const { mutate } = useAspidaSWR(
    aspida(headers).api.v1.teachers.login_teacher,
    {
      onSuccess: (response) => {
        setCurrentUser(response);
      },
      onError: () => {
        clearAlertMessages();
        addErrorMessage(
          "セッションの有効期限が切れました。再ログインをお願いします。"
        );
        setAccessToken(RESET);
        setClient(RESET);
        setUid(RESET);
        setTokenType(RESET);
        setIsLoggedIn(RESET);
        setCurrentUser(RESET);
      },
      enabled: isLoggedIn && Object.keys(headers).length > 0
    }
  );
  return { mutate };
};

export default useCurrentTeacher;
