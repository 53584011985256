import { useAtomValue } from "jotai";
import { tagsAtom } from "store/recruitments/NewStore";

import type React from "react";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { isOnlineOptions, schoolMinutesOptions } from "constants/selectOptions";
import { useFormContext } from "react-hook-form";
import FormTextField from "components/molecules/FormTextField";
import FormChipGroup from "components/molecules/FormChipGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FieldsFrame from "./FieldsFrame";

const LessonContentsFields: React.FC = () => {
  const { watch } = useFormContext();
  const isOffline =
    watch("is_online") === "false" || watch("is_online") === false;
  const tags = useAtomValue(tagsAtom);

  const themeOptions = tags.map((tag) => ({
    value: tag.id.toString(),
    label: tag.name
  }));

  return (
    <FieldsFrame title="授業内容">
      <FormRadioGroup
        name="school_minutes"
        label="授業時間"
        options={schoolMinutesOptions}
        isRequired
      />
      <FormRadioGroup
        name="is_online"
        label="実施方法"
        options={isOnlineOptions}
        isRequired
      />
      {isOffline && (
        <FormTextField
          name="travel_cost"
          label="交通費の取り扱い"
          isRequired
          placeholder="例：交通費は実費精算となります"
        />
      )}
      <FormChipGroup
        label="授業テーマ"
        name="tag_id"
        options={themeOptions}
        isRequired
      />
      <FormTextArea
        name="lesson_contents"
        label="授業内容"
        placeholder="例：現在の会社での業務について、小学生でもわかるように話して欲しいです。"
        isRequired
      />
      <FormTextArea
        name="want_to"
        label="こんな先生に来てほしい"
        placeholder="例：子どもたちに幅広い視点を提供してくださる方だと助かります。"
        isRequired
      />
    </FieldsFrame>
  );
};

export default LessonContentsFields;
