import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";

const WithdrawalContents: React.FC = () => {
  const theme = useTheme();

  return (
    <div>
      <Typography>
        退会を希望の方は、
        <Box
          component="a"
          href="https://forms.gle/psWKL6JwaErxF2Y67"
          target="_blank"
          rel="noreferrer"
          sx={{
            color: theme.palette.link?.main,
            textDecoration: "none",
            fontWeight: "bold"
          }}
        >
          こちらのリンク
        </Box>
        からお願いいたします
      </Typography>
    </div>
  );
};

export default WithdrawalContents;
