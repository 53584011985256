import { InputAdornment, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "components/atoms/fields/TextField";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useFormContext, Controller } from "react-hook-form";

type FormPasswordFieldProps = {
  name: string;
  label: string;
};

const FormPasswordField: React.FC<FormPasswordFieldProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { name, label } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleIsPasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".25rem"
      }}
    >
      <Typography fontWeight="bold">{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={isPasswordVisible ? "text" : "password"}
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString()}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleIsPasswordVisible}
                    edge="end"
                  >
                    {isPasswordVisible ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormPasswordField;
