import { FormControl } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import useTheme from "@mui/material/styles/useTheme";

import Check from "components/atoms/fields/Check";

import { useFormContext, Controller } from "react-hook-form";

type FormCheckBoxProps = {
  name: string;
  label: string | React.ReactNode;
  displayError?: boolean;
};

const FormCheckBox: React.FC<FormCheckBoxProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { name, label, displayError = false } = props;
  const theme = useTheme();

  return (
    <FormControl error={!!errors[name]}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormGroup>
            <Check
              {...field}
              label={label}
              noDecoration
              checked={field.value === "true" || field.value === true}
            />
            {displayError && (
              <FormHelperText sx={{ color: theme.palette.error.main }}>
                {errors[name]?.message?.toString()}
              </FormHelperText>
            )}
          </FormGroup>
        )}
      />
    </FormControl>
  );
};

export default FormCheckBox;
