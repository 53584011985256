import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AppalImage01 from "assets/lpClassi/AppealImage01.png";
import AppalImage02 from "assets/lpClassi/AppealImage02.png";
import AppalImage03 from "assets/lpClassi/AppealImage03.png";
import MultipleIcon from "assets/lpClassi/MultipleIcon.png";
import AppealFactor from "features/root/components/molecules/lpClassi/AppealFactor";

import type React from "react";

const Appeal: React.FC = () => (
  <Box component="section" sx={{ margin: "3rem auto" }}>
    <Typography align="center" variant="h2">
      <Typography
        component="span"
        align="center"
        variant="h2"
        sx={{ display: "inline-block" }}
      >
        複業先生の出前授業なら
      </Typography>
      <br />
      <Typography
        component="span"
        align="center"
        variant="h2"
        sx={{ display: "inline-block" }}
      >
        既存のシラバスに
      </Typography>
      <Typography
        component="span"
        align="center"
        variant="h2"
        sx={{ display: "inline-block" }}
      >
        あわせて導入しやすい
      </Typography>
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        margin: "2rem auto",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "center", sm: "flex-start" }
      }}
    >
      <AppealFactor
        Icon={AppalImage01}
        title="授業テーマ"
        desc={[
          "キャリア教育・STEAM教育・",
          "探求学習・グローバル教育",
          "をはじめ、その他の",
          "学科指導も可能です"
        ]}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center"
        }}
      >
        <img alt="かける" src={MultipleIcon} />
      </Box>
      <AppealFactor
        Icon={AppalImage02}
        title="コマ数"
        desc={[
          "1コマの単発から、",
          "4/6/8コマなど",
          "自由に指定できます。",
          "年間授業も可能です。"
        ]}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center"
        }}
      >
        <img alt="かける" src={MultipleIcon} />
      </Box>
      <AppealFactor
        Icon={AppalImage03}
        title="講師の人選"
        desc={[
          "事務局の審査を通過した",
          "1200名以上の",
          "多彩な講師から選択可能です。"
        ]}
      />
    </Box>
    <Typography align="center">
      <Typography
        component="span"
        align="center"
        sx={{ display: "inline-block" }}
      >
        テーマ・コマ数・講師など
      </Typography>
      <Typography
        component="span"
        align="center"
        sx={{ display: "inline-block" }}
      >
        自由にカスタマイズ。
      </Typography>
      <br />
      <Typography
        component="span"
        align="center"
        sx={{ display: "inline-block" }}
      >
        ヒアリングの上、貴校にあわせた
      </Typography>
      <Typography
        component="span"
        align="center"
        sx={{ display: "inline-block" }}
      >
        カリキュラムをご提案できます。
      </Typography>
    </Typography>
  </Box>
);

export default Appeal;
