import React, { useState } from "react";
import { FormProvider } from "react-hook-form";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import AuthLayout from "components/layouts/AuthLayout";
import SetupEyeCatch from "features/schools/components/atoms/profile/new/SetupEyeCatch";
import Meta from "components/organisms/Meta";
import NewProfileForm from "features/schools/components/organisms/profile/new/NewProfileForm";
import BottomButtonGroup from "components/molecules/BottomButtonGroup";

import useNewPageApi from "features/schools/hooks/profile/useNewPageApi";
import NewProfileConfirm from "../../organisms/profile/new/NewProfileConfirm";

const New: React.FC = () => {
  const theme = useTheme();
  const { methods, handleSubmit } = useNewPageApi();
  const [activeStep, setActiveStep] = useState(0);

  return (
    <AuthLayout>
      <Meta title="複業先生｜本登録" />
      <SetupEyeCatch />
      <Box
        sx={{
          backgroundColor:
            activeStep === 1
              ? theme.palette.backgroundColor?.main
              : theme.palette.common.white,
          padding: "0 0 1rem"
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: activeStep === 0 ? "block" : "none"
              }}
            >
              <NewProfileForm />
            </Box>
            <Box
              sx={{
                display: activeStep === 1 ? "block" : "none"
              }}
            >
              <NewProfileConfirm />
            </Box>
            <Container maxWidth="sm">
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.common.white,
                  borderRadius: "4px"
                }}
              >
                <BottomButtonGroup
                  activeStep={activeStep}
                  handleNext={() => setActiveStep((prev) => prev + 1)}
                  handleBack={() => setActiveStep((prev) => prev - 1)}
                  lastStep={1}
                  confirmText="確認画面へ"
                  isNextClickable={methods.formState.isValid}
                />
              </Box>
            </Container>
          </form>
        </FormProvider>
      </Box>
    </AuthLayout>
  );
};

export default New;
