import type { AspidaClient, BasicHeaders } from "aspida";
import { dataToURLString } from "aspida";
import type { Methods as Methods_4cyq2g } from "./api/v1/batchs/send_encourage_completion_reports_mail";
import type { Methods as Methods_u2usi0 } from "./api/v1/batchs/send_reminder_lesson_accept_mail";
import type { Methods as Methods_11cuda2 } from "./api/v1/batchs/send_select_candidate_mail";
import type { Methods as Methods_ww7wor } from "./api/v1/lesson_recruitments";
import type { Methods as Methods_9i1ho5 } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string";
import type { Methods as Methods_1oaal9x } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/cancel_recruitment";
import type { Methods as Methods_18hi0uc } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates";
import type { Methods as Methods_w7vafx } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates/cancel_candidate";
import type { Methods as Methods_1um51kr } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/manual_close";
import type { Methods as Methods_2exo47 } from "./api/v1/lesson_recruitments/_lessonRecruitmentId@string/select_teacher";
import type { Methods as Methods_289od6 } from "./api/v1/lesson_recruitments/new";
import type { Methods as Methods_19j59pu } from "./api/v1/lesson_requests";
import type { Methods as Methods_m6twiq } from "./api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import type { Methods as Methods_2xam2h } from "./api/v1/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_6mk5rq } from "./api/v1/lesson_requests/_lessonRequestId@string/agenda_sheets/_agendaSheetId@string";
import type { Methods as Methods_za4jka } from "./api/v1/lesson_requests/_lessonRequestId@string/agenda_sheets/new";
import type { Methods as Methods_su63zp } from "./api/v1/lesson_requests/_lessonRequestId@string/generate_teaching_plan";
import type { Methods as Methods_sp08e0 } from "./api/v1/lesson_requests/_lessonRequestId@string/lesson_complete";
import type { Methods as Methods_h80tyf } from "./api/v1/lesson_requests/_lessonRequestId@string/lesson_request_comments/_lessonRequestCommentId@string/logical_delete";
import type { Methods as Methods_1hvi4eg } from "./api/v1/lesson_requests/_lessonRequestId@string/ratings";
import type { Methods as Methods_d4307i } from "./api/v1/lesson_requests/_lessonRequestId@string/timelines";
import type { Methods as Methods_bv4z2n } from "./api/v1/lesson_requests/_lessonRequestId@string/timelines/_timelineId@string";
import type { Methods as Methods_1n47df6 } from "./api/v1/lesson_requests/_lessonRequestId@string/timelines/create_default_data";
import type { Methods as Methods_teno4w } from "./api/v1/lesson_requests/_lessonRequestId@string/timelines/multi_update";
import type { Methods as Methods_fqsaro } from "./api/v1/lesson_requests/_lessonRequestId@string/update_exact_start_at";
import type { Methods as Methods_13kl9c7 } from "./api/v1/lesson_requests/new";
import type { Methods as Methods_wegie9 } from "./api/v1/master_financial_institutions";
import type { Methods as Methods_t0d2xj } from "./api/v1/master_financial_institutions/_financialInstitutionCode@string/branches";
import type { Methods as Methods_kpn2az } from "./api/v1/master_schools";
import type { Methods as Methods_128uobd } from "./api/v1/school_teacher_auth";
import type { Methods as Methods_1tc3x4f } from "./api/v1/school_teacher_auth/password";
import type { Methods as Methods_1gmp8zz } from "./api/v1/school_teacher_auth/sign_in";
import type { Methods as Methods_1pe3510 } from "./api/v1/school_teacher_auth/sign_out";
import type { Methods as Methods_quxard } from "./api/v1/school_teacher_profiles";
import type { Methods as Methods_1eklzky } from "./api/v1/school_teacher_profiles/_schoolTeacherProfileId@string";
import type { Methods as Methods_1k7yp8b } from "./api/v1/school_teacher_profiles/_schoolTeacherProfileId@string/edit";
import type { Methods as Methods_mqmywc } from "./api/v1/school_teacher_profiles/new";
import type { Methods as Methods_15zq1ys } from "./api/v1/school_teachers/_schoolTeacherId@number/school_teacher_announcements/_schoolTeacherAnnouncementId@number/announcement_check";
import type { Methods as Methods_yra74p } from "./api/v1/school_teachers/_schoolTeacherId@string";
import type { Methods as Methods_16de948 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments";
import type { Methods as Methods_qqf0c4 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments/_lessonRecrutimentId@string";
import type { Methods as Methods_1n7b18l } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_requests";
import type { Methods as Methods_1fmdwj6 } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_6ej6pz } from "./api/v1/school_teachers/_schoolTeacherId@string/lesson_requests/no_rating";
import type { Methods as Methods_ywmfwk } from "./api/v1/school_teachers/_schoolTeacherId@string/school_teacher_announcements";
import type { Methods as Methods_68vypb } from "./api/v1/school_teachers/login_school_teacher";
import type { Methods as Methods_1nfgpxm } from "./api/v1/teacher_auth";
import type { Methods as Methods_yapgnq } from "./api/v1/teacher_auth/password";
import type { Methods as Methods_7h4cis } from "./api/v1/teacher_auth/sign_in";
import type { Methods as Methods_lzrqp } from "./api/v1/teacher_auth/sign_out";
import type { Methods as Methods_3ia6mm } from "./api/v1/teacher_profiles";
import type { Methods as Methods_e1ycuv } from "./api/v1/teacher_profiles/_teacherProfileId@string";
import type { Methods as Methods_1b882u4 } from "./api/v1/teacher_profiles/_teacherProfileId@string/edit";
import type { Methods as Methods_1b87kkx } from "./api/v1/teacher_profiles/_teacherProfileId@string/judge_request";
import type { Methods as Methods_1ovggn7 } from "./api/v1/teacher_profiles/new";
import type { Methods as Methods_jvfwzs } from "./api/v1/teachers";
import type { Methods as Methods_1ico1hg } from "./api/v1/teachers/_teacherId@number/teacher_announcements";
import type { Methods as Methods_1336rzc } from "./api/v1/teachers/_teacherId@number/teacher_announcements/_teacherAnnouncementId@number/announcement_check";
import type { Methods as Methods_2uegaq } from "./api/v1/teachers/_teacherId@string";
import type { Methods as Methods_gtxoyu } from "./api/v1/teachers/_teacherId@string/e_learnings";
import type { Methods as Methods_1hk2anv } from "./api/v1/teachers/_teacherId@string/e_learnings/new";
import type { Methods as Methods_7lhx1f } from "./api/v1/teachers/_teacherId@string/lesson_recruitments";
import type { Methods as Methods_1gehdre } from "./api/v1/teachers/_teacherId@string/lesson_requests";
import type { Methods as Methods_123jsc1 } from "./api/v1/teachers/_teacherId@string/lesson_requests/_lessonRequestId@string";
import type { Methods as Methods_3u3trf } from "./api/v1/teachers/_teacherId@string/teacher_bank_accounts";
import type { Methods as Methods_1syykh8 } from "./api/v1/teachers/_teacherId@string/teacher_bank_accounts/_teacherBankAccountId@string";
import type { Methods as Methods_e0322r } from "./api/v1/teachers/_teacherId@string/teacher_identifications";
import type { Methods as Methods_1l9446c } from "./api/v1/teachers/_teacherId@string/teacher_videos";
import type { Methods as Methods_108k0v3 } from "./api/v1/teachers/_teacherId@string/teacher_videos/_teacherVideoId@string";
import type { Methods as Methods_lpu3cj } from "./api/v1/teachers/login_teacher";
import type { Methods as Methods_1t202l5 } from "./api/v1/teachers/lp_classi";
import type { Methods as Methods_16ra1rl } from "./api/v1/teaching_plan_ideas";
import type { Methods as Methods_1on40ck } from "./api/v1/teaching_plan_ideas/new";
import type { Methods as Methods_5vgulz } from "./api/v1/webhooks/trustdock";

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (
    baseURL === undefined ? "http://localhost:3000" : baseURL
  ).replace(/\/$/, "");
  const PATH0 = "/api/v1/batchs/send_encourage_completion_reports_mail";
  const PATH1 = "/api/v1/batchs/send_reminder_lesson_accept_mail";
  const PATH2 = "/api/v1/batchs/send_select_candidate_mail";
  const PATH3 = "/api/v1/lesson_recruitments";
  const PATH4 = "/cancel_recruitment";
  const PATH5 = "/candidates";
  const PATH6 = "/candidates/cancel_candidate";
  const PATH7 = "/manual_close";
  const PATH8 = "/select_teacher";
  const PATH9 = "/api/v1/lesson_recruitments/new";
  const PATH10 = "/api/v1/lesson_requests";
  const PATH11 = "/lesson_request_comments";
  const PATH12 = "/agenda_sheets";
  const PATH13 = "/agenda_sheets/new";
  const PATH14 = "/generate_teaching_plan";
  const PATH15 = "/lesson_complete";
  const PATH16 = "/logical_delete";
  const PATH17 = "/ratings";
  const PATH18 = "/timelines";
  const PATH19 = "/timelines/create_default_data";
  const PATH20 = "/timelines/multi_update";
  const PATH21 = "/update_exact_start_at";
  const PATH22 = "/api/v1/lesson_requests/new";
  const PATH23 = "/api/v1/master_financial_institutions";
  const PATH24 = "/branches";
  const PATH25 = "/api/v1/master_schools";
  const PATH26 = "/api/v1/school_teacher_auth";
  const PATH27 = "/api/v1/school_teacher_auth/password";
  const PATH28 = "/api/v1/school_teacher_auth/sign_in";
  const PATH29 = "/api/v1/school_teacher_auth/sign_out";
  const PATH30 = "/api/v1/school_teacher_profiles";
  const PATH31 = "/edit";
  const PATH32 = "/api/v1/school_teacher_profiles/new";
  const PATH33 = "/api/v1/school_teachers";
  const PATH34 = "/school_teacher_announcements";
  const PATH35 = "/announcement_check";
  const PATH36 = "/lesson_recruitments";
  const PATH37 = "/lesson_requests";
  const PATH38 = "/lesson_requests/no_rating";
  const PATH39 = "/api/v1/school_teachers/login_school_teacher";
  const PATH40 = "/api/v1/teacher_auth";
  const PATH41 = "/api/v1/teacher_auth/password";
  const PATH42 = "/api/v1/teacher_auth/sign_in";
  const PATH43 = "/api/v1/teacher_auth/sign_out";
  const PATH44 = "/api/v1/teacher_profiles";
  const PATH45 = "/judge_request";
  const PATH46 = "/api/v1/teacher_profiles/new";
  const PATH47 = "/api/v1/teachers";
  const PATH48 = "/teacher_announcements";
  const PATH49 = "/e_learnings";
  const PATH50 = "/e_learnings/new";
  const PATH51 = "/teacher_bank_accounts";
  const PATH52 = "/teacher_identifications";
  const PATH53 = "/teacher_videos";
  const PATH54 = "/api/v1/teachers/login_teacher";
  const PATH55 = "/api/v1/teachers/lp_classi";
  const PATH56 = "/api/v1/teaching_plan_ideas";
  const PATH57 = "/api/v1/teaching_plan_ideas/new";
  const PATH58 = "/api/v1/webhooks/trustdock";
  const GET = "GET";
  const POST = "POST";
  const PUT = "PUT";
  const DELETE = "DELETE";
  const PATCH = "PATCH";

  return {
    api: {
      v1: {
        batchs: {
          send_encourage_completion_reports_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_4cyq2g["get"]["resBody"],
                BasicHeaders,
                Methods_4cyq2g["get"]["status"]
              >(prefix, PATH0, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_4cyq2g["get"]["resBody"],
                BasicHeaders,
                Methods_4cyq2g["get"]["status"]
              >(prefix, PATH0, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH0}`
          },
          send_reminder_lesson_accept_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_u2usi0["get"]["resBody"],
                BasicHeaders,
                Methods_u2usi0["get"]["status"]
              >(prefix, PATH1, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_u2usi0["get"]["resBody"],
                BasicHeaders,
                Methods_u2usi0["get"]["status"]
              >(prefix, PATH1, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH1}`
          },
          send_select_candidate_mail: {
            /**
             * @returns 定時実行バッチ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_11cuda2["get"]["resBody"],
                BasicHeaders,
                Methods_11cuda2["get"]["status"]
              >(prefix, PATH2, GET, option).json(),
            /**
             * @returns 定時実行バッチ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_11cuda2["get"]["resBody"],
                BasicHeaders,
                Methods_11cuda2["get"]["status"]
              >(prefix, PATH2, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH2}`
          }
        },
        lesson_recruitments: {
          _lessonRecruitmentId: (val3: string) => {
            const prefix3 = `${PATH3}/${val3}`;

            return {
              cancel_recruitment: {
                /**
                 * @returns 授業募集のキャンセル
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1oaal9x["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1oaal9x["patch"]["status"]
                  >(prefix, `${prefix3}${PATH4}`, PATCH, option).json(),
                /**
                 * @returns 授業募集のキャンセル
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1oaal9x["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1oaal9x["patch"]["status"]
                  >(prefix, `${prefix3}${PATH4}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH4}`
              },
              candidates: {
                cancel_candidate: {
                  /**
                   * @returns 授業応募のキャンセル
                   */
                  patch: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_w7vafx["patch"]["resBody"],
                      BasicHeaders,
                      Methods_w7vafx["patch"]["status"]
                    >(prefix, `${prefix3}${PATH6}`, PATCH, option).json(),
                  /**
                   * @returns 授業応募のキャンセル
                   */
                  $patch: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_w7vafx["patch"]["resBody"],
                      BasicHeaders,
                      Methods_w7vafx["patch"]["status"]
                    >(prefix, `${prefix3}${PATH6}`, PATCH, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH6}`
                },
                /**
                 * @returns 授業募集への応募
                 */
                post: (option: {
                  body: Methods_18hi0uc["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_18hi0uc["post"]["resBody"],
                    BasicHeaders,
                    Methods_18hi0uc["post"]["status"]
                  >(prefix, `${prefix3}${PATH5}`, POST, option).json(),
                /**
                 * @returns 授業募集への応募
                 */
                $post: (option: {
                  body: Methods_18hi0uc["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_18hi0uc["post"]["resBody"],
                    BasicHeaders,
                    Methods_18hi0uc["post"]["status"]
                  >(prefix, `${prefix3}${PATH5}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH5}`
              },
              manual_close: {
                /**
                 * @returns 授業募集早期終了
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1um51kr["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1um51kr["patch"]["status"]
                  >(prefix, `${prefix3}${PATH7}`, PATCH, option).json(),
                /**
                 * @returns 授業募集早期終了
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1um51kr["patch"]["resBody"],
                    BasicHeaders,
                    Methods_1um51kr["patch"]["status"]
                  >(prefix, `${prefix3}${PATH7}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH7}`
              },
              select_teacher: {
                /**
                 * @returns 授業募集複業先生選択
                 */
                patch: (option: {
                  body: Methods_2exo47["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_2exo47["patch"]["resBody"],
                    BasicHeaders,
                    Methods_2exo47["patch"]["status"]
                  >(prefix, `${prefix3}${PATH8}`, PATCH, option).json(),
                /**
                 * @returns 授業募集複業先生選択
                 */
                $patch: (option: {
                  body: Methods_2exo47["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_2exo47["patch"]["resBody"],
                    BasicHeaders,
                    Methods_2exo47["patch"]["status"]
                  >(prefix, `${prefix3}${PATH8}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH8}`
              },
              /**
               * @returns 募集詳細取得
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_9i1ho5["get"]["resBody"],
                  BasicHeaders,
                  Methods_9i1ho5["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 募集詳細取得
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_9i1ho5["get"]["resBody"],
                  BasicHeaders,
                  Methods_9i1ho5["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 募集作成時のデータ取得
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_289od6["get"]["resBody"],
                BasicHeaders,
                Methods_289od6["get"]["status"]
              >(prefix, PATH9, GET, option).json(),
            /**
             * @returns 募集作成時のデータ取得
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_289od6["get"]["resBody"],
                BasicHeaders,
                Methods_289od6["get"]["status"]
              >(prefix, PATH9, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH9}`
          },
          /**
           * @returns 授業募集作成
           */
          post: (option: {
            body: Methods_ww7wor["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_ww7wor["post"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["post"]["status"]
            >(prefix, PATH3, POST, option).json(),
          /**
           * @returns 授業募集作成
           */
          $post: (option: {
            body: Methods_ww7wor["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_ww7wor["post"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["post"]["status"]
            >(prefix, PATH3, POST, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 授業募集一覧取得
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_ww7wor["get"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["get"]["status"]
            >(prefix, PATH3, GET, option).json(),
          /**
           * @returns 授業募集一覧取得
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_ww7wor["get"]["resBody"],
              BasicHeaders,
              Methods_ww7wor["get"]["status"]
            >(prefix, PATH3, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH3}`
        },
        lesson_requests: {
          _lessonRequestId: (val3: number | string) => {
            const prefix3 = `${PATH10}/${val3}`;

            return {
              lesson_request_comments: {
                /**
                 * @returns 授業のチャット取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_m6twiq["get"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["get"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, GET, option).json(),
                /**
                 * @returns 授業のチャット取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_m6twiq["get"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["get"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 授業チャット送信
                 */
                post: (option: {
                  body: Methods_m6twiq["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_m6twiq["post"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["post"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, POST, option).json(),
                /**
                 * @returns 授業チャット送信
                 */
                $post: (option: {
                  body: Methods_m6twiq["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_m6twiq["post"]["resBody"],
                    BasicHeaders,
                    Methods_m6twiq["post"]["status"]
                  >(prefix, `${prefix3}${PATH11}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH11}`
              }
            };
          },
          _lessonRequestId_string: (val3: string) => {
            const prefix3 = `${PATH10}/${val3}`;

            return {
              agenda_sheets: {
                _agendaSheetId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH12}/${val5}`;

                  return {
                    /**
                     * @returns 授業準備シート更新
                     */
                    put: (option: {
                      body: Methods_6mk5rq["put"]["reqBody"];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_6mk5rq["put"]["resBody"],
                        BasicHeaders,
                        Methods_6mk5rq["put"]["status"]
                      >(prefix, prefix5, PUT, option).json(),
                    /**
                     * @returns 授業準備シート更新
                     */
                    $put: (option: {
                      body: Methods_6mk5rq["put"]["reqBody"];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_6mk5rq["put"]["resBody"],
                        BasicHeaders,
                        Methods_6mk5rq["put"]["status"]
                      >(prefix, prefix5, PUT, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                new: {
                  /**
                   * @returns 授業準備シートの取得
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_za4jka["get"]["resBody"],
                      BasicHeaders,
                      Methods_za4jka["get"]["status"]
                    >(prefix, `${prefix3}${PATH13}`, GET, option).json(),
                  /**
                   * @returns 授業準備シートの取得
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_za4jka["get"]["resBody"],
                      BasicHeaders,
                      Methods_za4jka["get"]["status"]
                    >(prefix, `${prefix3}${PATH13}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH13}`
                }
              },
              generate_teaching_plan: {
                /**
                 * @returns 授業依頼の授業案作成
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_su63zp["post"]["resBody"],
                    BasicHeaders,
                    Methods_su63zp["post"]["status"]
                  >(prefix, `${prefix3}${PATH14}`, POST, option).json(),
                /**
                 * @returns 授業依頼の授業案作成
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_su63zp["post"]["resBody"],
                    BasicHeaders,
                    Methods_su63zp["post"]["status"]
                  >(prefix, `${prefix3}${PATH14}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH14}`
              },
              lesson_complete: {
                /**
                 * @returns 授業完了
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_sp08e0["post"]["resBody"],
                    BasicHeaders,
                    Methods_sp08e0["post"]["status"]
                  >(prefix, `${prefix3}${PATH15}`, POST, option).json(),
                /**
                 * @returns 授業完了
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_sp08e0["post"]["resBody"],
                    BasicHeaders,
                    Methods_sp08e0["post"]["status"]
                  >(prefix, `${prefix3}${PATH15}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH15}`
              },
              lesson_request_comments: {
                _lessonRequestCommentId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH11}/${val5}`;

                  return {
                    logical_delete: {
                      /**
                       * @returns 授業チャットの論理削除
                       */
                      patch: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_h80tyf["patch"]["resBody"],
                          BasicHeaders,
                          Methods_h80tyf["patch"]["status"]
                        >(prefix, `${prefix5}${PATH16}`, PATCH, option).json(),
                      /**
                       * @returns 授業チャットの論理削除
                       */
                      $patch: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_h80tyf["patch"]["resBody"],
                          BasicHeaders,
                          Methods_h80tyf["patch"]["status"]
                        >(prefix, `${prefix5}${PATH16}`, PATCH, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH16}`
                    }
                  };
                }
              },
              ratings: {
                /**
                 * @returns 授業評価データ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1hvi4eg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1hvi4eg["get"]["status"]
                  >(prefix, `${prefix3}${PATH17}`, GET, option).json(),
                /**
                 * @returns 授業評価データ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1hvi4eg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1hvi4eg["get"]["status"]
                  >(prefix, `${prefix3}${PATH17}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 評価登録
                 */
                post: (option: {
                  body: Methods_1hvi4eg["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1hvi4eg["post"]["resBody"],
                    BasicHeaders,
                    Methods_1hvi4eg["post"]["status"]
                  >(prefix, `${prefix3}${PATH17}`, POST, option).json(),
                /**
                 * @returns 評価登録
                 */
                $post: (option: {
                  body: Methods_1hvi4eg["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1hvi4eg["post"]["resBody"],
                    BasicHeaders,
                    Methods_1hvi4eg["post"]["status"]
                  >(prefix, `${prefix3}${PATH17}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH17}`
              },
              timelines: {
                _timelineId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH18}/${val5}`;

                  return {
                    /**
                     * @returns タイムラインの削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_bv4z2n["delete"]["resBody"],
                        BasicHeaders,
                        Methods_bv4z2n["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns タイムラインの削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_bv4z2n["delete"]["resBody"],
                        BasicHeaders,
                        Methods_bv4z2n["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                create_default_data: {
                  /**
                   * @returns タイムラインのデフォルトデータ作成
                   */
                  post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1n47df6["post"]["resBody"],
                      BasicHeaders,
                      Methods_1n47df6["post"]["status"]
                    >(prefix, `${prefix3}${PATH19}`, POST, option).json(),
                  /**
                   * @returns タイムラインのデフォルトデータ作成
                   */
                  $post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1n47df6["post"]["resBody"],
                      BasicHeaders,
                      Methods_1n47df6["post"]["status"]
                    >(prefix, `${prefix3}${PATH19}`, POST, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH19}`
                },
                multi_update: {
                  /**
                   * @returns タイムラインの一括更新
                   */
                  patch: (option: {
                    body: Methods_teno4w["patch"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_teno4w["patch"]["resBody"],
                      BasicHeaders,
                      Methods_teno4w["patch"]["status"]
                    >(prefix, `${prefix3}${PATH20}`, PATCH, option).json(),
                  /**
                   * @returns タイムラインの一括更新
                   */
                  $patch: (option: {
                    body: Methods_teno4w["patch"]["reqBody"];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_teno4w["patch"]["resBody"],
                      BasicHeaders,
                      Methods_teno4w["patch"]["status"]
                    >(prefix, `${prefix3}${PATH20}`, PATCH, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH20}`
                },
                /**
                 * @returns タイムラインの作成
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_d4307i["post"]["resBody"],
                    BasicHeaders,
                    Methods_d4307i["post"]["status"]
                  >(prefix, `${prefix3}${PATH18}`, POST, option).json(),
                /**
                 * @returns タイムラインの作成
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_d4307i["post"]["resBody"],
                    BasicHeaders,
                    Methods_d4307i["post"]["status"]
                  >(prefix, `${prefix3}${PATH18}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH18}`
              },
              update_exact_start_at: {
                /**
                 * @returns 授業実施日時変更
                 */
                patch: (option: {
                  body: Methods_fqsaro["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_fqsaro["patch"]["resBody"],
                    BasicHeaders,
                    Methods_fqsaro["patch"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, PATCH, option).json(),
                /**
                 * @returns 授業実施日時変更
                 */
                $patch: (option: {
                  body: Methods_fqsaro["patch"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_fqsaro["patch"]["resBody"],
                    BasicHeaders,
                    Methods_fqsaro["patch"]["status"]
                  >(prefix, `${prefix3}${PATH21}`, PATCH, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH21}`
              },
              /**
               * @returns 授業依頼の更新
               */
              put: (option: {
                body: Methods_2xam2h["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_2xam2h["put"]["resBody"],
                  BasicHeaders,
                  Methods_2xam2h["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 授業依頼の更新
               */
              $put: (option: {
                body: Methods_2xam2h["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_2xam2h["put"]["resBody"],
                  BasicHeaders,
                  Methods_2xam2h["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 授業依頼時のデータ取得
             */
            get: (
              option?:
                | {
                    query?: Methods_13kl9c7["get"]["query"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<
                Methods_13kl9c7["get"]["resBody"],
                BasicHeaders,
                Methods_13kl9c7["get"]["status"]
              >(prefix, PATH22, GET, option).json(),
            /**
             * @returns 授業依頼時のデータ取得
             */
            $get: (
              option?:
                | {
                    query?: Methods_13kl9c7["get"]["query"] | undefined;
                    config?: T | undefined;
                  }
                | undefined
            ) =>
              fetch<
                Methods_13kl9c7["get"]["resBody"],
                BasicHeaders,
                Methods_13kl9c7["get"]["status"]
              >(prefix, PATH22, GET, option)
                .json()
                .then((r) => r.body),
            $path: (
              option?:
                | {
                    method?: "get" | undefined;
                    query: Methods_13kl9c7["get"]["query"];
                  }
                | undefined
            ) =>
              `${prefix}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
          },
          /**
           * @returns 授業依頼作成
           */
          post: (option: {
            body: Methods_19j59pu["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_19j59pu["post"]["resBody"],
              BasicHeaders,
              Methods_19j59pu["post"]["status"]
            >(prefix, PATH10, POST, option).json(),
          /**
           * @returns 授業依頼作成
           */
          $post: (option: {
            body: Methods_19j59pu["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_19j59pu["post"]["resBody"],
              BasicHeaders,
              Methods_19j59pu["post"]["status"]
            >(prefix, PATH10, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        master_financial_institutions: {
          _financialInstitutionCode: (val3: string) => {
            const prefix3 = `${PATH23}/${val3}`;

            return {
              branches: {
                /**
                 * @returns 支店一覧取得
                 */
                get: (option: {
                  query: Methods_t0d2xj["get"]["query"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_t0d2xj["get"]["resBody"],
                    BasicHeaders,
                    Methods_t0d2xj["get"]["status"]
                  >(prefix, `${prefix3}${PATH24}`, GET, option).json(),
                /**
                 * @returns 支店一覧取得
                 */
                $get: (option: {
                  query: Methods_t0d2xj["get"]["query"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_t0d2xj["get"]["resBody"],
                    BasicHeaders,
                    Methods_t0d2xj["get"]["status"]
                  >(prefix, `${prefix3}${PATH24}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_t0d2xj["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              }
            };
          },
          /**
           * @returns 金融機関一覧取得
           */
          get: (option: {
            query: Methods_wegie9["get"]["query"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_wegie9["get"]["resBody"],
              BasicHeaders,
              Methods_wegie9["get"]["status"]
            >(prefix, PATH23, GET, option).json(),
          /**
           * @returns 金融機関一覧取得
           */
          $get: (option: {
            query: Methods_wegie9["get"]["query"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_wegie9["get"]["resBody"],
              BasicHeaders,
              Methods_wegie9["get"]["status"]
            >(prefix, PATH23, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_wegie9["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        master_schools: {
          /**
           * @returns 学校マスター取得
           */
          get: (
            option?:
              | {
                  query?: Methods_kpn2az["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_kpn2az["get"]["resBody"],
              BasicHeaders,
              Methods_kpn2az["get"]["status"]
            >(prefix, PATH25, GET, option).json(),
          /**
           * @returns 学校マスター取得
           */
          $get: (
            option?:
              | {
                  query?: Methods_kpn2az["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_kpn2az["get"]["resBody"],
              BasicHeaders,
              Methods_kpn2az["get"]["status"]
            >(prefix, PATH25, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_kpn2az["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        school_teacher_auth: {
          password: {
            /**
             * @returns 学校教員パスワード変更
             */
            put: (option: {
              body: Methods_1tc3x4f["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["put"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["put"]["status"]
              >(prefix, PATH27, PUT, option).json(),
            /**
             * @returns 学校教員パスワード変更
             */
            $put: (option: {
              body: Methods_1tc3x4f["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["put"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["put"]["status"]
              >(prefix, PATH27, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns 学校教員パスワード変更リクエスト
             */
            post: (option: {
              body: Methods_1tc3x4f["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["post"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["post"]["status"]
              >(prefix, PATH27, POST, option).json(),
            /**
             * @returns 学校教員パスワード変更リクエスト
             */
            $post: (option: {
              body: Methods_1tc3x4f["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1tc3x4f["post"]["resBody"],
                BasicHeaders,
                Methods_1tc3x4f["post"]["status"]
              >(prefix, PATH27, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH27}`
          },
          sign_in: {
            /**
             * @returns 学校教員ログイン
             */
            post: (option: {
              body: Methods_1gmp8zz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gmp8zz["post"]["resBody"],
                Methods_1gmp8zz["post"]["resHeaders"],
                Methods_1gmp8zz["post"]["status"]
              >(prefix, PATH28, POST, option).json(),
            /**
             * @returns 学校教員ログイン
             */
            $post: (option: {
              body: Methods_1gmp8zz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gmp8zz["post"]["resBody"],
                Methods_1gmp8zz["post"]["resHeaders"],
                Methods_1gmp8zz["post"]["status"]
              >(prefix, PATH28, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH28}`
          },
          sign_out: {
            /**
             * @returns 学校教員ログアウト
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1pe3510["delete"]["resBody"],
                BasicHeaders,
                Methods_1pe3510["delete"]["status"]
              >(prefix, PATH29, DELETE, option).json(),
            /**
             * @returns 学校教員ログアウト
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1pe3510["delete"]["resBody"],
                BasicHeaders,
                Methods_1pe3510["delete"]["status"]
              >(prefix, PATH29, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH29}`
          },
          /**
           * @returns 学校教員認証系レスポンス
           */
          patch: (option: {
            body: Methods_128uobd["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["patch"]["resBody"],
              BasicHeaders,
              Methods_128uobd["patch"]["status"]
            >(prefix, PATH26, PATCH, option).json(),
          /**
           * @returns 学校教員認証系レスポンス
           */
          $patch: (option: {
            body: Methods_128uobd["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["patch"]["resBody"],
              BasicHeaders,
              Methods_128uobd["patch"]["status"]
            >(prefix, PATH26, PATCH, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 学校教員認証系レスポンス
           */
          post: (option: {
            body: Methods_128uobd["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["post"]["resBody"],
              BasicHeaders,
              Methods_128uobd["post"]["status"]
            >(prefix, PATH26, POST, option).json(),
          /**
           * @returns 学校教員認証系レスポンス
           */
          $post: (option: {
            body: Methods_128uobd["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_128uobd["post"]["resBody"],
              BasicHeaders,
              Methods_128uobd["post"]["status"]
            >(prefix, PATH26, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH26}`
        },
        school_teacher_profiles: {
          _schoolTeacherProfileId: (val3: string) => {
            const prefix3 = `${PATH30}/${val3}`;

            return {
              edit: {
                /**
                 * @returns 学校教員更新データ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1k7yp8b["get"]["resBody"],
                    BasicHeaders,
                    Methods_1k7yp8b["get"]["status"]
                  >(prefix, `${prefix3}${PATH31}`, GET, option).json(),
                /**
                 * @returns 学校教員更新データ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1k7yp8b["get"]["resBody"],
                    BasicHeaders,
                    Methods_1k7yp8b["get"]["status"]
                  >(prefix, `${prefix3}${PATH31}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH31}`
              },
              /**
               * @returns 学校教員プロフィール更新
               */
              put: (option: {
                body: Methods_1eklzky["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1eklzky["put"]["resBody"],
                  BasicHeaders,
                  Methods_1eklzky["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 学校教員プロフィール更新
               */
              $put: (option: {
                body: Methods_1eklzky["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1eklzky["put"]["resBody"],
                  BasicHeaders,
                  Methods_1eklzky["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 学校教員プロフィール登録
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_mqmywc["get"]["resBody"],
                BasicHeaders,
                Methods_mqmywc["get"]["status"]
              >(prefix, PATH32, GET, option).json(),
            /**
             * @returns 学校教員プロフィール登録
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_mqmywc["get"]["resBody"],
                BasicHeaders,
                Methods_mqmywc["get"]["status"]
              >(prefix, PATH32, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH32}`
          },
          /**
           * @returns 学校教員プロフィール登録
           */
          post: (option: {
            body: Methods_quxard["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_quxard["post"]["resBody"],
              BasicHeaders,
              Methods_quxard["post"]["status"]
            >(prefix, PATH30, POST, option).json(),
          /**
           * @returns 学校教員プロフィール登録
           */
          $post: (option: {
            body: Methods_quxard["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_quxard["post"]["resBody"],
              BasicHeaders,
              Methods_quxard["post"]["status"]
            >(prefix, PATH30, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH30}`
        },
        school_teachers: {
          _schoolTeacherId_number: (val3: number) => {
            const prefix3 = `${PATH33}/${val3}`;

            return {
              school_teacher_announcements: {
                _schoolTeacherAnnouncementId: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH34}/${val5}`;

                  return {
                    announcement_check: {
                      /**
                       * @returns OK
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<
                          Methods_15zq1ys["post"]["resBody"],
                          BasicHeaders,
                          Methods_15zq1ys["post"]["status"]
                        >(prefix, `${prefix5}${PATH35}`, POST, option).json(),
                      /**
                       * @returns OK
                       */
                      $post: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_15zq1ys["post"]["resBody"],
                          BasicHeaders,
                          Methods_15zq1ys["post"]["status"]
                        >(prefix, `${prefix5}${PATH35}`, POST, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH35}`
                    }
                  };
                }
              }
            };
          },
          _schoolTeacherId_string: (val3: string) => {
            const prefix3 = `${PATH33}/${val3}`;

            return {
              lesson_recruitments: {
                _lessonRecrutimentId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH36}/${val5}`;

                  return {
                    /**
                     * @returns 学校に紐づく授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_qqf0c4["get"]["resBody"],
                        BasicHeaders,
                        Methods_qqf0c4["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 学校に紐づく授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_qqf0c4["get"]["resBody"],
                        BasicHeaders,
                        Methods_qqf0c4["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 学校教員作成授業募集一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_16de948["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_16de948["get"]["resBody"],
                    BasicHeaders,
                    Methods_16de948["get"]["status"]
                  >(prefix, `${prefix3}${PATH36}`, GET, option).json(),
                /**
                 * @returns 学校教員作成授業募集一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_16de948["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_16de948["get"]["resBody"],
                    BasicHeaders,
                    Methods_16de948["get"]["status"]
                  >(prefix, `${prefix3}${PATH36}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_16de948["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lesson_requests: {
                _lessonRequestId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH37}/${val5}`;

                  return {
                    /**
                     * @returns 学校教員向け授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1fmdwj6["get"]["resBody"],
                        BasicHeaders,
                        Methods_1fmdwj6["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 学校教員向け授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1fmdwj6["get"]["resBody"],
                        BasicHeaders,
                        Methods_1fmdwj6["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                no_rating: {
                  /**
                   * @returns 未評価の授業一覧
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_6ej6pz["get"]["resBody"],
                      BasicHeaders,
                      Methods_6ej6pz["get"]["status"]
                    >(prefix, `${prefix3}${PATH38}`, GET, option).json(),
                  /**
                   * @returns 未評価の授業一覧
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_6ej6pz["get"]["resBody"],
                      BasicHeaders,
                      Methods_6ej6pz["get"]["status"]
                    >(prefix, `${prefix3}${PATH38}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH38}`
                },
                /**
                 * @returns 学校教員向け授業依頼一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_1n7b18l["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1n7b18l["get"]["resBody"],
                    BasicHeaders,
                    Methods_1n7b18l["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option).json(),
                /**
                 * @returns 学校教員向け授業依頼一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_1n7b18l["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1n7b18l["get"]["resBody"],
                    BasicHeaders,
                    Methods_1n7b18l["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_1n7b18l["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              school_teacher_announcements: {
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_ywmfwk["get"]["resBody"],
                    BasicHeaders,
                    Methods_ywmfwk["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option).json(),
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_ywmfwk["get"]["resBody"],
                    BasicHeaders,
                    Methods_ywmfwk["get"]["status"]
                  >(prefix, `${prefix3}${PATH34}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH34}`
              },
              /**
               * @returns 学校教員情報取得
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_yra74p["get"]["resBody"],
                  BasicHeaders,
                  Methods_yra74p["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 学校教員情報取得
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_yra74p["get"]["resBody"],
                  BasicHeaders,
                  Methods_yra74p["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          login_school_teacher: {
            /**
             * @returns 学校教員ログイン情報
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_68vypb["get"]["resBody"],
                BasicHeaders,
                Methods_68vypb["get"]["status"]
              >(prefix, PATH39, GET, option).json(),
            /**
             * @returns 学校教員ログイン情報
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_68vypb["get"]["resBody"],
                BasicHeaders,
                Methods_68vypb["get"]["status"]
              >(prefix, PATH39, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH39}`
          }
        },
        teacher_auth: {
          password: {
            /**
             * @returns 複業先生パスワード変更
             */
            put: (option: {
              body: Methods_yapgnq["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["put"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["put"]["status"]
              >(prefix, PATH41, PUT, option).json(),
            /**
             * @returns 複業先生パスワード変更
             */
            $put: (option: {
              body: Methods_yapgnq["put"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["put"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["put"]["status"]
              >(prefix, PATH41, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns 複業先生パスワード変更リクエスト
             */
            post: (option: {
              body: Methods_yapgnq["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["post"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["post"]["status"]
              >(prefix, PATH41, POST, option).json(),
            /**
             * @returns 複業先生パスワード変更リクエスト
             */
            $post: (option: {
              body: Methods_yapgnq["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_yapgnq["post"]["resBody"],
                BasicHeaders,
                Methods_yapgnq["post"]["status"]
              >(prefix, PATH41, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH41}`
          },
          sign_in: {
            /**
             * @returns 複業先生ログイン
             */
            post: (option: {
              body: Methods_7h4cis["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_7h4cis["post"]["resBody"],
                Methods_7h4cis["post"]["resHeaders"],
                Methods_7h4cis["post"]["status"]
              >(prefix, PATH42, POST, option).json(),
            /**
             * @returns 複業先生ログイン
             */
            $post: (option: {
              body: Methods_7h4cis["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_7h4cis["post"]["resBody"],
                Methods_7h4cis["post"]["resHeaders"],
                Methods_7h4cis["post"]["status"]
              >(prefix, PATH42, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH42}`
          },
          sign_out: {
            /**
             * @returns 複業先生ログアウト
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lzrqp["delete"]["resBody"],
                BasicHeaders,
                Methods_lzrqp["delete"]["status"]
              >(prefix, PATH43, DELETE, option).json(),
            /**
             * @returns 複業先生ログアウト
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lzrqp["delete"]["resBody"],
                BasicHeaders,
                Methods_lzrqp["delete"]["status"]
              >(prefix, PATH43, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH43}`
          },
          /**
           * @returns 複業先生認証系レスポンス
           */
          patch: (option: {
            body: Methods_1nfgpxm["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["patch"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["patch"]["status"]
            >(prefix, PATH40, PATCH, option).json(),
          /**
           * @returns 複業先生認証系レスポンス
           */
          $patch: (option: {
            body: Methods_1nfgpxm["patch"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["patch"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["patch"]["status"]
            >(prefix, PATH40, PATCH, option)
              .json()
              .then((r) => r.body),
          /**
           * @returns 複業先生認証系レスポンス
           */
          post: (option: {
            body: Methods_1nfgpxm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["post"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["post"]["status"]
            >(prefix, PATH40, POST, option).json(),
          /**
           * @returns 複業先生認証系レスポンス
           */
          $post: (option: {
            body: Methods_1nfgpxm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1nfgpxm["post"]["resBody"],
              BasicHeaders,
              Methods_1nfgpxm["post"]["status"]
            >(prefix, PATH40, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH40}`
        },
        teacher_profiles: {
          _teacherProfileId: (val3: string) => {
            const prefix3 = `${PATH44}/${val3}`;

            return {
              edit: {
                /**
                 * @returns 複業先生プロフィール変更のデータ取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b882u4["get"]["resBody"],
                    BasicHeaders,
                    Methods_1b882u4["get"]["status"]
                  >(prefix, `${prefix3}${PATH31}`, GET, option).json(),
                /**
                 * @returns 複業先生プロフィール変更のデータ取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b882u4["get"]["resBody"],
                    BasicHeaders,
                    Methods_1b882u4["get"]["status"]
                  >(prefix, `${prefix3}${PATH31}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH31}`
              },
              judge_request: {
                /**
                 * @returns 複業先生再審査依頼
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b87kkx["post"]["resBody"],
                    BasicHeaders,
                    Methods_1b87kkx["post"]["status"]
                  >(prefix, `${prefix3}${PATH45}`, POST, option).json(),
                /**
                 * @returns 複業先生再審査依頼
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1b87kkx["post"]["resBody"],
                    BasicHeaders,
                    Methods_1b87kkx["post"]["status"]
                  >(prefix, `${prefix3}${PATH45}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH45}`
              },
              /**
               * @returns 複業先生プロフィール更新
               */
              put: (option: {
                body: Methods_e1ycuv["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_e1ycuv["put"]["resBody"],
                  BasicHeaders,
                  Methods_e1ycuv["put"]["status"]
                >(prefix, prefix3, PUT, option).json(),
              /**
               * @returns 複業先生プロフィール更新
               */
              $put: (option: {
                body: Methods_e1ycuv["put"]["reqBody"];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_e1ycuv["put"]["resBody"],
                  BasicHeaders,
                  Methods_e1ycuv["put"]["status"]
                >(prefix, prefix3, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          new: {
            /**
             * @returns 複業先生プロフィール作成
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1ovggn7["get"]["resBody"],
                BasicHeaders,
                Methods_1ovggn7["get"]["status"]
              >(prefix, PATH46, GET, option).json(),
            /**
             * @returns 複業先生プロフィール作成
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1ovggn7["get"]["resBody"],
                BasicHeaders,
                Methods_1ovggn7["get"]["status"]
              >(prefix, PATH46, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH46}`
          },
          /**
           * @returns 複業先生プロフィール登録
           */
          post: (option: {
            body: Methods_3ia6mm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3ia6mm["post"]["resBody"],
              BasicHeaders,
              Methods_3ia6mm["post"]["status"]
            >(prefix, PATH44, POST, option).json(),
          /**
           * @returns 複業先生プロフィール登録
           */
          $post: (option: {
            body: Methods_3ia6mm["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3ia6mm["post"]["resBody"],
              BasicHeaders,
              Methods_3ia6mm["post"]["status"]
            >(prefix, PATH44, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH44}`
        },
        teachers: {
          _teacherId_number: (val3: number) => {
            const prefix3 = `${PATH47}/${val3}`;

            return {
              teacher_announcements: {
                _teacherAnnouncementId: (val5: number) => {
                  const prefix5 = `${prefix3}${PATH48}/${val5}`;

                  return {
                    announcement_check: {
                      /**
                       * @returns OK
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<
                          Methods_1336rzc["post"]["resBody"],
                          BasicHeaders,
                          Methods_1336rzc["post"]["status"]
                        >(prefix, `${prefix5}${PATH35}`, POST, option).json(),
                      /**
                       * @returns OK
                       */
                      $post: (
                        option?: { config?: T | undefined } | undefined
                      ) =>
                        fetch<
                          Methods_1336rzc["post"]["resBody"],
                          BasicHeaders,
                          Methods_1336rzc["post"]["status"]
                        >(prefix, `${prefix5}${PATH35}`, POST, option)
                          .json()
                          .then((r) => r.body),
                      $path: () => `${prefix}${prefix5}${PATH35}`
                    }
                  };
                },
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1ico1hg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1ico1hg["get"]["status"]
                  >(prefix, `${prefix3}${PATH48}`, GET, option).json(),
                /**
                 * @returns 複業先生向けお知らせのレスポンス
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_1ico1hg["get"]["resBody"],
                    BasicHeaders,
                    Methods_1ico1hg["get"]["status"]
                  >(prefix, `${prefix3}${PATH48}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH48}`
              }
            };
          },
          _teacherId_string: (val3: string) => {
            const prefix3 = `${PATH47}/${val3}`;

            return {
              e_learnings: {
                new: {
                  /**
                   * @returns eラーニング受験
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1hk2anv["get"]["resBody"],
                      BasicHeaders,
                      Methods_1hk2anv["get"]["status"]
                    >(prefix, `${prefix3}${PATH50}`, GET, option).json(),
                  /**
                   * @returns eラーニング受験
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<
                      Methods_1hk2anv["get"]["resBody"],
                      BasicHeaders,
                      Methods_1hk2anv["get"]["status"]
                    >(prefix, `${prefix3}${PATH50}`, GET, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix3}${PATH50}`
                },
                /**
                 * @returns eラーニング回答
                 */
                post: (option: {
                  body: Methods_gtxoyu["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_gtxoyu["post"]["resBody"],
                    BasicHeaders,
                    Methods_gtxoyu["post"]["status"]
                  >(prefix, `${prefix3}${PATH49}`, POST, option).json(),
                /**
                 * @returns eラーニング回答
                 */
                $post: (option: {
                  body: Methods_gtxoyu["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_gtxoyu["post"]["resBody"],
                    BasicHeaders,
                    Methods_gtxoyu["post"]["status"]
                  >(prefix, `${prefix3}${PATH49}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH49}`
              },
              lesson_recruitments: {
                /**
                 * @returns 複業先生応募授業
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_7lhx1f["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_7lhx1f["get"]["resBody"],
                    BasicHeaders,
                    Methods_7lhx1f["get"]["status"]
                  >(prefix, `${prefix3}${PATH36}`, GET, option).json(),
                /**
                 * @returns 複業先生応募授業
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_7lhx1f["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_7lhx1f["get"]["resBody"],
                    BasicHeaders,
                    Methods_7lhx1f["get"]["status"]
                  >(prefix, `${prefix3}${PATH36}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_7lhx1f["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              lesson_requests: {
                _lessonRequestId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH37}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生向け授業依頼詳細
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_123jsc1["get"]["resBody"],
                        BasicHeaders,
                        Methods_123jsc1["get"]["status"]
                      >(prefix, prefix5, GET, option).json(),
                    /**
                     * @returns 複業先生向け授業依頼詳細
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_123jsc1["get"]["resBody"],
                        BasicHeaders,
                        Methods_123jsc1["get"]["status"]
                      >(prefix, prefix5, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生向け授業依頼一覧
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_1gehdre["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1gehdre["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gehdre["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option).json(),
                /**
                 * @returns 複業先生向け授業依頼一覧
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_1gehdre["get"]["query"] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_1gehdre["get"]["resBody"],
                    BasicHeaders,
                    Methods_1gehdre["get"]["status"]
                  >(prefix, `${prefix3}${PATH37}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: "get" | undefined;
                        query: Methods_1gehdre["get"]["query"];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
              },
              teacher_bank_accounts: {
                _teacherBankAccountId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH51}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生銀行口座削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1syykh8["delete"]["resBody"],
                        BasicHeaders,
                        Methods_1syykh8["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns 複業先生銀行口座削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_1syykh8["delete"]["resBody"],
                        BasicHeaders,
                        Methods_1syykh8["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生銀行口座取得
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_3u3trf["get"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["get"]["status"]
                  >(prefix, `${prefix3}${PATH51}`, GET, option).json(),
                /**
                 * @returns 複業先生銀行口座取得
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_3u3trf["get"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["get"]["status"]
                  >(prefix, `${prefix3}${PATH51}`, GET, option)
                    .json()
                    .then((r) => r.body),
                /**
                 * @returns 複業先生銀行口座作成
                 */
                post: (option: {
                  body: Methods_3u3trf["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_3u3trf["post"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["post"]["status"]
                  >(prefix, `${prefix3}${PATH51}`, POST, option).json(),
                /**
                 * @returns 複業先生銀行口座作成
                 */
                $post: (option: {
                  body: Methods_3u3trf["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_3u3trf["post"]["resBody"],
                    BasicHeaders,
                    Methods_3u3trf["post"]["status"]
                  >(prefix, `${prefix3}${PATH51}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH51}`
              },
              teacher_identifications: {
                /**
                 * @returns 複業先生本人確認開始
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_e0322r["post"]["resBody"],
                    BasicHeaders,
                    Methods_e0322r["post"]["status"]
                  >(prefix, `${prefix3}${PATH52}`, POST, option).json(),
                /**
                 * @returns 複業先生本人確認開始
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<
                    Methods_e0322r["post"]["resBody"],
                    BasicHeaders,
                    Methods_e0322r["post"]["status"]
                  >(prefix, `${prefix3}${PATH52}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH52}`
              },
              teacher_videos: {
                _teacherVideoId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH53}/${val5}`;

                  return {
                    /**
                     * @returns 複業先生自己紹介動画削除
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_108k0v3["delete"]["resBody"],
                        BasicHeaders,
                        Methods_108k0v3["delete"]["status"]
                      >(prefix, prefix5, DELETE, option).json(),
                    /**
                     * @returns 複業先生自己紹介動画削除
                     */
                    $delete: (
                      option?: { config?: T | undefined } | undefined
                    ) =>
                      fetch<
                        Methods_108k0v3["delete"]["resBody"],
                        BasicHeaders,
                        Methods_108k0v3["delete"]["status"]
                      >(prefix, prefix5, DELETE, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix5}`
                  };
                },
                /**
                 * @returns 複業先生自己紹介動画作成
                 */
                post: (option: {
                  body: Methods_1l9446c["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1l9446c["post"]["resBody"],
                    BasicHeaders,
                    Methods_1l9446c["post"]["status"]
                  >(prefix, `${prefix3}${PATH53}`, POST, option).json(),
                /**
                 * @returns 複業先生自己紹介動画作成
                 */
                $post: (option: {
                  body: Methods_1l9446c["post"]["reqBody"];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1l9446c["post"]["resBody"],
                    BasicHeaders,
                    Methods_1l9446c["post"]["status"]
                  >(prefix, `${prefix3}${PATH53}`, POST, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH53}`
              },
              /**
               * @returns 複業先生詳細
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_2uegaq["get"]["resBody"],
                  BasicHeaders,
                  Methods_2uegaq["get"]["status"]
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns 複業先生詳細
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_2uegaq["get"]["resBody"],
                  BasicHeaders,
                  Methods_2uegaq["get"]["status"]
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`
            };
          },
          login_teacher: {
            /**
             * @returns 複業先生ログイン情報
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lpu3cj["get"]["resBody"],
                BasicHeaders,
                Methods_lpu3cj["get"]["status"]
              >(prefix, PATH54, GET, option).json(),
            /**
             * @returns 複業先生ログイン情報
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_lpu3cj["get"]["resBody"],
                BasicHeaders,
                Methods_lpu3cj["get"]["status"]
              >(prefix, PATH54, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH54}`
          },
          lp_classi: {
            /**
             * @returns classi向けLP
             */
            get: (option: {
              query: Methods_1t202l5["get"]["query"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1t202l5["get"]["resBody"],
                BasicHeaders,
                Methods_1t202l5["get"]["status"]
              >(prefix, PATH55, GET, option).json(),
            /**
             * @returns classi向けLP
             */
            $get: (option: {
              query: Methods_1t202l5["get"]["query"];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1t202l5["get"]["resBody"],
                BasicHeaders,
                Methods_1t202l5["get"]["status"]
              >(prefix, PATH55, GET, option)
                .json()
                .then((r) => r.body),
            $path: (
              option?:
                | {
                    method?: "get" | undefined;
                    query: Methods_1t202l5["get"]["query"];
                  }
                | undefined
            ) =>
              `${prefix}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
          },
          /**
           * 複業先生一覧取得
           * @returns 複業先生一覧
           */
          get: (
            option?:
              | {
                  query?: Methods_jvfwzs["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jvfwzs["get"]["resBody"],
              BasicHeaders,
              Methods_jvfwzs["get"]["status"]
            >(prefix, PATH47, GET, option).json(),
          /**
           * 複業先生一覧取得
           * @returns 複業先生一覧
           */
          $get: (
            option?:
              | {
                  query?: Methods_jvfwzs["get"]["query"] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jvfwzs["get"]["resBody"],
              BasicHeaders,
              Methods_jvfwzs["get"]["status"]
            >(prefix, PATH47, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: "get" | undefined;
                  query: Methods_jvfwzs["get"]["query"];
                }
              | undefined
          ) =>
            `${prefix}${PATH47}${option && option.query ? `?${dataToURLString(option.query)}` : ""}`
        },
        teaching_plan_ideas: {
          new: {
            /**
             * @returns 授業内容提案回答データ
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1on40ck["get"]["resBody"],
                BasicHeaders,
                Methods_1on40ck["get"]["status"]
              >(prefix, PATH57, GET, option).json(),
            /**
             * @returns 授業内容提案回答データ
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1on40ck["get"]["resBody"],
                BasicHeaders,
                Methods_1on40ck["get"]["status"]
              >(prefix, PATH57, GET, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH57}`
          },
          /**
           * @returns 授業内容提案
           */
          post: (option: {
            body: Methods_16ra1rl["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_16ra1rl["post"]["resBody"],
              BasicHeaders,
              Methods_16ra1rl["post"]["status"]
            >(prefix, PATH56, POST, option).json(),
          /**
           * @returns 授業内容提案
           */
          $post: (option: {
            body: Methods_16ra1rl["post"]["reqBody"];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_16ra1rl["post"]["resBody"],
              BasicHeaders,
              Methods_16ra1rl["post"]["status"]
            >(prefix, PATH56, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH56}`
        },
        webhooks: {
          trustdock: {
            post: (option: {
              body: Methods_5vgulz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_5vgulz["post"]["status"]>(
                prefix,
                PATH58,
                POST,
                option
              ).send(),
            $post: (option: {
              body: Methods_5vgulz["post"]["reqBody"];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_5vgulz["post"]["status"]>(
                prefix,
                PATH58,
                POST,
                option
              )
                .send()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH58}`
          }
        }
      }
    }
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
