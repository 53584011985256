import Button from "@mui/material/Button";
import { useAtomValue } from "jotai";

import {
  acceptSelectIsClickableAtom,
  teacherObjectAtom
} from "store/RequestShowStore";

import type React from "react";

type SendButtonProps = {
  handleClick: () => void;
};

const SendButton: React.VFC<SendButtonProps> = (props) => {
  const { handleClick } = props;
  const isClickable = useAtomValue(acceptSelectIsClickableAtom);
  const teacherObject = useAtomValue(teacherObjectAtom);

  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      fullWidth
      onClick={handleClick}
      disabled={!isClickable || !teacherObject.is_identification}
      sx={{
        display: "block",
        boxSizing: "border-box",
        height: "3rem"
      }}
    >
      送信する
    </Button>
  );
};

export default SendButton;
