import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import type { teachingPlanType } from "types/teacherInfoType";

const TeachingPlanCard: React.FC<teachingPlanType> = (props) => {
  const { title, url } = props;
  const theme = useTheme();

  return (
    <Box component="a" href={url} sx={{ textDecoration: "none" }}>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          borderRadius: "8px",
          border: `solid 1px ${theme.palette.border?.main}`,
          width: "240px",
          padding: "1rem"
        }}
      >
        <Typography fontWeight="bold" color={theme.palette.textColor.main}>
          {title}
          <LaunchIcon
            sx={{
              color: theme.palette.muted?.main,
              fontSize: "1rem",
              verticalAlign: "middle",
              marginLeft: ".2rem"
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default TeachingPlanCard;
