import Box from "@mui/material/Box";
import BankAccountField from "components/molecules/BankAccountField";
import FormTextField from "components/molecules/FormTextField";

import { useAtomValue } from "jotai";
import { bankAccountTypesAtom } from "store/BankAccountStore";

import type React from "react";
import { useFormContext } from "react-hook-form";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { useEffect } from "react";

const BankAccountForm: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const institutionCode = watch("financial_institution_code");
  const bankAccountTypes = useAtomValue(bankAccountTypesAtom);
  const bankAccountTypeOptions = bankAccountTypes.map((type) => ({
    value: type.id.toString(),
    label: type.name
  }));

  // 銀行が変わったら支店名をリセット
  useEffect(() => {
    setValue("branch_name", "");
    setValue("branch_code", "");
  }, [institutionCode]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <BankAccountField
        name="financial_institution_name"
        codeName="financial_institution_code"
        label="金融機関名"
        type="bank"
      />
      <BankAccountField
        name="branch_name"
        codeName="branch_code"
        label="支店名"
        type="branch"
        institutionCode={institutionCode}
      />
      <FormTextField name="account_number" label="口座番号" noLabel />
      <FormRadioGroup
        name="bank_account_type_id"
        label="口座種別"
        options={bankAccountTypeOptions}
        noLabel
      />
      <FormTextField
        name="account_name"
        label="口座名義（カタカナ）"
        supplementalText="※銀行口座はご本人名義の口座のみご利用いただけます。"
        noLabel
      />
      <FormSubmitButton label="登録する" fullWidth size="large" />
    </Box>
  );
};

export default BankAccountForm;
