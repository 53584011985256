import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import RequestInfoBlock from "features/requests/components/molecules/show/RequestInfoBlock";
import SchoolInfoBlock01 from "features/teachers/components/molecules/show/requests/SchoolInfoBlock01_v2";

import { LessonRequestTeacher } from "api/@types";

type RequestFellThroughProps = {
  school?: LessonRequestTeacher["send_user"];
  details?: LessonRequestTeacher["details"];
};

const RequestFellThrough: React.FC<RequestFellThroughProps> = (props) => {
  const { details, school } = props;
  const theme = useTheme();

  const getBlockText = () => {
    switch (details?.step) {
      case "canceled":
        return "依頼はキャンセルされました。";
      case "denied":
        return "依頼を辞退しました。";
      case "dead":
        return "承諾期限が切れました。";
      default:
        return "依頼がキャンセルとなりました。";
    }
  };

  return (
    <Box>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Typography align="center" sx={{ whiteSpace: "pre-wrap" }}>
          {getBlockText()}
        </Typography>
        <Box
          sx={{
            borderRadius: 4,
            border: `solid 1px ${theme.palette.border?.main}`,
            overflow: "hidden",
            margin: "1.5rem auto",
            padding: "1.5rem",
            [theme.breakpoints.down("sm")]: {
              padding: "1rem",
              margin: "1rem auto"
            }
          }}
        >
          <SchoolInfoBlock01 school={school} />
        </Box>
        <Box
          sx={{
            borderRadius: 4,
            border: `solid 1px ${theme.palette.border?.main}`,
            overflow: "hidden",
            margin: "1.5rem auto",
            padding: "1.5rem",
            [theme.breakpoints.down("sm")]: {
              padding: "1rem",
              margin: "1rem auto"
            }
          }}
        >
          <RequestInfoBlock details={details} />
        </Box>
        {details?.step === "denied" && (
          <Box sx={{ margin: "1.5rem auto" }}>
            <Typography variant="h3">あなたが送信したメッセージ</Typography>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {details.reply_comment}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RequestFellThrough;
