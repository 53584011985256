import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import LessonItem from "features/teachers/components/molecules/mypage/v2/lessons/LessonListItem";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import { currentUserAtom } from "store/AuthStore";
import {
  teacherLessonsAtom,
  kindOfTeacherLessonsAtom
} from "store/teachers/mypage/LessonsStore";

const LessonLists: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherLessons, setTeacherLessons] = useAtom(teacherLessonsAtom);
  const [kindOfTeacherLessons] = useAtom(kindOfTeacherLessonsAtom);

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_requests`
  });

  useEffect(() => {
    const params = {
      status: kindOfTeacherLessons
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setTeacherLessons(data);
      }
    });
  }, [getRequest, kindOfTeacherLessons, setTeacherLessons]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="授業一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 1 }}>
                授業
              </Typography>
              <Typography fontWeight="bold">ステータス</Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {!isLoading ? (
            teacherLessons ? (
              teacherLessons.data.map((lesson, index) => (
                <React.Fragment key={index}>
                  <LessonItem
                    id={lesson.details.id}
                    exactStartAt={new Date(lesson.details.exact_start_at)}
                    schoolMinutes={lesson.details.school_minutes}
                    step={lesson.details.step}
                    schoolName={lesson.send_user.school_name}
                    schoolImage={lesson.send_user.image?.url}
                    isCertified={lesson.send_user.is_match_accepted}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              ))
            ) : (
              <Typography color={theme.palette.sub.main}>
                授業がありません
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default LessonLists;
