import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";

import type React from "react";

type TextWithLinkItemType =
  | { kindOf: "text"; content: string }
  | { kindOf: "link"; content: string; link: string; isExternal?: boolean };

type PreListItemProps = {
  title: Array<TextWithLinkItemType>;
  text?: string;
};

const PreListItemWithLink: React.FC<PreListItemProps> = (props) => {
  const { title, text } = props;
  const theme = useTheme();

  const getTextWithLinkItem = (item: TextWithLinkItemType) => {
    switch (item.kindOf) {
      case "text":
        return (
          <Typography component="span" sx={{ verticalAlign: "middle" }}>
            {item.content}
          </Typography>
        );
      case "link":
        if (item.isExternal) {
          return (
            <Box
              component="a"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.link?.main,
                textDecoration: "none",
                display: "inline-block",
                verticalAlign: "middle"
              }}
            >
              <Box component="span" sx={{ wordBreak: "break-word" }}>
                {item.content}
              </Box>
              <LaunchIcon sx={{ verticalAlign: "middle", height: "1rem" }} />
            </Box>
          );
        }

        return (
          <Box
            component={Link}
            to={item.link}
            sx={{
              color: theme.palette.link?.main,
              textDecoration: "none",
              display: "inline-block",
              verticalAlign: "middle"
            }}
          >
            <Box component="span" sx={{ wordBreak: "break-word" }}>
              {item.content}
            </Box>
            <LaunchIcon sx={{ verticalAlign: "middle", height: "1rem" }} />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", margin: "1rem auto" }}>
      <DoneOutlineIcon color="primary" sx={{ marginRight: "1rem" }} />
      <Typography>
        {title.map((item) => getTextWithLinkItem(item))}
        {text && (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>{text}</Typography>
        )}
      </Typography>
    </Box>
  );
};

export default PreListItemWithLink;
