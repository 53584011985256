import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";

import Meta from "components/organisms/Meta";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";
import useSchoolAuthApi from "features/schools/hooks/useSchoolAuthApi";
import { Typography, Box, FormGroup } from "@mui/material";
import theme from "themes/theme";
import FormTextField from "components/molecules/FormTextField";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { FormProvider } from "react-hook-form";
import FormCheckBox from "components/molecules/FormCheckBox";
import TermAcceptedLabel from "components/atoms/TermAcceptedLabel";

const Signup: React.FC = () => {
  const { signup, signupForm } = useSchoolAuthApi();

  return (
    <AuthLayout>
      <Meta
        title="教員|新規登録"
        description="教員向けのアカウント作成ページです。複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。"
      />
      <Grid
        container
        sx={{
          margin: { xs: "3rem auto", lg: 0 },
          height: { xs: "100%", lg: "100vh" }
        }}
        alignItems="center"
      >
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: { xs: theme.spacing(3), lg: 0 },
              height: "100%"
            }}
          >
            <Grid item xs={10} sm={9} md={7}>
              <Typography variant="h3" align="center">
                教員アカウント新規登録
              </Typography>
              <Typography align="center">
                複業先生として授業をしたい方は
                <Box
                  component={RouterLink}
                  to="/teachers/signup"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                    fontWeight: 600
                  }}
                >
                  こちらから
                </Box>
              </Typography>
              <FormGroup>
                <FormProvider {...signupForm}>
                  <form onSubmit={signupForm.handleSubmit(signup)}>
                    <Box
                      sx={{
                        margin: "1rem auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                      }}
                    >
                      <FormTextField
                        name="email"
                        label="メールアドレス"
                        noLabel
                        placeholder="sample@fukugyo.co.jp"
                        fullWidth
                      />
                      <FormPasswordField name="password" label="パスワード" />
                      <FormCheckBox
                        name="is_term_accepted"
                        label={<TermAcceptedLabel forSchool />}
                      />
                      <FormSubmitButton
                        label="新規登録"
                        size="large"
                        fullWidth
                      />
                    </Box>
                  </form>
                </FormProvider>
              </FormGroup>
              <Typography align="center">
                すでにアカウントをお持ちですか？
                <Box
                  component={RouterLink}
                  to="/schools/login"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                    fontWeight: 600
                  }}
                >
                  ログインする
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Signup;
