import { atom } from "jotai";

type ELearningResultType = {
  is_pass: boolean;
  judge: Array<boolean>;
};

type ELearningStatusType = {
  is_pass: boolean;
};

// MEMO: 現状は解答が16個のため、16個の要素を持つを作成
export const eLearningAnswersAtom = atom(["", "", "", "", "", "", "", ""]);

export const resetELearningAnswersAtom = atom(null, (_get, set) => {
  set(eLearningAnswersAtom, ["", "", "", "", "", "", "", ""]);
});

export const eLearningResponseAtom = atom<ELearningResultType>({
  is_pass: false,
  judge: []
});

export const resetELearningResponseAtom = atom(null, (_get, set) => {
  set(eLearningResponseAtom, {
    is_pass: false,
    judge: []
  });
});

export const eLearningStatusAtom = atom<ELearningStatusType>({
  is_pass: false
});
