import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useAtomValue } from "jotai";

import useFormatJapaneseDaytime from "hooks/useFormatJapaneseDaytime";
import { requestObjectAtom } from "store/RequestShowStore";

import type React from "react";

const ExactStartAtDisplay: React.FC = () => {
  const theme = useTheme();
  const requestObject = useAtomValue(requestObjectAtom);
  const { formatJapaneseDaytime } = useFormatJapaneseDaytime(
    new Date(requestObject.exact_start_at)
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "4px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5rem"
      }}
    >
      <Box>
        <Typography fontWeight="bold">授業実施日と開始時間</Typography>
        {requestObject.exact_start_at && (
          <Typography color={theme.palette.sub?.main} fontSize=".8rem">
            日程の都合がつかない場合、下のチャット欄から学校の先生に連絡しましょう。
          </Typography>
        )}
      </Box>
      {requestObject.exact_start_at ? (
        <Typography fontWeight="bold" fontSize="1.2rem">
          {formatJapaneseDaytime}
        </Typography>
      ) : (
        <Typography color={theme.palette.sub?.main}>
          学校が日程を設定するまでお待ちください。
        </Typography>
      )}
    </Box>
  );
};

export default ExactStartAtDisplay;
