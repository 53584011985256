import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import type React from "react";

const ToRegisterCover: React.FC = () => (
  <Box
    sx={{
      position: "absolute",
      width: "100%",
      height: "25%",
      minHeight: "9rem",
      bottom: 0,
      right: 0,
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "1rem",
      background:
        "linear-gradient(to bottom, transparent 0%, rgba(250, 249, 247, 0.8) 50%, rgba(250, 249, 247, 1) 85%)"
    }}
  >
    <Typography fontSize="1.2rem" fontWeight="bold" align="center">
      複業先生になると、全ての募集を見ることができます
    </Typography>
    <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      component={Link}
      to="/teachers/signup"
      sx={{
        fontWeight: 600
      }}
    >
      複業先生登録はこちら
    </Button>
  </Box>
);

export default ToRegisterCover;
