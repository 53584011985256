import React from "react";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

type NoContentItemProps = {
  title: string;
  lead: string;
  link?: string;
  buttonText?: string;
};

const NoContentItem: React.FC<NoContentItemProps> = (props) => {
  const { title, lead, link, buttonText } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: ".5rem",
        width: "100%",
        height: "100%",
        minHeight: "10rem"
      }}
    >
      <Typography
        align="center"
        color={theme.palette.sub.main}
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography align="center" color={theme.palette.sub.main}>
        {lead}
      </Typography>
      {link && buttonText && (
        <Button variant="outlined" color="primary" component={Link} to={link}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default NoContentItem;
