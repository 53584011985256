import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PreparationList from "features/teachers/components/molecules/show/requests/PreparationList";

import type React from "react";

import { LessonRequestTeacher, AgendaSheetNew } from "api/@types";

type Step02Props = {
  details?: LessonRequestTeacher["details"];
  meetingSheetDetails?: AgendaSheetNew["details"];
};

const Step02: React.FC<Step02Props> = (props) => {
  const { details, meetingSheetDetails } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box sx={{ margin: "0 auto" }}>
        <Typography sx={{ margin: ".5rem auto" }}>
          授業依頼が成立しました。
          <br />
          学校または複業先生事務局から連絡が来ますのでお待ちください。
        </Typography>
        <Typography sx={{ margin: ".5rem auto" }}>
          今後の進め方については以下の「事前準備リスト」を参考にお願いします。
        </Typography>
        <Typography sx={{ margin: ".5rem auto" }}>
          授業完了後、授業実施完了ボタンが押せるようになります。授業が完了したら授業完了ボタンを押してください。
          <br />
          授業完了報告をすることで報酬の支払いが行われます。
        </Typography>
      </Box>
      <PreparationList
        details={details}
        meetingSheetDetails={meetingSheetDetails}
      />
    </Box>
  );
};

export default Step02;
