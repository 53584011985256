import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";

type TitleBlockProps = {
  title: string;
  description?: string;
};

// TODO: 他のセクションで利用する際に都度調整
const TitleBlock: React.FC<TitleBlockProps> = (props) => {
  const { title, description } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          gap: "1rem"
        }}
      >
        <Typography variant="h1">{title}</Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </Box>
    </Box>
  );
};

export default TitleBlock;
