import Box from "@mui/material/Box";

import NumberOfUsers from "assets/lpClassi/NumberOfUsers.png";
import SatisfactionScore from "assets/lpClassi/SatisfactionScore.png";

import type React from "react";

const FirstViewLabels: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      margin: "1.2rem auto 0",
      gap: ".75rem",
      justifyContent: { xs: "center", md: "flex-start" }
    }}
  >
    <Box
      component="img"
      src={NumberOfUsers}
      alt="利用生徒数"
      sx={{
        display: "block",
        width: { xs: "calc(202px * 0.75)", lg: "auto" },
        height: "auto"
      }}
    />
    <Box
      component="img"
      src={SatisfactionScore}
      alt="授業満足度"
      sx={{
        display: "block",
        width: { xs: "calc(168px * 0.75)", lg: "auto" },
        height: "auto"
      }}
    />
  </Box>
);

export default FirstViewLabels;
