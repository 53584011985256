import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "jotai";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GuestRoute from "components/routes/GuestRoute";
import PrivateRoute from "components/routes/PrivateRoute";
import SchoolGuestRoute from "components/routes/SchoolGuestRoute";
import SchoolPrivateRoute from "components/routes/SchoolPrivateRoute";
import ScrollToTop from "components/organisms/ScrollToTop";
import Contact from "features/root/components/pages/Contact";
import LPClassi from "features/root/components/pages/LPClassi";
import RecruitmentIndex from "features/recruitments/components/pages/Index";
import RecruitmentNew from "features/recruitments/components/pages/New";
import RecruitmentShow from "features/recruitments/components/pages/Show";
import RequestsCreate from "features/requests/components/pages/Create";
import RequestsNew from "features/requests/components/pages/New";
import MeetingSheet from "features/requests/components/pages/MeetingSheet";
import SchoolForgotPassword from "features/schools/components/pages/ForgotPassword";
import SchoolLogin from "features/schools/components/pages/Login";
import AiTeachingPlanCreate from "features/schools/components/pages/mypage/aiTeachingPlan/Create";
import AiTeachingPlanNew from "features/schools/components/pages/mypage/aiTeachingPlan/New";
import SchoolRequestShow from "features/schools/components/pages/mypage/requests/Show";
import SchoolMypageV2 from "features/schools/components/pages/Mypage_v2";
import SchoolPasswordReset from "features/schools/components/pages/PasswordReset";
import SchoolPasswordResetComplete from "features/schools/components/pages/PasswordResetComplete";
import SchoolChangeEmail from "features/schools/components/pages/profile/ChangeEmail";
import ChangePassword from "features/schools/components/pages/profile/ChangePassword";
import SchoolProfileComplete from "features/schools/components/pages/profile/Complete";
import SchoolProfileEdit from "features/schools/components/pages/profile/Edit";
import SchoolProfileNew from "features/schools/components/pages/profile/New";
import SchoolProfileToNew from "features/schools/components/pages/profile/ToNew";
import RecruitmentShowForSchool from "features/schools/components/pages/recruitments/Show";
import SchoolSignup from "features/schools/components/pages/Signup";
import SchoolThanksResetRequest from "features/schools/components/pages/ThanksResetRequest";
import SchoolThanksSignup from "features/schools/components/pages/ThanksSignup";
import Signup from "features/root/components/pages/Signup";
import ForgotPassword from "features/teachers/components/pages/ForgotPassword";
import TeachersIndex from "features/teachers/components/pages/Index";
import ELearningNew from "features/teachers/components/pages/mypage/eLearning/New";
import ElearningResult from "features/teachers/components/pages/mypage/eLearning/Result";
import TeacherRequestShow from "features/teachers/components/pages/mypage/requests/Show";
import Settings from "features/teachers/components/pages/mypage/Settings";
import TeacherPasswordReset from "features/teachers/components/pages/PasswordReset";
import TeacherPasswordResetComplete from "features/teachers/components/pages/PasswordResetComplete";
import TeacherProfileToNew from "features/teachers/components/pages/profile/ToNew";
import TeacherShow from "features/teachers/components/pages/Show_v2";
import TeacherThanksResetRequest from "features/teachers/components/pages/ThanksResetRequest";

import type React from "react";
import theme from "./themes/theme";
import Home from "./features/root/components/pages/Home";
import NotFound from "./features/root/components/pages/NotFound";
import Privacy from "./features/root/components/pages/Privacy";
import SchoolHome from "./features/root/components/pages/SchoolHome";
import TeacherLogin from "./features/teachers/components/pages/Login";
import TeacherMypageV2 from "./features/teachers/components/pages/Mypage_v2";
import TeacherProfileComplete from "./features/teachers/components/pages/profile/Complete";
import TeacherProfileEdit from "./features/teachers/components/pages/profile/Edit";
import TeacherProfileNew from "./features/teachers/components/pages/profile/New";
import TeacherSignup from "./features/teachers/components/pages/Signup";
import TeacherThanksSignup from "./features/teachers/components/pages/ThanksSignup";

function App(): React.ReactElement {
  return (
    <div className="App">
      <Provider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/signup" component={Signup} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/contact" component={Contact} />
              <Route
                path="/lp"
                render={({ match: { url } }) => (
                  <Switch>
                    <Route path={`${url}/school`} component={SchoolHome} />
                    <Route path={`${url}/classi`} component={LPClassi} />
                  </Switch>
                )}
              />
              <Route path="/not-found" component={NotFound} />
              {/* 複業先生向けRoute */}
              <Route
                path="/teachers"
                render={({ match: { url } }) => (
                  <Switch>
                    <Route exact path={url} component={TeachersIndex} />
                    <GuestRoute
                      path={`${url}/login`}
                      component={TeacherLogin}
                    />
                    <GuestRoute
                      path={`${url}/signup`}
                      component={TeacherSignup}
                    />
                    <GuestRoute
                      path={`${url}/thanks-signup`}
                      component={TeacherThanksSignup}
                    />
                    <GuestRoute
                      path={`${url}/forgot-password`}
                      component={ForgotPassword}
                    />
                    <GuestRoute
                      path={`${url}/password-reset`}
                      component={TeacherPasswordReset}
                    />
                    <GuestRoute
                      path={`${url}/thanks-reset-request`}
                      component={TeacherThanksResetRequest}
                    />
                    <GuestRoute
                      path={`${url}/password-reset-complete`}
                      component={TeacherPasswordResetComplete}
                    />
                    <Route
                      path={`${url}/mypage`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <PrivateRoute
                            exact
                            path={`${url}/settings`}
                            component={Settings}
                          />
                          <Route
                            path={`${url}/e-learnings`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path={`${url}/new`}
                                  component={ELearningNew}
                                />
                                <PrivateRoute
                                  exact
                                  path={`${url}/result`}
                                  component={ElearningResult}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/requests`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={TeacherRequestShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <PrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <PrivateRoute
                            path={`${url}/`}
                            component={TeacherMypageV2}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route
                      path={`${url}/profile`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <PrivateRoute
                            path={`${url}/new`}
                            component={TeacherProfileNew}
                          />
                          <PrivateRoute
                            path={`${url}/edit`}
                            component={TeacherProfileEdit}
                          />
                          <PrivateRoute
                            path={`${url}/complete`}
                            component={TeacherProfileComplete}
                          />
                          <PrivateRoute
                            path={`${url}/to-new`}
                            component={TeacherProfileToNew}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route path={`${url}/:id`} component={TeacherShow} />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 複業先生向けRoute ここまで */}
              {/* 授業依頼用Route (URLに関しては要検討) */}
              <Route
                path="/requests"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolPrivateRoute
                      path={`${url}/new`}
                      component={RequestsNew}
                    />
                    <SchoolPrivateRoute
                      path={`${url}/thanks-request`}
                      component={RequestsCreate}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 授業依頼用Route ここまで */}
              {/* 学校用Route */}
              <Route
                path="/schools"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolGuestRoute
                      path={`${url}/signup`}
                      component={SchoolSignup}
                    />
                    <SchoolGuestRoute
                      path={`${url}/login`}
                      component={SchoolLogin}
                    />
                    <SchoolGuestRoute
                      path={`${url}/thanks-signup`}
                      component={SchoolThanksSignup}
                    />
                    <SchoolGuestRoute
                      path={`${url}/forgot-password`}
                      component={SchoolForgotPassword}
                    />
                    <SchoolGuestRoute
                      path={`${url}/password-reset`}
                      component={SchoolPasswordReset}
                    />
                    <SchoolGuestRoute
                      path={`${url}/thanks-reset-request`}
                      component={SchoolThanksResetRequest}
                    />
                    <SchoolGuestRoute
                      path={`${url}/password-reset-complete`}
                      component={SchoolPasswordResetComplete}
                    />
                    <Route
                      path={`${url}/mypage`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <SchoolPrivateRoute
                            exact
                            path={`${url}/change-email`}
                            component={SchoolChangeEmail}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/change-password`}
                            component={ChangePassword}
                          />
                          <Route
                            path={`${url}/ai-teaching-plan`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/new`}
                                  component={AiTeachingPlanNew}
                                />
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/result`}
                                  component={AiTeachingPlanCreate}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/requests`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={SchoolRequestShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <SchoolPrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/recruitments`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={RecruitmentShowForSchool}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/`}
                            component={SchoolMypageV2}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route
                      path={`${url}/profile`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <SchoolPrivateRoute
                            path={`${url}/new`}
                            component={SchoolProfileNew}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/complete`}
                            component={SchoolProfileComplete}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/to-new`}
                            component={SchoolProfileToNew}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/edit`}
                            component={SchoolProfileEdit}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 授業募集要Route */}
              <Route
                path="/recruitments"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolPrivateRoute
                      path={`${url}/new`}
                      component={RecruitmentNew}
                    />
                    <PrivateRoute
                      path={`${url}/:id`}
                      component={RecruitmentShow}
                    />
                    <Route component={RecruitmentIndex} />
                  </Switch>
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
