import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import usePostFormDataRequest from "features/schools/hooks/usePostFormDataRequest";
import useFormatDateTime from "hooks/useFormatDateTime";
import {
  requestCancelOpenAtom,
  updateSuccessAtom,
  updateIsLoadingAtom,
  updateErrorAtom
} from "store/RequestShowStore";

import type React from "react";

import { LessonRequestSchoolTeacher } from "api/@types";

type RequestCancelAlertProps = {
  details?: LessonRequestSchoolTeacher["details"];
};

// TODO: 依頼のキャンセルはスタイルとaspidaを合わせて直す
const RequestCancelAlert: React.FC<RequestCancelAlertProps> = (props) => {
  const { details } = props;
  const history = useHistory();
  const { getFormatDateTime } = useFormatDateTime();

  const [requestCancelOpen, setRequestCancelOpen] = useAtom(
    requestCancelOpenAtom
  );
  const [, setUpdateSuccess] = useAtom(updateSuccessAtom);
  const [, setUpdateIsLoading] = useAtom(updateIsLoadingAtom);
  const [, setUpdateError] = useAtom(updateErrorAtom);

  const { postFormDataRequest, isLoading, isError } = usePostFormDataRequest({
    url: `/api/api/v1/lesson_requests/${details?.id}`,
    method: "put"
  });

  const handleClose = () => {
    setRequestCancelOpen(false);
  };

  const requestCancel = () => {
    const formData = new FormData();
    formData.append(
      "canceled_at",
      String(new Date(getFormatDateTime(new Date())))
    );

    postFormDataRequest({
      params: formData,
      onSuccess: () => {
        setUpdateSuccess(true);
        history.push("/schools/mypage");
      },
      onError: () => {
        setRequestCancelOpen(false);
      }
    });
  };

  useEffect(() => {
    setUpdateIsLoading(isLoading);
    setUpdateError(isError);
  }, [isError, isLoading, setUpdateError, setUpdateIsLoading]);

  return (
    <Modal
      open={requestCancelOpen}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Fade in={requestCancelOpen}>
        <Paper sx={{ padding: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                依頼をキャンセルしますか？
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={requestCancel}
                fullWidth
                disableElevation
              >
                はい
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                fullWidth
                disableElevation
              >
                いいえ
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default RequestCancelAlert;
