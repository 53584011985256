import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import IndicatePublic from "components/atoms/fieldLabels/IndicatePublic";
import { useFormContext } from "react-hook-form";
import FormLabel from "components/atoms/FormLabel";

type FormFullNameFieldProps = {
  label: string;
  isRequired?: boolean;
  isPublic?: boolean;
};

const FormFullNameField: React.FC<FormFullNameFieldProps> = (props) => {
  const { label, isRequired, isPublic } = props;
  const { setValue, clearErrors, setError } = useFormContext();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameKana, setLastNameKana] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [lastNameIsError, setLastNameIsError] = useState(false);
  const [firstNameIsError, setFirstNameIsError] = useState(false);
  const [lastNameKanaIsError, setLastNameKanaIsError] = useState(false);
  const [firstNameKanaIsError, setFirstNameKanaIsError] = useState(false);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    checkNameIsValid(event.target.value, firstName);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    checkNameIsValid(lastName, event.target.value);
  };

  const handleLastNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastNameKana(event.target.value);
    checkKanaNameIsValid(event.target.value, firstNameKana);
  };

  const handleFirstNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstNameKana(event.target.value);
    checkKanaNameIsValid(lastNameKana, event.target.value);
  };

  const checkNameIsValid = (lastName: string, firstName: string) => {
    const newLastNameIsError = lastName === "";
    const newFirstNameIsError = firstName === "";
    setLastNameIsError(newLastNameIsError);
    setFirstNameIsError(newFirstNameIsError);

    if (!newLastNameIsError && !newFirstNameIsError) {
      setValue("name", `${lastName}${firstName}`, {
        shouldValidate: true
      });
      clearErrors("name");
    } else {
      setError("name", { type: "invalid" });
    }
  };

  const checkKanaNameIsValid = (
    lastNameKana: string,
    firstNameKana: string
  ) => {
    const KanaRegex = /^[ァ-ンヴー]*$/;
    const newFirstNameKanaIsError =
      firstNameKana === "" || !KanaRegex.test(firstNameKana);
    const newLastNameKanaIsError =
      lastNameKana === "" || !KanaRegex.test(lastNameKana);
    setFirstNameKanaIsError(newFirstNameKanaIsError);
    setLastNameKanaIsError(newLastNameKanaIsError);

    if (!newFirstNameKanaIsError && !newLastNameKanaIsError) {
      setValue("kana_name", `${lastNameKana}${firstNameKana}`, {
        shouldValidate: true
      });
      clearErrors("kana_name");
    } else {
      setError("kana_name", { type: "invalid" });
    }
  };

  return (
    <Box>
      <FormLabel labelName={label} isRequired={isRequired} />
      <Grid container>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="姓：山田"
            fullWidth
            value={lastName}
            onChange={handleLastNameChange}
            error={lastNameIsError}
            helperText={lastNameIsError && "この項目は必須です"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="名：花子"
            fullWidth
            value={firstName}
            onChange={handleFirstNameChange}
            error={firstNameIsError}
            helperText={firstNameIsError && "この項目は必須です"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="セイ：ヤマダ"
            fullWidth
            value={lastNameKana}
            onChange={handleLastNameKanaChange}
            error={lastNameKanaIsError}
            helperText={lastNameKanaIsError && "全角カタカナで入力してください"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="メイ：ハナコ"
            fullWidth
            value={firstNameKana}
            onChange={handleFirstNameKanaChange}
            error={firstNameKanaIsError}
            helperText={
              firstNameKanaIsError && "全角カタカナで入力してください"
            }
          />
        </Grid>
      </Grid>
      {isPublic && <IndicatePublic />}
    </Box>
  );
};

export default FormFullNameField;
