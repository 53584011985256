import { useAtom } from "jotai";
import { useEffect } from "react";

import ErrorAlert from "components/atoms/ErrorAlert";
import SuccessAlert from "components/atoms/SuccessAlert";
import JudgeConfirmationAlert from "features/teachers/components/molecules/show/JudgeConfirmationAlert";
import JudgeNgAlert from "features/teachers/components/molecules/show/JudgeNgAlert";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import usePostFormDataRequest from "features/teachers/hooks/usePostFormDataRequest";
import { currentUserAtom } from "store/AuthStore";
import {
  judgeNgAtom,
  judgeNgReasonAtom,
  rejudgeRequestSuccessAtom,
  rejudgeRequestErrorAtom
} from "store/TeacherMyProfileStore";

import type React from "react";

const ProfileJudges: React.FC = () => {
  const [judgeNg, setJudgeNg] = useAtom(judgeNgAtom);
  const [, setJudgeNgReason] = useAtom(judgeNgReasonAtom);
  const [rejudgeRequestSuccess, setRejudgeRequestSuccess] = useAtom(
    rejudgeRequestSuccessAtom
  );
  // MEMO: 本来は必要ないが、移植前の仕様に合わせる
  const [rejudgeRequestError, setRejudgeRequestError] = useAtom(
    rejudgeRequestErrorAtom
  );
  const [currentUser] = useAtom(currentUserAtom);

  const { getRequest, isError, setIsError } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}`
  });

  const { postFormDataRequest } = usePostFormDataRequest({
    url: `/api/api/v1/teacher_profiles/${currentUser.teacher_profile_id}/judge_request`
  });

  const handleRejudgeRequest = () =>
    postFormDataRequest({
      onSuccess: (response) => {
        setRejudgeRequestSuccess(true);
        setJudgeNg(response.is_public_judge_ng);
      },
      onError: () => {
        setRejudgeRequestError(true);
      }
    });

  useEffect(() => {
    getRequest({
      onSuccess: (data: any) => {
        setJudgeNg(data.is_public_judge_ng);
        setJudgeNgReason(data.public_judge_ng_reason);
      }
    });
  }, []);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="複業先生情報の取得に失敗しました"
      />
      <ErrorAlert
        isError={rejudgeRequestError}
        setIsError={setRejudgeRequestError}
        errorMessage="再審査の処理に失敗しました"
      />
      <SuccessAlert
        isSuccess={rejudgeRequestSuccess}
        setIsSuccess={setRejudgeRequestSuccess}
        successMessage="再審査を依頼しました"
      />
      {!judgeNg && !currentUser.is_public && <JudgeConfirmationAlert />}
      {judgeNg && <JudgeNgAlert handlePostRequest={handleRejudgeRequest} />}
    </>
  );
};

export default ProfileJudges;
