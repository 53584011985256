import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useRef, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";

import ErrorAlert from "components/atoms/ErrorAlert";
import Loading from "components/atoms/Loading";
import ToAgendaSheetBanner from "features/requests/components/atoms/show/ToAgendaSheetBanner";
import ExactStartAtDisplay from "features/teachers/components/molecules/show/requests/ExactStartAtDisplay";
import Meta from "components/organisms/Meta";
import Chat from "features/requests/components/organisms/Show/Chat";
import SchoolInfoBlock from "features/teachers/components/organisms/show/requests/SchoolInfoBlock";
import SchoolInfoGuid from "features/teachers/components/organisms/show/requests/SchoolInfoGuid";
import Step01 from "features/teachers/components/organisms/show/requests/Step01";
import Step02 from "features/teachers/components/organisms/show/requests/Step02_v2";
import Step03 from "features/teachers/components/organisms/show/requests/Step03_v2";
import Step04 from "features/teachers/components/organisms/show/requests/Step04";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import useTeacherReset from "features/teachers/hooks/useTeacherReset";
import useActiveStepCheck from "hooks/useActiveStepCheck";
import CommonLayout from "components/layouts/CommonLayout";
import { currentUserAtom } from "store/AuthStore";
import {
  teacherBankAccountAtom,
  bankAccountTypesAtom
} from "store/BankAccountStore";
import { lessonRatingDisplayAtom } from "store/LessonRatingShowStore";
import {
  possibleDatesAtom,
  requestObjectAtom,
  schoolObjectAtom,
  teacherObjectAtom,
  meetingSheetDataAtom,
  supporterAtom,
  beforeMeetingAtom,
  updateIsLoadingAtom,
  updateErrorAtom
} from "store/RequestShowStore";

import useShowPageApi from "features/teachers/hooks/request/useShowPageApi";

import type React from "react";
import type { routerIdType } from "types/routerParamsType";
import RequestFellThrough from "features/teachers/components/organisms/show/requests/RequestFellThrough";

const Show: React.FC = () => {
  const [, setSchoolObject] = useAtom(schoolObjectAtom);
  const [requestObject, setRequestObject] = useAtom(requestObjectAtom);
  const [, setSupporter] = useAtom(supporterAtom);
  const [, setMeetingSheetData] = useAtom(meetingSheetDataAtom);
  const [, setTeacherObject] = useAtom(teacherObjectAtom);
  // MEMO: 直感的によくなさそうな実装
  const [, setBeforeMeeting] = useAtom(beforeMeetingAtom);
  const [updateIsLoading, setUpdateIsLoading] = useAtom(updateIsLoadingAtom);
  const [updateError, setUpdateError] = useAtom(updateErrorAtom);
  const [, setLessonRatingDisplay] = useAtom(lessonRatingDisplayAtom);
  const [, setTeacherBankAccount] = useAtom(teacherBankAccountAtom);
  const [, setBankAccountTypes] = useAtom(bankAccountTypesAtom);
  const [, setPossibleDates] = useAtom(possibleDatesAtom);

  const [currentUser] = useAtom(currentUserAtom);

  // MEMO: 授業が実施済み＆完了報告がない場合用の記述
  const exactStartAt = useMemo(() => {
    if (requestObject.exact_start_at) {
      return new Date(requestObject.exact_start_at);
    }
    return "";
  }, [requestObject.exact_start_at]);
  const now = useMemo(() => new Date(), []);

  const history = useHistory();
  const theme = useTheme();
  const [teacherReset] = useTeacherReset();
  const { id } = useParams<routerIdType>();
  const { getActiveStep } = useActiveStepCheck();

  const {
    school,
    supporter,
    details,
    getRequestIsLoading,
    meetingSheet,
    postLessonComplete,
    requestCommentsData,
    postRequestComments,
    deleteRequestComments,
    ratingDetails
  } = useShowPageApi(id);

  const schoolGuildInfoRef = useRef<HTMLDivElement>(null);

  const inValid =
    details?.step === "denied" ||
    details?.step === "canceled" ||
    details?.step === "dead" ||
    details?.step === "lesson_request";

  const { getRequest, isLoading, isError, setIsError } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_requests/${id}`
  });

  const {
    getRequest: getTeacherDataRequest,
    isLoading: teacherDataIsLoading,
    isError: teacherDataIsError,
    setIsError: setTeacherDataIsError
  } = useGetRequest({ url: `/api/api/v1/teachers/${currentUser.id}` });

  // for bank accounts
  const {
    getRequest: getBankAccountRequest,
    isError: getBankAccountIsError,
    setIsError: setGetBankAccountIsError,
    isLoading: getBankAccountIsLoading
  } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/teacher_bank_accounts`
  });

  // MEMO: どこかでこのメソッドリファクタできそう
  const getStepContents = (step: string) => {
    switch (step) {
      case "canceled":
      case "denied":
      case "dead":
        return <RequestFellThrough school={school} details={details} />;
      case "lesson_request":
        return <Step01 details={details} />;
      case "preparation":
        return <Step02 details={details} meetingSheetDetails={meetingSheet} />;
      case "lesson_end":
        return (
          <Step03 details={details} postLessonComplete={postLessonComplete} />
        );
      case "completed":
        return <Step04 details={details} ratingDetails={ratingDetails} />;
      default:
        return <div />;
    }
  };

  useEffect(() => {
    setUpdateIsLoading(false);
    getTeacherDataRequest({
      onSuccess: (data) => {
        setTeacherObject(data);
      }
    }).then(() => {
      getRequest({
        onSuccess: (response) => {
          setSchoolObject(response.send_user);
          setRequestObject(response.details);
          if (response.possible_dates) {
            setPossibleDates(response.possible_dates);
          }
          if (response.supporter) {
            setSupporter(response.supporter);
          }
          if (response.agenda_sheet_details) {
            setMeetingSheetData(response.agenda_sheet_details);
            setBeforeMeeting(false);
          } else {
            setBeforeMeeting(true);
          }

          if (response.details.step === "completed") {
            getBankAccountRequest({
              onSuccess: (data) => {
                // MEMO: APIで吸うルートもありそうではある
                if (Object.keys(data.teacher_bank_account).length) {
                  setTeacherBankAccount(data.teacher_bank_account);
                }
                setBankAccountTypes(data.bank_account_types);
              }
            });
          }
        }
      });
    });
  }, [
    exactStartAt,
    getActiveStep,
    getRequest,
    getTeacherDataRequest,
    history,
    setBeforeMeeting,
    setMeetingSheetData,
    setRequestObject,
    setSchoolObject,
    setTeacherObject,
    setUpdateIsLoading,
    teacherReset,
    now,
    setLessonRatingDisplay,
    setSupporter,
    getBankAccountRequest,
    setBankAccountTypes,
    setTeacherBankAccount,
    setPossibleDates,
    details?.step
  ]);

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業依頼" />
      <ErrorAlert
        isError={teacherDataIsError}
        setIsError={setTeacherDataIsError}
        errorMessage="情報が取得できませんでした。"
      />
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="情報が取得できませんでした。"
      />
      <ErrorAlert
        isError={updateError}
        setIsError={setUpdateError}
        errorMessage="情報が更新できませんでした。"
      />
      <ErrorAlert
        isError={getBankAccountIsError}
        setIsError={setGetBankAccountIsError}
        errorMessage="口座情報の取得に失敗しました。"
      />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          padding: { xs: 0, sm: "1.5rem 0" }
        }}
      >
        {!isLoading &&
        !updateIsLoading &&
        !teacherDataIsLoading &&
        !getRequestIsLoading &&
        !getBankAccountIsLoading ? (
          <Container
            maxWidth="lg"
            sx={{
              padding: { xs: 0, sm: "0 24px" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                margin: "0 auto",
                width: { xs: "100%", md: "90%" },
                gap: "1.5rem",
                flexDirection: { xs: "column", md: "row" }
              }}
            >
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box sx={{ width: "270px" }}>
                  <SchoolInfoBlock />
                </Box>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <div>
                  <SchoolInfoGuid SchoolInfoSpRef={schoolGuildInfoRef} />
                </div>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem"
                  }}
                >
                  {!inValid && <ExactStartAtDisplay />}
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      overflow: "hidden",
                      padding: "1.5rem"
                    }}
                  >
                    {getStepContents(details?.step || "")}
                  </Box>
                  {!inValid && (
                    <Chat
                      details={details}
                      schoolTeacher={school}
                      supporter={supporter}
                      requestCommentsData={requestCommentsData}
                      postRequestComments={postRequestComments}
                      deleteRequestComments={deleteRequestComments}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    marginBottom: { xs: "1.5rem", sm: 0 }
                  }}
                  ref={schoolGuildInfoRef}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      margin: "1rem auto",
                      width: "100%",
                      padding: "0 1.5rem"
                    }}
                  >
                    案件情報
                  </Typography>
                  <SchoolInfoBlock />
                </Box>
              </Box>
              {!inValid && <ToAgendaSheetBanner details={details} />}
            </Box>
          </Container>
        ) : (
          <Loading headerOnly />
        )}
      </Box>
    </CommonLayout>
  );
};

export default Show;
