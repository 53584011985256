import { useAtomValue } from "jotai";

import {
  isLoggedInAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom
} from "store/AuthStore";
import {
  schoolIsLoggedInAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom
} from "store/SchoolAuthStore";

const useHeaders = () => {
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const accessToken = useAtomValue(accessTokenAtom);
  const client = useAtomValue(clientAtom);
  const uid = useAtomValue(uidAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const schoolAccessToken = useAtomValue(schoolAccessTokenAtom);
  const schoolClient = useAtomValue(schoolClientAtom);
  const schoolUid = useAtomValue(schoolUidAtom);

  if (isLoggedIn && accessToken && client && uid) {
    return {
      "access-token": accessToken,
      client,
      uid
    };
  }

  if (schoolIsLoggedIn && schoolAccessToken && schoolClient && schoolUid) {
    return {
      "access-token": schoolAccessToken,
      client: schoolClient,
      uid: schoolUid
    };
  }

  return {};
};

export default useHeaders;
