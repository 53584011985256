import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import useFormatJapaneseDay from "hooks/useFormatJapaneseDay";

import type React from "react";

import { LessonRequestTeacher } from "api/@types";

type FinishReportProps = {
  details?: LessonRequestTeacher["details"];
  postLessonComplete: () => void;
};

const FinishReport: React.FC<FinishReportProps> = (props) => {
  const { details, postLessonComplete } = props;

  // MEMO: 一旦報告期限を一週間後に設定。
  // カスタムフックの活用などで、マジックナンバーの除去が必要？
  const deadline = new Date(details?.exact_start_at || "");
  deadline.setDate(deadline.getDate() + 7);
  const deadlineFormat = useFormatJapaneseDay(deadline);

  return (
    <Box
      sx={{
        backgroundColor: "#FFF5DD",
        padding: "2rem 0"
      }}
    >
      <Typography
        sx={{ margin: "1.5rem auto" }}
        fontWeight="bold"
        align="center"
      >
        報告期限：{deadlineFormat}まで
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disableElevation
        onClick={postLessonComplete}
        sx={{
          display: "block",
          margin: "2rem auto 0",
          padding: ".5rem 2.5rem",
          fontWeight: "bold"
        }}
      >
        授業終了しました
      </Button>
    </Box>
  );
};

export default FinishReport;
