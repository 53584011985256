import { Typography, Box } from "@mui/material";
import theme from "themes/theme";
import { Link as RouterLink } from "react-router-dom";

type TermAcceptedLabelProps = {
  forSchool?: boolean;
};

const TermAcceptedLabel: React.FC<TermAcceptedLabelProps> = (props) => {
  const { forSchool } = props;

  return (
    <Typography>
      <Box
        component={RouterLink}
        to={
          forSchool
            ? "/fukugyosensei_school_kiyaku.pdf"
            : "/fukugyosensei_kiyaku.pdf"
        }
        target="_blank"
        sx={{
          textDecoration: "none",
          color: theme.palette.secondary.main,
          fontWeight: 600
        }}
      >
        利用規約
      </Box>
      と
      <Box
        component={RouterLink}
        to="/privacy_policy.pdf"
        target="_blank"
        sx={{
          textDecoration: "none",
          color: theme.palette.secondary.main,
          fontWeight: 600
        }}
      >
        プライバシーポリシー
      </Box>
      に同意する
    </Typography>
  );
};

export default TermAcceptedLabel;
