import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";

import useConvertFileSize from "hooks/useConvertFileSize";

import type React from "react";
import type { fileType } from "store/RequestShowStore";

type FileDownloadModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  file: fileType;
};

const DownloadCheckModal: React.FC<FileDownloadModalProps> = (props) => {
  const { open, setOpen, file } = props;
  const convertFileSize = useConvertFileSize();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    window.open(file.url, "_blank");
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      sx={{ position: "relative" }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px",
          zIndex: 1600
        }}
      >
        <IconButton
          onClick={handleClose}
          size="small"
          sx={{
            position: "absolute",
            right: 6,
            top: 6
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          padding: { xs: "1rem", sm: "2rem" },
          height: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "100%"
          }}
        >
          <Typography>
            <Box component="span" sx={{ display: "inline-block" }}>
              以下のファイルを
            </Box>
            <Box component="span" sx={{ display: "inline-block" }}>
              ダウンロードします。
            </Box>
            <br />
            <Box component="span" sx={{ display: "inline-block" }}>
              よろしいですか？
            </Box>
          </Typography>
          <Typography fontWeight="bold">
            {file.name}（{convertFileSize(file.size)}）
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              disableElevation
              fullWidth
              sx={{
                backgroundColor: theme.palette.backgroundColor?.main,
                color: theme.palette.textColor.main,
                border: `1px solid ${theme.palette.border?.main}`,
                "&:hover": {
                  backgroundColor: theme.palette.backgroundColor?.dark
                }
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={handleDownload}
              sx={{
                fontWeight: "bold"
              }}
            >
              ダウンロード
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadCheckModal;
