import { Box, Typography, useTheme } from "@mui/material";
import { useAtom } from "jotai";

import RequestGrayButton from "features/schools/components/atoms/show/requests/RequestGrayButton";
import RequestInfoBlock from "features/requests/components/molecules/show/RequestInfoBlock";
import RequestCancelAlert from "features/schools/components/molecules/show/requests/RequestCancelAlert";
import { requestCancelOpenAtom } from "store/RequestShowStore";

import type React from "react";

import { LessonRequestSchoolTeacher } from "api/@types";

type Step01Props = {
  details?: LessonRequestSchoolTeacher["details"];
};

const Step01: React.FC<Step01Props> = (props) => {
  const { details } = props;
  const theme = useTheme();

  const [, setRequestCancelOpen] = useAtom(requestCancelOpenAtom);

  const openAlert = () => {
    setRequestCancelOpen(true);
  };

  return (
    <div>
      <Typography align="center" sx={{ margin: "0 auto 1rem" }}>
        <Box component="span" sx={{ display: "inline-block" }}>
          複業先生に授業を依頼しました。
        </Box>
        <Box component="span" sx={{ display: "inline-block" }}>
          複業先生の回答をお待ちください。
        </Box>
      </Typography>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        <RequestInfoBlock details={details} />
      </Box>
      <RequestGrayButton
        name="依頼をキャンセルしたい"
        handleClick={openAlert}
      />
      <RequestCancelAlert details={details} />
    </div>
  );
};

export default Step01;
