import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";

import prices from "constants/prices";
import { lessonOptionsAtom, totalAmountAtom } from "store/RequestNewStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import { useFormContext } from "react-hook-form";
import { NewFormSchemaType } from "features/requests/constants/newFormSchema";

const usePrice = () => {
  const { watch } = useFormContext<NewFormSchemaType>();
  const schoolMinutesSelect = watch("school_minutes");
  const selectedOptionIds = watch("lesson_option_ids");
  const lessonOptions = useAtomValue(lessonOptionsAtom);
  const [, setTotalAmount] = useAtom(totalAmountAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);

  const memoizeSchoolMinutesSelect = useMemo(
    () => schoolMinutesSelect,
    [schoolMinutesSelect]
  );
  const memoizeSelectedOptionIds = useMemo(
    () => selectedOptionIds,
    [selectedOptionIds]
  );
  const memoizeLessonOptions = useMemo(() => lessonOptions, [lessonOptions]);

  const basicCharge = useMemo(() => {
    if (!memoizeSchoolMinutesSelect) {
      return {
        label: "選択されていません",
        price: 0
      };
    }

    if (Number(memoizeSchoolMinutesSelect) > 120) {
      return {
        label: `${memoizeSchoolMinutesSelect}分`,
        price: prices.lesson3time
      };
    }

    if (Number(memoizeSchoolMinutesSelect) > 60) {
      return {
        label: `${memoizeSchoolMinutesSelect}分`,
        price: prices.lesson2time
      };
    }

    return {
      label: `${memoizeSchoolMinutesSelect}分`,
      price: prices.lesson1time
    };
  }, [memoizeSchoolMinutesSelect]);

  const selectedLessonOptions = useMemo(
    () =>
      memoizeLessonOptions.filter((lessonOption) =>
        (memoizeSelectedOptionIds ?? []).includes(lessonOption.id)
      ),
    [memoizeLessonOptions, memoizeSelectedOptionIds]
  );

  // 初めての学校は一律0円対応
  selectedLessonOptions.forEach((selectedLessonOption) => {
    if (!currentSchool.is_match_accepted) {
      selectedLessonOption.amount = 0;
    }
  });
  const totalAmount = useMemo(() => {
    const optionsPrice = selectedLessonOptions.reduce(
      (sum, lessonOption) => sum + lessonOption.amount,
      0
    );
    return optionsPrice + basicCharge.price;
  }, [basicCharge.price, selectedLessonOptions]);

  // 状態を更新する必要がある場所で外部に配置します
  setTotalAmount(totalAmount);

  return { basicCharge, totalAmount, selectedLessonOptions };
};

export default usePrice;
