import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

const RequestCancelLabel: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.muted?.main,
        borderRadius: "4px",
        width: "6rem",
        padding: "0.5rem"
      }}
    >
      <Typography
        align="center"
        fontSize=".8rem"
        color={theme.palette.common.white}
        fontWeight="bold"
      >
        キャンセル
      </Typography>
    </Box>
  );
};

export default RequestCancelLabel;
