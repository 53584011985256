import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";

type EditSectionProps = {
  FacePhotoRef: React.RefObject<HTMLDivElement>;
  SchoolInfoRef: React.RefObject<HTMLDivElement>;
  SchoolTeacherInfoRef: React.RefObject<HTMLDivElement>;
  handleSectionChange: (
    editSectionRef: React.RefObject<HTMLDivElement>
  ) => void;
};

const SectionList: React.FC<EditSectionProps> = (props) => {
  const {
    FacePhotoRef,
    SchoolInfoRef,
    SchoolTeacherInfoRef,
    handleSectionChange
  } = props;

  const handleClick = (editSectionRef: React.RefObject<HTMLDivElement>) => {
    handleSectionChange(editSectionRef);
  };

  return (
    <Box sx={{ position: "fixed" }}>
      <Typography variant="h2" sx={{ margin: "1rem 0" }}>
        プロフィールを編集
      </Typography>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          padding: ".5rem 1rem"
        }}
      >
        <List>
          <ListItemButton onClick={() => handleClick(FacePhotoRef)}>
            <ListItemText>顔写真</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => handleClick(SchoolInfoRef)}>
            <ListItemText>学校情報</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => handleClick(SchoolTeacherInfoRef)}>
            <ListItemText>登録者情報</ListItemText>
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

export default SectionList;
