import { atom } from "jotai";

import type { RatingDisplayType } from "types/ratingTypes";

export const lessonRatingDisplayAtom = atom<RatingDisplayType>({
  send_school_teacher_id: 0,
  receive_teacher_id: 0,
  lesson_request_id: 0,
  details: {
    lesson_rating: "",
    lesson_rating_reason: "",
    teacher_rating: "",
    teacher_rating_reason: "",
    is_lesson_rating_public: false,
    is_teacher_rating_public: false,
    created_at: "",
    updated_at: ""
  }
});
