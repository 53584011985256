import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type LicenseItemProps = {
  name: string;
  specialSubject?: string;
};

const LicenseItem: React.FC<LicenseItemProps> = (props) => {
  const { name, specialSubject } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0 .5rem"
      }}
    >
      <CheckCircleIcon sx={{ color: theme.palette.link?.main }} />
      <div>
        <Typography fontWeight="bold">{name}</Typography>
        {specialSubject && (
          <Typography color={theme.palette.sub.main} fontSize=".9em">
            {specialSubject}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default LicenseItem;
