import Box from "@mui/material/Box";

import LessonLists from "features/schools/components/organisms/mypage/v2/lessons/LessonLists";
import LessonSelectLabels from "features/schools/components/organisms/mypage/v2/lessons/LessonSelectLabels";
import TitleBlock from "features/schools/components/organisms/mypage/v2/TitleBlock";

import type React from "react";

const Lessons: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1.5rem",
      flexGrow: 1
    }}
  >
    <TitleBlock
      title="授業進行管理"
      description="授業予定が一覧で確認できます。"
    />
    <LessonSelectLabels />
    <LessonLists />
  </Box>
);

export default Lessons;
