import { useState, useEffect } from "react";

const useIsNew = (updatedAt: Date) => {
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    const now = new Date();
    const diff = now.getTime() - updatedAt.getTime();
    setIsNew(diff < 1000 * 60 * 60 * 24 * 3);
  }, [updatedAt]);

  return isNew;
};

export default useIsNew;
