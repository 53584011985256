import { atom } from "jotai";

import { getDefaultDate } from "services/requests/getDefaultDate";
import { Methods } from "api/api/v1/lesson_requests/new";

type schoolGradesType = Methods["get"]["resBody"]["school_grades"];
type tagsType = Methods["get"]["resBody"]["tags"];
type lessonOptionsType = Methods["get"]["resBody"]["lesson_options"];
type teacherProfileType = Methods["get"]["resBody"]["teacher_profile"];

export const schoolGradesAtom = atom<schoolGradesType>([]);

export const tagsAtom = atom<tagsType>([]);

export const lessonOptionsAtom = atom<lessonOptionsType>([]);

export const teacherProfileAtom = atom<teacherProfileType>({
  teacher_id: 0,
  name: "",
  organization_name: "",
  image: {
    url: ""
  },
  tags: [],
  teacher_rank: "",
  is_able_request: false
});

// TODO: これらはRequestFormで依存関係があるので、ゆ区ゆくは削除したい
export const selectedOptionIdsAtom = atom<Array<number>>([]);
export const totalAmountAtom = atom(0);
