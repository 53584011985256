import RecruitmentCancelLabel from "features/recruitments/components/atoms/RecruitmentCancelLabel";
import RecruitmentCandidateSelectLabel from "features/recruitments/components/atoms/RecruitmentCandidateSelectLabel";
import RecruitmentFinishLabel from "features/recruitments/components/atoms/RecruitmentFinishLabel";
import RecruitmentRecruitingLabel from "features/recruitments/components/atoms/RecruitmentRecruitingLabel";

const getStatusLabel = (status: string) => {
  switch (status) {
    case "candidate_select":
      return <RecruitmentCandidateSelectLabel />;
    case "now_recruting":
      return <RecruitmentRecruitingLabel />;
    case "canceled":
    case "no_candidate":
    case "early_closed":
    case "closed":
      return <RecruitmentCancelLabel />;
    case "early_accepted":
    case "accepted":
      return <RecruitmentFinishLabel />;
    default:
      return null;
  }
};

export default getStatusLabel;
