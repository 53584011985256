import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import RecruitmentListItem from "features/teachers/components/molecules/mypage/v2/recruitments/RecruitmentListItem";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import { currentUserAtom } from "store/AuthStore";
import {
  teacherRecruitmentsAtom,
  kindOfTeacherRecruitmentsAtom
} from "store/teachers/mypage/RecruitmentsStore";

const RecruitmentList: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherRecruitments, setTeacherRecruitments] = useAtom(
    teacherRecruitmentsAtom
  );
  const [kindOfRecruitments] = useAtom(kindOfTeacherRecruitmentsAtom);
  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_recruitments`
  });

  useEffect(() => {
    const params = {
      status: kindOfRecruitments
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setTeacherRecruitments(data);
      }
    });
  }, [getRequest, kindOfRecruitments, setTeacherRecruitments]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="募集一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 13 }}>
                募集タイトル
              </Typography>
              <Typography
                fontWeight="bold"
                align="center"
                sx={{
                  flex: 4,
                  width: "100%"
                }}
              >
                ステータス
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {isLoading ? (
            <CommonLoading />
          ) : teacherRecruitments && teacherRecruitments.data.length ? (
            teacherRecruitments.data.map((teacherRecruitment, index) => (
              <React.Fragment key={index}>
                <RecruitmentListItem
                  id={teacherRecruitment.details.id}
                  title={teacherRecruitment.details.title}
                  deadline={
                    new Date(teacherRecruitment.details.recruitment_dead_at)
                  }
                  status={teacherRecruitment.details.step}
                  schoolName={teacherRecruitment.school_teacher.school_name}
                  candidateStatus={teacherRecruitment.candidate.status || ""}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <Typography color={theme.palette.sub.main}>
              募集がありません。
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentList;
