import type React from "react";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

const CorrectIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="svg"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: "absolute",
        // MEMO: 50%だと若干上にずれる
        top: "51%",
        right: ".5rem",
        transform: "translateY(-50%)",
        width: "1.5rem",
        height: "1.5rem",
        fill: theme.palette.success.main,
        stroke: theme.palette.success.main
      }}
    >
      <path
        d="m11.998 2c5.517 0 9.997 4.48 9.997 9.998 0 5.517-4.48 9.997-9.997 9.997-5.518 0-9.998-4.48-9.998-9.997 0-5.518 4.48-9.998 9.998-9.998zm0 1.5c-4.69 0-8.498 3.808-8.498 8.498s3.808 8.497 8.498 8.497 8.497-3.807 8.497-8.497-3.807-8.498-8.497-8.498z"
        fillRule="nonzero"
      />
    </Box>
  );
};

export default CorrectIcon;
