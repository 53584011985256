import React, { useEffect } from "react";

import rewards from "constants/rewards";

const useReward = (teacherRank?: string, schoolMinutes?: number) => {
  const [reward, setReward] = React.useState<number>(0);

  const getReward = (teacherRank: string, schoolMinutes: number) => {
    let reward: number;

    switch (teacherRank) {
      case "ジュニア":
        if (schoolMinutes > 120) {
          reward = rewards.JrLesson3time;
        } else if (schoolMinutes > 60) {
          reward = rewards.JrLesson2time;
        } else {
          reward = rewards.JrLesson1time;
        }
        break;
      case "ミドル":
        if (schoolMinutes > 120) {
          reward = rewards.MidLesson3time;
        } else if (schoolMinutes > 60) {
          reward = rewards.MidLesson2time;
        } else {
          reward = rewards.MidLesson1time;
        }
        break;
      case "プロ":
        if (schoolMinutes > 120) {
          reward = rewards.ProLesson3time;
        } else if (schoolMinutes > 60) {
          reward = rewards.ProLesson2time;
        } else {
          reward = rewards.ProLesson1time;
        }
        break;
      default:
        if (schoolMinutes > 120) {
          reward = rewards.JrLesson3time;
        } else if (schoolMinutes > 60) {
          reward = rewards.JrLesson2time;
        } else {
          reward = rewards.JrLesson1time;
        }
        break;
    }

    return reward;
  };

  useEffect(() => {
    setReward(getReward(teacherRank || "", schoolMinutes || 0));
  }, [schoolMinutes, teacherRank]);

  return { reward, getReward };
};

export default useReward;
