import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";
import theme from "themes/theme";
import { useAtomValue } from "jotai";
import { prefectures } from "features/teachers/stores/profileStore";
import { useFormContext } from "react-hook-form";
import ConfirmField from "features/teachers/components/molecules/profile/ConfirmField";

import { licenses } from "features/teachers/constants/profile/teachingLicenses";

const ConfirmStep02: React.FC = () => {
  const { getValues } = useFormContext();
  const prefecturesData = useAtomValue(prefectures);
  const originPrefectureName = prefecturesData?.find(
    (prefecture) =>
      prefecture.id.toString() === getValues("origin_prefecture_id").toString()
  )?.name;
  const residencePrefectureName = prefecturesData?.find(
    (prefecture) =>
      prefecture.id.toString() ===
      getValues("residence_prefecture_id").toString()
  )?.name;
  const teachingLicenses = getValues("teaching_licenses");
  const teachingLicensesValue = (
    <Box>
      {teachingLicenses
        ?.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
        .map((license: { id: number; special_subject: string }) => {
          const label = licenses.find(
            (l) => l.id.toString() === license.id.toString()
          )?.label;
          const text = license.special_subject
            ? `${label}：${license.special_subject}`
            : label;
          return <Typography key={license.id}>{text}</Typography>;
        })}
    </Box>
  );

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px"
      }}
    >
      <Typography variant="h2">経歴 / 資格</Typography>
      <ConfirmField title="出身地" value={originPrefectureName} />
      <ConfirmField title="居住地" value={residencePrefectureName} />
      <ConfirmField
        title="出身高校"
        value={getValues("origin_high_school_name") || "未記入"}
      />
      <ConfirmField
        title="出身大学"
        value={getValues("origin_school_name") || "未記入"}
      />
      <ConfirmField
        title="出身大学院等"
        value={getValues("origin_graduate_school_name") || "未記入"}
      />
      <ConfirmField
        title="お持ちの教員免許（複数選択可）と専門科目"
        value={teachingLicensesValue}
      />
      <ConfirmField
        title="その他の免許・資格"
        value={getValues("license") || "未記入"}
      />
    </Box>
  );
};

export default ConfirmStep02;
