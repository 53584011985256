import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import RatingDisplayItem from "features/requests/components/molecules/show/RatingDisplayItem";

import type React from "react";

import { RatingGet } from "api/@types";

type RatingDislayProps = {
  ratingDetails?: RatingGet["details"];
};

const RatingDislay: React.FC<RatingDislayProps> = (props) => {
  const { ratingDetails } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.backgroundColor?.main,
        margin: "1.5rem auto 1rem",
        padding: "2rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem"
      }}
    >
      <Typography align="center" fontWeight="bold" sx={{ fontSize: "1.1rem" }}>
        今回の授業についての評価
      </Typography>
      {/* MEMO: RatingDisplayItemをどこまで共通化するか検討 */}
      <RatingDisplayItem
        title="授業の満足度"
        rating={ratingDetails?.lesson_rating || 0}
        ratingReason={ratingDetails?.lesson_rating_reason || ""}
      />
      <RatingDisplayItem
        title="講師への評価"
        rating={ratingDetails?.teacher_rating || 0}
        ratingReason={ratingDetails?.teacher_rating_reason || ""}
      />
    </Box>
  );
};

export default RatingDislay;
