import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import WebsiteLinkBox from "features/teachers/components/atoms/show/v2/WebsiteLinkBox";
import { teacherInfoAtom } from "store/TeachersShowStore";

import type React from "react";

const WebsitesBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      {teacherInfo.websites.map((website, index) => (
        <WebsiteLinkBox key={index} title={website.title} url={website.url} />
      ))}
    </Box>
  );
};

export default WebsitesBox;
