import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";

import type React from "react";

type LessonSchoolDataProps = {
  schoolName: string;
  schoolImage: string;
  isCertified: boolean;
};

const LessonSchoolData: React.FC<LessonSchoolDataProps> = (props) => {
  const { schoolName, schoolImage, isCertified } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: ".5rem"
      }}
    >
      <Avatar
        src={schoolImage}
        alt={schoolName}
        sx={{
          width: "1.5rem",
          height: "1.5rem"
        }}
      />
      <Typography>{schoolName}</Typography>
      {isCertified && (
        <Box
          component="img"
          src={SchoolCertificationBadge}
          alt="認定校"
          sx={{
            display: "block",
            margin: "0 .25rem",
            height: "16px"
          }}
        />
      )}
    </Box>
  );
};

export default LessonSchoolData;
