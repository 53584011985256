import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import ActivitiesCard from "features/teachers/components/organisms/profile/edit/ActivitiesCard";
import CareerCard from "features/teachers/components/organisms/profile/edit/CareerCard";
import IntroCard from "features/teachers/components/organisms/profile/edit/IntroCard";
import IntroVideosCard from "features/teachers/components/organisms/profile/edit/IntroVideosCard";
import LicensesCard from "features/teachers/components/organisms/profile/edit/LicensesCard";
import WebsitesCard from "features/teachers/components/organisms/profile/edit/WebsiteCard";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import { FormProvider } from "react-hook-form";
import useEditPageApi from "features/teachers/hooks/profile/useEditPageApi";
import FacePhotoCard from "features/teachers/components/organisms/profile/edit/FacePhotoCard";
import LoadingWithBackDrop from "components/molecules/LoadingWithBackDrop";

const Edit: React.FC = () => {
  const theme = useTheme();
  const {
    methods,
    updateProfile,
    isLoading,
    postTeacherVideo,
    removeTeacherVideo,
    postIsLoading,
    removeIsLoading
  } = useEditPageApi();
  const onSumbmit = methods.handleSubmit(updateProfile);

  return (
    <CommonLayout>
      <Meta title="プロフィール編集" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          boxSizing: "border-box",
          padding: "1.5rem 0",
          minHeight: "100%"
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={onSumbmit}>
            {!isLoading ? (
              <Container
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem"
                }}
              >
                <Typography variant="h2">プロフィール編集</Typography>
                <Box
                  sx={{
                    flex: 1,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem"
                  }}
                >
                  <FacePhotoCard />
                  <IntroCard />
                  <IntroVideosCard
                    postTeacherVideo={postTeacherVideo}
                    removeTeacherVideo={removeTeacherVideo}
                  />
                  <ActivitiesCard />
                  <CareerCard />
                  <LicensesCard />
                  <WebsitesCard />
                </Box>
              </Container>
            ) : (
              <Loading headerOnly />
            )}
          </form>
        </FormProvider>
      </Box>
      <LoadingWithBackDrop
        isSubmitting={postIsLoading || removeIsLoading}
        message={
          postIsLoading && (
            <Typography
              color={theme.palette.common.white}
              align="center"
              fontSize=".9rem"
            >
              アップロード中‥
              <br />
              時間がかかる場合があります
            </Typography>
          )
        }
      />
    </CommonLayout>
  );
};

export default Edit;
