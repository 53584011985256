import React from "react";
import { useTheme, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type LabelConfigKey = "mutedMain" | "secondaryMain" | "primaryMain";
interface LabelConfig {
  backgroundColor: LabelConfigKey;
  text: string;
}

const LABEL_CONFIG: Record<string, LabelConfig> = {
  RecruitmentCancelLabel: {
    backgroundColor: "mutedMain",
    text: "キャンセル"
  },
  CandidateCancelLabel: {
    backgroundColor: "mutedMain",
    text: "応募キャンセル"
  },
  RecruitmentWaitingForResultLabel: {
    backgroundColor: "secondaryMain",
    text: "結果待ち"
  },
  RecruitmentDeniedLabel: {
    backgroundColor: "mutedMain",
    text: "お見送り"
  },
  RecruitmentAdoptedLabel: {
    backgroundColor: "primaryMain",
    text: "採用"
  }
};

interface LabelProps {
  labelType: keyof typeof LABEL_CONFIG;
}

const StatusLabel: React.FC<LabelProps> = ({ labelType }) => {
  const theme = useTheme<Theme>();
  const { backgroundColor, text } = LABEL_CONFIG[labelType];

  const getBackgroundColor = (colorKey: LabelConfigKey) => {
    switch (colorKey) {
      case "mutedMain":
        return theme.palette.muted?.main;
      case "secondaryMain":
        return theme.palette.secondary.main;
      case "primaryMain":
        return theme.palette.primary.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(backgroundColor),
        borderRadius: "4px",
        width: "125px",
        padding: "0.5rem"
      }}
    >
      <Typography
        align="center"
        fontSize=".8rem"
        color={theme.palette.common.white}
        fontWeight="bold"
      >
        {text}
      </Typography>
    </Box>
  );
};

export const getStatusLabelForTeacher = (
  status: string,
  candidateStatus: string
) => {
  switch (status) {
    case "canceled":
    case "no_candidate":
    case "early_closed":
    case "closed":
      return <StatusLabel labelType="RecruitmentCancelLabel" />;
    case "candidate_select":
    case "now_recruting":
      if (candidateStatus === "candidate_canceled") {
        return <StatusLabel labelType="CandidateCancelLabel" />;
      }
      return <StatusLabel labelType="RecruitmentWaitingForResultLabel" />;
    case "early_accepted":
    case "accepted":
      if (candidateStatus === "accepted") {
        return <StatusLabel labelType="RecruitmentAdoptedLabel" />;
      }
      return <StatusLabel labelType="RecruitmentDeniedLabel" />;
    default:
      return null;
  }
};

export default getStatusLabelForTeacher;
