import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Close from "@mui/icons-material/Close";

import ModalTransition from "components/organisms/ModalTransition";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import type { timelineType } from "types/teacherInfoType";

type TimelinesModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timelines: timelineType[];
};

const TimelinesModal: React.FC<TimelinesModalProps> = (props) => {
  const { open, setOpen, timelines } = props;
  const inXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={ModalTransition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="md"
      fullScreen={inXs}
      sx={{
        paddingTop: { xs: "15vh", sm: 0 },
        "& .MuiPaper-root": {
          minWidth: { xs: "auto", sm: "420px" },
          position: "relative",
          padding: ".5rem",
          borderTopLeftRadius: { xs: "16px", sm: 0 },
          borderTopRightRadius: { xs: "16px", sm: 0 }
        }
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px"
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="h4">授業の時間配分</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem 0"
          }}
        >
          {timelines.map((timeline, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem"
              }}
            >
              <Typography sx={{ flex: 1 }}>
                <Typography
                  component="span"
                  fontSize="1.2rem"
                  sx={{
                    marginRight: ".2rem"
                  }}
                >
                  {timeline.minutes}
                </Typography>
                <Typography component="span" fontSize=".8rem">
                  分
                </Typography>
              </Typography>
              <Typography fontWeight="bold" sx={{ flex: 5 }}>
                {timeline.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TimelinesModal;
