import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useAtomValue } from "jotai";

import { teacherBankAccountAtom } from "store/BankAccountStore";
import { requestObjectAtom } from "store/RequestShowStore";

import type React from "react";

const Step03Text: React.FC = () => {
  const theme = useTheme();
  const requestObject = useAtomValue(requestObjectAtom);
  const teacherBankAccount = useAtomValue(teacherBankAccountAtom);

  const transferTiming =
    (new Date(requestObject.exact_start_at).getMonth() + 2) % 12 || 12;

  return (
    <Box sx={{ margin: "0 auto 1rem" }}>
      <Typography>授業お疲れ様でした！</Typography>
      {requestObject.reward !== 0 &&
        (teacherBankAccount.id === 0 ||
        !Object.keys(teacherBankAccount).length ? (
          <Typography>
            報酬振込先の口座が登録されていません。
            <Link
              href="/teachers/mypage/settings?accordion=bank-account"
              sx={{
                color: theme.palette.link?.main,
                textDecoration: "none",
                fontWeight: "bold"
              }}
            >
              アカウント設定
            </Link>
            から口座の登録をお願いします。
          </Typography>
        ) : (
          <>
            <Typography>
              報酬は以下の口座に、{transferTiming}月末に振り込まれます。
            </Typography>
            <Typography
              fontSize="1.1rem"
              fontWeight="bold"
              sx={{ margin: ".5rem auto" }}
            >
              {teacherBankAccount.financial_institution_name}***
              {teacherBankAccount.account_number_last_four}
            </Typography>
          </>
        ))}
      <Typography>最後に、授業後アンケートにご協力ください。</Typography>
    </Box>
  );
};

export default Step03Text;
