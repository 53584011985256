import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";
import Button from "components/atoms/mui/Button";
import { Methods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts";

type BankAccountDisplayProps = {
  handleDelete: () => void;
  teacherBankAccount: Methods["get"]["resBody"]["teacher_bank_account"];
};

const BankAccountDisplay: React.FC<BankAccountDisplayProps> = (props) => {
  const { handleDelete, teacherBankAccount } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <Typography>登録済みの銀行口座</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem"
        }}
      >
        <Typography fontSize="1.1rem" fontWeight="bold">
          {teacherBankAccount.financial_institution_name}***
          {teacherBankAccount.account_number_last_four}
        </Typography>
        <Button isLink onClick={handleDelete}>
          削除する
        </Button>
      </Box>
    </Box>
  );
};

export default BankAccountDisplay;
