import { useCallback } from "react";

import type { requestObjectAtomType } from "types/LessonRequestTypes";

const useActiveStepCheck = () => {
  const getActiveStep = useCallback((request: requestObjectAtomType) => {
    let activeStep = 0;

    switch (request.step) {
      case "lesson_request":
      case "canceled":
      case "denied":
      case "dead":
        activeStep = 0;
        break;
      case "preparation":
      case "lesson_end":
        activeStep = 1;
        break;
      case "completed":
        activeStep = 2;
        break;
      default:
        activeStep = 0;
        break;
    }

    return activeStep;
  }, []);

  return { getActiveStep };
};

export default useActiveStepCheck;
