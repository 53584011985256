import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps
} from "@mui/material";

type CustomButtonProps = {
  isLink?: boolean;
} & MuiButtonProps;

const Button = (props: CustomButtonProps) => (
  <MuiButton
    variant={props.isLink ? "text" : "contained"}
    color={props.isLink ? "secondary" : "primary"}
    sx={{
      textDecoration: props.isLink ? "underline" : "none",
      padding: props.isLink
        ? "3px 6px"
        : props.size === "small"
          ? "8px 16px"
          : "12px 24px"
    }}
    {...props}
  />
);

export default Button;
