import useFormatDateTime from "./useFormatDateTime";

const useLimitCheck = () => {
  const { getFormatDateTime } = useFormatDateTime();

  const getLimit = (acceptDeadAt: string) => {
    const today = new Date(getFormatDateTime(new Date()));
    const todayForLimit = today.setDate(today.getDate());
    let dead = false;

    acceptDeadAt = acceptDeadAt.replace(/-/g, "/");
    const limit =
      Math.floor(
        (new Date(acceptDeadAt).getTime() - todayForLimit) / 86400000
      ) + 2;

    if (limit < 0) {
      dead = true;
    } else {
      dead = false;
    }

    return { limit, dead };
  };

  return { getLimit };
};

export default useLimitCheck;
