import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import lodash from "lodash";

import type React from "react";
import { useFormContext } from "react-hook-form";

type SiteCardProps = {
  removeSite: () => void;
  order: number;
};

const SiteCard: React.FC<SiteCardProps> = (props) => {
  const { removeSite, order } = props;
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const theme = useTheme();

  const titleError = lodash.get(errors, `websites[${order}].title`);
  const urlError = lodash.get(errors, `websites[${order}].url`);

  return (
    <Card
      elevation={0}
      sx={{
        margin: "1rem auto",
        padding: "1rem 1.5rem",
        backgroundColor: theme.palette.backgroundColor?.main
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          "&:last-child": {
            padding: 0
          }
        }}
      >
        <Box sx={{ margin: ".5rem auto" }}>
          <Typography>サイト名</Typography>
          <TextField
            {...register(`websites[${order}].title`)}
            variant="outlined"
            placeholder="例：Twitter"
            fullWidth
            error={!!titleError}
            helperText={titleError?.message?.toString()}
          />
        </Box>
        <Box sx={{ margin: ".5rem auto" }}>
          <Typography>URL</Typography>
          <TextField
            {...register(`websites[${order}].url`)}
            variant="outlined"
            placeholder="例：https://www.twitter.com/******"
            fullWidth
            error={!!urlError}
            helperText={urlError?.message?.toString()}
          />
        </Box>
        {order !== 0 && (
          <CardActions>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              onClick={removeSite}
              sx={{
                color: theme.palette.sub.main
              }}
            >
              削除する
            </Button>
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
};

export default SiteCard;
