import { useState, useEffect, useMemo } from "react";

import JrBadge from "assets/JrBadge.png";
import MidBadge from "assets/MidBadge.png";
import ProBadge from "assets/ProBadge.png";

const useTeacherRankBadge = (rank: string) => {
  const memoizeRank = useMemo(() => rank, [rank]);
  const [teacherRankBadge, setTeacherRankBadge] = useState({
    alt: "",
    src: ""
  });

  useEffect(() => {
    switch (memoizeRank) {
      case "ジュニア":
        setTeacherRankBadge({ alt: "ジュニア", src: JrBadge });
        break;
      case "ミドル":
        setTeacherRankBadge({ alt: "ミドル", src: MidBadge });
        break;
      case "プロ":
        setTeacherRankBadge({ alt: "プロ", src: ProBadge });
        break;
      default:
        setTeacherRankBadge({ alt: "", src: "" });
        break;
    }
  }, [memoizeRank, rank]);

  return teacherRankBadge;
};

export default useTeacherRankBadge;
