import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Chip from "@mui/material/Chip";

import type React from "react";

type FormLavelProps = {
  labelName: string;
};

const OptionalFormLabel: React.FC<FormLavelProps> = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        margin: ".75rem auto",
        width: "100%"
      }}
    >
      <Chip
        label="任意"
        size="small"
        sx={{
          borderRadius: "4px",
          fontWeight: 600,
          fontSize: ".8rem",
          color: "#ffffff",
          backgroundColor: theme.palette.muted?.main
        }}
      />
      <Box
        component="label"
        sx={{
          fontWeight: 600,
          margin: "0 .6rem",
          fontSize: "1rem"
        }}
      >
        {props.labelName}
      </Box>
    </Box>
  );
};

export default OptionalFormLabel;
