import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { useAtom } from "jotai";

import FormTextField from "components/molecules/FormTextField";
import FormSelect from "components/molecules/FormSelect";
import FormChipGroup from "components/molecules/FormChipGroup";
import { prefecturesData, organizationCategoriesData } from "store/MasterData";

import type { Theme } from "@mui/material";
import type React from "react";

type SearchFormProps = {
  reset: () => void;
};

const SearchForm: React.FC<SearchFormProps> = (props) => {
  const { reset } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [prefectures] = useAtom(prefecturesData);
  const [organizationCategories] = useAtom(organizationCategoriesData);

  const prefectureOptions = prefectures.map((prefecture) => ({
    value: prefecture.id.toString(),
    label: prefecture.name
  }));

  const organizationCategoryOptions = organizationCategories.map(
    (category) => ({
      value: category.id.toString(),
      label: category.name
    })
  );

  if (isMobile) {
    return (
      <Accordion elevation={0} square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={theme.palette.secondary.main} fontWeight="bold">
            <SearchIcon
              sx={{
                marginRight: ".5rem",
                verticalAlign: "middle"
              }}
            />
            詳細検索はこちら
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            <FormTextField
              name="text"
              label="キーワード"
              placeholder="例）キャリア教育、プログラミング"
              noLabel
            />
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                width: "100%"
              }}
            >
              <FormTextField
                name="former_school"
                label="出身高校、出身大学"
                placeholder="例）○○高校、○○大学"
                noLabel
              />
              <FormSelect
                name="prefecture_id"
                label="都道府県"
                options={prefectureOptions}
                noLabel
                width="auto"
              />
            </Box>
            <FormChipGroup
              name="tag[]"
              label="授業ができるジャンル"
              options={organizationCategoryOptions}
              noLabel
              multiple
              noWrap
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: { xs: ".5rem", sm: "1rem" },
                width: "100%",
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
                startIcon={<SearchIcon />}
                sx={{
                  fontWeight: 600,
                  flex: 1
                }}
                type="submit"
              >
                検索
              </Button>
              <Button
                variant="text"
                color="inherit"
                disableElevation
                startIcon={<DeleteIcon />}
                sx={{
                  alignSelf: "flex-end",
                  height: { xs: "auto", sm: "3rem" },
                  color: theme.palette.sub.main
                }}
                onClick={reset}
              >
                条件をクリア
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1rem",
        padding: "1.5rem",
        margin: ".5rem auto",
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white
      }}
    >
      <FormTextField
        name="text"
        label="キーワード"
        placeholder="例）キャリア教育、プログラミング"
        noLabel
      />
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "100%"
        }}
      >
        <FormTextField
          name="former_school"
          label="出身高校、出身大学"
          placeholder="例）○○高校、○○大学"
          noLabel
        />
        <FormSelect
          name="prefecture_id"
          label="都道府県"
          options={prefectureOptions}
          noLabel
        />
      </Box>
      <FormChipGroup
        name="tag[]"
        label="授業ができるジャンル"
        options={organizationCategoryOptions}
        noLabel
        multiple
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: { xs: ".5rem", sm: "1rem" },
          width: "100%",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          startIcon={<SearchIcon />}
          sx={{
            fontWeight: 600,
            flex: 1
          }}
          type="submit"
        >
          複業先生を探す
        </Button>
        <Button
          variant="text"
          color="inherit"
          disableElevation
          startIcon={<DeleteIcon />}
          sx={{
            alignSelf: "flex-end",
            height: { xs: "auto", sm: "3rem" },
            color: theme.palette.sub.main
          }}
          onClick={reset}
        >
          条件をクリア
        </Button>
      </Box>
    </Box>
  );
};

export default SearchForm;
