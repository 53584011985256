import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";
import IndicatePrivate from "../../../../../components/atoms/fieldLabels/IndicatePrivate";
import IndicatePublic from "../../../../../components/atoms/fieldLabels/IndicatePublic";

type StepTitleProps = {
  stepName: string[];
  indicateStatus: "public" | "private" | "none";
  supplementMsg?: string;
};

const StepTitle: React.FC<StepTitleProps> = ({
  stepName,
  indicateStatus,
  supplementMsg
}) => (
  <Box sx={{ margin: ".5rem 0 1.5rem" }}>
    <Typography variant="h2">
      {stepName.map((element, index) => (
        <Typography
          component="span"
          key={index}
          fontWeight="bold"
          fontSize="1.2rem"
          sx={{ display: "inline-block", lineHeight: "1.5", margin: ".5rem 0" }}
        >
          {element}
        </Typography>
      ))}
    </Typography>
    {supplementMsg && (
      <Typography
        variant="body1"
        sx={{ margin: ".2rem auto", fontSize: "1em" }}
      >
        {supplementMsg}
      </Typography>
    )}
    {indicateStatus === "public" && <IndicatePublic />}
    {indicateStatus === "private" && <IndicatePrivate />}
  </Box>
);

export default StepTitle;
