import Box from "@mui/material/Box";
import React from "react";

import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import { useDialog } from "hooks/useDialog";
import Button from "components/atoms/mui/Button";
import SelectBankModal from "components/organisms/SelectBankModal";
import { useFormContext } from "react-hook-form";
import SelectBankBranchModal from "components/organisms/SelectBankBranchModal";

type ProfileContentProps = {
  name: string;
  codeName: string;
  label: string;
  type: "bank" | "branch";
  institutionCode?: string;
};

const BankAccountField: React.FC<ProfileContentProps> = (props) => {
  const { name, codeName, label, type, institutionCode } = props;
  const selectModal = useDialog();

  const { watch, setValue } = useFormContext();
  const title = watch(name);

  const handleSelect = (value: { code: string; name: string }) => {
    setValue(name, value.name, {
      shouldDirty: true
    });
    setValue(codeName, value.code, {
      shouldDirty: true
    });
    selectModal.onClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold"
        }}
        variant="body1"
      >
        {label}
      </Typography>

      {title && (
        <Typography fontSize="1.1rem" fontWeight="bold">
          {title}
        </Typography>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={selectModal.onOpen}
        sx={{ height: "3rem" }}
        disableElevation
        startIcon={<AddIcon />}
        disabled={type === "branch" && !institutionCode}
      >
        {type === "bank" ? "金融機関を選択" : "支店を選択"}
      </Button>

      {type === "bank" && (
        <SelectBankModal
          isOpen={selectModal.isOpen}
          onClose={selectModal.onClose}
          handleSelect={handleSelect}
        />
      )}

      {type === "branch" && institutionCode && (
        <SelectBankBranchModal
          isOpen={selectModal.isOpen}
          onClose={selectModal.onClose}
          handleSelect={handleSelect}
          institutionCode={institutionCode}
        />
      )}
    </Box>
  );
};

export default BankAccountField;
