import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

import { eLearningResponseAtom } from "store/ELearningStore";

import type React from "react";

const ResultDisplayBlock: React.FC = () => {
  const [eLearningResponse] = useAtom(eLearningResponseAtom);

  return (
    <Box
      sx={{
        margin: "2.5rem auto",
        maxWidth: 360,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1.5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography align="center">あなたの結果は...</Typography>
        {eLearningResponse.is_pass ? (
          <>
            <Typography align="center" fontWeight="bold" fontSize="2rem">
              合格
            </Typography>
            <Typography align="center">
              おめでとうございます。
              <br />
              ジュニアランクになりました！
              <br />
              次はミドルランクを目指しましょう。
            </Typography>
          </>
        ) : (
          <>
            <Typography align="center" fontWeight="bold" fontSize="2rem">
              不合格
            </Typography>
            <Typography align="center">
              e-Learningは合格するまで何度も受講できます。
              <br />
              合格を目指して頑張りましょう。
            </Typography>
          </>
        )}
      </Box>
      {!eLearningResponse.is_pass && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          component={Link}
          to="/teachers/mypage/e-learnings/new"
          sx={{
            height: "3rem",
            fontWeight: "bold",
            maxWidth: 240
          }}
        >
          もう一度受講する
        </Button>
      )}
      <Button
        variant="text"
        color="secondary"
        component={Link}
        to="/teachers/mypage"
        sx={{
          padding: ".25rem 1rem"
        }}
      >
        マイページに戻る
      </Button>
    </Box>
  );
};

export default ResultDisplayBlock;
