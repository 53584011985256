import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useLocation, useHistory, Link } from "react-router-dom";

import HeaderLogo from "components/atoms/HeaderLogo";
import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";
import Button from "components/atoms/mui/Button";

type DrawerProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderSp: React.FC<DrawerProps> = (props) => {
  const { isOpen, setIsOpen } = props;
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const handleToSignup = () => {
    history.push("/signup");
  };

  const handleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color={location.pathname === "/" ? "transparent" : "inherit"}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <Box
            component={Link}
            to="/"
            sx={{
              display: "flex",
              textDecoration: "none",
              color: theme.palette.textColor.main
            }}
          >
            <Box sx={{ width: "120px" }}>
              <HeaderLogo fullWidth />
            </Box>
          </Box>
          {(schoolIsLoggedIn || !isLoggedIn) && (
            <Box
              component={Link}
              to="/teachers"
              sx={{
                display: "flex",
                textDecoration: "none",
                color: theme.palette.textColor.main,
                margin: "0 1rem",
                alignItems: "center",
                fontSize: ".9rem"
              }}
            >
              <SearchIcon
                sx={{
                  display: "block",
                  margin: ".2rem",
                  fontSize: "inherit"
                }}
              />
              <Typography color="inherit" fontSize="inherit">
                {isLoggedIn || schoolIsLoggedIn
                  ? "複業先生を探す"
                  : "先生を探す"}
              </Typography>
            </Box>
          )}
          {isLoggedIn && !schoolIsLoggedIn && (
            <Box
              component={Link}
              to="/recruitments"
              sx={{
                display: "flex",
                textDecoration: "none",
                color: theme.palette.textColor.main,
                margin: "0 1rem",
                alignItems: "center",
                fontSize: ".9rem"
              }}
            >
              <SearchIcon
                sx={{
                  display: "block",
                  margin: ".2rem",
                  fontSize: "inherit"
                }}
              />
              <Typography color="inherit" fontSize="inherit">
                授業の募集を探す
              </Typography>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {!isLoggedIn && !schoolIsLoggedIn && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="small"
              onClick={handleToSignup}
              sx={{ padding: "4px", margin: 0 }}
            >
              登録
            </Button>
          )}
          <IconButton onClick={handleDrawer}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderSp;
