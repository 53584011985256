import { useAtomValue } from "jotai";
import { Route, Redirect } from "react-router-dom";

import { isLoggedInAtom } from "store/AuthStore";

function GuestRoute(props: any) {
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  if (isLoggedIn) {
    return <Redirect to="/teachers/mypage" />;
  }
  return <Route {...props} />;
}

export default GuestRoute;
