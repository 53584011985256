import { atom } from "jotai";

import type { schoolRequestResponseType } from "types/lessonRecruitmentsTypes";

export type kindOfSchoolLessonsType = "accepted" | "preparation" | "lesson_end";

export const schoolLessonsAtom = atom<schoolRequestResponseType | null>(null);

export const kindOfSchoolLessonsAtom =
  atom<kindOfSchoolLessonsType>("accepted");
