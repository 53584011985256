import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import { currentUserAtom } from "store/AuthStore";
import {
  eLearningAnswersAtom,
  eLearningResponseAtom,
  eLearningStatusAtom,
  resetELearningAnswersAtom,
  resetELearningResponseAtom
} from "store/ELearningStore";
import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";
import { useForm } from "react-hook-form";
import { Methods as PostMethods } from "api/api/v1/teachers/_teacherId@string/e_learnings";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import newELearningFormSchema from "features/teachers/constants/profile/newELearningFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const useELearningPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const postElerningForm = useForm<PostMethods["post"]["reqBody"]>({
    defaultValues: {
      // 固定値
      version: 1
    },
    resolver: yupResolver(newELearningFormSchema)
  });
  const [, setELearningResponse] = useAtom(eLearningResponseAtom);
  const [, setELearningStatus] = useAtom(eLearningStatusAtom);
  const [, setElerningAnswers] = useAtom(eLearningAnswersAtom);
  const [, setResetELearningAnswers] = useAtom(resetELearningAnswersAtom);
  const [, setResetELearningResponse] = useAtom(resetELearningResponseAtom);
  const [currentUser] = useAtom(currentUserAtom);
  const history = useHistory();

  useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(currentUser.id.toString())
      .e_learnings.new,
    {
      onSuccess: (response) => {
        setELearningStatus(response);
        setResetELearningAnswers();
        setResetELearningResponse();
      },
      onError: () => {
        addErrorMessage("e-Learningは受験できません");
        history.push("/teachers/mypage");
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const postELearning = async () => {
    try {
      const params = postElerningForm.getValues();
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(currentUser.id.toString())
        .e_learnings.post({ body: params });
      if (response.status === 200) {
        setELearningResponse(response.body);
        if (params.answers) {
          setElerningAnswers(params.answers);
        }
        history.push(`/teachers/mypage/e-learnings/result`);
      }
    } catch (error) {
      addErrorMessage("解答の送信に失敗しました。");
    }
  };

  return { postELearning, postElerningForm };
};

export default useELearningPageApi;
