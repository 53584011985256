import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import HasTeacherLabel from "features/teachers/components/atoms/show/v2/HasTeacherLabel";
import RequestButton from "features/teachers/components/atoms/show/v2/RequestButton";
import PriceBox from "features/teachers/components/molecules/show/v2/PriceBox";
import RatingBox from "features/teachers/components/molecules/show/v2/RatingBox";
import TeacherImageBox from "features/teachers/components/molecules/show/v2/TeacherImageBox";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import { teacherInfoAtom, ratingAtom } from "store/TeachersShowStore";

import type React from "react";
import useQuery from "hooks/useQuery";

const BasicInfoBox: React.FC = () => {
  const theme = useTheme();
  const teacherInfo = useAtomValue(teacherInfoAtom);
  const rating = useAtomValue(ratingAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const { getQuery } = useQuery();
  const isCandidateDisplay = getQuery("role") === "candidate_display";

  return (
    <Box
      component="section"
      sx={{
        margin: { xs: "1rem 0", md: "2rem 0" },
        padding: { xs: "0 1rem", md: "inherit" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: { xs: "0 auto .5rem", md: "inherit" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0 1.5rem",
            flexGrow: 1
          }}
        >
          <TeacherImageBox
            teacherName={teacherInfo.name}
            teacherRank={teacherInfo.teacher_rank}
            teacherImageUrl={teacherInfo.image.url}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem 0"
            }}
          >
            <Typography color={theme.palette.sub.main}>
              {teacherInfo.organization_name}
            </Typography>
            <Typography
              variant="h2"
              fontSize="1.5rem"
              sx={{ lineHeight: "1.5" }}
            >
              {teacherInfo.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              {teacherInfo.teaching_licenses.length !== 0 && (
                <HasTeacherLabel labelText="教員免許あり" />
              )}
              {teacherInfo.is_lesson_experience === true && (
                <HasTeacherLabel labelText="授業登壇経験あり" />
              )}
            </Box>
            {Object.keys(rating || {}).length !== 0 && (
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <RatingBox
                  totalRating={(rating?.total_rating || 0) / 10}
                  ratingCount={rating?.rating_count || 0}
                />
              </Box>
            )}
          </Box>
        </Box>
        {!isCandidateDisplay && (
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "320px" }}>
            {schoolIsLoggedIn && <PriceBox />}
            <RequestButton />
          </Box>
        )}
      </Box>
      {Object.keys(rating || {}).length !== 0 && (
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <RatingBox
            totalRating={(rating?.total_rating || 0) / 10}
            ratingCount={rating?.rating_count || 0}
          />
        </Box>
      )}
    </Box>
  );
};

export default BasicInfoBox;
