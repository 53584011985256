import { atom } from "jotai";

import type { commonRecruitmentResponseType } from "types/lessonRecruitmentsTypes";
import type { teacherRequestsResponseType } from "types/LessonRequestTypes";

export const teacherRequestsAtom = atom<teacherRequestsResponseType | null>(
  null
);

export const teacherLessonsAtom = atom<teacherRequestsResponseType | null>(
  null
);

export const teacherRecruitmentsAtom =
  atom<commonRecruitmentResponseType | null>(null);
