import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import RecruitmentListItem from "features/recruitments/components/molecules/index/RecruitmentListItem";
import RecruitmentListItemForGuest from "features/recruitments/components/molecules/index/RecruitmentListItemForGuest";
import RecruitmentsCount from "features/recruitments/components/molecules/index/RecruitmentsCount";
import ToRegisterCover from "features/recruitments/components/molecules/index/ToRegisterCover";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import useQuery from "hooks/useQuery";
import { isLoggedInAtom } from "store/AuthStore";
import {
  commonRecruitmentsAtom,
  paginationAtom,
  pageAtom
} from "store/recruitments/IndexStore";

import type React from "react";

const RecruitmentList: React.FC = () => {
  const [commonRecruitments, setCommonRecruitments] = useAtom(
    commonRecruitmentsAtom
  );
  const [, setPagination] = useAtom(paginationAtom);
  const [page] = useAtom(pageAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);

  const { getQuery } = useQuery();
  const pageQuery = getQuery("page");

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: "/api/api/v1/lesson_recruitments"
  });

  useEffect(() => {
    getRequest({
      params: {
        page: Number(pageQuery) | page
      },
      onSuccess: (data) => {
        setCommonRecruitments(data);
        setPagination(data.pagination);
      }
    });
  }, [getRequest, page, pageQuery, setCommonRecruitments, setPagination]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="募集情報の取得に失敗しました"
      />
      <Box
        component="section"
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: "100%"
        }}
      >
        <RecruitmentsCount />
        {!isLoading ? (
          commonRecruitments?.data.length === 0 ? (
            <Typography>現在募集中の授業はありません</Typography>
          ) : (
            commonRecruitments?.data.map((recruitment, index) => {
              if (isLoggedIn) {
                return (
                  <RecruitmentListItem
                    key={index}
                    id={recruitment.details.id}
                    title={recruitment.details.title}
                    schoolName={recruitment.school_teacher.school_name}
                    isOnline={recruitment.details.is_online}
                    tagName={recruitment.details.tag.name}
                    schoolAddress={
                      recruitment.school_teacher.school_prefecture_name
                    }
                    recruitmentDeadAt={recruitment.details.recruitment_dead_at}
                    schoolGradeName={recruitment.details.school_grade_name}
                    schoolMinutes={recruitment.details.school_minutes}
                    studentNumber={recruitment.details.student_number}
                    updatedAt={recruitment.details.updated_at}
                    proposedDates={recruitment.details.proposed_dates.data}
                    step={recruitment.details.step}
                  />
                );
              }
              return (
                <RecruitmentListItemForGuest
                  key={index}
                  id={recruitment.details.id}
                  title={recruitment.details.title}
                  isOnline={recruitment.details.is_online}
                  tagName={recruitment.details.tag.name}
                  schoolPrefectureName={
                    recruitment.school_teacher.school_prefecture_name
                  }
                  schoolTypeName={recruitment.school_teacher.school_type_name}
                  schoolDivisionName={
                    recruitment.school_teacher.school_division_name
                  }
                  recruitmentDeadAt={recruitment.details.recruitment_dead_at}
                  schoolGradeName={recruitment.details.school_grade_name}
                  schoolMinutes={recruitment.details.school_minutes}
                  studentNumber={recruitment.details.student_number}
                  updatedAt={recruitment.details.updated_at}
                  proposedDates={recruitment.details.proposed_dates.data}
                  step={recruitment.details.step}
                />
              );
            })
          )
        ) : (
          <CommonLoading />
        )}
        {!isLoggedIn && !isLoading && commonRecruitments?.data.length && (
          <ToRegisterCover />
        )}
      </Box>
    </>
  );
};

export default RecruitmentList;
