import { useState, useEffect, useCallback } from "react";

const useLimit = (deadAt: Date, addDay?: number) => {
  const [limit, setLimit] = useState(0);
  const [dead, setDead] = useState(false);

  const getLimit = useCallback(() => {
    const today = new Date();
    const deadAtForLimit = new Date(
      new Date(deadAt).setDate(new Date(deadAt).getDate() + (addDay || 0))
    );
    let dead = false;

    const limit = Math.floor(
      (deadAtForLimit.getTime() - today.getTime()) / 86400000
    );

    if (limit < 1) {
      dead = true;
    } else {
      dead = false;
    }

    setDead(dead);
    setLimit(limit);

    return { limit, dead };
  }, [deadAt, addDay]);

  useEffect(() => {
    getLimit();
  }, [deadAt, getLimit]);

  return { getLimit, limit, dead };
};

export default useLimit;
