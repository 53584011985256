import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import HeaderLogo from "components/atoms/HeaderLogo";
import SpDrawerContentsGuest from "components/molecules/SpDrawerContentsGuest";
import SpDrawerContentsSchool from "components/molecules/SpDrawerContentsSchool";
import SpDrawerContentsTeacher from "components/molecules/SpDrawerContentsTeacher";
import usePageTransitionEffect from "hooks/usePageTransitionEffect";
import toggleDrawer from "components/organisms/toggleDrawer";
import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";

type DrawerProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SpDrawer: React.FC<DrawerProps> = (props) => {
  const { isOpen, setIsOpen } = props;
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  usePageTransitionEffect(() => setIsOpen(false));

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={toggleDrawer(() => setIsOpen(false))}
      onOpen={toggleDrawer(() => setIsOpen(true))}
      sx={{ width: "auto" }}
    >
      <Box
        sx={{
          width: "72vw",
          minWidth: "260px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "block",
              margin: "0.5rem 1rem 0",
              width: "120px"
            }}
          >
            <HeaderLogo fullWidth />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
        <Box
          sx={{
            width: "90%",
            margin: "1rem auto 0"
          }}
        >
          {isLoggedIn && <SpDrawerContentsTeacher />}
          {schoolIsLoggedIn && <SpDrawerContentsSchool />}
          {!isLoggedIn && !schoolIsLoggedIn && <SpDrawerContentsGuest />}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default SpDrawer;
