import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

import type React from "react";

type CommonSidebarItemProps = {
  text: string;
  Icon: React.ReactElement;
  link?: string;
  selected?: boolean;
  onClick?: () => void;
};

const CommonSidebarItem: React.FC<CommonSidebarItemProps> = (props) => {
  const { text, Icon, link, selected, onClick } = props;

  return (
    <ListItemButton component={link ? Link : "div"} to={link} onClick={onClick}>
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText
        primary={text}
        sx={{ fontWeight: selected ? "bold" : "normal" }}
      />
    </ListItemButton>
  );
};

export default CommonSidebarItem;
