import { useCallback } from "react";

const useConvertFileSize = () => {
  const convertFileSize = useCallback((size: number) => {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let convertedSize = size;
    let unitIndex = 0;
    while (convertedSize > 1024) {
      convertedSize /= 1024;
      unitIndex++;
    }
    return `${Math.round(convertedSize * 100) / 100}${units[unitIndex]}`;
  }, []);

  return convertFileSize;
};

export default useConvertFileSize;
