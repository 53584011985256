import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

import type React from "react";

type CreateAcountButtonProps = {
  noLabel?: boolean;
};

const CreateAcountButton: React.FC<CreateAcountButtonProps> = (props) => {
  const { noLabel } = props;
  const history = useHistory();

  const handleToSchoolSignup = () => {
    history.push("/signup?active=school");
  };

  return (
    <div>
      {!noLabel && (
        <Typography align="center" fontSize=".9rem">
          ＼ 3分でかんたん！／
        </Typography>
      )}
      <Button
        color="primary"
        variant="contained"
        disableElevation
        onClick={handleToSchoolSignup}
        sx={{
          display: "block",
          width: "100%",
          height: "64px",
          fontWeight: 600,
          margin: ".5rem auto"
        }}
      >
        まずは教員アカウント作成（無料）
      </Button>
    </div>
  );
};

export default CreateAcountButton;
