import YouTube from "react-youtube";

// MEMO: Box componentの引数にYouTube要素が渡せないため、別途cssでスタイリング
import "themes/ReactYoutube.css";

import type React from "react";

const Video: React.FC = () => (
  <YouTube videoId="X6SP3edBXHE" className="reactYoutube" />
);

export default Video;
