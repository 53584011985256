import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

import RequestStepperBg from "assets/RequestStepperBg.png";
import PreListItem from "features/requests/components/atoms/show/PreListItem";
import PreListItemWithLink from "features/requests/components/atoms/show/PreListItemWithLink";
import ACCListItem from "features/teachers/components/atoms/show/requests/ACCListItem";

import { LessonRequestTeacher, AgendaSheetNew } from "api/@types";

type PreparationListProps = {
  details?: LessonRequestTeacher["details"];
  meetingSheetDetails?: AgendaSheetNew["details"];
};

const PreparationList: React.FC<PreparationListProps> = (props) => {
  const { details, meetingSheetDetails } = props;

  return (
    <Box
      sx={{
        backgroundImage: `url(${RequestStepperBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 0%",
        padding: "2rem 0",
        // MEMO: 背景の兼ね合いから、部分的にネガティブマージンを設定
        margin: { xs: "0 -1.5rem", sm: "0" }
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "84%", sm: "80%" }
        }}
      >
        <Typography
          sx={{ margin: "2rem auto 0" }}
          align="center"
          fontWeight="bold"
        >
          事前準備リスト
        </Typography>
        <Typography fontWeight="bold">授業打ち合わせ準備</Typography>
        <PreListItem title="チャット内で授業日２週間前を目安にお打ち合わせの日程調整をした（された）" />
        <PreListItem title="お打ち合わせのzoom発行をし、チャット内で共有した（された）" />
        <PreListItemWithLink
          title={[
            { kindOf: "text", content: "学校の先生が記入した" },
            {
              kindOf: "link",
              content: "授業準備シート",
              link: `/teachers/mypage/requests/${details?.id}/agenda`
            },
            {
              kindOf: "text",
              content: "の概要に基づいて、授業のタイムラインの構成案を記入した"
            }
          ]}
        />
        <Typography fontWeight="bold">授業打ち合わせ</Typography>
        <PreListItem title="授業準備シートを開いて画面共有しながら、メモ・タイムライン等に授業詳細を記入した" />
        <PreListItem title="お打ち合わせで緊急連絡先の交換をした" />
        <Typography fontWeight="bold">授業準備(授業打ち合わせ後)</Typography>
        <PreListItem title="授業スライドを作成、共有した" />
        <PreListItem title="授業当日のzoomを発行、チャット内で共有した（された）" />
        <PreListItem title="授業本番と同じ環境の準備・接続テストをした" />
        {meetingSheetDetails?.teacher_advance_todo && (
          <PreListItem
            title="事前準備の確認（当日までにやること）"
            text={meetingSheetDetails.teacher_advance_todo}
          />
        )}
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "84%", sm: "80%" }
        }}
      >
        <Typography
          sx={{ margin: "2rem auto 0" }}
          align="center"
          fontWeight="bold"
        >
          授業実施後以下のアンケートの回答をお願いします
        </Typography>
        <ACCListItem
          text="複業先生向けアンケート"
          exLink="https://forms.gle/Qqz2LsVsRgXCvmvFA"
        />
      </Box>
    </Box>
  );
};

export default PreparationList;
