// TODO: 削除予定

import { atom } from "jotai";

type prefecturesDataType = {
  id: number;
  name: string;
  code: number;
};

type organizationCategoriesType = {
  id: number;
  name: string;
};

type tagsType = {
  id: number;
  name: string;
};

export const prefecturesData = atom<prefecturesDataType[]>([]);
export const organizationCategoriesData = atom<organizationCategoriesType[]>(
  []
);
export const tagsData = atom<tagsType[]>([]);
