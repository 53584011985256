import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";

import { kindOfSchoolLessonsAtom } from "store/schools/mypage/LessonsStore";

import type React from "react";
import type { kindOfSchoolLessonsType } from "store/schools/mypage/LessonsStore";

type LessonSelectLabelProps = {
  label: string;
  value: kindOfSchoolLessonsType;
};

const LessonSelectLabel: React.FC<LessonSelectLabelProps> = (props) => {
  const { label, value } = props;
  const theme = useTheme();
  const [kindOfSchoolLessons, setKindOfSchoolLessons] = useAtom(
    kindOfSchoolLessonsAtom
  );

  const handleClick = () => {
    setKindOfSchoolLessons(value);
  };

  return (
    <Chip
      label={label}
      variant="outlined"
      clickable
      onClick={handleClick}
      sx={{
        fontWeight: kindOfSchoolLessons === value ? "bold" : "normal",
        color: theme.palette.common.black,
        borderColor:
          kindOfSchoolLessons === value
            ? theme.palette.common.black
            : theme.palette.border?.main,
        backgroundColor: theme.palette.common.white,
        borderWidth: kindOfSchoolLessons === value ? "2px" : "1px"
      }}
    />
  );
};

export default LessonSelectLabel;
