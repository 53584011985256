import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormHelperText,
  FormGroup
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useFormContext, Controller } from "react-hook-form";

import FormLabel from "components/atoms/FormLabel";
import Check from "components/atoms/fields/Check"; // Check コンポーネントのインポート

type FormCheckboxGroupProps = {
  name: string;
  label?: string;
  options: {
    label: string | React.ReactNode;
    value: string | number;
  }[];
  supplementalText?: string;
  isRequired?: boolean;
  disabledValues?: Array<number | string>;
};

const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
  name,
  label,
  options,
  supplementalText,
  isRequired,
  disabledValues
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const theme = useTheme();

  // チェックボックスの変更を処理
  const handleChange = (
    value: string | number,
    event: React.ChangeEvent<HTMLInputElement> // この行は変更なしですが、正しい型を明示しています
  ) => {
    // event.target を HTMLInputElement にキャストする
    const { checked } = event.target;
    const currentValues = getValues(name) || [];
    const newValues = checked
      ? [...currentValues, value]
      : currentValues.filter((v: string) => v !== value);
    setValue(name, newValues, { shouldDirty: true });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {label && <FormLabel labelName={label} isRequired={isRequired} />}
      {supplementalText && (
        <Typography variant="caption" sx={{ color: theme.palette.sub.main }}>
          {supplementalText}
        </Typography>
      )}
      <FormControl error={!!errors[name]} component="fieldset">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: ".5rem"
              }}
            >
              {options.map((option) => (
                <Check
                  key={option.value}
                  label={option.label}
                  checked={
                    field.value ? field.value.includes(option.value) : false
                  }
                  // イベントハンドラーを適切に修正
                  onChange={(e) =>
                    handleChange(
                      option.value,
                      e as React.ChangeEvent<HTMLInputElement>
                    )
                  }
                  disabled={
                    disabledValues && disabledValues.includes(option.value)
                  }
                />
              ))}
            </FormGroup>
          )}
        />

        <FormHelperText>{errors[name]?.message?.toString()}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default FormCheckboxGroup;
