import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import type React from "react";

type VoiceBoxProps = {
  title01: string;
  title02: string;
  contents: string;
  avatar: string;
  belongs: string;
  age: string;
};

const VoiceBox: React.FC<VoiceBoxProps> = (props) => {
  const { title01, title02, contents, avatar, belongs, age } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "360px"
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: "32px 32px 32px 0",
          padding: "1.5rem",
          width: "100%",
          height: { xs: "210px", sm: "180px", lg: "240px" }
        }}
      >
        <Typography variant="h4" sx={{ margin: ".25rem auto .5rem" }}>
          {title01}
          <br />
          {title02}
        </Typography>
        <Typography>{contents}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: ".75rem auto",
          alignItems: "center"
        }}
      >
        <Avatar
          alt={belongs}
          src={avatar}
          sx={{
            width: "64px",
            height: "64px"
          }}
        />
        <Typography
          sx={{
            color: theme.palette.sub.main,
            lineHeight: 1.8,
            marginLeft: "1rem"
          }}
        >
          <span>{belongs}</span>
          <br />
          <span>{age}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default VoiceBox;
