import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import { teacherInfoAtom } from "store/TeachersShowStore";

import type React from "react";
import LicenseItem from "./LicenseItem";

const LicenseBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <div>
      <Typography variant="h4" sx={{ margin: ".5rem auto 1rem" }}>
        免許・資格
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem 0"
        }}
      >
        {teacherInfo.teaching_licenses.map((teachingLicense, index) => (
          <LicenseItem
            key={index}
            name={teachingLicense.name}
            specialSubject={teachingLicense.special_subject}
          />
        ))}
      </Box>
    </div>
  );
};

export default LicenseBox;
