import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useState } from "react";

import { candidatesCountAtom } from "store/schools/recruitments/ShowStore";

import type React from "react";
import CancelModal from "./CancelModal";
import EarlyCloseModal from "./EarlyCloseModal";

const EarlyCloseBlock: React.FC = () => {
  const theme = useTheme();
  const [earlyCloseModalOpen, setEarlyCloseModalOpen] = useState(false);
  const [CancelModalOpen, setCancelModalOpen] = useState(false);
  const candidatesCount = useAtomValue(candidatesCountAtom);

  const handleErlyCloseModalOpen = () => {
    setEarlyCloseModalOpen(true);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primaryBackground?.main,
            borderRadius: "8px",
            padding: "1rem",
            display: "flex",
            gap: "1rem",
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold" fontSize="1.1rem">
              募集を締め切りたい
            </Typography>
            <Typography>
              応募者が集まった場合、期限よりも早く応募を締め切ることができます。
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleErlyCloseModalOpen}
            disabled={!candidatesCount}
            sx={{
              width: "12rem",
              fontWeight: "bold"
            }}
          >
            {!candidatesCount ? "まだ応募者がいません" : "募集を締め切る"}
          </Button>
        </Box>
        <Button
          variant="text"
          color="secondary"
          disableElevation
          onClick={handleCancelModalOpen}
          sx={{ alignSelf: "flex-start" }}
        >
          募集自体をキャンセルしたい場合はこちら
        </Button>
      </Box>
      <EarlyCloseModal
        open={earlyCloseModalOpen}
        setOpen={setEarlyCloseModalOpen}
      />
      <CancelModal open={CancelModalOpen} setOpen={setCancelModalOpen} />
    </>
  );
};

export default EarlyCloseBlock;
