import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";

import type React from "react";

type CustomTabListProps = {
  handleChange: (event: React.ChangeEvent<object>, newIndex: string) => void;
  tabContentsData: Array<{
    id: number;
    name: string;
  }>;
};

const CustomTabList: React.FC<CustomTabListProps> = (props) => {
  const { handleChange, tabContentsData } = props;
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TabList
      variant={underSm ? "scrollable" : "fullWidth"}
      onChange={handleChange}
      textColor="primary"
      indicatorColor="primary"
      scrollButtons
    >
      {tabContentsData.map((content, index) => (
        <Tab
          key={index}
          value={content.id.toString()}
          label={
            <span>
              <Box
                component="label"
                sx={{
                  display: "block",
                  fontSize: { xs: ".6rem", sm: ".8rem" },
                  cursor: "pointer"
                }}
              >
                [授業テーマ{content.id}]
              </Box>
              <Box
                component="label"
                sx={{
                  display: "block",
                  fontSize: { xs: "inherit", sm: "1rem" },
                  cursor: "pointer"
                }}
              >
                {content.name}
              </Box>
            </span>
          }
          sx={{
            width: { xs: "138px", sm: "162px" },
            "&:hover": {
              backgroundColor: "#FFF5E5",
              borderRadius: "4px"
            },
            "&.MuiTab-root.Mui-selected": {
              fontWeight: "bold"
            }
          }}
        />
      ))}
    </TabList>
  );
};

export default CustomTabList;
