import { atom } from "jotai";

import type { AnnouncementType } from "types/announcementTypes";

export const teacherAnnouncementsAtom = atom<Array<AnnouncementType>>([]);
export const schoolAnnouncementsAtom = atom<Array<AnnouncementType>>([]);

export const teacherAnnouncementIsErrorAtom = atom(false);
export const schoolAnnouncementIsErrorAtom = atom(false);

export const teacherDialogIsOpenAtom = atom(false);
export const schoolDialogIsOpenAtom = atom(false);
