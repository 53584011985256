import * as yup from "yup";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";

export type postChatFormSchemaType = Methods["post"]["reqBody"];

// 許可されたファイルのMIMEタイプ
const FILE_EXTENSIONS = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
  "video/mp4",
  "application/vnd.apple.keynote",
  "video/quicktime",
  "image/jpeg",
  "image/png"
];
// 最大ファイルサイズ（100MB）
const MAX_FILE_SIZE = 100 * 1024 * 1024;

const postChatFormSchema: yup.ObjectSchema<postChatFormSchemaType> = yup
  .object()
  .shape({
    comment: yup
      .string()
      .optional()
      .test("required", "メッセージを入力してください", (value, context) => {
        if (context.parent.file || value) return true;
        return false;
      }),
    file: yup
      .mixed<File>()
      .optional()
      .test(
        "check-file",
        "ファイル形式は次の形式のみアップロード可能です。.ppt, .pptx, .pdf, .mp4, .key, .mov, .jpg, .jpeg, .png",
        (value) => {
          if (!value) return true; // ファイルが存在しない場合はスルー
          const matches = value
            .toString()
            .match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
          if (!matches) return false; // メディアタイプが抽出できない場合は無効
          const mediaType = matches[1];
          return FILE_EXTENSIONS.includes(mediaType);
        }
      )
      .test(
        "check-size",
        "ファイルは100MB以下のみアップロード可能です。",
        (value) => {
          if (!value) return true; // ファイルが存在しない場合はスルー
          const base64Data = value.toString().split(",")[1];
          const fileSize = base64Data.length * 0.75; // ベース64からバイナリサイズに変換
          return fileSize <= MAX_FILE_SIZE;
        }
      ),
    is_office_notification: yup.boolean().optional()
  });

export default postChatFormSchema;
