import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import type React from "react";
import ModalTransition from "components/organisms/ModalTransition";

interface CommonModalProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const CommonModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  children,
  ...props
}) => {
  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={ModalTransition}
      fullWidth
      {...props}
      sx={{
        marginTop: { xs: "15vh", sm: "0" },
        "& .MuiPaper-root": {
          borderTopLeftRadius: { xs: "16px", sm: "4px" },
          borderTopRightRadius: { xs: "16px", sm: "4px" }
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 6,
          top: 6
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          padding: "1rem 2rem",
          height: "100%"
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CommonModal;
