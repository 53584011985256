import Grid from "@mui/material/Grid";
import Meta from "components/organisms/Meta";

import type React from "react";
import useSchoolAuthApi from "features/schools/hooks/useSchoolAuthApi";
import { FormProvider } from "react-hook-form";
import PasswordResetFormBlock from "components/organisms/auth/PasswordResetFormBlock";
import { Box } from "@mui/material";
import AuthLayout from "../../../../components/layouts/AuthLayout";

const PasswordReset: React.FC = () => {
  const { resetPassword, resetPasswordForm } = useSchoolAuthApi();

  return (
    <AuthLayout>
      <Meta />
      <Grid
        container
        sx={{
          height: { xs: "auto", lg: "100vh" }
        }}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: { xs: "auto", lg: "100%" }
          }}
        >
          <FormProvider {...resetPasswordForm}>
            <Box
              component="form"
              onSubmit={resetPasswordForm.handleSubmit(resetPassword)}
              sx={{
                height: { xs: "auto", lg: "100%" }
              }}
            >
              <PasswordResetFormBlock />
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default PasswordReset;
