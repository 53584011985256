import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import useTeacherRankBadge from "features/teachers/hooks/useTeacherRankBadge";

import type React from "react";

type LessonTeacherDataProps = {
  teacherName: string;
  teacherImage: string;
  teacherRank: string;
};

const LessonTeacherData: React.VFC<LessonTeacherDataProps> = (props) => {
  const { teacherName, teacherImage, teacherRank } = props;
  const teacherRankBadge = useTeacherRankBadge(teacherRank);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        gap: ".5rem"
      }}
    >
      <Avatar
        src={teacherImage}
        alt={teacherName}
        sx={{
          width: "1.5rem",
          height: "1.5rem"
        }}
      />
      <Typography>{teacherName}</Typography>
      {teacherRankBadge.src && (
        <Box
          component="img"
          src={teacherRankBadge.src}
          alt={teacherRankBadge.alt}
          sx={{
            display: "block",
            margin: "0 .25rem",
            height: "16px"
          }}
        />
      )}
    </Box>
  );
};

export default LessonTeacherData;
