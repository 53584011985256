import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import { useHistory } from "react-router-dom";

import type React from "react";

const ToRegenerateButton: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const handleClick = () => {
    history.push("/schools/mypage/ai-teaching-plan/new");
  };

  return (
    <Button
      variant="contained"
      color="inherit"
      disableElevation
      fullWidth
      // まずは相談してみる フォームへのリンク
      onClick={handleClick}
    >
      もう一度授業案を作成する
    </Button>
  );
};

export default ToRegenerateButton;
