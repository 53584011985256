import { ThemeOptions } from "@mui/material/styles/createTheme";
import { customColors } from "./customColors";

export const MuiPaginationTheme: ThemeOptions = {
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          "& > .MuiPagination-ul": {
            justifyContent: "center",
            "& > li": {
              "& > .MuiPaginationItem-root": {
                height: "36px",
                padding: "6px 12px",
                fontSize: "16px",
                minWidth: "36px"
              },
              "& > .MuiPaginationItem-outlinedPrimary.Mui-selected": {
                color: "#ffffff",
                backgroundColor: customColors.primary.main,
                border: "none"
              },
              "& > .MuiPaginationItem-page": {
                transition: "none",
                "& > .Mui-disabled": {
                  opacity: 0.5
                }
              },
              "& > .MuiPaginationItem-page.Mui-disabled": {
                opacity: 0.5
              },
              "& > .MuiPaginationItem-outlined": {
                border: `1px solid ${customColors.border?.main}`,
                backgroundColor: "#ffffff",
                color: customColors.textColor.main
              },
              "&:first-child": {
                "& > button::after": {
                  marginLeft: "15px",
                  content: '"前へ"'
                }
              },
              "&:last-child": {
                "& > button::before": {
                  marginRight: "15px",
                  content: '"次へ"'
                }
              }
            }
          }
        }
      }
    }
  }
};
