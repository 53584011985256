import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";

import type React from "react";
import FormTextField from "components/molecules/FormTextField";
import FormSubmitButton from "components/molecules/FormSubmitButton";

type PasswordResetFormBlockProps = {
  variant: "school" | "teacher";
};

const PasswordResetRequestFormBlock: React.FC<PasswordResetFormBlockProps> = ({
  variant
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: { xs: theme.spacing(variant === "school" ? 12 : 3), lg: 0 },
        height: "100%"
      }}
    >
      <Grid
        item
        xs={10}
        sm={9}
        md={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2)
        }}
      >
        <Typography variant="h3" align="center">
          パスワードの再発行
        </Typography>
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2)
            }}
          >
            <FormTextField
              name="email"
              label="メールアドレス"
              noLabel
              placeholder="sample@fukugyo.co.jp"
              fullWidth
            />
            <FormSubmitButton label="送信" size="large" fullWidth />
          </Box>
        </FormGroup>
        <Typography align="center">
          <Box
            component={RouterLink}
            to={variant === "school" ? "/schools/login" : "/teachers/login"}
            sx={{
              textDecoration: "none",
              color: theme.palette.secondary.main,
              fontWeight: 600
            }}
          >
            ログイン
          </Box>
          ・
          <Box
            component={RouterLink}
            to={variant === "school" ? "/schools/signup" : "/teachers/signup"}
            sx={{
              textDecoration: "none",
              color: theme.palette.secondary.main,
              fontWeight: 600
            }}
          >
            新規登録
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordResetRequestFormBlock;
