import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type React from "react";
import Button from "components/atoms/mui/Button";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";

type CandidateCancelModalProps = {
  cancelCandidate: () => void;
  isLoading: boolean;
} & CommonDialogProps;

const CandidateCancelModal: React.FC<CandidateCancelModalProps> = (props) => {
  const { isOpen, onClose, cancelCandidate, isLoading } = props;

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem"
        }}
      >
        <Typography align="center" fontWeight="bold" fontSize="1.1rem">
          本当に応募をキャンセルしますか？
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Button color="primary" variant="outlined" onClick={onClose}>
            戻る
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={cancelCandidate}
            disabled={isLoading}
          >
            応募をキャンセルする
          </Button>
        </Box>
      </Box>
    </CommonDialog>
  );
};

export default CandidateCancelModal;
