import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";
import { useState } from "react";

import { VideoJS } from "components/atoms/VideoJS";
import TeachingVideoModal from "features/teachers/components/atoms/show/v2/TeachingVideoModal";
import { teacherVideosShowAtom } from "store/TeacherVideosStore";

import type React from "react";
import type videojs from "video.js";

const TeachingMoviesBox: React.FC = () => {
  const [open, setOpen] = useState<number | null>(null);
  const teacherVideos = useAtomValue(teacherVideosShowAtom);

  const handleClick = (index: number) => {
    setOpen(index);
  };

  return (
    <div>
      <Typography variant="h4" fontSize="1rem" sx={{ margin: "0 auto .5rem" }}>
        紹介動画
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center"
        }}
      >
        {teacherVideos.map((teacherVideo, index) => {
          // MEMO: videojs setting
          const videoJSOptions: videojs.PlayerOptions = {
            controls: true,
            aspectRatio: "16:9",
            fluid: true,
            sources: [
              {
                src: teacherVideo.file.url,
                type: "video/mp4"
              }
            ]
          };

          return (
            <>
              <Box
                onClick={() => handleClick(index)}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  width: { xs: "100%", md: "calc(100% / 3 - 1rem)" }
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                />
                <div>
                  <VideoJS options={videoJSOptions} />
                </div>
              </Box>
              <TeachingVideoModal
                open={open === index}
                setOpen={setOpen}
                videoUrl={teacherVideo.file.url}
              />
            </>
          );
        })}
      </Box>
    </div>
  );
};

export default TeachingMoviesBox;
