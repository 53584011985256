import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import Typography from "@mui/material/Typography";

import type React from "react";

type SelectAccountButtonProps = {
  accountName: string;
  title: string;
  desc: string[];
  image: string;
  isActive: boolean;
  handleActiveState: () => void;
};

const SelectAccountButton: React.VFC<SelectAccountButtonProps> = (props) => {
  const { accountName, title, desc, image, isActive, handleActiveState } =
    props;
  const theme = useTheme();

  return (
    <Box
      onClick={handleActiveState}
      sx={{
        cursor: "pointer",
        display: { xs: "flex", sm: "block" },
        alignItems: { xs: "center", sm: "flex-start" },
        justifyContent: { xs: "space-around", sm: "center" },
        padding: { xs: "1rem .5rem", sm: ".5rem 0 0" },
        borderRadius: "4px",
        border: isActive
          ? `solid 1px ${theme.palette.primary.main}`
          : `solid 1px ${theme.palette.border?.main}`,
        backgroundColor: isActive
          ? "#FFFCF5"
          : theme.palette.backgroundColor?.main
      }}
    >
      <div>
        <Typography
          color={theme.palette.sub.main}
          fontWeight="bold"
          fontSize=".8rem"
          sx={{ textAlign: { xs: "left", sm: "center" } }}
        >
          {accountName}
        </Typography>
        <Typography
          fontSize="1.2rem"
          fontWeight="bold"
          sx={{ textAlign: { xs: "left", sm: "center" }, lineHeight: 1.8 }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: { xs: "left", sm: "center" },
            lineHeight: 1.5
          }}
        >
          {desc.map((line, index) => (
            <Box
              component="span"
              key={index}
              sx={{ display: { xs: "inline-block", sm: "block" } }}
            >
              {line}
            </Box>
          ))}
        </Typography>
      </div>
      <Box sx={{ textAlign: "center" }}>
        <Box
          component="img"
          src={image}
          alt={`${title}画像`}
          sx={{ verticalAlign: "bottom" }}
        />
      </Box>
    </Box>
  );
};

export default SelectAccountButton;
