import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import React from "react";

import Loading from "components/atoms/Loading";
import ToSupportBannerBottom from "features/teachers/components/atoms/index/ToSupportBannerBottom";
import Meta from "components/organisms/Meta";
import FirstView from "features/teachers/components/organisms/index/FirstView";
import SearchForm from "features/teachers/components/organisms/index/SearchForm";
import TeachersCount from "features/teachers/components/organisms/index/TeachersCount";
import TeachersList from "features/teachers/components/organisms/index/TeachersList";
import TeachersPagination from "features/teachers/components/organisms/index/TeachersPagination";
import CommonLayout from "components/layouts/CommonLayout";

import useIndexPageApi, {
  getIndexType
} from "features/teachers/hooks/useIndexPageApi";
import useAlertToNew from "hooks/useAlertToNew";
import { FormProvider } from "react-hook-form";

const Index: React.FC = () => {
  useAlertToNew();
  const theme = useTheme();

  const { data, search, reset, isLoading, methods } = useIndexPageApi();

  const handleSearch = async (data: getIndexType) => {
    search(data);
  };

  return (
    <CommonLayout>
      <Meta
        title="複業先生一覧"
        description="学校で授業を行うことのできる複業先生の一覧です。様々な経歴の方に授業依頼を依頼をすることができます。"
      />
      <FirstView />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main
        }}
      >
        <Container
          sx={{
            padding: "1.5rem 0"
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSearch)}>
              <SearchForm reset={reset} />
            </form>
          </FormProvider>
          {!isLoading ? (
            <>
              <TeachersCount />
              <TeachersList teachers={data?.data || []} />
              <TeachersPagination search={search} />
            </>
          ) : (
            <Loading />
          )}
        </Container>
        <ToSupportBannerBottom />
      </Box>
    </CommonLayout>
  );
};

export default Index;
