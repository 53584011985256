import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import RequestFirstViewImage from "assets/RequestFirstViewImage.png";

const SetupEyeCatch: React.FC = () => (
  <Box
    sx={{
      background: `no-repeat url(${RequestFirstViewImage}), linear-gradient(to right, #FFEFC7, #FFDBAA)`,
      backgroundPosition: { xs: "110% 8px", sm: "100% 8px", md: "90% 8px" },
      display: "flex",
      alignItems: "center",
      height: { xs: "120px", md: "125px" },
      backgroundSize: { xs: "240px, 100% 10%", sm: "270px, 100% 10%" }
    }}
  >
    <Typography
      variant="h2"
      sx={{
        margin: "24px auto 0",
        width: "90%",
        maxWidth: "1280px"
      }}
    >
      学校教員本登録
    </Typography>
  </Box>
);

export default SetupEyeCatch;
