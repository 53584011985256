import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import CareerIcon from "features/teachers/components/atoms/show/v2/CareerIcon";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type React from "react";

type CareerContentType = {
  ContentIcon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  contentTitle: string;
  content: string;
  contentDesc?: string;
};

const CareerContent: React.FC<CareerContentType> = (props) => {
  const { ContentIcon, contentTitle, content, contentDesc } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0 1rem"
      }}
    >
      <CareerIcon Icon={ContentIcon} />
      <Typography
        color={theme.palette.sub.main}
        fontSize=".9rem"
        sx={{
          width: "96px",
          flex: 1
        }}
      >
        {contentTitle}
      </Typography>
      <Box sx={{ flex: { xs: 2, sm: 3 } }}>
        <Typography fontWeight="bold">{content}</Typography>
        {contentDesc && (
          <Typography color={theme.palette.sub.main} fontSize=".9rem">
            {contentDesc}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CareerContent;
