import { atom } from "jotai";

export const lastNameAtom = atom("");
export const firstNameAtom = atom("");
export const lastNameKanaAtom = atom("");
export const firstNameKanaAtom = atom("");
export const emailAtom = atom("");
export const contactTypeAtom = atom("");
export const contactContentsAtom = atom("");

export const lastNameKanaIsErrorAtom = atom((get) => {
  const lastNameKana = get(lastNameKanaAtom);
  const validKanaRegex = /^[ァ-ンヴー]*$/;

  if (validKanaRegex.test(lastNameKana)) {
    return false;
  }
  return true;
});

export const firstNameKanaIsErrorAtom = atom((get) => {
  const firstNameKana = get(firstNameKanaAtom);
  const validKanaRegex = /^[ァ-ンヴー]*$/;

  if (validKanaRegex.test(firstNameKana)) {
    return false;
  }
  return true;
});

export const emailIsErrorAtom = atom((get) => {
  const email = get(emailAtom);
  const validEmailRegex = /[\w+\-.]+@[a-z\d\-.]+\.[a-z]+/i;

  if (validEmailRegex.test(email) || email === "") {
    return false;
  }
  return true;
});

export const isClickableAtom = atom((get) => {
  const lastName = get(lastNameAtom);
  const firstName = get(firstNameAtom);
  const lastNameKana = get(lastNameKanaAtom);
  const firstNameKana = get(firstNameKanaAtom);
  const email = get(emailAtom);
  const contactType = get(contactTypeAtom);
  const contactContents = get(contactContentsAtom);

  const lastNameKanaIsError = get(lastNameKanaIsErrorAtom);
  const firstNameKanaIsError = get(firstNameKanaIsErrorAtom);
  const emailIsError = get(emailIsErrorAtom);

  if (
    lastName === "" ||
    firstName === "" ||
    lastNameKana === "" ||
    firstNameKana === "" ||
    email === "" ||
    contactType === "" ||
    contactContents === "" ||
    lastNameKanaIsError ||
    firstNameKanaIsError ||
    emailIsError
  ) {
    return false;
  }
  return true;
});
