import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import type { CircularProgressProps } from "@mui/material/CircularProgress";
import type React from "react";

type StepperProgressWithLabelProps = CircularProgressProps & {
  step: number;
  totalSteps: number;
};

const StepperProgressWithLabel: React.FC<StepperProgressWithLabelProps> = (
  props
) => {
  const { step, totalSteps } = props;
  const theme = useTheme();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{ color: theme.palette.sub.lighter }}
      />
      <CircularProgress
        color="primary"
        variant="determinate"
        value={(step / totalSteps) * 100}
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" fontWeight="bold">
          {step}/{totalSteps}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepperProgressWithLabel;
