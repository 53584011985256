import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import type React from "react";

const CommonLoading: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%"
    }}
  >
    <CircularProgress />
  </Box>
);

export default CommonLoading;
