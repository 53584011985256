import { useEffect, useState } from "react";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";

import { Methods } from "api/api/v1/teachers/index";
import { prefecturesData, organizationCategoriesData } from "store/MasterData";

import { paginationAtom } from "store/TeachersIndexStore";
import useQueryParams from "hooks/useQueryParams";

import useHeaders from "hooks/useHeaders";

export type getIndexType = Methods["get"]["query"];

const useIndexPageApi = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const headers = useHeaders();

  const [query, setQuery] = useState<getIndexType>(queryParams);
  const [, setPrefectures] = useAtom(prefecturesData);
  const [, setOrganizationCategories] = useAtom(organizationCategoriesData);
  const [, setPagination] = useAtom(paginationAtom);

  const methods = useForm({
    mode: "onChange",
    defaultValues: { ...queryParams, pages: 1 } as getIndexType
  });

  const { data, error, isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers,
    {
      query
    }
  );

  const search = (searchQuery: getIndexType) => {
    setQuery({ ...query, ...searchQuery });
  };

  const reset = () => {
    methods.reset({
      text: "",
      former_school: "",
      pages: 1
    });
    setQuery({ pages: 1 });
  };

  useEffect(() => {
    if (data) {
      setPrefectures(data?.prefectures || []);
      setOrganizationCategories(data?.tags || []);
      setPagination(data?.pagination);
    }
  }, [data]);

  useEffect(() => {
    if (query) {
      setQueryParams(query);
    }
  }, [query]);

  return {
    data,
    error,
    search,
    reset,
    isLoading,
    queryParams,
    methods
  };
};

export default useIndexPageApi;
