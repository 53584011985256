import { ThemeOptions } from "@mui/material/styles/createTheme";
import { customColors } from "./customColors";

export const MuiChipTheme: ThemeOptions = {
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          border: `1px solid ${customColors.border.main}`,
          backgroundColor: customColors.background.gray
        },
        colorPrimary: {
          border: "none",
          backgroundColor: customColors.primary.main,
          color: customColors.textColor.white
        }
      }
    }
  }
};
