import Box from "@mui/material/Box";

import RequestList from "features/teachers/components/organisms/mypage/v2/requests/RequestList";
import RequestSelectLabels from "features/teachers/components/organisms/mypage/v2/requests/RequestSelectLabels";
import TitleBlock from "features/teachers/components/organisms/mypage/v2/TitleBlock";

import type React from "react";

const Requests: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1.5rem",
      flexGrow: 1
    }}
  >
    <TitleBlock
      title="直接依頼"
      description="学校からあなたへの授業依頼の一覧です。"
    />
    <RequestSelectLabels />
    <RequestList />
  </Box>
);

export default Requests;
