import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import CandidatesListItem from "features/schools/components/molecules/recruitments/show/CandidatesListItem";
import {
  candidatesCountAtom,
  candidatesAtom,
  recruitmentAtom
} from "store/schools/recruitments/ShowStore";

import type React from "react";

const CandidatesList: React.FC = () => {
  const theme = useTheme();
  const candidatesCount = useAtomValue(candidatesCountAtom);
  const candidates = useAtomValue(candidatesAtom);
  const recruitment = useAtomValue(recruitmentAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography variant="h2">
        応募者（{`${candidatesCount || 0}`}名）
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white
        }}
      >
        {candidates.length > 0 ? (
          candidates.map((candidate) => (
            <>
              <CandidatesListItem
                candidateTeacherId={candidate.teacher.id}
                candidateAvatar={candidate.teacher.image.url || ""}
                candidateName={candidate.teacher.name}
                candidateTeacherRank={candidate.teacher.teacher_rank}
                candidateOrganizationName={
                  candidate.teacher.organization_name || ""
                }
                candidateTotalRating={candidate.teacher.total_rating}
                candidateRatingCount={candidate.teacher.rating_count}
                schoolMinutes={recruitment?.school_minutes || 0}
                lessonContents={candidate.details.lesson_contents}
                appeal={candidate.details.appeal}
              />
              <Divider />
            </>
          ))
        ) : (
          <Typography>まだ応募者はいません。</Typography>
        )}
      </Box>
    </Box>
  );
};

export default CandidatesList;
