import Box from "@mui/material/Box";
import FormTextArea from "components/molecules/FormTextArea";
import type React from "react";

const Step02: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1rem"
    }}
  >
    <FormTextArea
      label="授業をお願いする先生にメッセージをお願いします。"
      name="accepted_comment"
      placeholder="例：この度は、ご応募いただきありがとうございます。他の先生との検討の結果、授業をお願いすることになりました。よろしくお願いします。"
      isRequired
    />
    <FormTextArea
      label="お断りする先生にメッセージをお願いします。"
      name="denied_comment"
      supplementalText="複数人いる場合、同一のメッセージとなります。入力がなかった場合はデフォルトのメッセージが送られます。"
      placeholder="デフォルトのメッセージ：&#13;この度は、応募いただき誠にありがとうございました。&#13;残念ながら、カリキュラムとの兼ね合いや、授業の内容の方向性から、今回は別の方にお願いすることになりました。&#13;また、次回の授業募集にご応募いただけると幸いです。"
    />
  </Box>
);

export default Step02;
