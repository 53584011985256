import React from "react";
import Slide from "@mui/material/Slide";

import type { TransitionProps } from "@mui/material/transitions";

const ModalTransition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default ModalTransition;
