import Typography from "@mui/material/Typography";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";

type FormLabelProps = {
  labelName: string;
  isRequired?: boolean;
};

const FormLabel: React.FC<FormLabelProps> = (props) => {
  const { labelName, isRequired } = props;
  const theme = useTheme();

  return (
    <label
      htmlFor={labelName}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}
    >
      <Typography
        sx={{
          backgroundColor: isRequired
            ? theme.palette.primary.main
            : theme.palette.muted?.main,
          color: theme.palette.common.white,
          fontWeight: "bold",
          borderRadius: "4px",
          padding: "2px 4px",
          width: { xs: "30px", sm: "36px" },
          fontSize: { xs: ".6rem", sm: ".75rem" },
          lineHeight: "1.5 !important",
          flexShrink: 0
        }}
        align="center"
        variant="body1"
      >
        {isRequired ? "必須" : "任意"}
      </Typography>
      <Typography
        sx={{
          fontWeight: "bold"
        }}
        variant="body1"
      >
        {labelName}
      </Typography>
    </label>
  );
};

export default FormLabel;
