import { Methods } from "api/api/v1/teachers/login_teacher";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

type currentUserAtomProps = Methods["get"]["resBody"];

const currentUserAtom = atomWithStorage<currentUserAtomProps>(
  "currentUser",
  {
    allow_password_change: false,
    deleted_at: null,
    email: "",
    id: 0,
    is_public: false,
    is_term_accepted: false,
    is_identification: false,
    is_identification_in_progress: false,
    has_birthday: false,
    provider: "",
    teacher_profile_id: 0,
    uid: "",
    user_id: 0,
    name: "",
    image: { url: "" },
    user_type: "",
    teacher_rank: "",
    is_reward: false,
    is_limited_public: false,
    is_match_accepted: false,
    can_interview: false
  },
  undefined,
  {
    getOnInit: true
  }
);

const isLoggedInAtom = atomWithStorage("isLoggedIn", false, undefined, {
  getOnInit: true
});
const accessTokenAtom = atomWithStorage("accessToken", "", undefined, {
  getOnInit: true
});
const clientAtom = atomWithStorage("client", "", undefined, {
  getOnInit: true
});
const uidAtom = atomWithStorage("uid", "", undefined, {
  getOnInit: true
});
const tokenTypeAtom = atomWithStorage("tokenType", "", undefined, {
  getOnInit: true
});

const loginAgainModalAtom = atom(false);

const prevLoginPathAtom = atom("");

export {
  currentUserAtom,
  isLoggedInAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom,
  tokenTypeAtom,
  loginAgainModalAtom,
  prevLoginPathAtom
};
