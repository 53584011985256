import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import type React from "react";

type HasTeacherLabelProps = {
  labelText: string;
};

const HasTeacherLabel: React.FC<HasTeacherLabelProps> = (props) => {
  const { labelText } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0 .5rem",
        marginRight: "1rem"
      }}
    >
      <CheckCircleIcon sx={{ color: theme.palette.link?.main }} />
      <Typography fontSize=".9rem" color={theme.palette.sub.main}>
        {labelText}
      </Typography>
    </Box>
  );
};

export default HasTeacherLabel;
