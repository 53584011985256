import { useState } from "react";

export type typeofUseDialogRetrunType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export function useDialog() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = (): void => {
    setIsOpen(true);
  };
  const onClose = (): void => {
    setIsOpen(false);
  };

  return { isOpen, onOpen, onClose };
}
