import Axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import useSchoolReset from "features/schools/hooks/useSchoolReset";
import {
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom,
  loginAgainModalAtom
} from "store/SchoolAuthStore";

import type { AxiosError } from "axios";

type usePostProps = {
  url: string;
};

export type doFetchProps = {
  params?: object;
  onSuccess?: (data?: any) => void;
  onError?: (error: AxiosError) => void;
};

const useGetRequest = ({ url }: usePostProps) => {
  const accessToken = useAtomValue(schoolAccessTokenAtom);
  const client = useAtomValue(schoolClientAtom);
  const uid = useAtomValue(schoolUidAtom);
  const [, setLoginAgainModal] = useAtom(loginAgainModalAtom);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<any>();
  const history = useHistory();
  const [schoolReset] = useSchoolReset();

  const memoizeUrl = useMemo(() => url, [url]);
  const memoizeAccessToken = useMemo(() => accessToken, [accessToken]);
  const memoizeClient = useMemo(() => client, [client]);
  const memoizeUid = useMemo(() => uid, [uid]);

  const getRequest = useCallback(
    async ({ params, onSuccess, onError }: doFetchProps) => {
      const shapingParams = params || {};
      await setIsLoading(true);
      const res = await Axios.get(memoizeUrl, {
        params: shapingParams,
        headers: {
          "access-token": memoizeAccessToken,
          uid: memoizeUid,
          client: memoizeClient
        }
      })
        .then((response) => {
          onSuccess && onSuccess(response.data);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            schoolReset();
            setLoginAgainModal(true);
            history.push("/schools/login");
          }

          setIsError(true);
          setError(error);
          onError && onError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return res;
    },
    [
      memoizeAccessToken,
      memoizeClient,
      memoizeUid,
      memoizeUrl,
      schoolReset,
      setLoginAgainModal,
      history
    ]
  );

  return { getRequest, isLoading, isError, error, setIsError };
};

export default useGetRequest;
