import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { requestObjectAtom } from "store/RequestShowStore";

import type React from "react";

import {
  postRatingsSchemaType,
  postRatingsSchema
} from "features/schools/constants/postRatingsSchema";
import FormRating from "components/molecules/FormRating";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

type RatingFormProps = {
  postRating: (data: postRatingsSchemaType) => void;
};

const RatingForm: React.FC<RatingFormProps> = (props) => {
  const { postRating } = props;
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(postRatingsSchema)
  });
  const [requestObject] = useAtom(requestObjectAtom);

  const onSubmit = methods.handleSubmit((data) => {
    postRating(data);
  });

  const lessonText = `以下の観点で評価をお願いします。
①依頼目的に沿った内容や授業構成・形態であり、児童・生徒にとってわかりやすかった。
②教材（スライド・事前課題等）や場作り・発問等は児童・生徒たちの学びを助長させた。
③児童・生徒に対して新たな気づき・発見や学びを与えた。`;

  const teacherText = `以下の観点で評価をお願いします。
①複業先生は児童・生徒の実態に合った適切な声量、表情、話し方、接し方をしていた。
②全体（授業計画〜授業実施）を通して複業先生の熱意が感じられた。
③事前連絡や準備（チャットの送受信、資料の授受等）、当日の授業（遅刻等がない）を滞りなく行っていた。 `;

  // TODO: ratingの有無をpropsで受け取るようにする
  if (!requestObject.has_rating) {
    return (
      <section>
        <Box
          sx={{
            backgroundColor: "#FFF5DD",
            margin: "1rem auto",
            padding: { xs: "1rem 2rem", sm: "2rem 2rem" },
            width: "100%"
          }}
        >
          <Typography
            align="center"
            fontWeight="bold"
            sx={{
              fontSize: "1.1rem",
              margin: "1rem auto"
            }}
          >
            今回の授業についての評価
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem"
                }}
              >
                <FormRating
                  name="lesson_rating"
                  label="授業の満足度"
                  supplementalText={lessonText}
                  isRequired
                  defaultValue={3}
                />
                <FormTextArea
                  name="lesson_rating_reason"
                  label="その理由を教えてください"
                  placeholder="例：子供たちが楽しみながら学べる授業内容でした。"
                  supplementalText="記入いただいた評価は複業先生のページのコメント欄に掲載されます。複業先生を選ぶ際に参考となるような理由をお願いします。"
                  isRequired
                />
                <FormRating
                  name="teacher_rating"
                  label="講師への評価"
                  supplementalText={teacherText}
                  isRequired
                  defaultValue={3}
                />
                <FormTextArea
                  name="teacher_rating_reason"
                  label="その理由を教えてください"
                  placeholder="例：細やかな事前準備にご協力いただきました。"
                  supplementalText="記入いただいた評価は複業先生のページのコメント欄に掲載されます。複業先生を選ぶ際に参考となるような理由をお願いします。"
                  isRequired
                />
                <FormSubmitButton label="送信" />
              </Box>
            </form>
          </FormProvider>
        </Box>
      </section>
    );
  }
  return <Box sx={{ display: "none" }} />;
};

export default RatingForm;
