import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";

import FormLabel from "components/atoms/FormLabel";
import { replyCommentAtom, teacherObjectAtom } from "store/RequestShowStore";

import type React from "react";

const ReplyCommentField: React.FC = () => {
  const [replyComment, setReplyComment] = useAtom(replyCommentAtom);
  const [teacherObject] = useAtom(teacherObjectAtom);
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyComment(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box sx={{ flex: 1 }}>
        <FormLabel
          labelName="学校へ向けてメッセージを入力してください。"
          isRequired
        />
      </Box>
      <TextField
        variant="outlined"
        value={replyComment}
        onChange={handleChange}
        multiline
        rows={underSm ? 6 : 4}
        disabled={!teacherObject.is_identification}
        FormHelperTextProps={{
          sx: {
            backgroundColor: "#FFF5DD",
            margin: 0,
            padding: "3px 14px 0"
          }
        }}
        placeholder="例：よろしくお願い致します。&#13;&#10;
        （断る場合）大変恐縮ながら、今回は仕事の繁忙期のため都合が合わず、辞退させて頂きます。またの機会がございましたら、どうぞよろしくお願いいたします。"
        sx={{
          width: "100%",
          backgroundColor: teacherObject.is_identification
            ? "#FFFFFF"
            : theme.palette.backgroundColor?.main
        }}
      />
    </Box>
  );
};

export default ReplyCommentField;
