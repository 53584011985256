import { FormControl, Grid, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormLabel from "components/atoms/FormLabel";
import Radio from "components/atoms/fields/Radio";

import { useFormContext, Controller } from "react-hook-form";

type FormRadioGroupProps = {
  name: string;
  label: string;
  options: { label: string; value: string | number | boolean }[];
  supplementalText?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  row?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
};

const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  const { control } = useFormContext();
  const {
    name,
    label,
    options,
    supplementalText,
    isRequired,
    row,
    noLabel,
    disabled = false
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "0.25rem"
        }}
      >
        {noLabel ? (
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {label}
          </Typography>
        ) : (
          <FormLabel labelName={label} isRequired={isRequired} />
        )}
        {supplementalText && (
          <Typography variant="caption" sx={{ color: theme.palette.sub.main }}>
            {supplementalText}
          </Typography>
        )}
      </Box>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <RadioGroup
              row
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: "100%",
                gap: ".5rem"
              }}
            >
              <Grid container spacing={2}>
                {options.map((option, index) => (
                  <Grid key={index} item xs={12} sm={row ? 12 : 6}>
                    <Radio
                      {...field}
                      key={index}
                      value={option.value}
                      label={option.label}
                      fullWidth
                      sx={{
                        margin: "0"
                      }}
                      // boolean型の場合は文字列に変換して比較
                      checked={
                        field.value === undefined || field.value === null
                          ? false
                          : field.value.toString() === option.value.toString()
                      }
                      disabled={disabled}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default FormRadioGroup;
