import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type AppealFactorProps = {
  Icon: string;
  title: string;
  desc: Array<string>;
};

const AppealFactor: React.FC<AppealFactorProps> = (props) => {
  const theme = useTheme();
  const { Icon, title, desc } = props;

  return (
    <Box
      sx={{
        border: `solid 1px ${theme.palette.muted?.main}`,
        borderRadius: "4px",
        width: { xs: "100%", sm: "30%" },
        maxWidth: { xs: 480, sm: 280 }
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFF6DE",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: ".5rem",
          padding: "1.5rem",
          borderRadius: "4px 4px 0 0"
        }}
      >
        <Box
          component="img"
          src={Icon}
          alt={title}
          sx={{
            height: 64,
            width: "auto",
            objectFit: "scale-down"
          }}
        />
        <Typography
          align="center"
          variant="h5"
          fontWeight="bold"
          fontSize="1.2rem"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ padding: "1.5rem" }}>
        <Typography align="center">
          {desc.map((item, index) => (
            <Typography
              component="span"
              key={index}
              sx={{ display: "inline-block" }}
            >
              {item}
            </Typography>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};

export default AppealFactor;
