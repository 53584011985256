import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import VoiceImage01 from "assets/schoolLp/VoiceImage01.png";
import VoiceImage02 from "assets/schoolLp/VoiceImage02.png";
import VoiceImage03 from "assets/schoolLp/VoiceImage03.png";
import VoiceBox from "features/root/components/molecules/schoolHome/VoiceBox";

import type React from "react";

// SwiperCore.use([Pagination, Autoplay]);
SwiperCore.use([Navigation]);

const Voice: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const overLg = useMediaQuery(theme.breakpoints.up("lg"));

  const perView = () => {
    if (isXs) {
      return 1.2;
    }
    if (isSm) {
      return 1.6;
    }
    if (isMd) {
      return 2.2;
    }
    return 3;
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "#FFF6DE",
        padding: "1.5rem 0 2rem"
      }}
    >
      <Box sx={{ margin: "0 auto 1.5rem" }}>
        <Typography
          variant="h2"
          align="center"
          color="#FFE6AD"
          fontSize="2.4rem"
        >
          VOICE
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{
            margin: "1rem 0"
          }}
          fontSize="1.5rem"
        >
          利用した学校の声
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: "1260px",
          margin: "1rem auto"
        }}
      >
        {overLg ? (
          <Grid container>
            <Grid item xs={12} lg={4}>
              <VoiceBox
                title01="児童や教員にとっても"
                title02="大きな学びになりました。"
                contents="『どんな大人が、関わってくれたか』が、子供たちの今後にとても重要なことだと思ってます。そのような意味でも、複業先生との連携授業は、学年の児童や教員にとって大きな学びとなりました。"
                avatar={VoiceImage01}
                belongs="公立小学校・教頭"
                age="40代"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <VoiceBox
                title01="生徒たちの可能性を広げる"
                title02="ことができると感じました。"
                contents="生徒たちの可能性を広げることのできるコンテンツであると、私自身はっきりと感じることができました。様々な人が関わることによるメリット（複眼的思考やアンテナの多様さ）が、今後の教育において重要であると考えています。"
                avatar={VoiceImage02}
                belongs="私立中高・社会科教諭"
                age="30代"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <VoiceBox
                title01="多様な経歴を持つ複業先生の"
                title02="授業で、進路の幅が広がった。"
                contents="多様なバックグラウンドを持つ複業先生の会社での働きぶりや大学での研究内容等を聞き、未来への自己投資を直に学べることで、進路の広がりを生徒は感じたと思います。年齢の近い現役大学生の複業先生によるコマで、リアルな話も聞くことができました。"
                avatar={VoiceImage03}
                belongs="公立高校・進路指導"
                age="50代"
              />
            </Grid>
          </Grid>
        ) : (
          <Swiper
            slidesPerView={perView()}
            navigation
            centeredSlides
            spaceBetween={30}
            initialSlide={0}
          >
            <SwiperSlide>
              <VoiceBox
                title01="児童や教員にとっても"
                title02="大きな学びになりました。"
                contents="『どんな大人が、関わってくれたか』が、子供たちの今後にとても重要なことだと思ってます。そのような意味でも、複業先生との連携授業は、学年の児童や教員にとって大きな学びとなりました。"
                avatar={VoiceImage01}
                belongs="公立小学校・教頭"
                age="40代"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceBox
                title01="生徒たちの可能性を広げる"
                title02="ことができると感じました。"
                contents="生徒たちの可能性を広げることのできるコンテンツであると、私自身はっきりと感じることができました。様々な人が関わることによるメリット（複眼的思考やアンテナの多様さ）が、今後の教育において重要であると考えています。"
                avatar={VoiceImage02}
                belongs="私立中高・社会科教諭"
                age="30代"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceBox
                title01="多様な経歴を持つ複業先生の"
                title02="授業で、進路の幅が広がった。"
                contents="多様なバックグラウンドを持つ複業先生の会社での働きぶりや大学での研究内容等を聞き、未来への自己投資を直に学べることで、進路の広がりを生徒は感じたと思います。年齢の近い現役大学生の複業先生によるコマで、リアルな話も聞くことができました。"
                avatar={VoiceImage03}
                belongs="公立高校・進路指導"
                age="50代"
              />
            </SwiperSlide>
          </Swiper>
        )}
      </Box>
      <Typography
        align="center"
        fontSize="1.1rem"
        sx={{
          margin: "1rem"
        }}
      >
        <Typography component="span" sx={{ display: "inline-block" }}>
          複業先生の授業を活用することで、
        </Typography>
        <br />
        <Typography component="span" sx={{ display: "inline-block" }}>
          生徒たちに
          <Typography component="span" fontSize="1.3rem" fontWeight="bold">
            “科目の枠を超えた
          </Typography>
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          <Typography component="span" fontSize="1.3rem" fontWeight="bold">
            学習の場”
          </Typography>
          を提供できます。
        </Typography>
      </Typography>
    </Box>
  );
};

export default Voice;
