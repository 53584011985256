import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useAspidaSWR from "@aspida/swr";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { useAtom } from "jotai";

import {
  candidatesCountAtom,
  candidatesAtom,
  recruitmentAtom,
  lessonOptionsAtom,
  selectCandidateFormStepAtom,
  recruitmentIdAtom
} from "store/schools/recruitments/ShowStore";
import { useForm } from "react-hook-form";
import { Methods as SelectTeacherMethods } from "api/api/v1/lesson_recruitments/_lessonRecruitmentId@string/select_teacher";

const useShowPageApi = (id: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [, setRecruitment] = useAtom(recruitmentAtom);
  const [, setCandidates] = useAtom(candidatesAtom);
  const [, setCandidatesCount] = useAtom(candidatesCountAtom);
  const [, setLessonOptions] = useAtom(lessonOptionsAtom);
  const [, setRecruitmentId] = useAtom(recruitmentIdAtom);
  const [, setFormStep] = useAtom(selectCandidateFormStepAtom);

  const selectCandidateForm =
    useForm<SelectTeacherMethods["patch"]["reqBody"]>();

  const { isLoading } = useAspidaSWR(
    aspida(headers)
      .api.v1.school_teachers._schoolTeacherId_string(
        currentSchool?.id.toString()
      )
      .lesson_recruitments._lessonRecrutimentId(id),
    {
      onSuccess: (response) => {
        setCandidatesCount(response.candidates_count);
        setCandidates(response.candidates.data);
        setRecruitment(response.details);
        setLessonOptions(response.lesson_options);
      },
      onError: () => {
        addErrorMessage("募集の取得に失敗しました");
      }
    }
  );

  const selectCandidate = async () => {
    try {
      const body = selectCandidateForm.getValues();
      const res = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .select_teacher.patch({
          body
        });
      if (res.status === 200) {
        setRecruitmentId(res.body.details.id);
        setFormStep(4);
      }
    } catch (error) {
      addErrorMessage("候補者選択ができませんでした");
    }
  };

  return { isLoading, selectCandidate, selectCandidateForm };
};

export default useShowPageApi;
