import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import type React from "react";
import TermAcceptedLabel from "components/atoms/TermAcceptedLabel";
import FormCheckBox from "components/molecules/FormCheckBox";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormTextField from "components/molecules/FormTextField";

type SignupFormProps = {
  variant: "teacher" | "school";
};

const SignupForm: React.FC<SignupFormProps> = (props) => {
  const { variant } = props;
  const theme = useTheme();

  return (
    <Box width="100%">
      <Typography variant="h2">
        {variant === "teacher" ? "複業先生" : "教員"}アカウント新規登録
      </Typography>
      <FormGroup>
        <Box
          sx={{
            margin: "1.5rem auto",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%"
          }}
        >
          <FormTextField
            name="email"
            label="メールアドレス"
            noLabel
            placeholder="sample@fukugyo.co.jp"
            fullWidth
          />
          <FormPasswordField name="password" label="パスワード" />
          <FormCheckBox name="is_term_accepted" label={<TermAcceptedLabel />} />
          <FormSubmitButton label="新規登録" size="large" fullWidth />
        </Box>
      </FormGroup>
      <Typography align="center" sx={{ margin: "1rem auto" }}>
        すでにアカウントをお持ちですか？
        <Box
          component={RouterLink}
          to={variant === "teacher" ? "/teachers/login" : "/schools/login"}
          sx={{
            textDecoration: "none",
            color: theme.palette.secondary.main,
            fontWeight: 600
          }}
        >
          ログインする
        </Box>
      </Typography>
    </Box>
  );
};

export default SignupForm;
