import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type NewRecruitmentLabelProps = {
  flexStart?: boolean;
};

const NewRecruitmentLabel: React.FC<NewRecruitmentLabelProps> = (props) => {
  const { flexStart } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
        padding: ".25rem .5rem",
        alignSelf: flexStart ? "baseline" : "center"
      }}
    >
      <Typography
        align="center"
        fontSize=".8rem"
        color={theme.palette.common.white}
        fontWeight="bold"
      >
        新着
      </Typography>
    </Box>
  );
};

export default NewRecruitmentLabel;
