import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import reactStringReplace from "react-string-replace";

import useGetRequest from "features/schools/hooks/useGetRequest";
import usePostFormDataRequest from "features/schools/hooks/usePostFormDataRequest";
import {
  schoolAnnouncementsAtom,
  schoolDialogIsOpenAtom,
  schoolAnnouncementIsErrorAtom
} from "store/AnnouncementsStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";

type AnnouncementBoxProps = {
  id: number;
  title: string;
  contents: string;
};

const AnnouncementBox: React.FC<AnnouncementBoxProps> = (props) => {
  const { id, title, contents } = props;
  const theme = useTheme();
  const [, setIsOpen] = useAtom(schoolDialogIsOpenAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [, setAnnouncements] = useAtom(schoolAnnouncementsAtom);
  const [, setIsError] = useAtom(schoolAnnouncementIsErrorAtom);
  const regExp = /(https?:\/\/\S+)/g;

  const { postFormDataRequest } = usePostFormDataRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/school_announcements/${id}/announcement_check`
  });

  const { getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/school_announcements`
  });

  const handleConfirm = () => {
    postFormDataRequest({
      onSuccess: () => {
        getRequest({
          onSuccess: async (response) => {
            await handleDialogOpen(response.data.length);

            // MEMO: ダイアログ開閉の有無を確認→アニメーション終了後に更新。
            // アニメーションは195msのため、200ms後にスケジュール
            await setTimeout(() => {
              setAnnouncements(response.data);
            }, 200);
          },
          onError: () => {
            setIsError(true);
          }
        });
      },
      onError: () => {
        setIsError(true);
      }
    });
  };

  const handleDialogOpen = (announcements_count: number) => {
    if (announcements_count > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".75rem 0"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem 0",
          border: `solid 1px ${theme.palette.border?.main}`,
          padding: "1rem"
        }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: 1.5
          }}
        >
          {reactStringReplace(contents, regExp, (match) => (
            <a href={match} target="_blank" rel="noreferrer">
              {match}
            </a>
          ))}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleConfirm}
        sx={{ fontWeight: "bold" }}
      >
        確認しました
      </Button>
    </Box>
  );
};

export default AnnouncementBox;
