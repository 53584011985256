import type React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import getPrice from "utils/calcPrice";
import { useFormContext } from "react-hook-form";
import FormCheckBox from "components/molecules/FormCheckBox";
import TermAcceptedLabel from "components/atoms/TermAcceptedLabel";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { stepAtom } from "store/recruitments/NewStore";
import { useAtom } from "jotai";
import FieldsFrame from "./FieldsFrame";

const ConfirmContentsBox: React.FC = () => {
  const theme = useTheme();
  const [, setStep] = useAtom(stepAtom);
  const { watch } = useFormContext();
  const schoolMinutes = watch("school_minutes");
  const price = getPrice(schoolMinutes || 0);
  return (
    <FieldsFrame>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          padding: "1rem",
          border: `1px solid ${theme.palette.border?.main}`,
          borderRadius: "4px",
          backgroundColor: theme.palette.backgroundColor?.main
        }}
      >
        <Typography fontWeight="bold">※想定される金額について</Typography>
        <Typography>
          ・講師料：1回あたり{`¥${price} ~`}
          （税抜）
          <br />
          ・サポート料：1回あたり{`¥${5000} ~`}（税抜・任意）
        </Typography>
      </Box>
      <FormCheckBox name="is_term_accepted" label={<TermAcceptedLabel />} />
      <FormSubmitButton
        label="募集内容を確認する"
        // typeを上書き。disabledの判定をそのまま使いたいので、FormSubmitButtonを使用
        type="button"
        onClick={() => {
          setStep(2);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      />
    </FieldsFrame>
  );
};

export default ConfirmContentsBox;
