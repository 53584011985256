import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const usePageTransitionEffect = (callback: () => void) => {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    if (
      prevLocationRef.current.pathname !== location.pathname ||
      prevLocationRef.current.search !== location.search
    ) {
      callback();
    }
    prevLocationRef.current = location;
  }, [callback, location]);
};

export default usePageTransitionEffect;
