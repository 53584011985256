import Pagination from "@mui/lab/Pagination";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import useQuery from "hooks/useQuery";
import { paginationAtom, pageAtom } from "store/recruitments/IndexStore";

import type React from "react";

const RecruitmentsPagination: React.FC = () => {
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [, setPage] = useAtom(pageAtom);
  const { setQuery, deleteQuery } = useQuery();

  const handleChange = (page: number) => {
    if (!pagination) return;
    setPagination({ ...pagination, current: page });
    setPage(page);

    page && page !== 1
      ? setQuery("page", page.toString())
      : deleteQuery("page");
  };

  return (
    <Box
      sx={{
        padding: "2rem 0",
        width: "100%"
      }}
    >
      {pagination && pagination.count !== 0 && (
        <Pagination
          count={pagination.pages}
          color="primary"
          variant="outlined"
          shape="rounded"
          onChange={(e, page) => handleChange(page)}
          page={pagination.current}
        />
      )}
    </Box>
  );
};

export default RecruitmentsPagination;
