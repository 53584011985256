import Box from "@mui/material/Box";

import RecruitmentIcon from "assets/schools/mypage/RecruitmentIcon.png";
import RequestIcon from "assets/schools/mypage/RequestIcon.png";
import GuideCard from "features/schools/components/molecules/mypage/v2/home/GuideCard";
import GuidesTitle from "features/schools/components/molecules/mypage/v2/home/GuidesTitle";

import type React from "react";

const Guides: React.FC = () => (
  <Box
    component="section"
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "1rem"
    }}
  >
    <GuidesTitle />
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "center", md: "start" }
      }}
    >
      <GuideCard
        title="個人に直接依頼"
        description={[
          "豊富な複業先生データベースから",
          "人材を選んで、直接依頼"
        ]}
        Image={RequestIcon}
        buttonTitle="複業先生を探す"
        link="/teachers"
      />
      <GuideCard
        title="募集を出す"
        description={[
          "依頼したい授業内容を作成し、",
          "複業先生からの応募を待つだけ"
        ]}
        Image={RecruitmentIcon}
        buttonTitle="募集内容を作成"
        link="/recruitments/new"
      />
    </Box>
  </Box>
);

export default Guides;
