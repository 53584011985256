import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RequestItem from "features/teachers/components/molecules/mypage/v2/home/RequestItem";
import ErrorAlert from "components/atoms/ErrorAlert";
import CommonLoading from "components/atoms/CommonLoading";
import NoContentItem from "features/teachers/components/molecules/mypage/v2/home/NoContentItem";

import { currentUserAtom } from "store/AuthStore";
import { teacherRequestsAtom } from "store/teachers/mypage/HomeStore";

import useGetRequest from "features/teachers/hooks/useGetRequest";

import RequestIcon from "assets/common/sidebar/unselected/teachers/request.svg";
import useLimitCheck from "hooks/useLimitCheck";

const Requests: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherRequests, setteacherRequests] = useAtom(teacherRequestsAtom);
  const { getLimit } = useLimitCheck();

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_requests`
  });

  useEffect(() => {
    const params = {
      status: "only_request",
      limit: 3
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setteacherRequests(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="依頼の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: "100%"
        }}
      >
        <CommonMypageHomeSectionTitle
          Icon={<img src={RequestIcon} alt="授業依頼" />}
          title="授業依頼"
          count={teacherRequests?.data_count || 0}
          linkName="すべての依頼を見る"
          link="/teachers/mypage?page_name=requests"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%",
            height: "100%"
          }}
        >
          {!isLoading ? (
            teacherRequests && teacherRequests.data.length > 0 ? (
              teacherRequests.data.map((teacherRequest, index) => {
                const acceptDeadAt = new Date(
                  teacherRequest.details.accept_dead_at
                );
                const { limit } = getLimit(String(acceptDeadAt));

                return (
                  <React.Fragment key={index}>
                    <RequestItem
                      id={teacherRequest.details.id}
                      schoolName={teacherRequest.send_user.school_name}
                      schoolTeacherName={teacherRequest.send_user.name}
                      schoolTeacherImage={teacherRequest.send_user.image.url}
                      isCertification={
                        teacherRequest.send_user.is_match_accepted
                      }
                      limit={limit}
                      step={teacherRequest.details.step}
                    />
                    <Divider sx={{ width: "100%" }} />
                  </React.Fragment>
                );
              })
            ) : (
              <NoContentItem
                title="まだ授業依頼はありません"
                lead="講師紹介文を充実させると依頼がきやすくなります。"
                link="/teachers/profile/edit"
                buttonText="プロフィールを編集する"
              />
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Requests;
