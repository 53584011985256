import React, { useState } from "react";

import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Pagination
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import theme from "themes/theme";
import useMasterFinantialBranchesApi from "hooks/useMasterFinantialBranchesApi";

interface SelectBankModalProps extends CommonDialogProps {
  institutionCode: string;
  handleSelect: (bank: { code: string; name: string }) => void;
}

const SelectBankModal: React.FC<SelectBankModalProps> = (props) => {
  const { isOpen, onClose, handleSelect, institutionCode } = props;
  const [searchWord, setSearchWord] = useState("");
  const [page, setPage] = useState(1);

  const { data, isLoading, search } =
    useMasterFinantialBranchesApi(institutionCode);
  const branches = data?.branches?.slice((page - 1) * 10, page * 10);
  const count = data?.count;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const handleSearch = () => {
    search({
      search_name: searchWord
    });
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%"
        }}
      >
        <Typography align="center" fontSize="1.2rem" fontWeight="bold">
          支店検索
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            gap: "1rem",
            flex: 1
          }}
        >
          <TextField
            id="school-search-word"
            variant="outlined"
            value={searchWord}
            onChange={handleChange}
            placeholder="支店名の一部を入力してください"
            fullWidth
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disableElevation
            disabled={isLoading || !searchWord}
            startIcon={<SearchIcon />}
            type="submit"
            sx={{
              width: "8rem",
              padding: { xs: "4px 8px", sm: "12px, 24px" }
            }}
          >
            検索
          </Button>
        </Box>
        {branches && branches.length > 0 && (
          <List>
            {branches?.map((bank) => (
              <ListItem
                key={bank.code}
                onClick={() => handleSelect(bank)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: ".5rem",
                  borderBottom: theme.palette.divider
                }}
              >
                <Typography>{bank.name}</Typography>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => handleSelect(bank)}
                  sx={{
                    fontWeight: "bold",
                    padding: { xs: "4px 8px", sm: "12px, 24px" }
                  }}
                >
                  選択
                </Button>
              </ListItem>
            ))}
          </List>
        )}
        {!!count && count > 10 && (
          <Pagination
            count={Math.ceil(count / 10)}
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={(e, n) => setPage(n)}
            page={page}
          />
        )}
      </Box>
    </CommonDialog>
  );
};

export default SelectBankModal;
