import {
  DndContext,
  closestCenter,
  TouchSensor,
  useSensor,
  useSensors,
  MouseSensor
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import { useAtom, useAtomValue } from "jotai";

import TimelineCard from "features/requests/components/molecules/meetingSheet/TimelineCard";
import { editAuthAtom, requestObjectAtom } from "store/MeetingSheetStore";

import type React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ReqBodyType } from "features/requests/hooks/useMeetingSheet";
import Button from "components/atoms/mui/Button";

interface TimelineSectionProps {
  addTimeline: () => void;
  removeTimeline: (id: number) => void;
  moveTimeline: (oldIndex: number, newIndex: number) => void;
  timelines: ReqBodyType["timelines"];
}

const TimelineSection: React.FC<TimelineSectionProps> = (props) => {
  const { addTimeline, removeTimeline, moveTimeline, timelines } = props;
  const theme = useTheme();
  const { control } = useFormContext<ReqBodyType>();

  const [editAuth] = useAtom(editAuthAtom);
  const requestObject = useAtomValue(requestObjectAtom);
  const schoolMinutes = requestObject?.school_minutes || 0;
  const totalTime =
    useWatch({
      control,
      name: "timelines"
    })?.reduce((acc, timeline) => acc + (Number(timeline.minutes) || 0), 0) ||
    0;
  const isOver = totalTime > schoolMinutes;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (timelines && active && over && active.id !== over.id) {
      const oldIndex = timelines.findIndex((field) => field.id === active.id);
      const newIndex = timelines.findIndex((field) => field.id === over.id);
      moveTimeline(oldIndex, newIndex);
    }
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Typography variant="h3">授業の時間配分</Typography>
      <Typography>
        授業のタイムラインを記入しましょう。
        <br />
        タイムラインは後から並び替えることもできます。
      </Typography>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={timelines || []}
          strategy={verticalListSortingStrategy}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            {timelines?.map((timeline, index) => (
              <TimelineCard
                key={timeline?.id}
                id={timeline?.id}
                index={index}
                minutes={timeline?.minutes || 0}
                title={timeline?.title || ""}
                memo={timeline?.memo || ""}
                removeTimeline={() => {
                  if (timeline.id) {
                    removeTimeline(timeline.id);
                  }
                }}
              />
            ))}
          </Box>
        </SortableContext>
      </DndContext>
      {editAuth?.has_auth && (
        <Button
          variant="outlined"
          color="secondary"
          disableElevation
          fullWidth
          startIcon={<Add />}
          onClick={() => {
            addTimeline();
          }}
        >
          コンテンツを追加
        </Button>
      )}
      <Box>
        <Typography align="right" fontWeight="bold">
          合計時間：
          <Typography
            component="span"
            fontWeight="bold"
            fontSize="1.5rem"
            color={
              isOver ? theme.palette.error.main : theme.palette.secondary.main
            }
          >
            {totalTime}
          </Typography>{" "}
          分 / {schoolMinutes}分
        </Typography>
      </Box>
    </Box>
  );
};

export default TimelineSection;
