import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";

type ProcedureCardProps = {
  number: number;
  image: string;
  title: string;
  desc: string;
};

const ProcedureCard: React.FC<ProcedureCardProps> = (props) => {
  const { number, image, title, desc } = props;

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        margin: { xs: "2.4rem auto", md: "2.4rem 1.5rem" }
      }}
    >
      <Avatar
        sx={{
          backgroundColor: "primary.main",
          position: "absolute",
          left: { xs: "calc(50% - 20px)", md: "-25px" },
          top: { xs: "-25px", md: "calc(50% - 20px)" }
        }}
      >
        {number}
      </Avatar>
      <Card elevation={0}>
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                textAlign: "center",
                background: "linear-gradient(to right, #FFEFC7, #FFDBAA)"
              }}
            >
              <img src={image} alt={title} height="100%" />
            </Grid>
            <Grid item xs={12} md={8} p="1.5rem">
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: "1.2rem", md: "1.3rem" },
                  lineHeight: "1.8 !important"
                }}
              >
                {title}
              </Typography>
              <Typography variant="body2">{desc}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default ProcedureCard;
