import { Box, Typography } from "@mui/material";

interface DetailSectionProps {
  title: string;
  content?: string | React.ReactNode;
}

const DetailItem: React.FC<DetailSectionProps> = ({ title, content }) => {
  if (!content) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography fontWeight="bold">{title}</Typography>
      {typeof content === "string" ? (
        <Typography>{content}</Typography>
      ) : (
        content
      )}
    </Box>
  );
};

export default DetailItem;
