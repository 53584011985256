import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import Meta from "components/organisms/Meta";
import AiTeachingPlanDisplay from "features/schools/components/organisms/mypage/AiTeachingPlan/AiTeachingPlanDisplay";
import FirstView from "features/schools/components/organisms/mypage/AiTeachingPlan/FirstView";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";

const AiTeachingPlan: React.FC = () => {
  const theme = useTheme();

  return (
    <CommonLayout>
      <Meta title="複業先生｜AIによる授業案生成の結果" />
      <FirstView />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="sm"
          sx={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <AiTeachingPlanDisplay />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default AiTeachingPlan;
