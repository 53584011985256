import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CreateAcountButton from "features/root/components/atoms/schoolHome/CreateAcountButton";

import type React from "react";

const FirstViewContents: React.FC = () => (
  <Box
    sx={{
      margin: "0 auto",
      width: { xs: "90%", md: "100%" }
    }}
  >
    <Typography
      variant="h1"
      sx={{
        margin: "1rem auto",
        fontSize: {
          xs: "1.5rem",
          sm: "calc(1.3rem + 1vw)",
          md: "calc(1rem + 1vw)"
        },
        lineHeight: { xs: "1.5", sm: "1.8", md: "2" }
      }}
    >
      外部人材を活用した
      <Box
        component="br"
        sx={{ display: { xs: "block", sm: "none", md: "block" } }}
      />
      授業をもっと気軽に。
    </Typography>
    <Typography sx={{ margin: "1rem auto" }}>
      生徒たちに“科目の枠を超えた学習の場”を。
      <br />
      教育現場と外部人材をつなぎ、
      <br />
      授業までサポートします。
    </Typography>
    <CreateAcountButton />
  </Box>
);

export default FirstViewContents;
