import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import FirstView from "features/requests/components/organisms/New/FirstView";
import PossibleDateForm from "features/requests/components/organisms/New/PossibleDateForm";
import PriceBox from "features/requests/components/organisms/New/PriceBox";
import RequestForm from "features/requests/components/organisms/New/RequestForm";
import TeacherCard from "features/requests/components/organisms/New/TeacherCard";
import useQuery from "hooks/useQuery";
import CommonLayout from "components/layouts/CommonLayout";

import { teacherProfileAtom, lessonOptionsAtom } from "store/RequestNewStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import useNewPageApi from "features/requests/hooks/useNewPageApi";

import newFormSchema, {
  NewFormSchemaType
} from "features/requests/constants/newFormSchema";
import newFormDefaultValues from "features/requests/constants/newFormDefaultValues";
import { useAlertMessages } from "hooks/useAlertMessages";

const New: React.FC = () => {
  const { getQuery } = useQuery();
  const [teacherProfile] = useAtom(teacherProfileAtom);
  const [lesson_options] = useAtom(lessonOptionsAtom);
  const { postNewRequest, postGenerateTeachingPlan, getIsLoading } =
    useNewPageApi(Number(getQuery("id")));
  const theme = useTheme();

  const history = useHistory();
  const { addErrorMessage } = useAlertMessages();

  const [currentSchool] = useAtom(currentSchoolAtom);

  const methods = useForm<NewFormSchemaType>({
    resolver: yupResolver(newFormSchema),
    mode: "onChange",
    defaultValues: newFormDefaultValues
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    const { response, error } = await postNewRequest(data);
    if (error) {
      addErrorMessage("リクエストの送信に失敗しました。");
      return;
    }
    history.push("/requests/thanks-request");
    if (response && response.body.details?.id) {
      const { error } = await postGenerateTeachingPlan(
        response.body.details.id.toString()
      );
      if (error) {
        addErrorMessage("授業案の作成に失敗しました。");
      }
    }
  });

  useEffect(() => {
    methods.setValue("teacher_id", teacherProfile?.teacher_id || 0);
    !currentSchool.is_match_accepted &&
      methods.setValue(
        "lesson_option_ids",
        lesson_options.map((option) => option.id)
      );
  }, [teacherProfile?.teacher_id]);

  return (
    <CommonLayout>
      <Meta />

      {!getIsLoading ? (
        <>
          <FirstView />
          <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Container maxWidth="md">
                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <Box
                        sx={{
                          padding: "1.5rem 0",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1.5rem"
                        }}
                      >
                        <TeacherCard />
                        <PossibleDateForm />
                        <RequestForm />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <PriceBox handleSubmit={onSubmit} />
                    </Grid>
                  </Grid>
                </Container>
              </form>
            </FormProvider>
          </Box>
        </>
      ) : (
        <Loading headerOnly />
      )}
    </CommonLayout>
  );
};

export default New;
