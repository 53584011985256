import * as yup from "yup";
import { Methods } from "api/api/v1/school_teacher_profiles/_schoolTeacherProfileId@string";

type ProfileEditFormSchemaType = Methods["put"]["reqBody"];

const profileEditFormSchema: yup.ObjectSchema<ProfileEditFormSchemaType> = yup
  .object()
  .shape({
    school_code: yup.string().optional(),
    school_name: yup.string().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.string().required("学校名は必須です"),
      otherwise: () => yup.string().optional()
    }),
    school_division_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("学校区分は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_type_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("運営主体は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_prefecture_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("学校の所在都道府県は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_address: yup.string().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.string().required("学校住所は必須です"),
      otherwise: () => yup.string().optional()
    }),
    manage_grade: yup.string().optional(),
    manage_subject: yup.string().optional(),
    school_job: yup.string().optional(),
    image: yup.string().optional(),
    image_file_name: yup.string().optional(),
    is_mail_magazine: yup.boolean().required()
  });

export default profileEditFormSchema;
