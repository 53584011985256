import { Helmet } from "react-helmet-async";

import type React from "react";

type MetaProps = {
  title?: string;
  description?: string;
  noIndex?: boolean;
};

const Meta: React.VFC<MetaProps> = (props) => {
  const { title, description, noIndex } = props;

  return (
    <Helmet>
      <title>{title || "複業先生"}</title>
      <meta
        name="description"
        content={
          description ||
          "複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。これまでの仕事での経験を活かした授業をすることができます。"
        }
      />
      <meta property="og:title" content="複業先生" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={
          description ||
          "複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。これまでの仕事での経験を活かした授業をすることができます。"
        }
      />
      <meta property="og:site_name" content="複業先生" />
      <meta property="og:image" content="https://fukugyo-sensei.net/ogp.png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Lxdesign321" />
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default Meta;
