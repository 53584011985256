import { atom } from "jotai";
import { Methods as RequestCommentsMethods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";

import type {
  teacherObjectAtomType,
  schoolObjectAtomType,
  requestObjectAtomType
} from "types/LessonRequestTypes";

type meetingSheetDataAtomType = {
  is_confirmed: boolean;
  is_online: boolean;
  is_rehearsal: boolean;
  is_transmit: boolean;
  place: string;
  device: string;
  purpose: string;
  rehearsal_at: Date | "";
  school_advance_todo: string;
  start_at: Date | "";
  teacher_advance_todo: string;
  time_allocation: string;
  tool: string;
};

// for chat
export type fileType = {
  url: string;
  name: string;
  size: number;
};

type lessonRequestCommentsType = {
  id: number;
  comment: string;
  file: fileType;
  created_at: Date;
  updated_at: Date;
  is_deleted: boolean;
  is_office_notification: boolean;
  send_user: {
    category: string;
    id: number;
    name: string;
    image: {
      url: string;
    };
  };
};

// type lessonRequestCommentsType = {
//   id: number;
//   comment: string;
//   file: fileType;
//   created_at: Date;
//   updated_at: Date;
//   is_deleted: boolean;
//   send_user: {
//     category: string;
//     id: number;
//     name: string;
//     image: {
//       url: string;
//     };
//   };
// };

type supporterAtomType = {
  name: string;
};

// type chatIndexAtomType = Array<lessonRequestCommentsType>;
type chatIndexAtomType =
  RequestCommentsMethods["get"]["resBody"]["lesson_request_comments"];

// for request accept
type PossibleDateProps = {
  id: number;
  is_adjusted: boolean;
  start_at_datetime: string;
};

export const teacherObjectAtom = atom<teacherObjectAtomType>({
  id: 0,
  teacher_id: 0,
  teacher_profile_id: 0,
  origin_prefecture_id: "",
  name: "",
  kana_name: "",
  email: "",
  phone_number: "",
  birthday: "",
  organization_name: "",
  is_organization_name_public: false,
  origin_high_school_name: "",
  origin_school_name: "",
  license: "",
  introduction: "",
  frequency: "",
  is_experience_lesson: false,
  know_from: "",
  enthusiasm: "",
  image: {
    url: ""
  },
  is_public: false,
  is_limited_public: false,
  is_match_accepted: false,
  is_identification: false,
  is_identification_in_progress: false,
  teacher_rank: "",
  total_rating: 0,
  rating_count: 0,
  tags: [],
  organization_categories: [],
  websites: [],
  teaching_licenses: []
});

export const schoolObjectAtom = atom<schoolObjectAtomType>({
  school_teacher_id: 0,
  school_teacher_profile_id: 0,
  school_prefecture_id: "",
  school_prefecture_name: "",
  school_type_name: "",
  school_division_name: "",
  name: "",
  kana_name: "",
  email: "",
  phone_number: "",
  birthday: "",
  school_name: "",
  school_address: "",
  manage_grade: "",
  manage_subject: "",
  school_job: "",
  image: {
    url: ""
  },
  is_public: false,
  is_match_accepted: false
});

export const requestObjectAtom = atom<requestObjectAtomType>({
  id: 0,
  tag: "",
  school_grade: "",
  student_number: 0,
  start_at: "",
  is_online: false,
  school_minutes: 0,
  lesson_content: "",
  lesson_options: [],
  accept_dead_at: "",
  student_condition: "",
  issue: "",
  is_term_accepted: false,
  canceled_at: null,
  accept_at: null,
  denied_at: null,
  reply_comment: "",
  lesson_completed_at: "",
  created_at: "",
  updated_at: "",
  step: "",
  // FIXME: 型の共有が良くないこと引き起こしてるかも
  exact_start_at: "",
  reward: 0,
  has_rating: false,
  is_recruitment: false,
  is_first_lesson: false
});

export const supporterAtom = atom<supporterAtomType>({
  name: "複業先生サポート"
});

export const meetingSheetDataAtom = atom<meetingSheetDataAtomType>({
  is_confirmed: false,
  is_online: false,
  is_rehearsal: false,
  is_transmit: false,
  place: "",
  device: "",
  purpose: "",
  rehearsal_at: "",
  school_advance_todo: "",
  start_at: "",
  teacher_advance_todo: "",
  time_allocation: "",
  tool: ""
});

export const requestCancelOpenAtom = atom(false);
export const replyCommentErrorAtom = atom(false);

export const updateIsLoadingAtom = atom(false);
export const updateSuccessAtom = atom(false);
export const updateErrorAtom = atom(false);

export const exactStartAtUpdateModalControl = atom(false);

export const changeExactStartAtIsSuccessAtom = atom(false);

export const beforeMeetingAtom = atom(true);

// for chat
export const chatIndexAtom = atom<chatIndexAtomType>([]);

// for exact start at
export const exactStartAtAtom = atom(new Date());

export const exactStartAtDateAtom = atom(
  (get) => get(exactStartAtAtom),
  (_get, set, update: Date) => {
    set(exactStartAtAtom, update);
  }
);

export const exactStartAtHoursAtom = atom(
  (get) => get(exactStartAtAtom).getHours(),
  (_get, set, update: number) => {
    const exactStartAt = _get(exactStartAtAtom);
    exactStartAt.setHours(update);
    set(exactStartAtAtom, new Date(exactStartAt));
  }
);

export const exactStartAtMinutesAtom = atom(
  (get) => get(exactStartAtAtom).getMinutes(),
  (_get, set, update: number) => {
    const exactStartAt = _get(exactStartAtAtom);
    exactStartAt.setMinutes(update);
    set(exactStartAtAtom, new Date(exactStartAt));
  }
);

export const exactStartAtIsErrorAtom = atom((get) => {
  const exactStartAt = get(exactStartAtAtom);
  if (exactStartAt < new Date()) {
    return true;
  }
  return false;
});

// for request accept
export const isAcceptAtom = atom(true);
export const possibleDateIdAtom = atom(0);
export const replyCommentAtom = atom("");

export const possibleDatesAtom = atom<Array<PossibleDateProps>>([]);

export const acceptSelectIsClickableAtom = atom((get) => {
  const isAccept = get(isAcceptAtom);
  const replyComment = get(replyCommentAtom);
  const possibleDateId = get(possibleDateIdAtom);
  const possibleDates = get(possibleDatesAtom);

  if (possibleDates.length === 0) {
    if (replyComment !== "") {
      return true;
    }
    return false;
  }

  if (isAccept && replyComment !== "" && possibleDateId !== 0) {
    return true;
  }
  if (!isAccept && replyComment !== "") {
    return true;
  }
  return false;
});
