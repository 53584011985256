const rewards = {
  JrLesson1time: 2200,
  JrLesson2time: 4400,
  JrLesson3time: 6600,
  MidLesson1time: 5000,
  MidLesson2time: 10000,
  MidLesson3time: 15000,
  ProLesson1time: 12300,
  ProLesson2time: 24600,
  ProLesson3time: 36900
};

export default rewards;
