import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import { useDropzone } from "react-dropzone";

import type React from "react";
import { postTeacherVideo } from "features/teachers/components/types/teacherVideoApiTypes";
import { typeofUseDialogRetrunType } from "hooks/useDialog";

type VideoControlModalProps = {
  keyForApi: string;
  postTeacherVideo: postTeacherVideo;
  fileUploadModalControl: typeofUseDialogRetrunType;
};

const VideoUploadModal: React.FC<VideoControlModalProps> = (props) => {
  const {
    keyForApi,
    postTeacherVideo,
    fileUploadModalControl: { onClose, isOpen }
  } = props;
  const theme = useTheme();

  const onDrop = async (files: File[]) => {
    await postTeacherVideo({
      file: files[0],
      key: keyForApi,
      file_name: keyForApi
    });

    await onClose();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "0px",
          zIndex: 1600
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 6,
            top: 6
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          width: theme.breakpoints.values.sm,
          height: theme.breakpoints.values.sm,
          position: "relative",
          padding: 0
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: ".5rem",
              cursor: "pointer"
            }}
          >
            <PublishIcon
              sx={{
                fontSize: "4.5rem",
                color: isDragActive ? theme.palette.primary.main : "inherit"
              }}
            />
            <Typography
              align="center"
              fontWeight="bold"
              sx={{
                color: isDragActive ? theme.palette.primary.main : "inherit"
              }}
            >
              アップロードする動画ファイルを
              <br />
              ドラッグ＆ドロップしてください。
            </Typography>
            <Typography
              align="center"
              fontSize="0.8rem"
              color={theme.palette.sub.main}
            >
              もしくは、ここをクリックしてファイルを選択してください。
              <br />
              ※アップロードには時間がかかる場合があります。
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoUploadModal;
