import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import RequireFormLabel from "features/root/components/atoms/contact/RequireFormLabel";
import { contactContentsAtom } from "store/ContactFormStore";

import type React from "react";

const ContactContentsField: React.FC = () => {
  const [contactContents, setContactContents] = useAtom(contactContentsAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactContents(event.target.value);
  };

  return (
    <Box
      sx={{
        margin: "1.5rem auto",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <RequireFormLabel labelName="お問い合わせ内容" />
      <Grid container>
        <Grid xs={12}>
          <TextField
            variant="outlined"
            placeholder="例：授業の日程が変更になってしまいました。どう対応すればいいでしょうか。"
            fullWidth
            multiline
            minRows={4}
            value={contactContents}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactContentsField;
