import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import LessonExampleItem from "features/root/components/molecules/lpClassi/LessonExampleItem";
import SectionTitle from "features/root/components/molecules/lpClassi/SectionTilte";
import { lpClassiFrontData } from "constants/lpClassiFrontData";

import type React from "react";
import type { exampleTeacherTypes } from "store/LpClassiStore";

type LessonExampleProps = {
  title: string;
  teachers: Array<exampleTeacherTypes>;
};

const LessonExample: React.FC<LessonExampleProps> = (props) => {
  const { title, teachers } = props;
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        rowGap: "2rem",
        flexDirection: "column"
      }}
    >
      <SectionTitle
        mainTitle={`${title}教育カリキュラム / 講師の一例`}
        subTitle={`${title}×${teachers.length}コマ想定のサンプル`}
      />
      <Box
        component="table"
        sx={{
          borderCollapse: "separate",
          borderSpacing: 0,
          borderRadius: "12px 12px 0 0",
          overflow: "hidden"
        }}
      >
        <Box
          component="thead"
          sx={{
            backgroundColor: theme.palette.sub.main,
            width: "100%",
            display: { xs: "none", sm: "table-header-group" }
          }}
        >
          <th />
          <Box
            component="th"
            sx={{
              textAlign: "left",
              color: theme.palette.common.white
            }}
          >
            授業概要
          </Box>
          <Box
            component="th"
            sx={{
              textAlign: "left",
              color: theme.palette.common.white
            }}
          >
            講師候補（自由に選択可）
          </Box>
        </Box>
        <tbody>
          {teachers.map((teacher, index) => {
            const lessonData = lpClassiFrontData.find(
              (item) => item.id === teacher.teacher_id
            );

            return (
              <LessonExampleItem
                key={index}
                id={index + 1}
                lessonTitle={lessonData?.lessonTitle || ""}
                lessonLabel={lessonData?.lessonLavel || ""}
                teacherOrganization={teacher.organization_name}
                teacherName={teacher.teacher_name}
                teacherImageUrl={teacher.teacher_image.url}
              />
            );
          })}
        </tbody>
      </Box>
    </Box>
  );
};

export default LessonExample;
