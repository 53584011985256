import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import LaunchIcon from "@mui/icons-material/Launch";
import { useState } from "react";

import TimelinesModal from "features/teachers/components/organisms/show/v2/TimelinesModal";

import type React from "react";
import type { teacherLessonRequestType } from "types/teacherInfoType";

type PastClassCardProps = {
  school_grade: string;
  theme: string;
  timelines: teacherLessonRequestType["timelines"];
};

const PastClassCard: React.FC<PastClassCardProps> = (props) => {
  const { school_grade, theme, timelines } = props;
  const [open, setOpen] = useState(false);
  const muiTheme = useTheme();

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: muiTheme.palette.backgroundColor?.main,
          borderRadius: "8px",
          border: `solid 1px ${muiTheme.palette.border?.main}`,
          width: "240px",
          padding: "1rem",
          cursor: "pointer"
        }}
        onClick={handleClick}
      >
        <Typography fontWeight="bold" color={muiTheme.palette.textColor.main}>
          {school_grade}へ{theme}の授業
          <LaunchIcon
            sx={{
              color: muiTheme.palette.muted?.main,
              fontSize: "1rem",
              verticalAlign: "middle",
              marginLeft: ".2rem"
            }}
          />
        </Typography>
      </Box>
      <TimelinesModal open={open} setOpen={setOpen} timelines={timelines} />
    </>
  );
};

export default PastClassCard;
