import {
  Box,
  FormControl,
  Typography,
  FormHelperText,
  useTheme
} from "@mui/material";

import FormLabel from "components/atoms/FormLabel";
import Rating from "components/atoms/fields/Rating";
import { useEffect } from "react";

import { useFormContext, Controller } from "react-hook-form";

type FormRatingProps = {
  name: string;
  supplementalText?: string;
  label: string;
  isRequired?: boolean;
  defaultValue?: number;
};

const FormRating: React.FC<FormRatingProps> = (props) => {
  const { name, supplementalText, label, isRequired, defaultValue } = props;
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();
  const theme = useTheme();

  const handleChange = (newValue: number) => {
    // APIの受取り側の仕様に合わせて10倍にして送信する
    setValue(name, newValue * 10);
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      <FormControl
        fullWidth
        sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "0.25rem"
          }}
        >
          <FormLabel labelName={label} isRequired={isRequired} />
          {supplementalText && (
            <Typography variant="caption" sx={{ whiteSpace: "pre-wrap" }}>
              {supplementalText}
            </Typography>
          )}
        </Box>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Rating
              {...field}
              onChange={(event, newValue) => handleChange(newValue || 0)}
            />
          )}
        />
        {errors[name] && (
          <FormHelperText
            sx={{
              color: `${theme.palette.error.main} !important`,
              marginTop: -0.5
            }}
          >
            {errors[name]?.message?.toString()}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default FormRating;
