import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import TeaacherRankCardContents from "features/teachers/components/molecules/mypage/TeacherRankCardContents";
import TeacherRankLabels from "features/teachers/components/molecules/mypage/TeacherRankLabels";
import { currentUserAtom } from "store/AuthStore";

import type { Theme } from "@mui/material";
import type React from "react";

const TeacherRankCard: React.FC = () => {
  const theme = useTheme();
  const currentUser = useAtomValue(currentUserAtom);
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  return (
    <Box
      sx={{
        padding: "2rem",
        width: "100%",
        display: "flex",
        gap: "1.5rem",
        alignItems: { xs: "center", sm: "flex-start" },
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border?.main}`,
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: ".75rem"
        }}
      >
        <Typography align="center">現在のランク</Typography>
        {TeacherRankLabels(currentUser.teacher_rank)}
      </Box>
      <Divider
        orientation={isMobileSize ? "horizontal" : "vertical"}
        flexItem={!isMobileSize}
        sx={{
          width: { xs: "90%", sm: "auto" }
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        {TeaacherRankCardContents(currentUser.teacher_rank)}
      </Box>
    </Box>
  );
};

export default TeacherRankCard;
