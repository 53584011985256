import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteForever from "@mui/icons-material/DeleteForever";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useAtomValue } from "jotai";
import { useState } from "react";

import FileContent from "features/requests/components/atoms/show/FileContent";
import { isLoggedInAtom, currentUserAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom, currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";
import type { fileType } from "store/RequestShowStore";
import DownloadCheckModal from "./DownloadCheckModal";
import DeleteCheckModal from "./DeleteCheckModal";

type MessageFileProps = {
  messageId?: number;
  sendUserId?: number;
  src: string;
  name: string;
  date: string;
  file: fileType;
  isDeleted?: boolean;
  deleteRequestComments?: (commentId: string) => void;
};

const MessageBlock: React.FC<MessageFileProps> = (props) => {
  const {
    src,
    name,
    date,
    file,
    messageId,
    isDeleted,
    sendUserId,
    deleteRequestComments
  } = props;
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);
  const theme = useTheme();

  const handleDownLoadModalOpen = () => {
    setDownloadModalOpen(true);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const deletable = () => {
    if (!sendUserId || isDeleted || !messageId) return false;

    return isLoggedIn
      ? currentUser.id === sendUserId
      : schoolIsLoggedIn && currentSchool.id === sendUserId;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          margin: "1.5rem auto"
        }}
      >
        <Avatar
          alt={name}
          src={src}
          sx={{
            width: "48px",
            height: "48px",
            marginRight: "1rem"
          }}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold">{name}</Typography>
            <Typography color={theme.palette.sub.main}>{date}</Typography>
          </Box>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            ファイルをアップロードしました。
          </Typography>
          <FileContent file={file} />
          <Box
            sx={{
              backgroundColor: theme.palette.link?.light,
              padding: "0.5rem",
              margin: "0.5rem 0",
              display: "flex",
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Button
              variant="text"
              color="secondary"
              onClick={handleDownLoadModalOpen}
              sx={{
                color: theme.palette.link?.main,
                textTransform: "none",
                padding: ".5rem"
              }}
              startIcon={
                <InsertDriveFileOutlinedIcon
                  sx={{ color: theme.palette.link?.main }}
                />
              }
            >
              {file.name}
            </Button>
            <Typography>をダウンロード</Typography>
          </Box>
          {deletable() && (
            <Box sx={{ textAlign: "end" }}>
              <Button
                color="inherit"
                variant="text"
                size="small"
                startIcon={<DeleteForever />}
                onClick={handleDeleteModalOpen}
              >
                削除する
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <DownloadCheckModal
        open={downloadModalOpen}
        setOpen={setDownloadModalOpen}
        file={file}
      />
      <DeleteCheckModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        messageId={messageId || 0}
        deleteRequestComments={deleteRequestComments}
      />
    </>
  );
};

export default MessageBlock;
