import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";

type overviewProps = {
  Image: string;
  title: string;
  desc: string;
};

const Overview: React.FC<overviewProps> = (props) => {
  const { Image, title, desc } = props;

  return (
    <Box
      sx={{
        backgroundColor: "#FFF6DE",
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
        borderRadius: "8px",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box
        sx={{
          paddingLeft: { xs: 0, sm: "4rem" },
          width: { xs: "90%", sm: "auto" },
          margin: { xs: "1rem auto 0", sm: 0 }
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Typography>{desc}</Typography>
      </Box>
      <Box
        component="img"
        src={Image}
        alt={title}
        sx={{
          width: "100%",
          maxWidth: "400px"
        }}
      />
    </Box>
  );
};

export default Overview;
