import { atom } from "jotai";

type PagenationType = {
  current: number;
  previous: number;
  next: number | null;
  limit_value: number;
  pages: number;
  count: number;
};

export const tagIdsAtom = atom<number[]>([]);
export const prefectureIdAtom = atom(0);
export const searchTextAtom = atom("");
export const searchFormerSchoolAtom = atom("");
export const paginationAtom = atom<PagenationType>({
  current: 1,
  previous: 1,
  next: 1,
  limit_value: 1,
  pages: 1,
  count: 1
});

export const pageAtom = atom(1);

export const conditionsResetAtom = atom(null, (_get, set) => {
  set(tagIdsAtom, []);
  set(prefectureIdAtom, 0);
  set(searchTextAtom, "");
  set(searchFormerSchoolAtom, "");
  set(pageAtom, 1);
});
