import FormFacePhotoField from "components/molecules/FormFacePhotoField";
import ProfileEditContentsAccordion from "features/teachers/components/organisms/profile/edit/ProfileEditContentsAccordion";

import type React from "react";

const FacePhotoCard: React.FC = () => (
  <ProfileEditContentsAccordion
    title="顔写真"
    label="face-photo"
    fields={["image"]}
  >
    <FormFacePhotoField
      name="image"
      supplementalText={
        <div>
          お顔がはっきり写っているご本人写真を登録してください。
          ご本人の確認できない写真の場合、審査を通過することができません。
          <br />
          ※お写真は1:1のものを推奨しております。
        </div>
      }
    />
  </ProfileEditContentsAccordion>
);

export default FacePhotoCard;
