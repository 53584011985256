import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type WebsiteLinkBoxProps = {
  title: string;
  url: string;
};

const WebsiteLinkBox: React.FC<WebsiteLinkBoxProps> = (props) => {
  const { title, url } = props;
  const theme = useTheme();

  return (
    <Box component="a" href={url} sx={{ textDecoration: "none" }}>
      <Typography
        color={theme.palette.link?.main}
        sx={{
          verticalAlign: "middle"
        }}
      >
        <LinkIcon
          sx={{
            transform: "rotate(-45deg)",
            color: theme.palette.sub.main,
            marginRight: ".2rem",
            verticalAlign: "middle"
          }}
        />
        {title}
      </Typography>
    </Box>
  );
};

export default WebsiteLinkBox;
