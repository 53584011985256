export const licenses = [
  { id: 1, label: "幼稚園教諭", hasSpecialSubject: false },
  { id: 2, label: "小学校教諭", hasSpecialSubject: false },
  {
    id: 3,
    label: "中学校教諭",
    hasSpecialSubject: true,
    specialLabel: "専門科目を記入（例：国語）"
  },
  {
    id: 4,
    label: "高等学校教諭",
    hasSpecialSubject: true,
    specialLabel: "専門科目を記入（例：国語表現Ⅰ）"
  },
  { id: 5, label: "特別支援学校", hasSpecialSubject: false }
];
