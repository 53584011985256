import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import getCroppedImg from "hooks/getCroppedImg";
import type React from "react";
import type { Crop } from "react-image-crop";

type ImageCropModalProps = {
  open: boolean;
  onClose: () => void;
  src: string;
  onComplete: (croppedImage: Blob) => void;
};

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  open,
  onClose,
  src,
  onComplete
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    x: 0,
    y: 0,
    width: 150,
    height: 150
  });
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const handleComplete = () => {
    if (!imageRef) return;
    const croppedImage = getCroppedImg({ crop, imageRef });
    onComplete(croppedImage);
  };

  const onImageLoaded = (image: HTMLImageElement) => setImageRef(image);

  useEffect(() => {
    if (crop.height < 100 || crop.width < 100) {
      const newCrop = { ...crop, width: 100, height: 100 };
      setCrop(newCrop);
    }
  }, [crop]);

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          width: "90vw",
          maxWidth: "480px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <CardContent>
          <Typography variant="h4" sx={{ margin: "0 auto .5rem" }}>
            画像を編集
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <ReactCrop
              crop={crop}
              keepSelection={false}
              onChange={(newCrop) => setCrop(newCrop)}
              minHeight={100}
              minWidth={100}
              aspect={1}
              ruleOfThirds
            >
              <img
                src={src}
                alt="preview"
                onLoad={(
                  event: React.SyntheticEvent<HTMLImageElement, Event>
                ) => onImageLoaded(event.target as HTMLImageElement)}
              />
            </ReactCrop>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleComplete}
          >
            編集完了
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ImageCropModal;
