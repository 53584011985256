import Button from "@mui/material/Button";
import { useHistory } from "react-router";

import type React from "react";

const ToTeachersIndexButton: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/teachers");
  };

  return (
    <Button
      color="primary"
      disableElevation
      fullWidth
      variant="contained"
      onClick={handleClick}
      sx={{ fontWeight: "bold" }}
    >
      複業先生を探してみる
    </Button>
  );
};

export default ToTeachersIndexButton;
