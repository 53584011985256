import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import type React from "react";

type LoadingProps = {
  headerOnly?: boolean;
};

const Loading: React.FC<LoadingProps> = (props) => {
  const { headerOnly } = props;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: headerOnly
          ? { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
          : {
              xs: "calc(100vh - 156px)",
              sm: "calc(100vh - 164px)",
              md: "calc(100vh - 184px)"
            }
      }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
