import { ThemeOptions } from "@mui/material/styles/createTheme";
import { customColors } from "./customColors";

export const MuiFieldsTheme: ThemeOptions = {
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: customColors.border.main
          },
          // ホバースタイル
          "&:focus .MuiOutlinedInput-notchedOutline": {
            borderColor: customColors.textColor.main
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: customColors.textColor.main
          },
          // エラースタイル
          "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: customColors.error.main
          },
          // 無効スタイル
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            color: customColors.border.main
          },
          // プレースホルダーのスタイル
          "& .MuiInputBase-input::placeholder": {
            color: "#BDB6B1",
            opacity: 1
          },
          "&.MuiInputBase-root": {
            backgroundColor: "#ffffff"
          },
          "&.MuiInputBase-multiline": {
            padding: "0"
          },
          "& .MuiInputBase-inputMultiline": {
            padding: "12px 16px",
            resize: "vertical"
          },
          "&::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
            MozAppearance: "textfield"
          },
          "&::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
            MozAppearance: "textfield"
          },
          "& .MuiInputBase-inputAdornedStart": {
            paddingLeft: "0"
          }
        },
        input: {
          padding: "12px 16px",
          height: "auto"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // フォーム下部のテキスト、エラーメッセージの左余白を無くしています。
          marginLeft: 0
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          "& .MuiRadio-root": {
            padding: "0",
            marginRight: "8px",
            "&.Mui-checked": {
              color: customColors.textColor.main
            },
            "&.Mui-disabled": {
              color: customColors.border.main
            }
          },

          "& .MuiCheckbox-root": {
            padding: "0",
            marginRight: "8px",
            "&.Mui-checked": {
              color: customColors.textColor.main
            },
            "&.Mui-disabled": {
              color: customColors.border.main
            }
          }
        }
      }
    },

    // Rating
    MuiRating: {
      styleOverrides: {
        root: {
          margin: ".5rem 0"
        },
        iconFilled: {
          margin: "0 .5rem"
        },
        iconEmpty: {
          margin: "0 .5rem"
        }
      }
    }

    // FIXME: Numberのスピンボタンのカスタム
    // FIXME: セレクトボックスのアイコンのカスタム

    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       // 数値入力フィールドのスピンボタンを非表示にするスタイル
    //       // '& input[type="number"]::-webkit-inner-spin-button': {
    //       //   WebkitAppearance: "none",
    //       //   margin: 0
    //       // },
    //       // '& input[type="number"]::-webkit-outer-spin-button': {
    //       //   WebkitAppearance: "none",
    //       //   margin: 0
    //       // },
    //       // Firefox の場合
    //       '& input[type="number"]': {
    //         MozAppearance: "textfield"
    //       }
    //     }
    //   }
    // }
  }
};
