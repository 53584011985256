import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import type React from "react";

import { LessonRequestSchoolTeacher } from "api/@types";

type teacherObject = LessonRequestSchoolTeacher["teacher"];

type TeacherInfoGuidProps = {
  TeacherInfoSpRef: React.RefObject<HTMLDivElement>;
  teacherObject?: teacherObject;
};

const TeacherInfoGuid: React.VFC<TeacherInfoGuidProps> = (props) => {
  const { TeacherInfoSpRef, teacherObject } = props;
  const theme = useTheme();

  const handleClick = (ref: React.RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white,
        padding: "1rem"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={teacherObject?.image.url}
          sx={{
            width: "3.5rem",
            height: "3.5rem",
            marginRight: "1rem"
          }}
        />
        <div>
          <Typography sx={{ fontSize: ".8rem", color: theme.palette.sub.main }}>
            {teacherObject?.organization_name}
          </Typography>
          <Typography fontWeight="bold">{teacherObject?.name}</Typography>
        </div>
      </Box>
      <Button color="inherit" onClick={() => handleClick(TeacherInfoSpRef)}>
        案件情報を見る
      </Button>
    </Box>
  );
};

export default TeacherInfoGuid;
