// TODO: 純粋関数かつdayjsで置き換えできるので、削除したい
const useFormatJapaneseDay = (importedDate: Date) => {
  const year = importedDate.getFullYear();
  const month = importedDate.getMonth() + 1;
  const date = importedDate.getDate();
  const dayNumber = importedDate.getDay();
  const dayArray = ["日", "月", "火", "水", "木", "金", "土"];
  const day = dayArray[dayNumber];

  return `${String(year)}年 ${String(month)}月 ${String(date)}日（${day}）`;
};

export default useFormatJapaneseDay;
