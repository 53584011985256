import { atom } from "jotai";

export type exampleTeacherTypes = {
  teacher_id: number;
  teacher_name: string;
  organization_name: string;
  teacher_image: {
    url: string;
  };
};

export const exampleTeachersAtom = atom<Array<exampleTeacherTypes>>([]);
