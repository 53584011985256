import { Methods } from "api/api/v1/master_financial_institutions";
import useAspidaSWR from "@aspida/swr";
import { useState } from "react";
import aspida from "libs/aspida";
import useHeaders from "./useHeaders";
import { useAlertMessages } from "./useAlertMessages";

const useMasterFinantialInstitutionsApi = () => {
  const headers = useHeaders();
  const [query, setQuery] = useState<Methods["get"]["query"]>({
    search_name: ""
  });
  const { addErrorMessage } = useAlertMessages();

  const { data, isLoading } = useAspidaSWR(
    aspida(headers).api.v1.master_financial_institutions,
    {
      query,
      onSuccess: (res) => {
        if (res?.count === 0) {
          addErrorMessage("該当する金融機関が見つかりませんでした");
        }
        if (res?.count >= 100) {
          addErrorMessage(
            "取得件数が多すぎます。検索ワードを絞ってみてください。"
          );
        }
      },
      onError: () => {
        addErrorMessage("データの取得に失敗しました");
      },
      enabled: !!query.search_name,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const search = (searchQuery: Methods["get"]["query"]) => {
    setQuery({ ...query, ...searchQuery });
  };

  return {
    data,
    isLoading,
    search
  };
};

export default useMasterFinantialInstitutionsApi;
