import * as yup from "yup";
import { Methods } from "api/api/v1/school_teacher_profiles";

import {
  katakanaSchema,
  phoneNumberSchema,
  optionalYearDateSchema
} from "utils/yupUtils";

type ProfileNewFormSchemaType = Methods["post"]["reqBody"];

const profileNewFormSchema: yup.ObjectSchema<ProfileNewFormSchemaType> = yup
  .object()
  .shape({
    school_code: yup.string().optional(),
    school_name: yup.string().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.string().required("学校名は必須です"),
      otherwise: () => yup.string().optional()
    }),
    school_division_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("学校区分は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_type_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("運営主体は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_prefecture_id: yup.number().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.number().required("学校の所在都道府県は必須です"),
      otherwise: () => yup.number().optional()
    }),
    school_address: yup.string().when("school_code", {
      is: (schoolCode: string | undefined) => !schoolCode,
      then: () => yup.string().required("学校住所は必須です"),
      otherwise: () => yup.string().optional()
    }),
    manage_grade: yup.string().optional(),
    manage_subject: yup.string().optional(),
    school_job: yup.string().optional(),
    name: yup.string().required("必須の項目です"),
    kana_name: katakanaSchema.required("必須の項目です"),
    // MEMO: yearDateSchemaはオプションの場合に機能しないため、別途設定
    birthday: optionalYearDateSchema.optional(),
    image: yup.string().optional(),
    image_file_name: yup.string().optional(),
    phone_number: phoneNumberSchema.required("必須の項目です"),
    is_mail_magazine: yup.boolean().required()
  });

export default profileNewFormSchema;
