import Box from "@mui/material/Box";

import SetupEyeCatchImage from "assets/SetupEyeCatch.png";

import type React from "react";

const SetupEyeCatch: React.FC = () => (
  <Box sx={{ width: "100%" }}>
    <Box
      component="img"
      src={SetupEyeCatchImage}
      alt="プロフィール登録"
      sx={{
        objectFit: "cover",
        width: "100%",
        minHeight: "100px"
      }}
    />
  </Box>
);

export default SetupEyeCatch;
