import Box from "@mui/material/Box";
import FirstViewImagePC from "assets/schoolLp/FirstViewImagePC.png";
import FirstViewImageSP from "assets/schoolLp/FirstViewImageSP.png";

import type React from "react";

const FirstViewImage: React.VFC = () => (
  <picture>
    <Box
      component="source"
      srcSet={FirstViewImageSP}
      media="(max-width: 960px)"
      sx={{
        display: "block",
        width: { xs: "100%", sm: "90%", md: "100%" },
        margin: { xs: "2rem auto", md: "auto" }
      }}
    />
    <Box
      component="img"
      src={FirstViewImagePC}
      alt="first view"
      sx={{
        display: "block",
        width: { xs: "100%", sm: "90%", md: "100%" },
        margin: { xs: "2rem auto", md: "auto" }
      }}
    />
  </picture>
);

export default FirstViewImage;
