import Box from "@mui/material/Box";

import SendIconImage from "assets/SendIcon.svg";

import type React from "react";

const SendIcon: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      textAlign: "center"
    }}
  >
    <Box
      component="img"
      src={SendIconImage}
      alt="送信完了"
      sx={{
        width: "18vw",
        height: "18vw",
        maxWidth: "120px",
        maxHeight: "120px"
      }}
    />
  </Box>
);

export default SendIcon;
