// 認証関連のフォームはロールが異なっても共通のため、共通のスキーマを定義する
import { optionalYearDateSchema, passwordSchema } from "utils/yupUtils";
import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("メールアドレスの形式が正しくありません")
    .required("必須の項目です"),
  password: passwordSchema.required("必須の項目です")
});

export const signupSchema = yup.object().shape({
  email: yup
    .string()
    .email("メールアドレスの形式が正しくありません")
    .required("必須の項目です"),
  password: passwordSchema.required("必須の項目です"),
  is_term_accepted: yup
    .boolean()
    .oneOf([true], "利用規約に同意してください")
    .required("必須の項目です")
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("メールアドレスの形式が正しくありません")
    .required("必須の項目です"),
  redirect_url: yup.string().required("必須の項目です")
});

export const resetPasswordSchema = yup.object().shape({
  password: passwordSchema.required("必須の項目です"),
  password_confirmation: passwordSchema
    .oneOf([yup.ref("password")], "パスワードが一致しません")
    .required("必須の項目です"),
  reset_password_token: yup.string().required("必須の項目です")
});

export const updatePasswordSchema = yup.object().shape({
  current_password: passwordSchema.required("必須の項目です"),
  password: passwordSchema.required("必須の項目です"),
  password_confirmation: passwordSchema
    .oneOf([yup.ref("password")], "新しいパスワードが一致しません")
    .required("必須の項目です")
});

export const updateEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("メールアドレスの形式が正しくありません")
    .required("必須の項目です")
});

export const updateBirthdaySchema = yup.object().shape({
  birthday: optionalYearDateSchema.required("必須の項目です")
});

export const sendFamilyRegisterNameSchema = yup.object().shape({
  family_register_name: yup.string().required("必須の項目です")
});
