import { useHistory } from "react-router";

import type React from "react";
import Button from "components/atoms/mui/Button";

const FirstViewButtonBox: React.VFC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/signup?active=teacher");
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      onClick={handleClick}
      sx={{
        width: { xs: "100%", sm: "calc(90% - 6vw)" },
        height: "64px",
        fontWeight: 600,
        fontSize: { xs: "100%", sm: "1rem", lg: "1.2rem" },
        margin: { xs: "1.2rem auto", lg: "1.5rem auto" }
      }}
    >
      先生をやりたい方はこちら
    </Button>
    // {/* 一旦コメントアウト */}
    // {/* <Typography className={classes.sub}>
    //   学校や塾などで複業先生を導入したい方は<a href="/" className={classes.link}>こちら</a>
    // </Typography> */}
  );
};

export default FirstViewButtonBox;
