import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";

type FormLavelProps = {
  labelName: string;
};

const RequireFormLabel: React.FC<FormLavelProps> = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <Chip
        label="必須"
        color="primary"
        size="small"
        sx={{
          borderRadius: "4px",
          fontWeight: 600,
          fontSize: ".8rem",
          backgroundColor: theme.palette.advanced?.main
        }}
      />
      <Typography
        component="label"
        fontWeight="bold"
        fontSize="1rem"
        sx={{ margin: "0 .6rem" }}
      >
        {props.labelName}
      </Typography>
    </Box>
  );
};

export default RequireFormLabel;
