import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { teacherInfoAtom } from "store/TeachersShowStore";

import type React from "react";
import CareerContent from "./CareerContent";

const CareerBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);
  const organizationCategoriesFormated = useMemo(() => {
    const returnValueMaterial: Array<string> = [];
    teacherInfo.organization_categories.map((catetory) => {
      returnValueMaterial.push(catetory.name);
      return null;
    });

    return returnValueMaterial.join("、");
  }, [teacherInfo.organization_categories]);

  return (
    <div>
      <Typography variant="h4" sx={{ margin: ".5rem auto 1rem" }}>
        経歴
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <CareerContent
          ContentIcon={RoomIcon}
          contentTitle="出身地"
          content={teacherInfo.origin_prefecture_name}
        />
        <CareerContent
          ContentIcon={RoomIcon}
          contentTitle="居住都道府県"
          content={teacherInfo.residence_prefecture_name}
        />
        {teacherInfo.origin_high_school_name && (
          <CareerContent
            ContentIcon={SchoolIcon}
            contentTitle="高校"
            content={teacherInfo.origin_high_school_name}
          />
        )}
        {teacherInfo.origin_school_name && (
          <CareerContent
            ContentIcon={SchoolIcon}
            contentTitle="大学"
            content={teacherInfo.origin_school_name}
          />
        )}
        {teacherInfo.origin_graduate_school_name && (
          <CareerContent
            ContentIcon={SchoolIcon}
            contentTitle="大学院等"
            content={teacherInfo.origin_graduate_school_name}
          />
        )}
        {teacherInfo.organization_name && (
          <CareerContent
            ContentIcon={BusinessCenterIcon}
            contentTitle="企業"
            content={teacherInfo.organization_name}
            contentDesc={organizationCategoriesFormated}
          />
        )}
      </Box>
    </div>
  );
};

export default CareerBox;
