import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import SelectCandidateAlert from "features/schools/components/atoms/recruitments/show/SelectCandidateAlert";
import Step01 from "features/schools/components/organisms/recruitments/show/CandidateSelect/Step01";
import Step02 from "features/schools/components/organisms/recruitments/show/CandidateSelect/Step02";
import Step03 from "features/schools/components/organisms/recruitments/show/CandidateSelect/Step03";

import Step04 from "features/schools/components/organisms/recruitments/show/CandidateSelect/Step04";
import BottomButtonGroup from "components/molecules/BottomButtonGroup";
import { useFormContext } from "react-hook-form";
import StepDisplay from "components/molecules/StepDisplay";
import { SelectCandidateSteps } from "features/schools/constants/selectCandidateSteps";
import { selectCandidateFormStepAtom } from "store/schools/recruitments/ShowStore";
import { useAtom } from "jotai";

const SelectCandidate: React.FC = () => {
  const theme = useTheme();
  const {
    watch,
    formState: { isValid }
  } = useFormContext();
  const [step, setStep] = useAtom(selectCandidateFormStepAtom);

  const getIsNextClickable = () => {
    switch (step) {
      case 1:
        return (
          !!watch("candidate_id") && !!watch("proposed_date_id") && isValid
        );
      case 2:
        return !!watch("accepted_comment") && isValid;
      default:
        return false;
    }
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      case 4:
        return <Step04 />;
      default:
        return <div />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <SelectCandidateAlert />
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: theme.palette.common.white,
          padding: "1rem",
          borderRadius: "8px",
          border: `2px solid ${theme.palette.primary.darker}`
        }}
      >
        <Typography variant="h3">
          今回採用する複業先生は決まりましたか？
        </Typography>
        <StepDisplay step={step} steps={SelectCandidateSteps} />
        {getStepContent()}
        {step < 4 && (
          <BottomButtonGroup
            handleBack={() => setStep(step - 1)}
            handleNext={() => setStep(step + 1)}
            activeStep={step - 1}
            isNextClickable={getIsNextClickable()}
            lastStep={2}
            submitText="候補者を確定する"
          />
        )}
      </Box>
    </Box>
  );
};

export default SelectCandidate;
