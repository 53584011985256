import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
import { MuiButtonTheme } from "./MuiButtonTheme";
import { MuiTypographyTheme } from "./MuiTypographyTheme";
import { MuiChipTheme } from "./MuiChipTheme";
import { MuiFieldsTheme } from "./MuiFieldsTheme";
import { MuiPaginationTheme } from "./MuiPaginationTheme";

const theme = createTheme({
  components: {
    ...MuiTypographyTheme.components,
    ...MuiButtonTheme.components,
    ...MuiChipTheme.components,
    ...MuiFieldsTheme.components,
    ...MuiPaginationTheme.components
  },
  palette
});

export default theme;
