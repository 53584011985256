import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import RequestStepperBg from "assets/RequestStepperBg.png";
import PreListItem from "features/requests/components/atoms/show/PreListItem";
import PreListItemWithLink from "features/requests/components/atoms/show/PreListItemWithLink";
import { requestObjectAtom, schoolObjectAtom } from "store/RequestShowStore";

import type React from "react";

const AfterClassCheckList: React.VFC = () => {
  const lessonRequestData = useAtomValue(requestObjectAtom);
  const schoolData = useAtomValue(schoolObjectAtom);

  return (
    <Box
      sx={{
        backgroundImage: `url(${RequestStepperBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 0%",
        padding: "2rem 0"
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "84%", sm: "80%" }
        }}
      >
        <Typography
          align="center"
          fontWeight="bold"
          sx={{ margin: "2rem auto 0" }}
        >
          授業後チェックリスト
        </Typography>
        <PreListItem
          title="事後アンケート（約5~10分）"
          text={`https://docs.google.com/forms/d/e/1FAIpQLSewTm4MjQ8mjOYgDPdb_ZcFHykJlbrnzSQLpi12UNB0m93Kzw/viewform?usp=pp_url&entry.464159861=${lessonRequestData.id}&entry.699512014=${String(schoolData.school_prefecture_name)}&entry.1960217994=${schoolData.school_name}`}
        />
        <Typography>
          ※上記URLを先頭から末尾まで正しくコピーして生徒にお渡しください
        </Typography>
        {lessonRequestData.is_first_lesson && (
          <PreListItemWithLink
            title={[
              { kindOf: "text", content: "サービス改善のため" },
              {
                kindOf: "link",
                content: "教員向け授業後アンケート",
                link: `https://docs.google.com/forms/d/e/1FAIpQLSdIU1auTc2o7QZ1lz6JIm_m_zAsjj9uySNRucWBackw0gSdEg/viewform`,
                isExternal: true
              },
              { kindOf: "text", content: "にご協力お願いします" }
            ]}
          />
        )}
      </Box>
    </Box>
  );
};

export default AfterClassCheckList;
