import Box from "@mui/material/Box";

import RecruitmentSelectLabel from "features/schools/components/atoms/mypage/v2/recruitments/RecruitmentSelectLabel";

import type React from "react";

const RecruitmentSelectLabels: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem",
      flexWrap: "wrap"
    }}
  >
    <RecruitmentSelectLabel label="すべて" value="" />
    <RecruitmentSelectLabel label="応募者の選択" value="select_candidate" />
    <RecruitmentSelectLabel label="募集中" value="recruting" />
    <RecruitmentSelectLabel
      label="終了・キャンセル"
      value="closed_or_canceled"
    />
  </Box>
);

export default RecruitmentSelectLabels;
