import FormTextArea from "components/molecules/FormTextArea";
import IntroductionText from "features/teachers/components/molecules/profile/InctoductionText";
import ProfileEditContentsAccordion from "features/teachers/components/organisms/profile/edit/ProfileEditContentsAccordion";

import type React from "react";

const IntroCard: React.FC = () => (
  <ProfileEditContentsAccordion
    title="紹介文"
    label="intro"
    fields={["enthusiasm", "introduction"]}
  >
    <FormTextArea
      name="enthusiasm"
      label="意気込みを一言で（50文字以内）"
      placeholder="例：自分たちが今生きている場所が全てではない、そこからさらに面白い世界や未来が広がっていることを伝えたい"
      isRequired
      maxCharacters={50}
    />
    <FormTextArea
      name="introduction"
      label="講師紹介文"
      isRequired
      supplementalText={<IntroductionText />}
      placeholder="例：実績やバックグラウンドなど"
    />
  </ProfileEditContentsAccordion>
);

export default IntroCard;
