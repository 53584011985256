import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import type React from "react";

type SeeNextButtonProps = {
  seeNext: boolean;
  setSeeNext: React.Dispatch<React.SetStateAction<boolean>>;
};

const SeeNextButton: React.FC<SeeNextButtonProps> = (props) => {
  const { seeNext, setSeeNext } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage:
          "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%)",
        textAlign: "center",
        position: seeNext ? "static" : "absolute",
        bottom: seeNext ? "auto" : 0
      }}
    >
      <Button
        variant="text"
        startIcon={seeNext ? <RemoveIcon /> : <AddIcon />}
        onClick={() => setSeeNext(!seeNext)}
        sx={{
          color: theme.palette.link?.main,
          textAlign: "center",
          margin: seeNext ? "0 auto .5rem" : ".5rem auto"
        }}
      >
        {seeNext ? "閉じる" : "続きを読む"}
      </Button>
    </Box>
  );
};

export default SeeNextButton;
