import Typography from "@mui/material/Typography";
import Rating from "@mui/lab/Rating";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

import { ReactComponent as RatingStarIcon } from "assets/Star.svg";
import { ReactComponent as RatingStarEmptyIcon } from "assets/StarEmpty.svg";

import type React from "react";

type RatingDislayItemProps = {
  title: string;
  rating: number;
  ratingReason: string;
};

const RatingDisplayItem: React.FC<RatingDislayItemProps> = (props) => {
  const { title, rating, ratingReason } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          columnGap: "1rem",
          alignItems: "center"
        }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        <Rating
          value={rating / 10}
          readOnly
          icon={
            <RatingStarIcon fontSize="inherit" width="1.2rem" height="1.2rem" />
          }
          emptyIcon={
            <RatingStarEmptyIcon
              fontSize="inherit"
              width="1.2rem"
              height="1.2rem"
            />
          }
          color="default"
        />
      </Box>
      <Typography>{ratingReason}</Typography>
    </Box>
  );
};

export default RatingDisplayItem;
