import ProfileEditContentsAccordion from "features/teachers/components/organisms/profile/edit/ProfileEditContentsAccordion";

import FormTextField from "components/molecules/FormTextField";

import type React from "react";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { isPublicOptions } from "constants/selectOptions";
import FormChipGroup from "components/molecules/FormChipGroup";

import { useAtomValue } from "jotai";
import {
  organizationCategories,
  prefectures
} from "features/teachers/stores/profileStore";
import FormSelect from "components/molecules/FormSelect";
import OriginSchoolField from "features/teachers/components/molecules/profile/OriginSchoolField";

const CareerCard: React.FC = () => {
  const organizationCategoriesValue = useAtomValue(organizationCategories);

  const organizationCategoriesOptions = organizationCategoriesValue.map(
    (category) => ({
      value: category.id,
      label: category.name
    })
  );

  const prefectureList = useAtomValue(prefectures);
  const prefectureOptions = prefectureList.map((p) => ({
    value: p.id,
    label: p.name
  }));

  return (
    <ProfileEditContentsAccordion
      title="経歴"
      label="career"
      fields={[
        "organization_name",
        "is_organization_name_public",
        "organization_category_ids",
        "origin_prefecture_id",
        "residence_prefecture_id",
        "origin_high_school_name",
        "origin_school_name",
        "origin_graduate_school_name"
      ]}
    >
      <FormTextField
        name="organization_name"
        label="所属企業（学生の方は学校・学部名）"
        placeholder="例：〇〇株式会社"
        isRequired
      />
      <FormRadioGroup
        name="is_organization_name_public"
        label="所属先の公開"
        options={isPublicOptions}
        isRequired
      />
      <FormChipGroup
        name="organization_category_ids"
        label="現在所属している企業の業界・専門領域で該当するものはどれですか？（複数選択可）"
        options={organizationCategoriesOptions}
        isRequired
        multiple
      />
      <FormSelect
        name="origin_prefecture_id"
        label="出身地"
        options={prefectureOptions}
        isRequierd
        fullWidth
      />
      <FormSelect
        name="residence_prefecture_id"
        label="居住地"
        options={prefectureOptions}
        isRequierd
        fullWidth
      />
      <OriginSchoolField
        name="origin_high_school_name"
        codeName="origin_high_school_code"
        label="出身高校（または高等専門学校）"
        schoolDivision="high_school"
      />
      <OriginSchoolField
        name="origin_school_name"
        codeName="origin_school_code"
        label="出身大学（または専門学校）"
        schoolDivision="university"
      />
      <FormTextField
        name="origin_graduate_school_name"
        label="出身大学院等"
        placeholder="例：〇〇大学院"
        fullWidth
      />
    </ProfileEditContentsAccordion>
  );
};

export default CareerCard;
