import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

import ManImage2 from "assets/ManImage2.png";

import type React from "react";

const ToSignupBlock: React.FC = () => {
  const history = useHistory();

  const handleToSignup = () => {
    history.push("/signup?active=school");
  };

  return (
    <Box
      sx={{
        position: "relative",
        margin: "1rem auto",
        padding: "2rem 0",
        backgroundColor: "#FFF5DD",
        overflow: "hidden"
      }}
    >
      <Box
        component="img"
        src={ManImage2}
        alt="新規教員登録"
        sx={{
          display: { xs: "none", sm: "block" },
          position: "absolute",
          right: -36,
          top: -24,
          overflow: "hidden"
        }}
      />
      <div>
        <Typography
          align="center"
          fontWeight="bold"
          sx={{
            margin: "0 auto 1em",
            width: "80%"
          }}
        >
          <Box component="span" sx={{ display: "inline-block" }}>
            教員アカウントを作成後、
          </Box>
          <Box component="span" sx={{ display: "inline-block" }}>
            口コミをご覧いただけます（無料）
          </Box>
        </Typography>
        <Box
          sx={{
            width: "100%",
            textAlign: "center"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleToSignup}
            sx={{ width: "240px" }}
          >
            新規教員登録
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default ToSignupBlock;
