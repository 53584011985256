import type React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import FormDatetime from "components/molecules/FormDatetime";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import CommonDialog from "components/molecules/CommonDialog";

import patchExactStartAtSchema, {
  patchExactStartAtSchemaType
} from "features/requests/constants/PatchExactStartAtSchema";

import { CommonDialogProps } from "types/commonDialogProps";
import { LessonRequestSchoolTeacher } from "api/@types";

type ExactStartAtUpdateModalProps = {
  updateExactStartAt: (data: patchExactStartAtSchemaType) => void;
  details?: LessonRequestSchoolTeacher["details"];
  isLoading?: boolean;
} & CommonDialogProps;

const ExactStartAtUpdateModal: React.FC<ExactStartAtUpdateModalProps> = (
  props
) => {
  const { details, isLoading, updateExactStartAt, onClose, isOpen } = props;
  const theme = useTheme();

  const methods = useForm<patchExactStartAtSchemaType>({
    resolver: yupResolver(patchExactStartAtSchema),
    mode: "onChange",
    defaultValues: {
      exact_start_at: details?.exact_start_at
        ? new Date(details.exact_start_at).toString()
        : new Date().toString()
    }
  });
  const onSubmit = methods.handleSubmit(async (data) => {
    updateExactStartAt(data);
    onClose();
  });

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.sub.main }}>
          {details?.exact_start_at
            ? "授業実施日・開始時間の変更"
            : "授業実施日と開始時間の設定"}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem"
              }}
            >
              <FormDatetime
                name="exact_start_at"
                label=""
                minDate={dayjs().add(3, "day").toDate()}
              />
              <FormSubmitButton
                label={
                  details?.exact_start_at ? "日程を変更する" : "日程を設定する"
                }
                fullWidth
              />
            </Box>
          </form>
        </FormProvider>
      </Box>
      {/* MEMO: ローディングも共通化できるかも */}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress sx={{ color: theme.palette.primary.main }} />
        </Box>
      )}
    </CommonDialog>
  );
};

export default ExactStartAtUpdateModal;
