import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

import SendIcon from "components/atoms/SendIcon";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";

const Create: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/teachers");
  };

  return (
    <CommonLayout>
      <Meta />
      <Container maxWidth="sm">
        <div>
          <Box sx={{ margin: "1rem auto" }}>
            <SendIcon />
          </Box>
          <Typography variant="h2" align="center" sx={{ margin: "1rem auto" }}>
            複業先生へのご依頼
            <br />
            ありがとうございました。
          </Typography>
          <Typography align="center" sx={{ margin: ".5rem auto" }}>
            <Typography component="span" sx={{ display: "inline-block" }}>
              依頼した複業先生から依頼の
            </Typography>
            <Typography component="span" sx={{ display: "inline-block" }}>
              承諾の可否が連絡されますので、
            </Typography>
            <br />
            <Typography component="span" sx={{ display: "inline-block" }}>
              今しばらくお待ちください。
            </Typography>
          </Typography>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            onClick={handleClick}
            sx={{ margin: "1rem auto" }}
          >
            複業先生一覧ページへ戻る
          </Button>
        </div>
      </Container>
    </CommonLayout>
  );
};

export default Create;
