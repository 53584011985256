import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useForm } from "react-hook-form";
import { Methods as SignInMethods } from "api/api/v1/school_teacher_auth/sign_in";
import { Methods as SignUpMethods } from "api/api/v1/school_teacher_auth";
import { Methods as PasswordMethods } from "api/api/v1/school_teacher_auth/password";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  loginSchema,
  forgotPasswordSchema,
  signupSchema,
  resetPasswordSchema,
  updatePasswordSchema,
  updateEmailSchema
} from "constants/AuthFormSchema";
import aspida from "libs/aspida";
import { RESET } from "jotai/utils";

import {
  currentSchoolAtom,
  schoolIsLoggedInAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom,
  schoolTokenTypeAtom,
  prevLoginPathAtom
} from "store/SchoolAuthStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { registeredEmailAtom } from "store/SchoolSignupStore";
import useQueryParams from "hooks/useQueryParams";

const useSchoolAuthApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const { queryParams } = useQueryParams();

  const loginForm = useForm<SignInMethods["post"]["reqBody"]>({
    resolver: yupResolver(loginSchema),
    mode: "all"
  });
  const signupForm = useForm<SignUpMethods["post"]["reqBody"]>({
    resolver: yupResolver(signupSchema),
    mode: "all"
  });
  const forgotPasswordForm = useForm<PasswordMethods["post"]["reqBody"]>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "all",
    defaultValues: {
      email: "",
      redirect_url: `${process.env.REACT_APP_HOST}/schools/password-reset`
    }
  });
  const resetPasswordForm = useForm<PasswordMethods["put"]["reqBody"]>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "all",
    defaultValues: {
      reset_password_token: queryParams.reset_password_token?.toString() || ""
    }
  });
  const updatePasswordForm = useForm({
    resolver: yupResolver(updatePasswordSchema),
    mode: "all"
  });
  const updateEmailForm = useForm({
    resolver: yupResolver(updateEmailSchema),
    mode: "all"
  });

  const [currentSchool, setCurrentSchool] = useAtom(currentSchoolAtom);
  const [, setSchoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const [, setSchoolAccessToken] = useAtom(schoolAccessTokenAtom);
  const [, setSchoolClient] = useAtom(schoolClientAtom);
  const [, setSchoolUid] = useAtom(schoolUidAtom);
  const [, setSchoolTokenType] = useAtom(schoolTokenTypeAtom);
  const [, setRegisteredEmail] = useAtom(registeredEmailAtom);
  const [prevLoginPath, setPrevLoginPath] = useAtom(prevLoginPathAtom);
  const history = useHistory();

  const login = async () => {
    try {
      const params = loginForm.getValues();
      const response = await aspida(
        headers
      ).api.v1.school_teacher_auth.sign_in.post({
        body: params
      });
      if (response.status === 200) {
        setSchoolAccessToken(response.headers["access-token"]);
        setSchoolClient(response.headers.client);
        setSchoolUid(response.headers.uid);
        setSchoolTokenType(response.headers["token-type"]);
        setCurrentSchool({ ...currentSchool, ...response.body.data });
        setSchoolIsLoggedIn(true);
        if (prevLoginPath) {
          const path = prevLoginPath;
          setPrevLoginPath("");
          history.push(path);
        } else {
          history.push("/schools/mypage");
        }
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        addErrorMessage("メールアドレス・パスワードが間違っています");
      } else {
        addErrorMessage("ログインに失敗しました");
      }
    }
  };

  const signup = async () => {
    try {
      const params = signupForm.getValues();
      const response = await aspida(headers).api.v1.school_teacher_auth.post({
        body: params
      });
      if (response.status === 200) {
        setRegisteredEmail(params.email);
        history.push("/schools/thanks-signup");
      }
    } catch (error: any) {
      if (error.response?.status === 422) {
        addErrorMessage("メールアドレスはすでに登録されています");
      } else {
        addErrorMessage("登録に失敗しました");
      }
    }
  };

  const forgotPassword = async () => {
    try {
      const params = forgotPasswordForm.getValues();
      await aspida(headers).api.v1.school_teacher_auth.password.post({
        body: params
      });
      history.push("/schools/thanks-reset-request");
    } catch (error: any) {
      if (error.response?.status === 404) {
        addErrorMessage("メールアドレスが見つかりません");
      } else {
        addErrorMessage("リクエストに失敗しました");
      }
    }
  };

  const resetPassword = async () => {
    try {
      const params = resetPasswordForm.getValues();
      await aspida(headers).api.v1.school_teacher_auth.password.put({
        body: params
      });
      history.push("/schools/password-reset-complete");
    } catch (error: any) {
      addErrorMessage("リクエストに失敗しました");
    }
  };

  const updatePassword = async () => {
    try {
      const params = updatePasswordForm.getValues();
      const response = await aspida(headers).api.v1.school_teacher_auth.patch({
        body: params
      });
      if (response.status === 200) {
        addSuccessMessage("パスワードを変更しました");
        updatePasswordForm.reset();
      }
    } catch (error: any) {
      addErrorMessage("パスワードの変更に失敗しました。");
    }
  };

  const updateEmail = async () => {
    try {
      const params = updateEmailForm.getValues();
      const response = await aspida(headers).api.v1.school_teacher_auth.patch({
        body: params
      });
      if (response.status === 200) {
        addSuccessMessage("確認メールを送信しました");
      }
    } catch (error: any) {
      addErrorMessage("入力内容を確認してください");
    }
  };

  const resetSchool = async () => {
    try {
      await aspida(headers).api.v1.school_teacher_auth.sign_out.delete();
    } catch {
      // Intentionally empty catch block
    } finally {
      setSchoolAccessToken(RESET);
      setSchoolClient(RESET);
      setSchoolTokenType(RESET);
      setCurrentSchool(RESET);
      setSchoolIsLoggedIn(RESET);
    }
  };

  const logout = async () => {
    resetSchool().then(() => {
      history.push("/schools/login");
    });
  };

  return {
    login,
    loginForm,
    signup,
    signupForm,
    forgotPassword,
    forgotPasswordForm,
    resetPassword,
    resetPasswordForm,
    updatePassword,
    updatePasswordForm,
    updateEmail,
    updateEmailForm,
    logout,
    resetSchool
  };
};

export default useSchoolAuthApi;
