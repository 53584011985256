import Axios from "axios";
import { useAtom } from "jotai";
import { useCallback } from "react";

import {
  currentUserAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom,
  tokenTypeAtom,
  isLoggedInAtom
} from "store/AuthStore";

const useTeacherReset = () => {
  const [, setCurrentUser] = useAtom(currentUserAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [client, setClient] = useAtom(clientAtom);
  const [uid, setUid] = useAtom(uidAtom);
  const [, setTokenType] = useAtom(tokenTypeAtom);
  const [, setIsLoggedIn] = useAtom(isLoggedInAtom);

  const teacherReset = useCallback(async () => {
    await Axios.delete("/api/api/v1/teacher_auth/sign_out", {
      headers: {
        "access-token": accessToken,
        uid,
        client
      }
    })
      .then(() => {
        setAccessToken("");
        setClient("");
        setUid("");
        setTokenType("");
        setIsLoggedIn(false);
        setCurrentUser({
          allow_password_change: false,
          deleted_at: null,
          email: "",
          id: 0,
          is_public: false,
          is_term_accepted: false,
          is_identification: false,
          is_identification_in_progress: false,
          has_birthday: false,
          provider: "",
          teacher_profile_id: 0,
          uid: "",
          user_id: 0,
          name: "",
          image: { url: "" },
          user_type: "",
          teacher_rank: "",
          is_reward: false,
          is_limited_public: false,
          is_match_accepted: false,
          can_interview: false
        });
      })
      .catch(() => {
        // 失敗した際もログアウトさせる
        setAccessToken("");
        setClient("");
        setUid("");
        setTokenType("");
        setIsLoggedIn(false);
        setCurrentUser({
          allow_password_change: false,
          deleted_at: null,
          email: "",
          id: 0,
          is_public: false,
          is_term_accepted: false,
          is_identification: false,
          is_identification_in_progress: false,
          has_birthday: false,
          provider: "",
          teacher_profile_id: 0,
          uid: "",
          user_id: 0,
          name: "",
          image: { url: "" },
          user_type: "",
          teacher_rank: "",
          is_reward: false,
          is_limited_public: false,
          is_match_accepted: false,
          can_interview: false
        });
      });
  }, [
    accessToken,
    client,
    setAccessToken,
    setClient,
    setCurrentUser,
    setIsLoggedIn,
    setTokenType,
    setUid,
    uid
  ]);

  return [teacherReset];
};

export default useTeacherReset;
