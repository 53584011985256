import { atom } from "jotai";
import type { Methods } from "api/api/v1/lesson_recruitments/_lessonRecruitmentId@string";

// MEMO: Acquired data control
export const candidatesCountAtom = atom(0);
export const recruitmentAtom = atom<
  Methods["get"]["resBody"]["details"] | null
>(null);
export const schoolTeacherAtom = atom<
  Methods["get"]["resBody"]["school_teacher"] | null
>(null);
export const candidateAtom = atom<
  Methods["get"]["resBody"]["candidate"] | null
>(null);

// MEMO: candidate create control
export const createCandidateStepAtom = atom(1);
