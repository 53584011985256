import useTheme from "@mui/material/styles/useTheme";
import Chip from "@mui/material/Chip";
import { useAtom } from "jotai";

import { kindOfSchoolRecruitmentsAtom } from "store/schools/mypage/RecruitmentsStore";

import type React from "react";
import type { kindOfSchoolRecruitmentsType } from "store/schools/mypage/RecruitmentsStore";

type RecruitmentSelectLabelProps = {
  label: string;
  value: kindOfSchoolRecruitmentsType;
};

const RecruitmentSelectLabel: React.FC<RecruitmentSelectLabelProps> = (
  props
) => {
  const { label, value } = props;
  const theme = useTheme();
  const [kindOfSchoolRecruitments, setKindOfSchoolRecruitments] = useAtom(
    kindOfSchoolRecruitmentsAtom
  );

  const handleClick = () => {
    setKindOfSchoolRecruitments(value);
  };

  return (
    <Chip
      label={label}
      variant="outlined"
      clickable
      onClick={handleClick}
      sx={{
        fontWeight: kindOfSchoolRecruitments === value ? "bold" : "normal",
        color: theme.palette.common.black,
        borderColor:
          kindOfSchoolRecruitments === value
            ? theme.palette.common.black
            : theme.palette.border?.main,
        backgroundColor: theme.palette.common.white,
        borderWidth: kindOfSchoolRecruitments === value ? "2px" : "1px"
      }}
    />
  );
};

export default RecruitmentSelectLabel;
