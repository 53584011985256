import Box from "@mui/material/Box";

import TeacherImage01 from "assets/lpClassi/TeacherImage01.png";
import TeacherImage02 from "assets/lpClassi/TeacherImage02.png";
import TeacherImage03 from "assets/lpClassi/TeacherImage03.png";
import TeacherImage04 from "assets/lpClassi/TeacherImage04.png";
import TeacherImage05 from "assets/lpClassi/TeacherImage05.png";
import TeacherImage06 from "assets/lpClassi/TeacherImage06.png";

import type React from "react";

const FirstViewImages: React.FC = () => (
  <Box
    sx={{
      overflow: "hidden",
      width: { xs: "auto", lg: "690px" },
      height: { xs: "auto", lg: "480px" },
      position: "relative"
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { xs: "1rem", lg: "1.2rem" },
        position: { xs: "static", lg: "absolute" },
        margin: { xs: "1rem auto", lg: 0 },
        top: { xs: "auto", lg: -12 },
        left: { xs: "auto", lg: 0 }
      }}
    >
      <Box
        component="img"
        src={TeacherImage01}
        alt="teacher01"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
      <Box
        component="img"
        src={TeacherImage02}
        alt="teacher02"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
      <Box
        component="img"
        src={TeacherImage03}
        alt="teacher03"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { xs: "1rem", lg: "1.2rem" },
        position: { xs: "static", lg: "absolute" },
        margin: { xs: "1rem auto", lg: 0 },
        bottom: { xs: "auto", lg: -12 },
        right: { xs: "auto", lg: 0 }
      }}
    >
      <Box
        component="img"
        src={TeacherImage04}
        alt="teacher04"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
      <Box
        component="img"
        src={TeacherImage05}
        alt="teacher05"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
      <Box
        component="img"
        src={TeacherImage06}
        alt="teacher06"
        sx={{
          display: "block",
          borderRadius: "8px",
          width: { sx: "100%", lg: "186px" }
        }}
      />
    </Box>
  </Box>
);

export default FirstViewImages;
