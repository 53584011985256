import { atom } from "jotai";

import type { teacherRecruitmentResponseType } from "types/lessonRecruitmentsTypes";

export type kindOfTeacherRecruitmentsType =
  | ""
  | "waiting_for_result"
  | "adopted"
  | "denied_or_canceled";

export const teacherRecruitmentsAtom =
  atom<teacherRecruitmentResponseType | null>(null);
export const kindOfTeacherRecruitmentsAtom =
  atom<kindOfTeacherRecruitmentsType>("");
