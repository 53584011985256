import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useAtom } from "jotai";

import FormLabel from "components/atoms/FormLabel";
import useFormatJapaneseDaytime from "hooks/useFormatJapaneseDaytime";
import {
  possibleDatesAtom,
  possibleDateIdAtom,
  teacherObjectAtom
} from "store/RequestShowStore";

import type React from "react";

const StartAtSelectField: React.VFC = () => {
  const theme = useTheme();
  const [possibleDates] = useAtom(possibleDatesAtom);
  const [possibleDateId, setPossibleDateId] = useAtom(possibleDateIdAtom);
  const [teacherObject] = useAtom(teacherObjectAtom);
  const { getFormatJapaneseDaytime } = useFormatJapaneseDaytime();

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setPossibleDateId(Number(value));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box sx={{ flex: 1 }}>
        <FormLabel labelName="授業日時" isRequired />
      </Box>
      <FormControl fullWidth>
        <RadioGroup
          row
          aria-label="can-interview"
          name="can-interview"
          value={possibleDateId}
          onChange={handleSelectChange}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            {possibleDates.map((possibleDate, index) => {
              const formatJapaneseDaytime = getFormatJapaneseDaytime(
                new Date(possibleDate.start_at_datetime)
              );

              return (
                <FormControlLabel
                  key={possibleDate.id}
                  value={possibleDate.id}
                  disabled={!teacherObject.is_identification}
                  control={<Radio color="primary" />}
                  label={`第${index + 1}希望：${formatJapaneseDaytime}`}
                  sx={{
                    width: "100%",
                    margin: 0,
                    padding: "6px",
                    border: `1px solid ${theme.palette.muted?.main}`,
                    borderRadius: "6px",
                    boxSizing: "border-box",
                    backgroundColor: teacherObject.is_identification
                      ? theme.palette.common.white
                      : theme.palette.backgroundColor?.main
                  }}
                />
              );
            })}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default StartAtSelectField;
