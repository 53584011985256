import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import FormTextField from "components/molecules/FormTextField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { FormProvider } from "react-hook-form";
import useSchoolAuthApi from "features/schools/hooks/useSchoolAuthApi";
import { Box } from "@mui/material";

const ChangeEmail: React.FC = () => {
  const { updateEmail, updateEmailForm } = useSchoolAuthApi();
  const theme = useTheme();

  return (
    <CommonLayout>
      <Meta title="メールアドレス変更" />
      <FormProvider {...updateEmailForm}>
        <Box
          component="form"
          onSubmit={updateEmailForm.handleSubmit(updateEmail)}
        >
          <Container
            maxWidth="md"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              margin: "2rem auto"
            }}
          >
            <Typography variant="h2">メールアドレスを変更</Typography>
            <Typography color={theme.palette.sub.main} fontSize=".9rem">
              新しいメールアドレスを入力して、「確認メールを送信」ボタンをクリックしてください。
              <br />
              本人確認メールが送信されるので、メールに記載のURLにアクセスすると、メールアドレスの変更が完了します。
              <br />
              一度ログアウトされるので新しいメールアドレスで再ログインをお願いします。
            </Typography>
            <FormTextField
              label="新しいメールアドレス"
              name="email"
              noLabel
              placeholder="sample@fukugyo.co.jp"
              fullWidth
            />
            <FormSubmitButton label="確認メールを送信" size="large" fullWidth />
          </Container>
        </Box>
      </FormProvider>
    </CommonLayout>
  );
};

export default ChangeEmail;
