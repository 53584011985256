import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";

type CommonConfirmContentProps = {
  title: string;
  content: string;
  multiline?: boolean;
};

const CommonInfoContent: React.FC<CommonConfirmContentProps> = (props) => {
  const { title, content, multiline } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <Typography fontWeight="bold">{title}</Typography>
      <Typography
        sx={{
          whiteSpace: multiline ? "pre-wrap" : "inherit"
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default CommonInfoContent;
