import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import AddIcon from "@mui/icons-material/Add";

// MEMO: インデントが表記に反映されてしまうため詰める
const sampleTextData = `この度は、授業依頼の承諾ありがとうございます。
授業準備のための打ち合わせを〇分（平均30分〜45分）程度行いたいと思っております。
当方の予定といたしましては
以下の日時が空いております。
【○月○日（月）時間帯】
【○月○日（月）時間帯】
【○月○日（月）時間帯】
【○月○日（月）時間帯】
【複業先生の名前】様のご都合はいかがでしょうか。
ご検討の上、○月○日（月）までにお返事をいただけますと幸いです。
万が一、上記日程にて調整が難しい場合は、
ご都合の良い日時をいくつかいただければ幸いです。
なお、打ち合わせは【オンライン/対面】にて行いたいと考えています。`;

const SampleMessageBlock: React.FC = () => {
  const theme = useTheme();
  const [seeAll, setSeeAll] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleTextClip = () => {
    navigator.clipboard.writeText(sampleTextData);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <Box
      sx={{
        position: "relative",
        border: `solid 1px ${theme.palette.border?.main}`,
        borderRadius: "4px",
        padding: theme.spacing(2),
        width: "100%"
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: theme.spacing(2)
        }}
      >
        <Button
          color="inherit"
          onClick={handleTextClip}
          variant="contained"
          disableElevation
          sx={{
            padding: "4px 8px",
            fontSize: "0.75rem"
          }}
        >
          {copied ? "コピーしました" : "全文コピー"}
        </Button>
      </Box>
      <div>
        <Collapse in={seeAll} collapsedSize={100}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
            <Typography variant="h4">
              日程調整時の
              <Box
                component="br"
                sx={{ display: { xs: "block", sm: "none" } }}
              />
              サンプルメッセージ
            </Typography>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {sampleTextData}
            </Typography>
          </Box>
        </Collapse>
      </div>
      <Box
        sx={{
          width: "100%",
          textAlign: "center"
        }}
      >
        <Button
          variant="text"
          color="secondary"
          startIcon={!seeAll && <AddIcon />}
          onClick={() => setSeeAll(!seeAll)}
        >
          {!seeAll ? "続きを読む" : "閉じる"}
        </Button>
      </Box>
    </Box>
  );
};

export default SampleMessageBlock;
