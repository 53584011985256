import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import PreparationList from "features/schools/components/molecules/show/requests/PreparationList";
import SampleMessageBlock from "features/schools/components/molecules/show/requests/SampleMessageBlock";

import type React from "react";

import { LessonRequestSchoolTeacher, AgendaSheetNew } from "api/@types";

type Step02Props = {
  details?: LessonRequestSchoolTeacher["details"];
  meetingSheetDetails?: AgendaSheetNew["details"];
};

const Step02: React.FC<Step02Props> = (props) => {
  const { details, meetingSheetDetails } = props;

  const hasSupport = details?.lesson_options.some((option) => option.id === 3);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box sx={{ margin: "0 auto", width: "100%" }}>
        <Typography sx={{ margin: ".5rem auto" }}>
          授業依頼が承諾されました。
          <br />
          {hasSupport ? (
            <>事務局からの連絡をお待ちください。</>
          ) : (
            <>
              下のチャットから、複業先生に連絡が可能ですので「日程調整時のサンプルメッセージ」を参考に学校の先生から打ち合わせ日程の調整の連絡をしてください。
            </>
          )}
        </Typography>
        <Typography sx={{ margin: ".5rem auto" }}>
          今後の進め方については以下の「事前準備リスト」を参考にお願いします。
        </Typography>
      </Box>
      {!hasSupport && <SampleMessageBlock />}
      <PreparationList
        details={details}
        meetingSheetDetails={meetingSheetDetails}
      />
    </Box>
  );
};

export default Step02;
