import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import FormTextField from "components/molecules/FormTextField";
import FormSubmitButton from "components/molecules/FormSubmitButton";

interface SendFamilyRegisterNameModalProps extends CommonDialogProps {
  onSubmit: () => void;
}

const SendFamilyRegisterNameModal: React.FC<
  SendFamilyRegisterNameModalProps
> = (props) => {
  const { isOpen, onClose, onSubmit } = props;

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%"
        }}
        component="form"
        onSubmit={onSubmit}
      >
        <Typography align="center" fontSize="1.2rem" fontWeight="bold">
          本名の保存
        </Typography>
        <FormTextField
          name="family_register_name"
          label=""
          noLabelAndTitle
          placeholder="例）山田花子"
          fullWidth
        />
        <FormSubmitButton label="保存する" fullWidth />
      </Box>
    </CommonDialog>
  );
};

export default SendFamilyRegisterNameModal;
