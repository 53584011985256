import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FinishReport from "features/teachers/components/molecules/show/requests/FinishReport";

import type React from "react";

import { LessonRequestTeacher } from "api/@types";

type Step03Props = {
  details?: LessonRequestTeacher["details"];
  postLessonComplete: () => void;
};

const Step03: React.FC<Step03Props> = (props) => {
  const { details, postLessonComplete } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: "100%"
        }}
      >
        <Typography>
          授業お疲れ様でした。下記ボタンを押して完了報告をお願いします。
          <br />
          授業完了報告をすることで報酬の支払いが行われます。
        </Typography>
      </Box>
      <FinishReport details={details} postLessonComplete={postLessonComplete} />
    </Box>
  );
};

export default Step03;
