import Hidden from "@mui/material/Hidden";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import getStatusLabelForTeacher from "services/recruitments/GetStatusLabelForTeacher";

import type React from "react";
import useFormatDate from "hooks/useFormatDate";

type RecruitmentListItemProps = {
  id: number;
  title: string;
  schoolName: string;
  deadline: Date;
  status: string;
  candidateStatus: string;
};

const RecruitmentListItem: React.FC<RecruitmentListItemProps> = (props) => {
  const { id, title, schoolName, deadline, status, candidateStatus } = props;
  const { getFormatDate } = useFormatDate();

  return (
    <Link
      href={`/recruitments/${id}`}
      sx={{
        textDecoration: "none",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            flex: { xs: "auto", sm: 13 }
          }}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <Typography>{schoolName}</Typography>
          <Typography fontSize=".9rem">
            締め切り：
            {status === "now_recruting" ? getFormatDate(deadline) : "-----"}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Box
            sx={{
              flex: 4,
              display: "flex",
              justifyContent: "center"
            }}
          >
            {getStatusLabelForTeacher(status, candidateStatus)}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default RecruitmentListItem;
