import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";

const PasswordResetFormBlock: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: { xs: theme.spacing(3), lg: 0 },
        height: "100%"
      }}
    >
      <Grid
        item
        xs={10}
        sm={9}
        md={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2)
        }}
      >
        <Typography variant="h3" align="center">
          新しいパスワードを入力してください
        </Typography>
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <FormPasswordField name="password" label="パスワード" />
            <FormPasswordField
              name="password_confirmation"
              label="パスワード（確認）"
            />
            <FormSubmitButton label="パスワードを変更" size="large" fullWidth />
          </Box>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default PasswordResetFormBlock;
