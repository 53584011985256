import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

type FooterMenuItemProps = {
  href: string;
  text: string;
};

const FooterMenuItem: React.FC<FooterMenuItemProps> = (props) => {
  const { href, text } = props;
  const theme = useTheme();

  return (
    <Box
      component="li"
      sx={{
        color: theme.palette.sub.main,
        display: "inline-block",
        padding: `0 ${theme.spacing(2)}`,
        "& + li": {
          borderLeft: `1px solid ${theme.palette.sub.main}`
        }
      }}
    >
      <Box
        component="a"
        href={href}
        target="_blank"
        sx={{
          textDecoration: "none",
          color: theme.palette.sub.main
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default FooterMenuItem;
