import { useHistory } from "react-router-dom";

import type React from "react";
import Button from "components/atoms/mui/Button";

const ViewMoreTeachersButton: React.VFC = () => {
  const history = useHistory();
  const handleClick = () => history.push("/teachers");

  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      onClick={handleClick}
      sx={{
        display: "block",
        margin: "1.5rem auto",
        width: "90%",
        maxWidth: "480px",
        padding: "calc(1rem + .3vw)"
      }}
    >
      複業先生をもっと見る
    </Button>
  );
};

export default ViewMoreTeachersButton;
