import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";
import PasswordResetCompleteBlock from "components/organisms/auth/PasswordResetCompleteBlock";

const PasswordResetComplete: React.FC = () => {
  const theme = useTheme();

  return (
    <AuthLayout>
      <Meta />
      <Box sx={theme.mixins.toolbar} />
      <PasswordResetCompleteBlock variant="teacher" />
    </AuthLayout>
  );
};

export default PasswordResetComplete;
