import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import ErrorAlert from "components/atoms/ErrorAlert";
import Meta from "components/organisms/Meta";
import AiTeachingPlanForm from "features/schools/components/organisms/mypage/AiTeachingPlan/AiTeachingPlanForm";
import FirstView from "features/schools/components/organisms/mypage/AiTeachingPlan/FirstView";
import useGetRequest from "features/schools/hooks/useGetRequest";
import usePostFormDataRequest from "features/schools/hooks/usePostFormDataRequest";
import CommonLayout from "components/layouts/CommonLayout";
import {
  ideaRequestAtom,
  schoolMinutesAtom,
  schoolMinutesSelectAtom,
  schoolGradeIdAtom,
  schoolGradesAtom,
  teachingPlanResponseAtom
} from "store/AiTeachingPlanStore";

import type React from "react";

const New: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const [ideaRequest, setIdeaRequest] = useAtom(ideaRequestAtom);
  const [schoolMinutes, setSchoolMinutes] = useAtom(schoolMinutesAtom);
  const [, setSchoolMinutesSelect] = useAtom(schoolMinutesSelectAtom);
  const [schoolGradeId, setSchoolGradeId] = useAtom(schoolGradeIdAtom);
  const [, setSchoolGrades] = useAtom(schoolGradesAtom);
  const [, setTeachingPlanResponse] = useAtom(teachingPlanResponseAtom);

  const {
    getRequest,
    isError: getIsError,
    setIsError: setGetIsError,
    isLoading: getIsLoading
  } = useGetRequest({ url: "/api/api/v1/teaching_plan_ideas/new" });

  const {
    postFormDataRequest,
    isError: postIsError,
    setIsError: setPostIsError,
    isLoading: postIsLoading
  } = usePostFormDataRequest({ url: "/api/api/v1/teaching_plan_ideas" });

  useEffect(() => {
    getRequest({
      onSuccess: (response) => {
        setSchoolGrades(response);
      }
    });
  }, [getRequest, setSchoolGrades]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("idea_request", ideaRequest);
    formData.append("school_minutes", schoolMinutes.toString());
    formData.append("school_grade_id", schoolGradeId.toString());

    postFormDataRequest({
      params: formData,
      onSuccess: (response) => {
        setTeachingPlanResponse(response.data.details.teaching_plan_response);
        setIdeaRequest("");
        setSchoolGradeId("");
        setSchoolMinutes("");
        setSchoolMinutesSelect("");

        history.push("/schools/mypage/ai-teaching-plan/result");
      },
      onError: (res) => {
        console.log(res);
      }
    });
  };

  return (
    <CommonLayout>
      <Meta title="複業先生｜AIで授業案生成" />
      <ErrorAlert
        isError={getIsError}
        setIsError={setGetIsError}
        errorMessage="情報の取得に失敗しました"
      />
      <ErrorAlert
        isError={postIsError}
        setIsError={setPostIsError}
        errorMessage="授業案の生成に失敗しました"
      />
      <FirstView />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="sm"
          sx={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <AiTeachingPlanForm
            isLoading={postIsLoading || getIsLoading}
            handleSubmit={handleSubmit}
          />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default New;
