import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useState } from "react";
import reactStringReplace from "react-string-replace";

import { isLoggedInAtom, currentUserAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom, currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";
import DeleteCheckModal from "./DeleteCheckModal";

type MessageBlockProps = {
  messageId?: number;
  sendUserId?: number;
  src: string;
  name: string;
  date: string;
  message: string;
  isDeleted?: boolean;
  isOfficeNotification?: boolean;
  deleteRequestComments?: (commentId: string) => void;
};

const MessageBlock: React.FC<MessageBlockProps> = (props) => {
  const {
    src,
    name,
    date,
    message,
    isDeleted,
    messageId,
    sendUserId,
    isOfficeNotification,
    deleteRequestComments
  } = props;
  const theme = useTheme();
  const regExp = /(https?:\/\/\S+)/g;
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);

  const deletable = () => {
    if (!sendUserId) return false;
    if (isDeleted) return false;
    if (!messageId) return false;

    if (isLoggedIn) {
      return currentUser.id === sendUserId;
    }
    if (schoolIsLoggedIn) {
      return currentSchool.id === sendUserId;
    }
    return false;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          margin: "1.5rem auto"
        }}
      >
        <Avatar
          alt={name}
          src={src}
          sx={{
            width: "48px",
            height: "48px",
            marginRight: "1rem"
          }}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold">{name}</Typography>
            <Typography color={theme.palette.sub.main}>{date}</Typography>
          </Box>
          {isDeleted ? (
            <Typography
              sx={{
                color: theme.palette.sub.main,
                fontStyle: "italic"
              }}
            >
              このメッセージは削除されました
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  whiteSpace: "pre-wrap",
                  margin: "0.5rem 0"
                }}
              >
                {isOfficeNotification && (
                  <Typography
                    fontSize="0.8rem"
                    color={theme.palette.sub.main}
                    sx={{
                      width: "fit-content",
                      padding: "0.2rem 0.7rem",
                      border: `1px solid ${theme.palette.sub.main}`
                    }}
                  >
                    事務局に向けたメッセージ
                  </Typography>
                )}
                {reactStringReplace(message, regExp, (match) => (
                  <a href={match} target="_blank" rel="noreferrer">
                    {match}
                  </a>
                ))}
              </Typography>
              {deletable() && (
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    color="inherit"
                    variant="text"
                    size="small"
                    startIcon={<DeleteForever />}
                    onClick={() => setIsOpen(true)}
                  >
                    削除する
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <DeleteCheckModal
        open={isOpen}
        setOpen={setIsOpen}
        messageId={messageId || 0}
        deleteRequestComments={deleteRequestComments}
      />
    </>
  );
};

export default MessageBlock;
