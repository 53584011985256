import Procedure1 from "assets/Procedure1.png";
import Procedure2 from "assets/Procedure2.png";
import Procedure3 from "assets/Procedure3.png";
import Procedure4 from "assets/Procedure4.png";
import ProcedureCard from "features/root/components/molecules/ProcedureCard";
import TopPageTitle from "features/root/components/molecules/TopPageTitle";

import type React from "react";
import { Box } from "@mui/material";

const Procedures: React.VFC = () => (
  <section>
    <Box
      sx={{
        padding: "calc(1.5rem + .25vw) 0",
        backgroundColor: "backgroundColor.main",
        maxWidth: "1280px",
        margin: "3rem auto"
      }}
    >
      <TopPageTitle
        title="HOW IT WORKS"
        subText={["“複業先生”としてデビューするまで"]}
        grey
      />
      <Box
        sx={{
          width: "90%",
          margin: "1.5rem auto"
        }}
      >
        <ProcedureCard
          number={1}
          image={Procedure1}
          title='"複業先生"に登録'
          desc="3分簡単無料登録。登録いただいた内容を元に事務局にて審査を行います。審査通過後、事務局から連絡を致します。"
        />
        <ProcedureCard
          number={2}
          image={Procedure2}
          title="事務局から依頼が届く"
          desc="ご登録者様に適した案件があれば、学校または事務局より依頼のご連絡を差し上げます。依頼が届くまでお待ち下さい。"
        />
        <ProcedureCard
          number={3}
          image={Procedure3}
          title="実施授業の打ち合わせ"
          desc="学校側との交渉が成立したら、実施授業に向けてオンラインで打ち合わせを開始しましょう。打ち合わせには事務局が立ち会うので、気になること・不安なことはなんでも相談できます。"
        />
        <ProcedureCard
          number={4}
          image={Procedure4}
          title="複業先生デビュー"
          desc="事務局と一緒に授業準備をした後、授業実施。授業後のアンケートを記入して、複業先生のお仕事完了です。"
        />
      </Box>
    </Box>
  </section>
);

export default Procedures;
