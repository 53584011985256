import { atom } from "jotai";

type schoolGradeType = {
  id: number;
  name: string;
};

type tagType = {
  id: number;
  name: string;
};

// MEMO: master data control
export const schoolGradesAtom = atom<schoolGradeType[]>([]);
export const tagsAtom = atom<Array<tagType>>([]);
export const stepAtom = atom(1);
export const createdRecruitmentIdAtom = atom<number | null>(null);
