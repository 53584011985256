import Fab from "@mui/material/Fab";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useRef } from "react";

import DefaultFacePhoto from "assets/DefaultFacePhoto.png";

import type React from "react";
import { Box, useTheme } from "@mui/material";

type FacePhotoProps = {
  src: string;
  onImageSelected: (image: File) => void;
};

const FacePhoto: React.FC<FacePhotoProps> = ({ src, onImageSelected }) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  // const sizeLimit = 1024 * 1024 * 1; // 制限サイズ(1M)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      onImageSelected(file); // 親コンポーネントに画像のソースを通知
    }
  };

  const fileUpload = () => {
    inputRef.current?.click();
  };

  return (
    <Box
      sx={{
        margin: ".25rem auto",
        position: "relative",
        maxWidth: "150px",
        maxHeight: "150px"
      }}
    >
      <Box
        sx={{
          width: "150px",
          height: "150px",
          objectFit: "cover",
          border: `1px solid ${theme.palette.muted?.main}`
        }}
      >
        <Box
          component="img"
          src={src || DefaultFacePhoto}
          alt={src ? "uploaded" : "default"}
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "100%"
          }}
        />
      </Box>
      <Box
        component="input"
        type="file"
        ref={inputRef}
        accept="image/*"
        multiple
        onChange={handleInputChange}
        sx={{ display: "none" }}
      />
      <label htmlFor="icon-button-file">
        <Fab
          color="primary"
          size="medium"
          onClick={fileUpload}
          sx={{
            boxShadow: "none",
            position: "absolute",
            right: "-18px",
            bottom: "-18px",
            "&:hover": {
              boxShadow: "none"
            }
          }}
        >
          <PhotoCameraIcon
            sx={{
              width: "28px",
              height: "28px",
              fontSize: "28px"
            }}
          />
        </Fab>
      </label>
    </Box>
  );
};

export default FacePhoto;
