import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import RecruitmentListItem from "features/schools/components/molecules/mypage/v2/recruitments/RecruitmentListItem";
import useGetRequest from "features/schools/hooks/useGetRequest";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  schoolRecruitmentsAtom,
  kindOfSchoolRecruitmentsAtom
} from "store/schools/mypage/RecruitmentsStore";

const RecruitmentList: React.FC = () => {
  const theme = useTheme();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolRecruitments, setSchoolRecruitments] = useAtom(
    schoolRecruitmentsAtom
  );
  const [kindOfRecruitments] = useAtom(kindOfSchoolRecruitmentsAtom);
  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lesson_recruitments`
  });

  useEffect(() => {
    const params = {
      status: kindOfRecruitments
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setSchoolRecruitments(data);
      }
    });
  }, [getRequest, kindOfRecruitments, setSchoolRecruitments]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="募集一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 13 }}>
                募集タイトル
              </Typography>
              <Typography
                sx={{
                  flex: 4,
                  width: "100%"
                }}
                align="center"
                fontWeight="bold"
              >
                ステータス
              </Typography>
              <Typography
                sx={{
                  flex: 3,
                  width: "100%"
                }}
                fontWeight="bold"
                align="center"
              >
                応募人数
              </Typography>
              <Typography
                sx={{
                  flex: 3,
                  width: "100%"
                }}
                fontWeight="bold"
                align="center"
              >
                募集期限
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {isLoading ? (
            <CommonLoading />
          ) : schoolRecruitments && schoolRecruitments.data.length ? (
            schoolRecruitments.data.map((schoolRecruitment, index) => (
              <React.Fragment key={index}>
                <RecruitmentListItem
                  id={schoolRecruitment.details.id}
                  title={schoolRecruitment.details.title}
                  deadline={
                    new Date(schoolRecruitment.details.recruitment_dead_at)
                  }
                  status={schoolRecruitment.details.step}
                  candidatesCount={schoolRecruitment.candidates_count}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <Typography color={theme.palette.sub.main}>
              募集がありません。
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentList;
