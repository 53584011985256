import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type React from "react";

type CareerIconType = {
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
};

const CareerIcon: React.FC<CareerIconType> = (props) => {
  const { Icon } = props;
  const theme = useTheme();

  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2.4rem",
        height: "2.4rem",
        textAlign: "center",
        lineHeight: "2rem",
        borderRadius: "50%",
        backgroundColor: theme.palette.border?.main
      }}
    >
      <Icon
        fontSize="medium"
        sx={{
          verticalAlign: "middle",
          color: theme.palette.sub.main
        }}
      />
    </Box>
  );
};

export default CareerIcon;
