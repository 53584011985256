import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";
import { useLayoutEffect } from "react";

import AnnouncementBox from "features/schools/components/molecules/show/AnnouncementBox";
import useGetRequest from "features/schools/hooks/useGetRequest";
import {
  schoolAnnouncementsAtom,
  schoolDialogIsOpenAtom,
  schoolAnnouncementIsErrorAtom
} from "store/AnnouncementsStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";

const AnnouncementDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useAtom(schoolDialogIsOpenAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [announcements, setAnnouncements] = useAtom(schoolAnnouncementsAtom);
  const [, setIsError] = useAtom(schoolAnnouncementIsErrorAtom);

  const { getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/school_announcements`
  });

  useLayoutEffect(() => {
    getRequest({
      onSuccess: (response) => {
        setAnnouncements(response.data);
        if (response.data.length > 0) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      },
      onError: () => {
        setIsError(true);
      }
    });
  }, [getRequest, setAnnouncements, setIsError, setIsOpen]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <Box sx={{ padding: "1rem" }}>
        <Typography
          fontSize="1.2rem"
          fontWeight="bold"
          sx={{ margin: "0 auto 1rem" }}
          align="center"
        >
          重要なお知らせ
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".75rem 0"
          }}
        >
          {announcements.map((announcement, index) => (
            <AnnouncementBox
              key={index}
              id={announcement.id}
              title={announcement.title}
              contents={announcement.contents}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AnnouncementDialog;
