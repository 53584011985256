import { atom } from "jotai";
import { Methods } from "api/api/v1/teacher_profiles/new";

type PrefectureType = Methods["get"]["resBody"]["prefectures"][number];

type OrganizationCategoryType =
  Methods["get"]["resBody"]["organization_categories"][number];

type TagType = Methods["get"]["resBody"]["tags"][number];

export const prefectures = atom<PrefectureType[]>([]);
export const organizationCategories = atom<OrganizationCategoryType[]>([]);
export const tags = atom<TagType[]>([]);
