import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

const useQueryParams = () => {
  const history = useHistory();
  const { search } = useLocation();

  const setQueryParams = (params: object) => {
    const queryString = qs.stringify(params, { arrayFormat: "brackets" });
    history.push({ search: queryString });
  };

  return {
    queryParams: qs.parse(search, { ignoreQueryPrefix: true }),
    setQueryParams
  };
};

export default useQueryParams;
