import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtomValue } from "jotai";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";

import { currentUserAtom } from "store/AuthStore";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

type postRequestCommentsType = Methods["post"]["reqBody"];

const useShowPageApi = (lessonRequestId: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const currentUser = useAtomValue(currentUserAtom);

  const {
    data: requestData,
    error: getRequestError,
    isLoading: getRequestIsLoading,
    mutate: mutateRequestData
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers)
      .api.v1.teachers._teacherId_string(currentUser.id.toString())
      .lesson_requests._lessonRequestId(lessonRequestId)
  );

  const postLessonComplete = async () => {
    try {
      const response =
        // eslint-disable-next-line no-underscore-dangle
        await aspida(headers)
          .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
          .lesson_complete.post({});
      mutateRequestData();
      return { response, error: null };
    } catch (error) {
      return { response: null, error };
    }
  };

  const school = requestData?.send_user;
  const supporter = requestData?.supporter;
  const details = requestData?.details;

  // for meeting sheet
  const { data: meetingSheetData, error: getMeetingSheetDataError } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers).api.v1.lesson_requests._lessonRequestId_string(
        lessonRequestId
      ).agenda_sheets.new
    );

  const meetingSheet = meetingSheetData?.details;

  // for chat
  const {
    data: requestCommentsData,
    error: getRequestCommentsError,
    isLoading: getRequestCommentsIsLoading,
    mutate: mutateRequestComments
  } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId(lessonRequestId)
      .lesson_request_comments,
    {
      refreshInterval: 60000,
      onError: (error) => {
        if (error.response.status !== 404) {
          addErrorMessage("チャットの取得に失敗しました");
        }
      }
    }
  );

  const postRequestComments = async (data: postRequestCommentsType) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId(lessonRequestId)
        .lesson_request_comments.post({
          body: data
        });

      mutateRequestComments();

      return { response, error: null };
    } catch (error) {
      console.error(error);
      addErrorMessage("チャットの投稿に失敗しました");

      return { response: null, error };
    }
  };

  const deleteRequestComments = async (commentId: string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .lesson_request_comments._lessonRequestCommentId(commentId)
        .logical_delete.patch({});

      mutateRequestComments();

      return { response, error: null };
    } catch (error) {
      console.error(error);

      return { response: null, error };
    }
  };

  // for rating
  const { data: ratingData, error: getRatingError } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.lesson_requests._lessonRequestId_string(
      lessonRequestId
    ).ratings
  );

  const ratingDetails = ratingData?.details;

  return {
    school,
    supporter,
    details,
    requestData,
    getRequestError,
    getRequestIsLoading,
    meetingSheet,
    getMeetingSheetDataError,
    mutateRequestData,
    postLessonComplete,
    requestCommentsData,
    getRequestCommentsError,
    getRequestCommentsIsLoading,
    mutateRequestComments,
    postRequestComments,
    deleteRequestComments,
    ratingDetails,
    getRatingError
  };
};

export default useShowPageApi;
