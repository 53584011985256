import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useHistory, useLocation } from "react-router-dom";

import type React from "react";

const Header: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const inResult = location.pathname === "/teachers/mypage/e-learnings/result";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ backgroundColor: theme.palette.link?.dark }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              width: "100%",
              maxWidth: "960px",
              position: "relative"
            }}
          >
            {!inResult && (
              <Button
                startIcon={<KeyboardArrowLeft />}
                disableElevation
                variant="text"
                onClick={() => history.goBack()}
                sx={{
                  color: theme.palette.common.white
                }}
              >
                戻る
              </Button>
            )}
            <Typography
              fontWeight="bold"
              fontSize="1.1rem"
              color={theme.palette.common.white}
              align="center"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              e-learning受講
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
