import Box from "@mui/material/Box";

import RecruitmentSelectLabel from "features/teachers/components/atoms/mypage/v2/recruitments/RecruitmentSelectLabel";

import type React from "react";

const RecruitmentSelectLabels: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem",
      flexWrap: "wrap"
    }}
  >
    <RecruitmentSelectLabel label="すべて" value="" />
    <RecruitmentSelectLabel label="結果待ち" value="waiting_for_result" />
    <RecruitmentSelectLabel label="採用" value="adopted" />
    <RecruitmentSelectLabel
      label="お見送り・キャンセル"
      value="denied_or_canceled"
    />
  </Box>
);

export default RecruitmentSelectLabels;
