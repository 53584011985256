import Meta from "components/organisms/Meta";
import Conclusion from "features/root/components/organisms/schoolHome/Conclusion";
import Faq from "features/root/components/organisms/schoolHome/FAQ";
import FirstView from "features/root/components/organisms/schoolHome/FirstView";
import Issue from "features/root/components/organisms/schoolHome/Issue";
import Procedures from "features/root/components/organisms/schoolHome/Procedures";
import Service from "features/root/components/organisms/schoolHome/Service";
import Teachers from "features/root/components/organisms/schoolHome/Teachers";
import Voice from "features/root/components/organisms/schoolHome/Voice";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";

const SchoolHome: React.FC = () => (
  <CommonLayout>
    <Meta
      title="出前授業といえば複業先生|多様なキャリアの社会人が在籍"
      description="出前授業の依頼プラットフォームです。複業先生を使うことで多種多様な経歴を持った1000人以上の外部人材の方に学校での授業依頼をすることができます。金融、地方創生、起業など様々な授業依頼が可能です。"
    />
    <FirstView />
    <Issue />
    <Voice />
    <Service />
    <Teachers />
    <Procedures />
    <Faq />
    <Conclusion />
  </CommonLayout>
);

export default SchoolHome;
