import Box from "@mui/material/Box";

import type React from "react";
import SPSliderContents from "./SPSliderContents";

const FirstViewImagesSP: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      overflow: "hidden"
    }}
  >
    <SPSliderContents />
    <SPSliderContents />
    <SPSliderContents />
  </Box>
);

export default FirstViewImagesSP;
