import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import RequestFirstViewImage from "assets/RequestFirstViewImage.png";

import type React from "react";

const FirstView: React.FC = () => (
  <Box
    sx={{
      background: `no-repeat url(${RequestFirstViewImage}), linear-gradient(to right, #FFEFC7, #FFDBAA)`,
      backgroundPosition: { xs: "110% 10%", sm: "100% 10%", md: "90% 15%" },
      display: "flex",
      alignItems: "center",
      height: { xs: "100px", md: "120px" },
      backgroundSize: { xs: "240px, 100% 10%", sm: "270px, 100% 10%" }
    }}
  >
    <Typography
      variant="h2"
      sx={{
        margin: "0 auto",
        width: "90%",
        maxWidth: "1280px"
      }}
    >
      授業の依頼をする
    </Typography>
  </Box>
);

export default FirstView;
