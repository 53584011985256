import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { currentUserAtom } from "store/AuthStore";

import type React from "react";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormTextField from "components/molecules/FormTextField";

const ChangeEmailContents: React.FC = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        width: "100%"
      }}
    >
      <Typography color={theme.palette.sub.main} fontSize=".9rem">
        新しいメールアドレスを入力して、「確認メールを送信」ボタンをクリックしてください。
        <br />
        本人確認メールが送信されるので、メールに記載のURLにアクセスすると、メールアドレスの変更が完了します。
        <br />
        一度ログアウトされるので新しいメールアドレスで再ログインをお願いします。
      </Typography>
      <Box>
        <Typography>変更前のメールアドレス</Typography>
        <Typography
          fontSize="1.2rem"
          fontWeight="bold"
          sx={{ margin: ".5rem auto" }}
        >
          {currentUser.email}
        </Typography>
      </Box>
      <FormTextField
        name="email"
        label=""
        noLabelAndTitle
        placeholder="sample@fukugyo.co.jp"
        fullWidth
      />
      <FormSubmitButton label="確認メールを送信" size="large" fullWidth />
    </Box>
  );
};

export default ChangeEmailContents;
