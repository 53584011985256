// MEMO: 学校マスターを経由して選択された学校の情報を保存するStore。API側で要求されないが、フロントの表示で一時的に必要になる情報を保持する。

import { atom } from "jotai";
import { masterSchoolType } from "types/masterDataTypes";

// MEMO: 学校の先生が所属する学校を選択した際、その学校のマスターデータ経由の情報を保持するStore
export const selectedSchoolAtom = atom<masterSchoolType | null>(null);

// MEMO: 所属する学校が例外的に入力されたかどうかを保持するStore
export const isExceptionSchoolAtom = atom<boolean>(false);
