import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";

import type React from "react";

type LessonOptionItemProps = {
  name: string;
};

const LessonOptionItem: React.FC<LessonOptionItemProps> = (props) => {
  const { name } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <CheckCircleIcon color="primary" sx={{ marginRight: "1rem" }} />
      <Typography fontSize=".9rem">{name}</Typography>
    </Box>
  );
};

export default LessonOptionItem;
