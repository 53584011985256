import { PaletteOptions } from "@mui/material/styles";
import { customColors } from "./customColors";

export const palette: PaletteOptions = {
  primary: {
    main: customColors.primary.main,
    dark: customColors.primary.dark,
    contrastText: customColors.textColor.white,
    lighter: "#FFFAEC",
    darker: "#F66800"
  },
  secondary: {
    lighter: "#EDFBFF",
    main: customColors.secondary.main,
    dark: customColors.secondary.dark,
    contrastText: customColors.textColor.white
  },
  error: {
    lighter: "#FBEEEE",
    main: customColors.error.main
  },
  textColor: {
    main: customColors.textColor.main
  },
  sub: {
    lighter: "#E3E2DF",
    main: customColors.textColor.sub
  },
  border: {
    main: customColors.border.main
  },
  // FIXME: 名称が実にわかりにくい
  backgroundColor: {
    main: customColors.background.gray
  },
  background: {
    default: customColors.background.default
  },
  muted: {
    main: "#BDB6B1"
  },
  link: {
    light: "#EDFBFF",
    main: "#0093C3",
    dark: "#005977"
  },
  advanced: {
    light: "#D7EEF3",
    main: "#00B7E0",
    dark: "#00809C"
  },
  primaryBackground: {
    main: customColors.primary.background
  }
};
