import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import RecruitmentList from "features/schools/components/organisms/mypage/v2/recruitments/RecruitmentList";
import RecruitmentSelectLabels from "features/schools/components/organisms/mypage/v2/recruitments/RecruitmentSelectLabels";
import TitleBlock from "features/schools/components/organisms/mypage/v2/TitleBlock";

import type React from "react";

const Recruitments: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.5rem",
        flexGrow: 1
      }}
    >
      <TitleBlock
        title="募集状況"
        description="依頼したい授業内容を作成し、複業先生からの応募を待ちましょう。"
        Button={
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            component={Link}
            to="/recruitments/new"
            sx={{
              height: "3rem",
              width: "12rem",
              fontWeight: "bold",
              backgroundColor: theme.palette.primary.dark,
              "&:hover": {
                backgroundColor: theme.palette.primary.darker
              }
            }}
          >
            募集内容を作成
          </Button>
        }
      />
      <RecruitmentSelectLabels />
      <RecruitmentList />
    </Box>
  );
};

export default Recruitments;
