import type React from "react";
import TextField from "components/atoms/fields/TextField";
import { Controller } from "react-hook-form";

type AnswerFieldProps = {
  index: number;
  wordCount: number;
};

const AnswerField: React.FC<AnswerFieldProps> = (props) => {
  const { index, wordCount } = props;

  const getHint = () => {
    let hint = "";
    for (let i = 0; i < wordCount; i++) {
      hint += "○";
    }

    return hint;
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // エンター送信時に次のフォームにフォーカスを移動
    if (e.key === "Enter") {
      e.preventDefault();
      const nextIndex = index + 1;
      const nextField = document.querySelector(
        `input[name="answers[${nextIndex}]`
      ) as HTMLInputElement;
      if (nextField) {
        nextField.focus();
      }
    }
  };

  return (
    <Controller
      name={`answers[${index}]`}
      render={({ field }) => (
        <TextField
          {...field}
          type="text"
          variant="outlined"
          size="small"
          placeholder={`(${index + 1}) ${getHint()}`}
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0.5rem"
          }}
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
};

export default AnswerField;
