import { ThemeOptions } from "@mui/material/styles/createTheme";
import { customColors } from "./customColors";

export const MuiTypographyTheme: ThemeOptions = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: customColors.textColor.main
        },
        // TODO: 以下はもともとの設定を移植したのみ。要修正。
        h1: {
          fontSize: "22px",
          fontWeight: "bold",
          lineHeight: "27.5px",
          "@media (min-width:600px)": {
            fontSize: "24px",
            lineHeight: "30px"
          }
        },
        h2: {
          fontSize: "20px",
          fontWeight: "bold",
          lineHeight: "25px"
        },
        h3: {
          fontSize: "18px",
          fontWeight: "bold",
          lineHeight: "22.5px"
        },
        h4: {
          fontSize: "16px",
          fontWeight: "bold",
          lineHeight: "20px"
        },
        h5: {
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "17.5px"
        },
        body1: {
          fontSize: "14px", // デフォルト
          lineHeight: "21px",
          "@media (min-width:600px)": {
            lineHeight: "23.8px" // 600px以上の場合
          }
        },
        body2: {
          fontSize: "16px", // デフォルト
          lineHeight: "24px"
        },
        caption: {
          fontSize: "12px", // デフォルト
          lineHeight: "18px",
          color: customColors.textColor.sub
        }
      }
    }
  }
};
