import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import LessonItem from "features/schools/components/molecules/mypage/v2/home/LessonItem";
import useGetRequest from "features/schools/hooks/useGetRequest";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { schoolLessonsAtom } from "store/schools/mypage/HomeStore";

import LessonIcon from "assets/common/sidebar/selected/lesson.svg";

const Lessons: React.FC = () => {
  const theme = useTheme();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolLessons, setSchoolLessons] = useAtom(schoolLessonsAtom);

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lesson_requests`
  });

  useEffect(() => {
    const params = {
      status: "accepted",
      limit: 3
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setSchoolLessons(data);
      }
    });
  }, [getRequest, setSchoolLessons]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="授業の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%"
        }}
      >
        <CommonMypageHomeSectionTitle
          Icon={<img src={LessonIcon} alt="授業進行管理" />}
          title="授業進行管理"
          count={schoolLessons?.data_count || 0}
          linkName="すべての授業を見る"
          link="/schools/mypage?page_name=lessons"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Typography fontWeight="bold" sx={{ flex: 1 }}>
              授業
            </Typography>
            <Typography fontWeight="bold">ステータス</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          {!isLoading ? (
            schoolLessons !== null ? (
              schoolLessons.data.map((schoolLesson, index) => (
                <React.Fragment key={index}>
                  <LessonItem
                    id={schoolLesson.details.id}
                    exactStartAt={new Date(schoolLesson.details.exact_start_at)}
                    schoolMinutes={schoolLesson.details.school_minutes}
                    teacherName={schoolLesson.teacher.name}
                    teacherImage={schoolLesson.teacher.image.url}
                    teacherRank={schoolLesson.teacher.teacher_rank}
                    step={schoolLesson.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              ))
            ) : (
              <Typography color={theme.palette.sub.main}>
                授業はありません
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Lessons;
