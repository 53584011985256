import { useTheme } from "@mui/material/styles";
import type React from "react";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { Typography, Box } from "@mui/material";

const ChangePasswordContents: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        width: "100%"
      }}
    >
      <Typography color={theme.palette.sub.main} fontSize=".9rem">
        パスワードは8文字以上で、半角英数字をそれぞれ1文字以上含めてください。
      </Typography>
      <FormPasswordField name="current_password" label="現在のパスワード" />
      <FormPasswordField name="password" label="新しいパスワード" />
      <FormPasswordField
        name="password_confirmation"
        label="新しいパスワード（確認）"
      />
      <FormSubmitButton label="パスワードを変更する" size="large" fullWidth />
    </Box>
  );
};

export default ChangePasswordContents;
