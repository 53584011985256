import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type TextFieldProps = {
  search?: boolean;
} & MuiTextFieldProps;

const TextField: React.FC<TextFieldProps> = ({ search, ...props }) => {
  // undefainedをわたすと値が更新されないため明示的に空文字にする
  props.value = props.value === undefined ? "" : props.value;

  return (
    <MuiTextField
      InputProps={
        search
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }
          : undefined
      }
      {...props}
    />
  );
};

export default TextField;
