import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import AuthEyeCatchImage from "assets/AuthEyeCatchImage.png";

import type React from "react";

const AuthEyeCatch: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `url(${AuthEyeCatchImage}), linear-gradient(150deg, #FFEFC7, #FFDBAA)`,
        backgroundSize: "cover",
        backgroundPosition: "60% 50%",
        height: { xs: "36vh", lg: "100%" }
      }}
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          lineHeight: "2 !important",
          paddingTop: { xs: "calc(48px + 3vw)", lg: "27vh" },
          fontSize: { xs: "calc(.75rem + 2vw)", lg: "2.4rem" }
        }}
      >
        <Box
          component="span"
          sx={{
            display: "inline-block",
            backgroundColor: theme.palette.backgroundColor?.main,
            padding: "3px 9px",
            borderRadius: "4px",
            margin: ".25rem auto"
          }}
        >
          <span>複業で</span>
          <Box
            component="span"
            sx={{
              color: theme.palette.primary.main
            }}
          >
            先生をしたい
          </Box>
          <span>人と</span>
        </Box>
        <br />
        <Box
          component="span"
          sx={{
            display: "inline-block",
            backgroundColor: theme.palette.backgroundColor?.main,
            padding: "3px 9px",
            borderRadius: "4px",
            margin: ".25rem auto"
          }}
        >
          <Box
            component="span"
            sx={{
              color: theme.palette.primary.main
            }}
          >
            学校
          </Box>
          <span>をつなぐ</span>
        </Box>
      </Typography>
    </Box>
  );
};

export default AuthEyeCatch;
