import Box from "@mui/material/Box";

import LessonSelectLabel from "features/teachers/components/atoms/mypage/v2/lessons/LessonSelectLabel";

import type React from "react";

const RecruitmentSelectLabels: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem",
      flexWrap: "wrap"
    }}
  >
    <LessonSelectLabel label="すべて" value="accepted" />
    <LessonSelectLabel label="授業準備中" value="preparation" />
    <LessonSelectLabel label="授業終了・完了" value="lesson_end" />
  </Box>
);

export default RecruitmentSelectLabels;
