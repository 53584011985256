import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

// TODO: CommonModalへの置き換えを別PRで実施
import CommonModal from "components/molecules/CommonDialog";

import { typeofUseDialogRetrunType } from "hooks/useDialog";

import type React from "react";

type VideoControlModalProps = {
  videoId: number;
  removeTeacherVideo: (videoId: number) => Promise<void>;
  fileRemoveModalControl: typeofUseDialogRetrunType;
};

const VideoDeleteModal: React.FC<VideoControlModalProps> = (props) => {
  const {
    videoId,
    removeTeacherVideo,
    fileRemoveModalControl: { onClose, isOpen }
  } = props;
  const theme = useTheme();

  const handleDelete = async () => {
    await removeTeacherVideo(videoId);
    await onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        sx={{
          padding: "1rem 1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Typography fontWeight="bold">本当に削除しますか？</Typography>
          <Typography fontSize=".8rem" color={theme.palette.sub.main}>
            動画は再度アップロードする必要があります。
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <Button color="secondary" onClick={onClose} sx={{ flex: 1 }}>
            キャンセル
          </Button>
          <Button
            sx={{
              flex: 1,
              color: theme.palette.common.white,
              fontWeight: 600,
              backgroundColor: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.dark
              }
            }}
            onClick={handleDelete}
          >
            削除
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoDeleteModal;
