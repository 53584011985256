import { atom } from "jotai";

import type {
  schoolRequestsAtomType,
  teacherRequestsAtomType
} from "types/LessonRequestTypes";

export const teacherRequestsAtom = atom<teacherRequestsAtomType>([]);
export const schoolRequestsAtom = atom<schoolRequestsAtomType>([]);

export const noRatingRequestsAtom = atom<schoolRequestsAtomType>([]);
