import FirstViewImage from "assets/FirstViewImage.png";
import FirstViewContents from "features/root/components/molecules/FirstViewContents";

import type React from "react";
import { Box, useTheme } from "@mui/material";

const FirstView: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        background: `linear-gradient(to bottom, ${theme.palette.backgroundColor?.main}, ${theme.palette.backgroundColor?.main} 90%, #ffffff 90%, #ffffff)`
      }}
    >
      <Box
        sx={{
          maxWidth: "1500px",
          margin: "0 auto",
          position: "relative",
          height: {
            xs: "calc(48vh + 36vw)",
            sm: "calc(20vh + 54vw)",
            md: "calc(50vw + 30px)",
            lg: "600px"
          },
          backgroundColor: { xs: "backgroundColor.main", md: "transparent" },
          overflow: { xs: "hidden", sm: "visible" }
        }}
      >
        <FirstViewContents />
        <Box
          component="img"
          sx={{
            position: "absolute",
            top: { xs: "-10%", sm: "-20%", md: "-9%" },
            right: { xs: "-2%", md: "5%", lg: "8%" },
            maxWidth: "730px",
            minWidth: "360px",
            maxHeight: "730px",
            width: { xs: "96vw", sm: "80vw", md: "60vw", lg: "auto" }
          }}
          src={FirstViewImage}
          alt="firstview"
        />
      </Box>
    </Box>
  );
};

export default FirstView;
