import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import { currentUserAtom } from "store/AuthStore";

import type React from "react";
import Button from "components/atoms/mui/Button";

interface IdentificationCheckContentsProps {
  postIdentification: () => void;
  handleModalOpen: () => void;
}

const IdentificationCheckContents: React.FC<
  IdentificationCheckContentsProps
> = ({ postIdentification, handleModalOpen }) => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column"
      }}
    >
      {currentUser.is_identification_in_progress ? (
        <Typography>本人確認中です。しばらくお待ちください。</Typography>
      ) : (
        <>
          <Typography>
            授業を実施するためには、本人確認を行う必要があります。
          </Typography>
          <Typography
            color={theme.palette.sub.main}
            fontSize=".9rem"
            sx={{ marginTop: ".5rem" }}
          >
            旧姓の場合など、本人確認書類に記載の名前が登録名と異なる場合は
            <Typography
              component="span"
              onClick={handleModalOpen}
              color={theme.palette.link?.main}
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              こちら
            </Typography>
            から本名を入力してください。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={postIdentification}
          >
            本人確認を行う
          </Button>
        </>
      )}
    </Box>
  );
};

export default IdentificationCheckContents;
