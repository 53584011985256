import * as yup from "yup";
import { Methods } from "api/api/v1/lesson_requests";
import { futureDateStringSchema } from "utils/yupUtils";

export type NewFormSchemaType = Methods["post"]["reqBody"];

const newFormSchema: yup.ObjectSchema<NewFormSchemaType> = yup.object().shape({
  teacher_id: yup.number().required(),
  tag_id: yup
    .number()
    .required("授業テーマを選択してください")
    .min(1, "授業テーマを選択してください"),
  school_grade_id: yup.number().required("学年を選択してください"),
  student_number: yup
    .number()
    .typeError("生徒数は半角数字で入力してください")
    .required("生徒数を入力してください")
    .min(1, "生徒数を入力してください"),
  is_online: yup.boolean().required("授業方法を選択してください"),
  travel_cost: yup
    .string()
    .defined()
    .when("is_online", (isOnline, schema) => {
      // MEMO: 何故かbooleanの配列になってしまう。
      const isOnlineBools = isOnline as unknown as boolean[];
      return isOnlineBools[0]
        ? schema.notRequired()
        : schema.required("交通費を入力してください");
    }),
  school_minutes: yup.number().required("授業時間を選択してください"),
  lesson_content: yup.string().required("話してほしいことを入力してください"),
  accept_dead_at: futureDateStringSchema
    .test(
      "is-before-possible-dates",
      "授業候補日より前の日付を選択してください",
      (value, context) => {
        if (!value) return false;
        let isBefore = false;
        const possibleDates: string[] = context.parent.possible_dates;
        possibleDates.forEach((possibleDate) => {
          if (new Date(value) < new Date(possibleDate)) {
            isBefore = true;
          }
        });
        return isBefore;
      }
    )
    .required("承諾期限を入力してください"),
  student_condition: yup.string().defined().optional(),
  issue: yup.string().defined().optional(),
  lesson_option_ids: yup.array().of(yup.number().defined()),
  amount: yup.number().required(),
  possible_dates: yup
    .array()
    .of(
      futureDateStringSchema
        .test(
          "is-after-accept-dead",
          "承諾期限より後の日付を選択してください",
          (value, context) => {
            if (!value) return false;
            if (!context.from) return false;
            // MEMO: おまじない。https://github.com/jquense/yup/issues/398#issuecomment-916693907
            const parentOfparent = context.from[0].value;
            const acceptDeadAt = parentOfparent.accept_dead_at;

            return new Date(value) > new Date(acceptDeadAt);
          }
        )
        .required("授業候補日を入力してください")
    )
    .required("授業候補日は1つ以上入力してください"), // undefined または null を許容し、配列内は文字列
  is_term_accepted: yup
    .boolean()
    .required()
    .oneOf([true], "利用規約とプライバシーポリシーに同意してください")
});

export default newFormSchema;
