import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ConceptImage from "assets/ConceptImage.png";
import ServiceImage from "assets/ServiceImage.png";
import TopPageTitle from "features/root/components/molecules/TopPageTitle";

import type React from "react";
import { Box } from "@mui/material";

const Intro: React.FC = () => (
  <Box
    component="section"
    sx={{
      margin: "0 auto",
      width: { sm: "90%", xl: "auto" }
    }}
  >
    <Grid container direction="row-reverse" alignItems="center">
      <Grid item xs={12} md={6}>
        <TopPageTitle
          title="CONCEPT"
          subText={[
            "自分の知識・経験を活かして、",
            "生徒に新しい学びを提供できます"
          ]}
        />
        <Typography variant="body2">
          これまでの仕事や経験を学校現場で分かち合いたい人がスポットで「先生」の仕事ができる、教育特化型の複業案件プラットフォームです。「教育現場に関わりたい」「専門性を教育に活かしたい」と思っている皆様をサポートします。
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            component="img"
            src={ConceptImage}
            alt="concept"
            sx={{
              display: "block",
              width: "100%",
              maxWidth: "480px"
            }}
          />
        </Box>
      </Grid>
    </Grid>
    <Grid container alignItems="center">
      <Grid item xs={12} md={6}>
        <TopPageTitle
          title="SERVICE"
          subText={['"複業先生に"', "なったらできること"]}
        />
        <Typography variant="body2">
          キャリア教育の講師、探究学習のアドバイザー、国際理解教育講師、外国語/グローバル教育支援、金融教育、ICT支援などなど...。その他にも、人それぞれの形で学校を支援することができます。
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            component="img"
            src={ServiceImage}
            alt="service"
            sx={{
              display: "block",
              width: "100%",
              maxWidth: "480px"
            }}
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default Intro;
