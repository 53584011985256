// MEMO: layoutに入れるルートも全然ある

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";

import type React from "react";

type FieldsFrameProps = {
  title?: string;
  info?: string;
  children: React.ReactNode;
};

const FieldsFrame: React.FC<FieldsFrameProps> = (props) => {
  const { title, info, children } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box
        sx={{
          width: "12rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center"
        }}
      >
        {title && <Typography fontWeight="bold">{title}</Typography>}
        {info && (
          <Tooltip title={info} placement="bottom-end">
            <IconButton size="small">
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FieldsFrame;
