import * as yup from "yup";
import { Methods } from "api/api/v1/teachers/_teacherId@string/e_learnings";

const newELearningFormSchema: yup.ObjectSchema<Methods["post"]["reqBody"]> = yup
  .object()
  .shape({
    answers: yup.array().of(yup.string().required()).required(),
    version: yup.number().required()
  });

export default newELearningFormSchema;
