import Grid from "@mui/material/Grid";

import ExampleImage01 from "assets/schoolLp/ExampleImage01.png";
import ExampleImage02 from "assets/schoolLp/ExampleImage02.png";
import ExampleImage03 from "assets/schoolLp/ExampleImage03.png";

import type React from "react";
import ExampleBox from "./ExampleBox";

const ExampleList: React.VFC = () => (
  <Grid
    container
    sx={{
      margin: { xs: "0 auto 1rem", md: "3rem auto" },
      width: "90%",
      maxWidth: "1500px"
    }}
  >
    <Grid item xs={12} md={4}>
      <ExampleBox
        image={ExampleImage01}
        title="キャリア教育"
        desc="自分らしい生き方を体現している講師の原体験や、それに伴う決断・葛藤を対話的に学ぶことで、社会との関わり方を考えるきっかけに。また多様なものの見方や考え方を知ることで学びたい・働きたいといった自己実現への意欲を醸成。"
        sub="（対象：小中高）"
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <ExampleBox
        image={ExampleImage02}
        title="探究学習"
        desc="社会人が実際に取り組んでいる課題解決の事例をもとに、講演会や学習活動への個別フィードバック、プレゼンテーション講座等を行う。課題の設定から情報の収集・整理・分析、表現活動まで、課題解決の基礎を実社会から学ぶことができる。"
        sub="（対象：小中高）"
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <ExampleBox
        image={ExampleImage03}
        title="教科学習"
        desc="プログラミング、グローバル、スポーツなど、学びたい教科や内容に合わせて、ワークショップや講演を実施する。日常の教科学習に対する興味喚起を行いながら、実社会でどのように教科の知識が活かされているのかを体感することができる。"
        sub="（対象：小中高）"
      />
    </Grid>
  </Grid>
);

export default ExampleList;
