import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ManImage from "assets/ManImage.png";
import WomanImage from "assets/WomanImage.png";
import CreateAcountButton from "features/root/components/atoms/schoolHome/CreateAcountButton";

import type React from "react";

const Conclusion: React.FC = () => (
  <Box
    component="section"
    sx={{
      background: "linear-gradient(to right, #FFEFC7, #FFDBAA)",
      padding: "calc(3rem + .25vw) 0 2rem",
      overflow: "hidden"
    }}
  >
    <Box
      sx={{
        position: "relative",
        width: { xs: "90%", md: "80%" },
        maxWidth: "960px",
        margin: "0 auto",
        height: { xs: "calc(300px + 3vw)", sm: "calc(270px + 3vw)", md: "auto" }
      }}
    >
      <div>
        <Typography
          variant="h3"
          align="center"
          sx={{ fontSize: { xs: "1.5rem", md: "1.8rem" } }}
        >
          <Typography component="span" sx={{ display: "inline-block" }}>
            外部人材を活用した授業を
          </Typography>
          <Typography component="span" sx={{ display: "inline-block" }}>
            もっと気軽に。
          </Typography>
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxWidth: "380px",
            margin: "1rem auto"
          }}
        >
          <CreateAcountButton noLabel />
        </Box>
      </div>
      <Box
        component="img"
        src={ManImage}
        alt="man"
        sx={{
          display: { xs: "block", md: "none" },
          position: "absolute",
          bottom: "-102%",
          right: "calc(48vw - 120px)"
        }}
      />
      <Box
        component="img"
        src={WomanImage}
        alt="woman"
        sx={{
          display: { xs: "block", md: "none" },
          position: "absolute",
          bottom: "-102%",
          left: "calc(48vw - 120px)"
        }}
      />
    </Box>
  </Box>
);

export default Conclusion;
