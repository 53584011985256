import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import Leading01 from "assets/Leading01.png";
import Leading02 from "assets/Leading02.png";
import { requestModalOpenAtom } from "store/TeachersShowStore";

import type React from "react";

const LeadingRegisterModal: React.FC = () => {
  const [isOpen, setIsOpen] = useAtom(requestModalOpenAtom);
  const history = useHistory();
  const theme = useTheme();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(false);
    history.push("/schools/signup");
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "960px",
        width: "90%",
        margin: "1rem auto"
      }}
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "relative",
            padding: { xs: ".5rem", sm: "1rem 2rem" }
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "1.2rem",
              right: "1.2rem"
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid
            container
            spacing={2}
            sx={{
              maxWidth: "480px",
              width: { xs: "100%", sm: "90%" },
              margin: "1rem auto"
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                サービスを利用するには
                <br />
                教員アカウントの会員登録をしてください。
              </Typography>
              <Typography align="center" color={theme.palette.sub.main}>
                会員登録すると以下のことができます。
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={Leading01}
                alt="授業を依頼できる"
                sx={{
                  display: "block",
                  margin: "1rem auto",
                  width: "90%"
                }}
              />
              <Typography align="center">授業を依頼できる</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={Leading02}
                alt="非公開の複業先生が見れる"
                sx={{
                  display: "block",
                  margin: "1rem auto",
                  width: "90%"
                }}
              />
              <Typography align="center">非公開の複業先生が見れる</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                disableElevation
                onClick={handleClick}
                sx={{
                  height: "3rem",
                  display: "block",
                  margin: "1rem auto",
                  padding: "0 2rem"
                }}
              >
                新規登録する（無料）
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default LeadingRegisterModal;
