import { atom } from "jotai";

type schoolGradesType = {
  id: number;
  name: string;
}[];

export const schoolGradesAtom = atom<schoolGradesType>([]);
export const ideaRequestAtom = atom("");
export const teachingPlanResponseAtom = atom("");
export const schoolGradeIdAtom = atom<number | "">("");
export const schoolMinutesAtom = atom<number | "">("");
export const schoolMinutesSelectAtom = atom("");

export const isClickableAtom = atom((get) => {
  const schoolGradeId = get(schoolGradeIdAtom);
  const schoolMinutes = get(schoolMinutesAtom);
  const ideaRequest = get(ideaRequestAtom);

  if (
    ideaRequest !== "" &&
    schoolGradeId !== "" &&
    schoolMinutes !== "" &&
    schoolMinutes !== 0
  ) {
    return true;
  }
  return false;
});
