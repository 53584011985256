import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { useState } from "react";

import CustomTabList from "features/root/components/molecules/lpClassi/CustomTabList";

import type React from "react";
import Career from "./Career";
import Global from "./Global";
import InquiryBased from "./InquiryBased";
import STEAM from "./STEAM";

const tabContentsData = [
  {
    id: 1,
    name: "キャリア教育"
  },
  {
    id: 2,
    name: "STEAM教育"
  },
  {
    id: 3,
    name: "探究学習"
  },
  {
    id: 4,
    name: "グローバル教育"
  }
];

const MainContents: React.FC = () => {
  const [tabIndex, setTabIndex] = useState("1");
  const handleChange = (event: React.ChangeEvent<object>, newIndex: string) => {
    setTabIndex(newIndex);
  };

  return (
    <Box
      sx={{
        margin: "3rem auto",
        display: "flex",
        flexDirection: "column",
        rowGap: "3rem",
        maxWidth: "960px"
      }}
    >
      <TabContext value={tabIndex}>
        <CustomTabList
          handleChange={handleChange}
          tabContentsData={tabContentsData}
        />
        <TabPanel value="1" sx={{ padding: 0 }}>
          <Career />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <STEAM />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0 }}>
          <InquiryBased />
        </TabPanel>
        <TabPanel value="4" sx={{ padding: 0 }}>
          <Global />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default MainContents;
