import { Box, useMediaQuery } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import RatingAttentionBlock from "features/schools/components/molecules/show/requests/RatingAttentionBlock";
import ModalTransition from "components/organisms/ModalTransition";
import { noRatingRequestsAtom } from "store/RequestIndexStore";

import type React from "react";
import type { schoolRequestAtomType } from "types/LessonRequestTypes";
import CommonDialog from "components/molecules/CommonDialog";
import { CommonDialogProps } from "types/commonDialogProps";

const RatingsAttentionModal: React.FC<CommonDialogProps> = ({
  isOpen,
  onClose
}) => {
  const noRatingRequests = useAtomValue(noRatingRequestsAtom);

  const theme = useTheme();
  const inXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <CommonDialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={ModalTransition}
      maxWidth="md"
      fullScreen={inXs}
    >
      <Box>
        <Typography
          align="center"
          fontWeight="bold"
          fontSize="1.1rem"
          sx={{
            margin: "1.5rem auto .75rem",
            lineHeight: 1.8
          }}
        >
          授業はどうでしたか？
          <br />
          評価をお願いします！
        </Typography>
        <Typography
          align="center"
          color={theme.palette.sub.main}
          sx={{
            margin: ".75rem auto 1rem"
          }}
        >
          まだ評価していない授業：{noRatingRequests.length}件
        </Typography>
        <div>
          {noRatingRequests.map((request: schoolRequestAtomType) => (
            <RatingAttentionBlock
              key={request.details.id}
              id={request.details.id}
              lessonStartAt={request.details.exact_start_at}
              avatar={request.teacher.image.url}
              organization={request.teacher.organization_name}
              teacherName={request.teacher.name}
            />
          ))}
        </div>
      </Box>
    </CommonDialog>
  );
};

export default RatingsAttentionModal;
