import React from "react";

import { Backdrop, Box, CircularProgress } from "@mui/material";

type FileUploadLoadingProps = {
  isSubmitting: boolean;
  message?: React.ReactNode;
};

const LoadingWithBackDrop: React.FC<FileUploadLoadingProps> = (props) => {
  const { isSubmitting, message } = props;

  return (
    <Backdrop
      open={isSubmitting}
      sx={{
        zIndex: 100000,
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "center"
        }}
      >
        <CircularProgress color="primary" />
        {message && message}
      </Box>
    </Backdrop>
  );
};

export default LoadingWithBackDrop;
