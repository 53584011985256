import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import React from "react";

import { requestObjectAtom } from "store/MeetingSheetStore";

const RequestContents: React.FC = () => {
  const requestObject = useAtomValue(requestObjectAtom);

  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        padding: "1rem",
        border: `solid 1px ${theme.palette.border?.main}`,
        borderRadius: "4px"
      }}
    >
      <Grid item xs={12} sx={{ margin: "1rem auto" }}>
        <Typography variant="h3">依頼内容</Typography>
      </Grid>
      <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main}>学年・生徒数</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
        <Typography>
          {requestObject?.school_grade}（{requestObject?.student_number}人）
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main}>授業時間</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
        <Typography>{requestObject?.school_minutes}分</Typography>
      </Grid>
      <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main}>授業テーマ</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
        <Typography>{requestObject?.tag}</Typography>
      </Grid>
      <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main}>話してほしいこと</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {requestObject?.lesson_content}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main}>実施方法</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
        {requestObject?.is_online ? (
          <Typography>オンライン</Typography>
        ) : (
          <Typography>対面授業</Typography>
        )}
      </Grid>
      {requestObject?.is_online || (
        <>
          <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
            <Typography color={theme.palette.sub.main}>交通費</Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
            <Typography>{requestObject?.travel_cost}</Typography>
          </Grid>
        </>
      )}
      {requestObject?.student_condition !== "" && (
        <>
          <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
            <Typography color={theme.palette.sub.main}>
              生徒の今の様子
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {requestObject?.student_condition}
            </Typography>
          </Grid>
        </>
      )}
      {requestObject?.issue !== "" && (
        <>
          <Grid item xs={12} md={3} sx={{ margin: "1rem auto" }}>
            <Typography color={theme.palette.sub.main}>
              今感じている課題
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ margin: "1rem auto" }}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {requestObject?.issue}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestContents;
