import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import CorrectIcon from "features/teachers/components/atoms/mypage/eLearning/CorrectIcon";
import IncorrectIcon from "features/teachers/components/atoms/mypage/eLearning/IncorrectIcon";
import {
  eLearningAnswersAtom,
  eLearningResponseAtom
} from "store/ELearningStore";

import type React from "react";

type AnswerResultProps = {
  index: number;
};

const AnswerResult: React.FC<AnswerResultProps> = (props) => {
  const { index } = props;
  const [eLearningAnswers] = useAtom(eLearningAnswersAtom);
  const [eLearningResponse] = useAtom(eLearningResponseAtom);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
        margin: "0.5rem"
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={`(${index + 1}) ${eLearningAnswers[index] || ""}`}
        disabled
        sx={{
          display: "inline-block",
          verticalAlign: "middle",
          "& .MuiOutlinedInput-root": {
            color: theme.palette.text.primary
          }
        }}
      />
      {eLearningResponse.judge[index] ? <CorrectIcon /> : <IncorrectIcon />}
    </Box>
  );
};

export default AnswerResult;
