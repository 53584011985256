import AlertMessages from "components/molecules/AlertMessages";
import AuthHeader from "../../features/teachers/components/organisms/auth/AuthHeader";

const AuthLayout = ({ children }: any) => (
  <div id="layout-root">
    <AuthHeader />
    {children}
    <AlertMessages />
  </div>
);

export default AuthLayout;
