import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom, useAtomValue } from "jotai";
import { useLayoutEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import RequestButton from "features/teachers/components/atoms/show/v2/RequestButton";
import LeadingRegisterModal from "features/teachers/components/molecules/show/LeadingRegisterModal";
import PriceBox from "features/teachers/components/molecules/show/v2/PriceBox";
import Meta from "components/organisms/Meta";
import RatingLists from "features/teachers/components/organisms/show/RatingLists";
import BasicInfoBox from "features/teachers/components/organisms/show/v2/BasicInfoBox";
import LessonInfoBox from "features/teachers/components/organisms/show/v2/LessonInfoBox";
import ProfileBox from "features/teachers/components/organisms/show/v2/ProfileBox";
import useGetRequest from "hooks/useGetRequest";
import CommonLayout from "components/layouts/CommonLayout";
import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import {
  teacherInfoAtom,
  ratingAtom,
  teacherLessonRequestsAtom
} from "store/TeachersShowStore";
import { teacherVideosShowAtom } from "store/TeacherVideosStore";

import type React from "react";

import useQuery from "hooks/useQuery";
import useAlertToNew from "hooks/useAlertToNew";

type paramTypes = {
  id: string;
};

const Show: React.FC = () => {
  const history = useHistory();
  useAlertToNew();
  const [teacherInfo, setTeacherInfo] = useAtom(teacherInfoAtom);
  const [, setRating] = useAtom(ratingAtom);
  const [, setTeacherLessonRequests] = useAtom(teacherLessonRequestsAtom);
  const [, setTeacherVideos] = useAtom(teacherVideosShowAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const theme = useTheme();
  const { id } = useParams<paramTypes>();
  const { getQuery } = useQuery();
  const isCandidateDisplay = getQuery("role") === "candidate_display";

  const { getRequest } = useGetRequest({ url: `/api/api/v1/teachers/${id}` });

  useLayoutEffect(() => {
    getRequest({
      onSuccess: (data) => {
        if (data) {
          setTeacherVideos(data.videos);
          setTeacherInfo(data);
          setRating(data.rating);
          setTeacherLessonRequests(data.lesson_requests);
        } else {
          history.push("/not-found");
        }
      }
    });
  }, [
    getRequest,
    history,
    setRating,
    setTeacherInfo,
    setTeacherLessonRequests,
    setTeacherVideos
  ]);

  return (
    <CommonLayout>
      <Meta
        title={`複業先生|${teacherInfo.name}`}
        description={`${teacherInfo.name}|${teacherInfo.introduction}`}
      />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem 0",
          padding: { sx: 0, sm: "inherit" }
        }}
      >
        <BasicInfoBox />
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: "1rem 0"
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem 0",
            padding: { sx: 0, sm: "inherit" }
          }}
        >
          <LessonInfoBox />
          <ProfileBox />
          <RatingLists />
        </Container>
        {!isCandidateDisplay && (
          <>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                padding: "1.2rem 1rem 0"
              }}
            >
              {schoolIsLoggedIn && <PriceBox />}
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                padding: "0 1rem 1.2rem",
                position: isLoggedIn ? "block" : "sticky",
                bottom: 0,
                width: "100%"
              }}
            >
              <RequestButton />
            </Box>
          </>
        )}
      </Box>
      <LeadingRegisterModal />
    </CommonLayout>
  );
};

export default Show;
