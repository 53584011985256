import Close from "@mui/icons-material/Close";
import { Alert, Box, IconButton, Portal, Stack } from "@mui/material";
import { useAlertMessages } from "hooks/useAlertMessages";

const AlertMessages: React.FC = () => {
  const { alertMessages, removeAlertMessage } = useAlertMessages();

  return (
    <Portal>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          top: (theme) => theme.spacing(10),
          width: "90vw",
          maxWidth: "720px",
          margin: "auto",
          zIndex: 1600,
          "@media (max-width:600px)": {
            top: (theme) => theme.spacing(8)
          }
        }}
      >
        <Stack spacing={1}>
          {alertMessages.map((message, index) => (
            <Alert
              key={index}
              severity={message.type}
              elevation={2}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    removeAlertMessage(index);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ alignItems: "center" }}
            >
              {message.message || "入力内容をご確認ください。"}
            </Alert>
          ))}
        </Stack>
      </Box>
    </Portal>
  );
};

export default AlertMessages;
