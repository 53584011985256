export const ContactFormKeysDevelopment = {
  action:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLScmHtTsWORQIqPI5JqDnI4cyduxxrFiCMOyGBu5LSlTEbEVaA/formResponse",
  name: "entry.1261541289",
  nameKana: "entry.1959483738",
  email: "entry.384797295",
  contactType: "entry.1508917917",
  contactContents: "entry.1334228275"
};

export const ContactCorsProxyLocal =
  "https://radiant-sands-22476.herokuapp.com/";

export const ContactFormKeysProduction = {
  action:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLScll4lmnbzdoCdPg3NGPWL546CWyiN3iUTYLUoNj1nNSf1u2g/formResponse",
  name: "entry.1711120843",
  nameKana: "entry.1557642707",
  email: "entry.1914756356",
  contactType: "entry.1988790257",
  contactContents: "entry.396104224"
};

export const ContactCorsProxyNetwork = "/form/";
