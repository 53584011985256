import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useQuery from "hooks/useQuery";

import type React from "react";
import SectionLoading from "components/atoms/SectionLoading";

type SettingsContentsAccordionProps = {
  title: string;
  children: React.ReactNode;
  label: string;
  isLoading?: boolean;
};

const SettingsContentsAccordion: React.FC<SettingsContentsAccordionProps> = (
  props
) => {
  const { title, children, label, isLoading } = props;
  const { getQuery, setQuery, deleteQuery } = useQuery();

  return (
    <div>
      <Accordion
        elevation={0}
        expanded={getQuery("accordion") === label}
        onChange={(event, expanded) => {
          if (expanded) {
            setQuery("accordion", label);
          } else {
            deleteQuery("accordion");
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${label}-settings-content`}
          id={`${label}-settings-header`}
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold"
          }}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? <SectionLoading /> : children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SettingsContentsAccordion;
