import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";

import type React from "react";

const IndicatePrivate: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <LockIcon
        sx={{
          fontSize: "1rem",
          color: theme.palette.sub.main
        }}
      />
      <Typography
        fontSize=".8rem"
        color={theme.palette.sub.main}
        sx={{
          margin: "0 .4rem"
        }}
      >
        自分のみ
      </Typography>
    </Box>
  );
};

export default IndicatePrivate;
