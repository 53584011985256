import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import getStatusLabel from "services/recruitments/GetStatusLabel";

import type React from "react";
import useFormatDate from "hooks/useFormatDate";

import useLimit from "hooks/useLimit";

type RecruitmentListItemProps = {
  id: number;
  title: string;
  deadline: Date;
  status: string;
  candidatesCount: number;
};

const RecruitmentListItem: React.FC<RecruitmentListItemProps> = (props) => {
  const { id, title, deadline, status, candidatesCount } = props;
  const { getFormatDate } = useFormatDate();
  const { limit } = useLimit(deadline, 2);

  return (
    <Link
      href={`mypage/recruitments/${id}`}
      sx={{
        textDecoration: "none",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            flex: { xs: "auto", sm: 13 }
          }}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <Typography fontSize=".9rem">
            締め切り：
            {status === "now_recruting" ? getFormatDate(deadline) : "-----"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flex: 4,
            justifyContent: "center"
          }}
        >
          {getStatusLabel(status)}
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" }, flex: 3 }}>
          <Typography fontWeight="bold" fontSize="1.2rem" align="center">
            {candidatesCount || 0}名
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" }, flex: 3 }}>
          <Typography sx={{ flex: 3 }} align="center">
            {status === "now_recruting" ? `あと${limit}日` : "--"}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default RecruitmentListItem;
