import Box from "@mui/material/Box";
import ImportantInfoBox from "features/schools/components/molecules/recruitments/show/ImportantInfoBox";

import type React from "react";
import SelectedCandidateDisplay from "features/schools/components/molecules/recruitments/show/SelectedCandidateDisplay";
import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

const Step03: React.FC = () => {
  const { watch } = useFormContext();
  const acceptedComment = watch("accepted_comment");
  const deniedComment = watch("denied_comment");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <ImportantInfoBox showDisplay />
      <SelectedCandidateDisplay />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography fontWeight="bold">採用する先生へのメッセージ</Typography>
        <Typography>{acceptedComment}</Typography>
      </Box>
      {deniedComment && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">
            お断りする先生へのメッセージ
          </Typography>
          <Typography>{deniedComment}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Step03;
