// MEMO: データ頂き次第、ここにidと合わせて積んでいく

export const lpClassiFrontData = [
  {
    id: 33,
    lessonTitle: "「キャリア教育って何？どうして学ぶの？」",
    lessonLavel: "導入"
  },
  {
    id: 861,
    lessonTitle: "「科学を学んだ先にあるキャリア」",
    lessonLavel: "教科の先にある仕事"
  },
  {
    id: 851,
    lessonTitle: "「”人事” ”営業” “広報”それってどんな仕事？」",
    lessonLavel: "企業の中にある仕事をしろう"
  },
  {
    id: 895,
    lessonTitle: "「起業するってどんな働き方？」",
    lessonLavel: "自分で仕事をつくる"
  },
  {
    id: 1010,
    lessonTitle: "「芸術を仕事にするということとは？」",
    lessonLavel: "表現活動を仕事にする"
  },
  {
    id: 520,
    lessonTitle: "「専門職・技術職ってどんな仕事？」",
    lessonLavel: "専門性を極めた仕事"
  },
  {
    id: 42,
    lessonTitle: "「日本語教師ってどんな仕事？」",
    lessonLavel: "教育を仕事にする"
  },
  {
    id: 758,
    lessonTitle: "「キャリア授業を振り返って学び・気づきを共有しよう」",
    lessonLavel: "ふりかえり・まとめ"
  },
  {
    id: 784,
    lessonTitle: "「STEAM教育について知ろう！」",
    lessonLavel: "導入"
  },
  {
    id: 928,
    lessonTitle: "「最新科学を学ぼう!」",
    lessonLavel: "Science"
  },
  {
    id: 245,
    lessonTitle: "「生活を激変させる技術革新を学ぼう!」",
    lessonLavel: "Technology"
  },
  {
    id: 953,
    lessonTitle: "「ものづくりの世界を楽しもう！」",
    lessonLavel: "Engineering"
  },
  {
    id: 936,
    lessonTitle: "「アートの視点や感覚を磨こう！」",
    lessonLavel: "Art"
  },
  {
    id: 843,
    lessonTitle: "「論理的思考力を学ぼう！」",
    lessonLavel: "Mathematics"
  },
  {
    id: 490,
    lessonTitle: "「エンジニアってどういう仕事？」",
    lessonLavel: "STEAMの分野をキャリアに活かす"
  },
  {
    id: 1159,
    lessonTitle: "「STEAMの興味・関心分野を深ぼってみよう」",
    lessonLavel: "まとめ・ふりかえり"
  },
  {
    id: 965,
    lessonTitle: "「そもそも探究ってなに？探究学習ことはじめ」",
    lessonLavel: "導入"
  },
  {
    id: 92,
    lessonTitle: "「自分を知ろう、深ぼろう」",
    lessonLavel: "課題の設定"
  },
  {
    id: 1303,
    lessonTitle: "「探究学習のアドバイスをもらおう」",
    lessonLavel: "情報の収集・整理・分析"
  },
  {
    id: 1133,
    lessonTitle: "「ふりかえりの手法を学ぼう」",
    lessonLavel: "ふりかえり・まとめ"
  },
  {
    id: 773,
    lessonTitle: "「多様性ってなに？グローバルな視点になってみよう」",
    lessonLavel: "導入"
  },
  {
    id: 256,
    lessonTitle: "「文化理解~アフリカについて知ろう！~」",
    lessonLavel: "世界について知ろう①"
  },
  {
    id: 381,
    lessonTitle: "「旅を通して世界を知ろう」",
    lessonLavel: "世界について知ろう②"
  },
  {
    id: 515,
    lessonTitle: "「海外大学に進学するには？」",
    lessonLavel: "海外で学ぶ"
  },
  {
    id: 414,
    lessonTitle: "「海外企業ではたらく」",
    lessonLavel: "グローバルなキャリア①"
  },
  {
    id: 500,
    lessonTitle: "「国際協力って何？」",
    lessonLavel: "グローバルなキャリア②"
  },
  {
    id: 125,
    lessonTitle: "「海外で起業すること」",
    lessonLavel: "グローバルなキャリア③"
  },
  {
    id: 1016,
    lessonTitle: "「世界と自分をつなげて考えてみよう」",
    lessonLavel: "まとめ・ふりかえり"
  }
];
